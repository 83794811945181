import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import './DeleteShift.scss';

const DeleteShift = (props) => {
  const {
    _toggleModal, _deleteShift, userCookie, selectedShift,
  } = props;
  let confirmationText = (
    <FormattedMessage
      id="DeleteShift.confirmDeleteLabel"
      defaultMessage="Are you sure you want to delete this shift?"
    />
  );
  let confirmButtonText = (
    <FormattedMessage
      id="DeleteShift.confirmDelete"
      defaultMessage="Yes, Delete This Shift"
    />
  );

  if (selectedShift.name === 'shift_vto') {
    confirmationText = (
      <FormattedMessage
        id="DeleteShift.confirmDeleteLabelVTO"
        defaultMessage="Are you sure you want to delete this VTO offer?"
      />
    );
    confirmButtonText = (
      <FormattedMessage
        id="DeleteShift.confirmDeleteVTO"
        defaultMessage="Yes, Delete VTO offer"
      />
    );
  }

  return (
    <div className="delete-shift">
      <ModalWrapper className="cover-shift">
        <div className="modal-components__container">
          <CloseModal _toggleModal={_toggleModal} dark />
          <h1>
            {confirmationText}
          </h1>
          <div className="modal-components__row">
            <div className="modal-components__col modal-components__col--submit">
              <button
                className="modal-components__submit-btn modal-components__submit-btn--back"
                onClick={() => {
                  _toggleModal(null);
                }}
                type="button"
              >
                <FormattedMessage
                  id="DeleteShift.cancelDelete"
                  defaultMessage="Cancel"
                />
              </button>
              <button
                className="modal-components__submit-btn modal-components__submit-btn--post"
                onClick={() => {
                  _deleteShift(
                    userCookie.location_id,
                    selectedShift.id,
                  );
                }}
                type="button"
              >
                {confirmButtonText}
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

DeleteShift.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
  selectedShift: PropTypes.shape({}),
  userCookie: PropTypes.shape({}).isRequired,
};

DeleteShift.defaultProps = {
  selectedShift: {},
};

export { DeleteShift };
