import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from "classnames";
import Datetime from 'react-datetime';

import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '_components/ModalComponents/ModalWrapper';
import { GetUserRequestTimeOffBalance, DeleteRequestTimeOff } from '_helpers/api-calls/rto-calls';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import { deleteFreePost, deleteComment, } from '_helpers/api-calls/comments';
import { formatDateforApi, getDefaultTimezone} from '_helpers/date-time';
import { setRequestTimeOff, removeRequestTimeOff} from "_helpers/app-state";
import { rgba } from 'polished';
import { ModalTypes } from '_constants';

import './Comment.scss';
import styled from './styled';

class DeleteComment extends React.Component {
	constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      paidTimeOff: {
        id: 'RequestedTimeOff.paidTimeOff',
        defaultMessage: 'Paid Time Off',
      },
    });

    this.state = {
      deleting: false,
    };
    
    this._isMounted = false;

  }

  componentDidMount() {
    const { intl: { formatMessage }, locationSettings, userPrivileges, commentId, } = this.props;

    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const { intl: { formatMessage }, userPrivileges, } = this.props;

  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  handleGoNext = () => {
    if(this._isMounted) {
       this.setState({confirmDelete: true});
    }
  }


  handleGoBackClick = () => {
    const { _toggleModal, intl, userCookie, locale, handleShowDeleteWin, commentId,} = this.props;
    //_toggleModal();
    handleShowDeleteWin(false, 0);
  }

  handleDelete = () => {
    const { _toggleModal, intl, userCookie, locale, commentId, handleShowDeleteWin, } = this.props;
    let msg = '';
    this.setState({deleting: true});
    deleteFreePost(commentId).then((response) => {
      msg = `Comment ${commentId} has been deleted successfully.`;
      console.log(msg);
      this.setState({deleting: false});
      //_toggleModal();
      handleShowDeleteWin(false, commentId);
    }).catch((error) => {
      msg = `Failed to delete free post ${commentId}. Error: ${error}.`;
      console.log(msg);
      deleteComment(commentId).then((response1) => {
        msg = `Comment ${commentId} has been deleted successfully.`;
        console.log(msg);
        this.setState({deleting: false});
        //_toggleModal();
        handleShowDeleteWin(false, commentId);
      }).catch((error1) => {
        msg = `Failed to delete comment ${commentId}. Error: ${error1}.`;
        console.log(msg);
        this.setState({deleting: false});
        //_toggleModal();
        handleShowDeleteWin(false, -1);
      });
    });
  }

  handleClose = () => {
    const { _toggleModal, intl, userCookie, locale, handleShowDeleteWin, commentId,} = this.props;
    handleShowDeleteWin(false, 0);
  }

  render () {
  	const { _toggleModal, intl, userCookie, locale, } = this.props;
    const { deleting, } = this.state;

    let winTop = `${(window.innerHeight - 250)/2 - 125}px`;
    let winLeft = `${(window.innerWidth - 600)/2 - 300}px`;
    let winW = '600px';//`50%`;
    let winH = `250px`; //94.9% viewport height

    const deleteNo = (<FormattedMessage
                id="DeleteComment.no"
                defaultMessage="No"
              />);
    const deleteYes = (<FormattedMessage
                id="DeleteComment.yes"
                defaultMessage="Yes, delete"
              />);
    /*
    return (
       <div className="delete-comment" top={winTop} left={winLeft} width={winW} height={winH}>
        <ModalWrapper>
          <CloseModal _toggleModal={_toggleModal} dark />
          <h1 className="delete-comment__title">
            <FormattedMessage
              id="DeleteComment.delete"
              defaultMessage="Delete this comment?"
            />
          </h1>
          <div className="modal-components__row">
          <div className="modal-components__col modal-components__col--submit">
            <button
              className="modal-components__submit-btn modal-components__submit-btn--back"
              onClick={this.handleGoBackClick}
              type="button"
            >
              {deleteNo}
            </button>
            <button
              className="modal-components__submit-btn modal-components__submit-btn--post"
              disabled={deleting}
              onClick={this.handleDelete}
              type="button"
            >
              {deleteYes}
            </button>
          </div>
        </div>
        </ModalWrapper>
       </div>
    ); 
    */
    return (
      <styled.DeleteWinContainer top={winTop} left={winLeft} width={winW} height={winH}>
        <styled.DeleteWinCloseButton onClick={this.handleClose}>
        </styled.DeleteWinCloseButton>
        <h1 className="delete-comment__title">
          <FormattedMessage
            id="DeleteComment.delete"
            defaultMessage="Delete this comment?"
          />
        </h1>
        <div className="modal-components__row">
          <div className="modal-components__col modal-components__col--submit">
            <button
              className="modal-components__submit-btn modal-components__submit-btn--back"
              onClick={this.handleGoBackClick}
              type="button"
            >
              {deleteNo}
            </button>
            <button
              className="modal-components__submit-btn modal-components__submit-btn--post"
              disabled={deleting}
              onClick={this.handleDelete}
              type="button"
            >
              {deleteYes}
            </button>
          </div>
        </div>
      </styled.DeleteWinContainer>
    );
  }
}

DeleteComment.propTypes = {
  //userCookie: PropTypes.shape({}).isRequired,
  //costCenterData: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (DeleteComment);

