import { BASE_URL } from '_constants/index';
import { getLocaleCookie } from '_helpers/cookies';
import { axiosInstance } from '../api-calls';

export const signInAttempt = (phone_number) => {
  const charactersToStrip = /[-()\s+]/g;
  const strippedPhoneNumber = phone_number.replace(charactersToStrip, '');

  return axiosInstance.request({
    url: `https://${BASE_URL}/api/code_auth/${strippedPhoneNumber}`,
    method: 'GET',
  });
};

export const SignInConfirm = (phoneNumber, pin) => {
  const charactersToStrip = /[-()\s+]/g;
  const strippedPhoneNumber = phoneNumber.replace(charactersToStrip, '');
  const api_user = {
    phone_number: strippedPhoneNumber,
    sign_in_code: pin,
  };

  return axiosInstance.request({
    url: '/api/users/sign_in',
    method: 'POST',
    data: {
      api_user,
    },
  });
};

export const signInWithEmail = (email, password) => axiosInstance.request({
  method: 'POST',
  url: `https://${BASE_URL}/api/users/sign_in`,
  data: {
    api_user: {
      email,
      password,
    },
  },
});

export const signUpWithEmail = ({
  first_name,
  last_name,
  email,
  password,
  password_confirmation,
  locale,
}) => axiosInstance.request({
  method: 'POST',
  url: '/api/users',
  headers: { locale },
  data: {
    api_user: {
      provider: 'email',
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      locale,
      email_host: window.location.host,
      signup_from: 'web_app',
    },
  },
});

export const signUpConfirmation = confirmation_token => axiosInstance.request({
  url: `https://${BASE_URL}/api/users/confirmation`,
  params: {
    confirmation_token,
  },
});

/**
 * Makes a request to reset the user's password.
 * If successful, this will send an email to the user
 *
 * 201 - success - response object will not include anything in it
 * 422 - unprocessable entity. Check response object for error messages
 * @param { String } email - the email of the user
 *
 * @return { Promise }
 */
export const StartPasswordReset = email => axiosInstance.request({
  method: 'POST',
  url: `https://${BASE_URL}/api/users/password`,
  headers: {
    locale: getLocaleCookie(),
  },
  data: {
    api_user: {
      email,
      email_host: window.location.host,
    },
  },
});

export const ConfirmPasswordReset = (
  password,
  password_confirmation,
  reset_password_token,
) => axiosInstance.request({
  method: 'PUT',
  url: '/api/users/password',
  data: {
    api_user: {
      password,
      password_confirmation,
      reset_password_token,
    },
  },
});

/**
 * Renew SSO session
 * Takes an SSO authentication code and sends it to the backend,
 * receiving a success or failure
 */
export const RenewSSOsession = (company, code) => axiosInstance.request({
  url: `https://${BASE_URL}/api/customer/user_role/sso_sessions/renew?sso_provider_name=${company}&code=${code}`,
  method: 'POST',
});
