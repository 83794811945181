import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import {
  StatusIcon,
  StatusText,
} from "_components/GeneralComponents/ShiftStatus";
import { getShiftSegments } from "_helpers/api-calls/shift-calls";
import { updateSelectedShiftSegments } from "_helpers/app-state";
import "./ConfirmShiftTemplate.scss";

import {
  DATE_FORMAT,
  TIME_FORMAT,
  createFullName,
  isNextDay,
} from "_helpers/formatting";

import { shiftStatuses, shiftOwner, shiftStatus, shiftNames } from "_constants";
import ShiftSegments from "../_components/ShiftSegments";

class ConfirmShiftTemplate extends React.Component {
  constructor(props) {
    super(props);

    const { selectedShift } = this.props;
    this.times = [moment(selectedShift.start_at), moment(selectedShift.end_at)];
    this.state = {
      channelMembers: 0,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { selectedShift, shifts, _setAppState, channel_id, channels, } = this.props;
    this._isMounted = true;
    if (selectedShift.segment_count > 0 && !selectedShift.segments) {
      // Load segments
      getShiftSegments(selectedShift.id).then(({ data }) => {
        updateSelectedShiftSegments(data.segments, shifts, _setAppState);
      });
    }
    let twoTypeChannels = [];
    let allTypeChannels = [];
    let members = 0;
    if(channels && channel_id) {
      const twoTypeChannels = channels.location_feed.concat(channels.custom_feed);
      const allTypeChannels = twoTypeChannels.concat(channels.region_feed);
      const idx = allTypeChannels.findIndex(
        (channel) => channel.id === channel_id
      );
      if(idx >= 0) {
        members = allTypeChannels[idx].member_count;
        this.setState({channelMembers: members});
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  _renderName = () => {
    const { selectedShift, userCookie } = this.props;
    const owner = shiftOwner(selectedShift, userCookie);
    let fullName = createFullName(selectedShift.owner);

    if (owner === shiftStatuses.YOUR_SHIFT) {
      fullName = createFullName(userCookie);

      return (
        <>
          <p className="modal-components__label">
            <FormattedMessage
              id="ConfirmShiftTemplate.fullName"
              defaultMessage="My Full Name"
            />
          </p>
          <p className="modal-components__confirm-details">{fullName}</p>
        </>
      );
    }

    if (selectedShift.name === shiftNames.SHIFT_VTO) {
      return (
        <>
          <p className="modal-components__label">
            <FormattedMessage
              id="ConfirmShiftTemplate.postedBy"
              defaultMessage="Posted by"
            />
          </p>
          <p className="modal-components__confirm-details">{fullName}</p>
        </>
      );
    }

    return (
      <>
        <p className="modal-components__label">
          <FormattedMessage
            id="ConfirmShiftTemplate.coveringFor"
            defaultMessage="Covering For"
          />
        </p>
        <p className="modal-components__confirm-details">{fullName}</p>
      </>
    );
  };

  _renderReach = () => {
    const { selectedShift, userCookie } = this.props;
    const owner = shiftOwner(selectedShift, userCookie);

    if (
      (owner === shiftStatuses.YOUR_SHIFT ||
        owner === shiftStatuses.COVERING_SHIFT) &&
      selectedShift.name !== "shift_inventory"
    ) {
      return (
        <div className="modal-components__row">
          <div className="modal-components__col">
            <p className="modal-components__label">
              <FormattedMessage
                id="ConfirmShiftTemplate.postReact"
                defaultMessage="This post will reach"
              />
            </p>
            <p className="modal-components__confirm-details">
              {selectedShift.channel_name}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  _renderAddress = () => {
    const { selectedShift, locations } = this.props;

    const currentLocation = locations.filter(
      (l) => l.id === selectedShift.location_id
    );

    if (currentLocation.length) {
      return (
        <>
          <p className="modal-components__label">
            <FormattedMessage
              id="ConfirmShiftTemplate.location"
              defaultMessage="Location"
            />
          </p>
          <p className="modal-components__confirm-details modal-components__confirm-details--date-time">
            {`${currentLocation[0].location_name}, ${currentLocation[0].formatted_address}`}
          </p>
        </>
      );
    }

    return "";
  };

  _renderShiftContent = () => {
    const { selectedShift, userCookie } = this.props;
    const status = shiftStatus(selectedShift, userCookie);
    const owner = shiftOwner(selectedShift, userCookie);

    if (
      (status === shiftStatuses.AVAILABLE ||
        owner === shiftStatuses.YOUR_SHIFT) &&
      selectedShift.name !== "shift_inventory"
    ) {
      return (
        <div className="modal-components__row">
          <div className="modal-components__col">
            <p className="modal-components__label">
              <FormattedMessage
                id="ConfirmShiftTemplate.shiftDetails"
                defaultMessage="Shift Details"
              />
            </p>
            <p className="modal-components__confirm-details">
              {selectedShift.content}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  _renderStatus = () => {
    const { selectedShift, userCookie } = this.props;
    const status = shiftStatus(selectedShift, userCookie);
    const statusText =
      status === shiftStatuses.AVAILABLE ? (
        <FormattedMessage
          id="StatusText.available"
          defaultMessage="Available"
        />
      ) : (
        <StatusText selectedShift={selectedShift} userCookie={userCookie} />
      );

    return (
      <div className="modal-components__col modal-components__col--flex">
        <p className="modal-components__label">
          <FormattedMessage
            id="ConfirmShiftTemplate.shiftStatus"
            defaultMessage="Status"
          />
        </p>
        <p className="modal-components__confirm-details modal-components__confirm-details--date-time">
          <StatusIcon
            className="confirm-shift-template__status-icon"
            selectedShift={selectedShift}
            userCookie={userCookie}
          />
          {statusText}
        </p>
      </div>
    );
  };

   _renderJobTitle = () => {
    const { selectedShift, userCookie, jobTitle } = this.props;

    return (
      <div className="modal-components__col modal-components__col--flex">
        <p className="modal-components__label">
          <FormattedMessage
            id="ConfirmShiftTemplate.jobTitle"
            defaultMessage="Job Title"
          />
        </p>
        <p className="modal-components__confirm-details modal-components__confirm-details--date-time">
          {jobTitle}
        </p>
      </div>
    );
  };

  render() {
    const { selectedShift } = this.props;

    return (
      <>
        <div className="modal-components__row">
          <div className="modal-components__col modal-components__col--flex">
            {this._renderName()}
          </div>
          <div className="modal-components__col modal-components__col--flex">
            <p className="modal-components__label">
              <FormattedMessage
                id="ConfirmShiftTemplate.dateTime"
                defaultMessage="Date and Time"
              />
            </p>
            <p className="modal-components__confirm-details modal-components__confirm-details--date-time">
              <strong>
                <FormattedMessage
                  id="ConfirmShiftTemplate.dateLabel"
                  defaultMessage="Date:"
                />
                &nbsp;
              </strong>
              {moment(this.times[0]).format(DATE_FORMAT)}
            </p>
            <p className="modal-components__confirm-details modal-components__confirm-details--date-time">
              <strong>
                <FormattedMessage
                  id="ConfirmShiftTemplate.startTimeLabel"
                  defaultMessage="Start Time:"
                />
                &nbsp;
              </strong>
              {moment(this.times[0]).format(TIME_FORMAT)}
              &nbsp;&nbsp;&nbsp;
              <strong>
                <FormattedMessage
                  id="ConfirmShiftTemplate.endTimeLabel"
                  defaultMessage="End Time:"
                />
                &nbsp;
              </strong>{" "}
              {moment(this.times[1]).format(TIME_FORMAT)}{" "}
              {isNextDay(this.times) ? (
                <FormattedMessage
                  id="ConfirmShiftTemplate.nextDayLabel"
                  defaultMessage="(Next Day)"
                />
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="modal-components__col modal-components__col--flex">
            {this._renderAddress()}
          </div>
          {this._renderJobTitle()}
          {this._renderStatus()}
        </div>
        {this._renderShiftContent()}
        {this._renderReach()}
        {selectedShift.segments ? (
          <ShiftSegments segments={selectedShift.segments} />
        ) : null}
      </>
    );
  }
}

ConfirmShiftTemplate.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  selectedShift: PropTypes.shape({}).isRequired,
  userCookie: PropTypes.shape({}).isRequired,
  shifts: PropTypes.shape([]).isRequired,
};

export { ConfirmShiftTemplate };
