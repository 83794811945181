import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import * as styled from './styled';


class TimeItem extends React.Component {
	constructor(props){
    super(props);
    this.state = {
                 	
                  };

   
    
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  handleMenuItemClick = (event) => {
  	const {item, handleMenuItemClick} = this.props;
  	handleMenuItemClick(item);
  	event.preventDefault();
  }

  render() {
    const {item, intl} = this.props;
    const {hide} = this.state;

    return(
    		<styled.MenuItem onClick={this.handleMenuItemClick}>
	        <styled.Content>
	        	{item}
	        </styled.Content>
        </styled.MenuItem>
    );
  }
}

TimeItem.propTypes={
  
}

export default injectIntl(TimeItem);