import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import HoneyPotInput from "_components/GeneralComponents/HoneyPotInput/HoneyPotInput";
import messages from "./mesages";

class SignupComponenti18n extends React.Component {
  componentDidMount() {
    this.firstNameInput.focus();
  }

  _renderConditionalText = (text, condition) => {
    if (condition) {
      return text;
    }

    return null;
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      password,
      passwordConfirmation,
      _submitForm,
      _nameChange,
      _emailChange,
      _passwordChange,
      _passwordConfirmationChange,
      _handleHoneyPotChange,
      accountCreationErrorText,
      _routeToLogin,
      intl: { formatMessage },
    } = this.props;

    const firstNameConditionalText = (
      <FormattedMessage
        id="SignupComponent.firstNameError"
        defaultMessage="Please enter your first name."
      />
    );

    const lastNameConditionalText = (
      <FormattedMessage
        id="SignupComponent.lastNameError"
        defaultMessage="Please enter your last name."
      />
    );

    const emailConditionalText = (
      <FormattedMessage
        id="SignupComponent.emailError"
        defaultMessage="Please enter a valid email address."
      />
    );

    const passwordConditionalText = (
      <FormattedMessage
        id="SignupComponent.passwordError"
        defaultMessage="Please enter a valid password. Your password must contain 1 uppercase, 1 lowercase and 1 special character and must be at least 8 characters long."
      />
    );

    const passwordConfirmationConditionalText = (
      <FormattedMessage
        id="SignupComponent.passwordMatchError"
        defaultMessage="Your passwords must match."
      />
    );

    return (
      <>
        <article className="login">
          <h2 className="login__header">
            <FormattedMessage
              id="SignupComponent.header"
              defaultMessage="Create your Account"
            />
          </h2>

          <form className="login__form" onSubmit={_submitForm}>
            <section className="login__form-group">
              <input
                ref={(input) => {
                  this.firstNameInput = input;
                }}
                onChange={_nameChange}
                name="first_name"
                placeholder={formatMessage(messages.firstNameLabel)}
                className="login__input-first-name"
              />
              <p className="login__form-error-text show">
                {this._renderConditionalText(
                  firstNameConditionalText,
                  first_name.showValidity
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_nameChange}
                name="last_name"
                placeholder={formatMessage(messages.lastNameLabel)}
                className="login__input-last-name"
              />
              <p className="login__form-error-text show">
                {this._renderConditionalText(
                  lastNameConditionalText,
                  last_name.showValidity
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_emailChange}
                name="email"
                placeholder={formatMessage(messages.emailLabel)}
                className="login__input-email"
              />
              <p className="login__form-error-text show">
                {this._renderConditionalText(
                  emailConditionalText,
                  email.showValidity
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_passwordChange}
                name="password"
                type="password"
                placeholder={formatMessage(messages.passwordLabel)}
                className="login__input-password"
              />
              <p className="login__form-error-text show">
                {this._renderConditionalText(
                  passwordConditionalText,
                  password.showValidity
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_passwordConfirmationChange}
                name="passwordConfirmation"
                type="password"
                placeholder={formatMessage(messages.confirmPasswordLabel)}
                className="login__input-password-confirm"
              />
              <HoneyPotInput handleChange={_handleHoneyPotChange} />
              <p className="login__form-error-text show">
                {this._renderConditionalText(
                  passwordConfirmationConditionalText,
                  passwordConfirmation.showValidity
                )}
              </p>
            </section>

            <section className="login__form-group">
              <p className="login__form-error-text show">
                {accountCreationErrorText}
              </p>
            </section>

            <section className="login__form-action">
              <button
                className="login__form-submit button button-primary"
                type="submit"
              >
                <FormattedMessage
                  id="SignupComponent.createAccountButton"
                  defaultMessage="Create an Account"
                />
              </button>
            </section>
          </form>

          <footer className="login__footer">
            <p>
              <FormattedMessage
                id="SignupComponent.existingAccountLabel"
                defaultMessage="Already have an account?"
              />
              <a className="login__link" href="" onClick={_routeToLogin}>
                <FormattedMessage
                  id="SignupComponent.loginLink"
                  defaultMessage="Log In"
                />
              </a>
            </p>
          </footer>
        </article>
      </>
    );
  }
}

const SignupComponent = injectIntl(SignupComponenti18n);
export { SignupComponent };

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
SignupComponent.propTypes = {
  _routeToLogin: PropTypes.func.isRequired,
  _submitForm: PropTypes.func.isRequired,
  _nameChange: PropTypes.func.isRequired,
  _emailChange: PropTypes.func.isRequired,
  _passwordChange: PropTypes.func.isRequired,
  _passwordConfirmationChange: PropTypes.func.isRequired,
  _handleHoneyPotChange: PropTypes.func.isRequired,
  email: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  password: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  first_name: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  last_name: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  passwordConfirmation: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  intl: intlShape.isRequired,
};

SignupComponent.defaultProps = {
  email: {
    value: "",
    showValidity: false,
  },
  password: {
    value: "",
    showValidity: false,
  },
  first_name: {
    value: "",
    showValidity: false,
  },
  last_name: {
    value: "",
    showValidity: false,
  },
  passwordConfirmation: {
    value: "",
    showValidity: false,
  },
};
