import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger'); // eslint-disable-line global-require

  middleware.push(logger);
}

export default middleware;
