import { publicRoutes } from 'App/App';


const checkForPublicRouteMatch = path => publicRoutes.some((element) => {
  if (element.path === path) {
    return true;
  }
  return false;
});

export { checkForPublicRouteMatch };
