import styled from 'styled-components';

const dimensions = '46px';

const ThumbWrap = styled.div`
  width: ${dimensions};
  height: ${dimensions};
  margin-right: 1rem;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 30px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-position: center;
  background-image: url('/icons/trash-light.svg');
  background-size: 100%; /* 100 To fill the dimensions of the button */
  background-repeat: no-repeat;

   &:hover {
    //border: 1px solid blue;
  }
`;

const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  //background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  //rgba(198,198,201,255)
`;

export default {
  ThumbWrap,
  DeleteButton,
  Overlay,
};
