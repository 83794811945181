import styled from 'styled-components';
import { rem } from 'polished';
import { rgba } from 'polished';


const DateTimeBtn = styled.button`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.border};

  &:disabled {
    cursor: default;
  }
`;

const DateTimeSection = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
  font-size: 12px;
  min-width: 150px;
  text-align: left;
  padding: 0.66rem 1rem;
  height: 30px;

  &:last-child {
    border-left: 1px solid ${({ theme }) => theme.palette.border};
  }

  .icon-calendar, .icon-clock {
    font-size: 15px;
    color: ${({ theme }) => theme.palette.border};
  }
`;

const DateTimeText = styled.span`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
  font-size: 1.14rem;

  ${DateTimeBtn}:disabled & {
    color: ${({ theme }) => theme.palette.label};
  }
`;
const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 20px;
  border-bottom: 1px solid lightgrey;

   &:hover {
    background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    background-image: url('/icons/check-mark.svg');
    background-repeat: no-repeat;
    //background-position: right;
    //background-position: 90% 50%;
    background-position: 95% 50%;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }

  //border: 1px solid green;
`;
const Content = styled.label `
  //color: ${textColor};
  font-size: 14px;
`;
const WinContainer = styled.section`
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: 152px;
  height: 300px;
  position: absolute;
  background-color: white;
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 3;
  overflow-y: scroll;
  //border: 1px solid red;
`;

export default {
  DateTimeBtn,
  DateTimeSection,
  DateTimeText,
  MenuItem,
  Content,
  WinContainer,
};
