import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';
import rootSaga from './sagas';
import middleware, { sagaMiddleware } from './middleware';

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

// Start sagas
sagaMiddleware.run(rootSaga);

// configure subscribing localstorage to store

export default store;
