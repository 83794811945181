import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DataNames } from '../../constants';
import './RadioButton.scss';

class RadioButton extends React.PureComponent {
  render() {
    const {
      checked, label, id, children, onChange,
    } = this.props;
    const circleClass = classNames({
      'radio-button__btn__circle': true,
      'radio-button__btn__circle--active': checked,
    });
    const checkClass = classNames({
      'radio-button__btn__check': true,
      'radio-button__btn__check--active': checked,
    });
    const reachData = {
      reachValue: label,
      channel_id: id,
    };

    return (
      <div
        className="radio-button"
        onClick={() => {
          onChange(DataNames.REACH, reachData);
        }}
        onKeyPress={() => {}}
        value={label}
        role="radio"
        aria-checked={checked}
        tabIndex={0}
      >
        <div className="radio-button__btn">
          <div className={circleClass} />
          <div className={checkClass} />
        </div>
        <div className="radio-button__label">
          {children || label}
        </div>
      </div>
    );
  }
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  label: '',
};

export default RadioButton;
