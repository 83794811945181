import React from 'react';
import PropTypes from 'prop-types';

import { shiftStatuses, shiftStatus, shiftNames } from '_constants';
import coveredIcon from './assets/covered-icon.svg';
import chatIcon from './assets/chat-icon.svg';
import pendingIcon from './assets/pending-icon.svg';
import rejectedIcon from './assets/rejected-icon.svg';
import './StatusIcon.scss';

const StatusIcon = ({ selectedShift, userCookie, className, applicants }) => {
  const status = shiftStatus(selectedShift, userCookie, applicants);
  let icon = '';

  switch (status) {
    case shiftStatuses.AVAILABLE:
      icon = chatIcon;
      break;

    case shiftStatuses.YOUR_UNPOSTED:
    case shiftStatuses.YOUR_SHIFT:
    case shiftStatuses.YOUR_POSTED:
      icon = chatIcon;
      break;

    case shiftStatuses.YOUR_APPROVED:
    case shiftStatuses.VTO_APPROVED:
      icon = coveredIcon;
      break;

    case shiftStatuses.YOUR_PENDING:
    case shiftStatuses.VTO_PENDING:
      icon = pendingIcon;
      break;

    case shiftStatuses.YOUR_REJECTED:
    case shiftStatuses.COVERING_REJECTED:
    case shiftStatuses.VTO_REJECTED:
      icon = rejectedIcon;
      break;

    case shiftStatuses.COVERING_APPROVED:
    case shiftStatuses.YOUR_COVERED:
      icon = coveredIcon;
      break;

    case shiftStatuses.COVERING_PENDING:
      icon = pendingIcon;
      break;

    default:
      break;
  }

  if (selectedShift.name === shiftNames.SHIFT_INVENTORY) {
    return null;
  }

  return (
    <img
      className={`status-icon ${className}`}
      src={icon}
      alt={`Icon ${selectedShift.trade_status}`}
    />
  );
};

StatusIcon.propTypes = {
  className: PropTypes.string,
  userCookie: PropTypes.object.isRequired,
  selectedShift: PropTypes.object.isRequired,
};

StatusIcon.defaultProps = {
  className: '',
};

export default StatusIcon;
