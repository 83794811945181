import { defineMessages } from 'react-intl';

const messages = defineMessages({
  emailError: {
    id: 'SignupPage.emailError',
    defaultMessage: 'That email has already been taken. Please try another or try logging in instead.',
  },
  mobilePinError: {
    id: 'SignupPage.mobilePinError',
    defaultMessage: 'Oops, that PIN doesn\'t match our records.Please try again.',
  },
  serverError: {
    id: 'SignupPage.serverError',
    defaultMessage: 'Something went wrong while processing the information. Please try again.',
  },
  backButtonText: {
    id: 'SignupPage.backButtonText',
    defaultMessage: 'Wrong account?',
  },
});

export default messages;
