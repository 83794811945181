import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from "classnames";

import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import { RadioButton, DateTimeButton, DateTimePicker } from "../PostShift/components";
import { DataNames } from '../PostShift/constants';
import { callOffShift } from '_helpers/api-calls/shift-calls';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import {
  toggleModal,
  removeShift,
  updateSelectedShift,
  addShift,
  setCalledOffShift,
  setCalledOffShiftNew,
  updateCanceledShift,
} from "_helpers/app-state";



import './CallOffShift.scss';
import styled from './styled';



class CallOffShiftI18n extends React.Component {
	constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.state = {
        timer: null,
        callOffReason1: null,
        callOffReason2: null,
        selectedReason1: '',
        selectedReason2: '',
        channel_id: '',
        confirmCallOff: false,
        previewDone: false,
        jobTitle: '',
    };

    this.handleClose = this.handleClose.bind(this);

    this._isMounted = false;
    this.winTop = '100px';
    this.winLeft = '100px';
    this.winWidth = '700px';
    this.winHeight = '473px';
    
    this.messages = defineMessages({
      header: {
        id: 'CallOffShfit.callOffShift',
        defaultMessage: 'Call Off Shift',
      },
      
      preview: {
        id: 'CallOffShfit.preview',
        defaultMessage: 'Preview',
      },
      submit: {
        id: 'CallOffShift.submit',
        defaultMessage: 'Submit',
      },
      
    });
    
  }

  componentDidMount(){
    const {costCenterData, } = this.props;
    const {
      _toggleModal, channels, userCookie, locale, selectedShift,
    } = this.props;

    this._isMounted = true;
    if(selectedShift) {
      if(selectedShift.primary_job) {
        if(this._isMounted) {
          this.setState({jobTitle: selectedShift.primary_job});
        }
      }else {
        getUserInfoNonAdmin(selectedShift.owner.id, selectedShift.location_id).then((response) => {
          if(this._isMounted) {
            this.setState({jobTitle: response.data.user.current_position});
          }
        }).catch((error) =>{
           const msg = `CallOffShift.componentDidUpdate: call getUserInfoNonAdmin failed. error: ${error}`;
           console.log(msg);
        });
      }
    }

    let myTimer = setInterval(this.tick, 100000); //unit: ms, 100000 = 100 seconds
    this.setState({timer: myTimer});

    if(costCenterData){
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_1'){
          this.setState({callOffReason1: costCenterData.tag_groups[i].tags})
        }
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_2'){
          this.setState({callOffReason2: costCenterData.tag_groups[i].tags})
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {costCenterData} = this.props;

    if(costCenterData){
      //console.log('here');
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  handleClose = (event) => {
    //because this button is also on the shift card, both the button and the card get clicked. 
    //The stopPropagation() method allows you to prevent propagation of the current event.
    event.stopPropagation();
    //callback function from parent
    this.props.closeWin && this.props.closeWin(event);
    event.preventDefault();
  };

  _updateState1 = (action, data) => {
    
    switch (action) {
      case DataNames.REACH:
        this.setState({
          selectedReason1: data.reachValue,
          channel_id: data.channel_id,
        });
        break;
      case DataNames.DATE:
        this.setState({ date: data, dateString: createDateString(data) });
        break;
      case DataNames.TIMES:
        this.setState({
          times: data,
          timeRangeString: createTimeRangeString(data),
        });
        break;
      default:
        break;
    }
    //console.log(this.state.selectedReason1);
  };

  _updateState2 = (action, data) => {
    
    switch (action) {
      case DataNames.REACH:
        this.setState({
          selectedReason2: data.reachValue,
          channel_id: data.channel_id,
        });
        break;
      case DataNames.DATE:
        this.setState({ date: data, dateString: createDateString(data) });
        break;
      case DataNames.TIMES:
        this.setState({
          times: data,
          timeRangeString: createTimeRangeString(data),
        });
        break;
      default:
        break;
    }
    //console.log(this.state.selectedReason2);
  };

   _renderReason1 = () => {
    const { channels, _updateState } = this.props;
    const {callOffReason1, selectedReason1} = this.state;

    const reasons = callOffReason1 && callOffReason1.map((reason) => (
      <div
        key={reason.id}
        className="post-shift__radio-item post-shift__radio-item--groups"
      >
        <RadioButton
          checked={selectedReason1 === reason.tag_content}
          id={reason.id}
          label={reason.tag_content}
          onChange={this._updateState1}
        />
      </div>
    ));

    if (reasons) {
      return reasons;
    }

    return (
      <div className="post-shift__radio-item post-shift__radio-item--groups">
        <div className="post-shift__team-list__member">
          <FormattedMessage id="CallOffShift.noReason" defaultMessage="Reason is not available" />
        </div>
      </div>
    );
  };

   _renderReason2 = () => {
    const { channels,  _updateState } = this.props;
    const {callOffReason2, selectedReason2} = this.state;

    const reasons = callOffReason2 && callOffReason2.map((reason) => (
      <div
        key={reason.id}
        className="post-shift__radio-item post-shift__radio-item--groups"
      >
        <RadioButton
          checked={selectedReason2 === reason.tag_content}
          id={reason.id}
          label={reason.tag_content}
          onChange={this._updateState2}
        />
      </div>
    ));

    if (reasons) {
      return reasons;
    }

    return (
      <div className="post-shift__radio-item post-shift__radio-item--groups">
        <div className="post-shift__team-list__member">
          <FormattedMessage id="CallOffShift.noReason" defaultMessage="Reason is not available" />
        </div>
      </div>
    );
  };

  _canProgress = () => {
    const {selectedReason1, selectedReason2, callOffReason1, callOffReason2, } = this.state;
    let canProgress = false;
    if(callOffReason2) {
      canProgress = selectedReason1 && selectedReason2; 
    }else {
      canProgress = selectedReason1
    }
    return canProgress;
  };

  _submitCallOffShift = () =>{
    const {selectedShift, _toggleModal, _handleCallOffShift, shifts, _setAppState} = this.props;
    const {selectedReason1, selectedReason2, callOffReason1, callOffReason2, } = this.state;

    let reason1_tag = '';
    let reason2_tag = '';

    //find tag keys for reason 1 and reason 2
    const idx = callOffReason1.findIndex(
        (reason) => reason.tag_content === selectedReason1
    );
    if(idx >=0){
      reason1_tag = callOffReason1[idx].tag_key;
    }
    if(callOffReason2) {
      const idx2 = callOffReason2.findIndex(
          (reason) => reason.tag_content === selectedReason2
      );
      if(idx2 >=0){
        reason2_tag = callOffReason2[idx2].tag_key;
      }
    }


    if(callOffReason2) {
      if(reason1_tag && reason2_tag) {
        callOffShift({
          shift_id: selectedShift.id,
          call_off_reasons_1_tag_key: reason1_tag,
          call_off_reasons_2_tag_key: reason2_tag,
        }).then((response) => {
          const data = response.data;
          const id = data.schedule_element.id;
          const location = data.schedule_element.location_id;
          const owner = `${data.schedule_element.owner.first_name} ${data.schedule_element.owner.last_name}`;
          const msg = `Call off shift: id: ${id}, location: ${location}, owner: ${owner}`;
          //console.log(msg);

          //setCalledOffShift(selectedShift, shifts, reason1_tag, reason2_tag, _setAppState);
          setCalledOffShiftNew(data.schedule_element, shifts, _setAppState);
          _toggleModal();
          _handleCallOffShift(selectedShift, reason1_tag, reason2_tag, selectedReason1, selectedReason2);
        }).catch((error) => {
          console.log(error);
        });
      }else {
        alert('reason 1 tag or reason 2 tag not found.');
      }
    }else {
      if(reason1_tag) {
        callOffShift({
          shift_id: selectedShift.id,
          call_off_reasons_1_tag_key: reason1_tag,
        }).then((response) => {
          const data = response.data;
          const id = data.schedule_element.id;
          const location = data.schedule_element.location_id;
          const owner = `${data.schedule_element.owner.first_name} ${data.schedule_element.owner.last_name}`;
          const msg = `Call off shift: id: ${id}, location: ${location}, owner: ${owner}`;
          //console.log(msg);

          //setCalledOffShift(selectedShift, shifts, reason1_tag, reason2_tag, _setAppState);
          setCalledOffShiftNew(data.schedule_element, shifts, _setAppState);
          _toggleModal();
          _handleCallOffShift(selectedShift, reason1_tag, reason2_tag, selectedReason1, selectedReason2);
        }).catch((error) => {
          console.log(error);
        });
      }else {
        alert('reason 1 tag or reason 2 tag not found.');
      }
    }
  };

  _handleNextClick = (event) =>{
    if(!this._canProgress()){
      return;
    }
    this.setState({confirmCallOff: true});
    event.preventDefault();
  };

  _handleGoBackClick = (event) => {
    this.setState({confirmCallOff: false});
    event.preventDefault();
  };

  _handleSubmitClick = (event) => {
    this._submitCallOffShift(); 
    event.preventDefault();
  };

/*
  _handleReverse = (event) => {
    this.setState({previewDone: false});
    event.preventDefault();
  };

  _handleCallOff = (event) => {
    this._submitCallOffShift(); 
    event.preventDefault();
  };
*/
  
  render() {
    const { _toggleModal, shifts, selectedShift, costCenterData } = this.props;
    const { intl: { formatMessage } } = this.props;
    const {callOffReason1, 
      callOffReason2, 
      selectedReason1, 
      selectedReason2,
      confirmCallOff, 
      previewDone,
      jobTitle,
    } = this.state;

    let dateText = '';
    let timeText = '';
    let startTime = '';
    let endTime = '';
    let ownerName = '';

    if(selectedShift){
      //dateText = `${selectedShift.start_at}`;
      const startDateTime = moment(selectedShift.start_at);
      const endDateTime = moment(selectedShift.end_at);
      dateText = startDateTime.format('MMM Do');
      const start = startDateTime.format('h:mm A');
      startTime = start;
      const end = endDateTime.format('h:mm A');
      endTime = end;
      timeText = `${start} to ${end}`;
      const firstName = selectedShift.owner.first_name
      ownerName = `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`;
    }

    const submitBtnClass = classNames({
      "modal-components__submit-btn": true,
      "modal-components__submit-btn--active": this._canProgress(),
    });

    const previewSubmit = `${formatMessage(this.messages.preview)} & ${formatMessage(this.messages.submit)}`;

    if(!confirmCallOff){
      return (
        <div className="cover-shift">
          <ModalWrapper>
            <CloseModal _toggleModal={_toggleModal} dark />
            <div className="modal-components__container">
              <h1>{formatMessage(this.messages.header)}</h1>
              <div className="top-section">
                <div className="top-left-section">
                  <label className="section-title">
                    <FormattedMessage
                      id="CallOffShift.shiftPeriod"
                      defaultMessage="Shift Period"
                    />
                  </label>
                  <div className="calendar-time-container">
                    <div className="calendar-container">
                      <img className="calendar-icon" src='/icons/calendar-icon.png' />
                      <h3 className="calendar-text">
                        {dateText}
                      </h3>
                    </div>
                    <div className="time-container">
                      {/*<img className="time-icon" src='/icons/calendar-icon.png' />*/}
                      <span className="icon-clock" />
                      <h3 className="time-text">
                        {timeText}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="top-right-section">
                  <label className="section-title">
                    <FormattedMessage
                      id="CallOffShift.jobTitle"
                      defaultMessage="Job Title"
                    />
                  </label>
                  <div className="job-title-name">
                    {jobTitle}
                  </div>
                </div>
              </div>
              <div className="bottom-section">
                <div className="bottom-left-section">
                  <label className="section-title">
                    <FormattedMessage
                      id="CallOffShift.callOffReason"
                      defaultMessage="Call Off Reason"
                    />
                  </label>
                  <div className="call-off-reasons-1">
                    {this._renderReason1()}
                  </div>
                </div>
                {callOffReason2 && <div className="bottom-right-section">
                  <label className="section-title">
                    <FormattedMessage
                      id="CallOffShift.useSickLeave"
                      defaultMessage="Use Protected Sick Leave?"
                    />
                  </label>
                  <div className="call-off-reasons-2">
                    {this._renderReason2()}
                  </div>
                </div>}
              </div>
              <div className="modal-components__col modal-components__col--submit">
                <button
                  className={submitBtnClass}
                  onClick={this._handleNextClick}
                  type="button"
                >
                  <FormattedMessage id="CallOffShift.nextButton" defaultMessage="Next" />
                  &nbsp;
                  <span className="icon-submit-arrow" />
                </button>
              </div>
            </div>
          </ModalWrapper>
        </div>
      );
    }

    if(confirmCallOff) {
      return (
        <div className="cover-shift">
          <ModalWrapper>
             <CloseModal _toggleModal={_toggleModal} dark />
             <div className="modal-components__container">
              <h1>{previewSubmit}</h1>
              <div className="top-section-preview">
                <label className="section-title-preview">
                  <FormattedMessage
                    id="CallOffShift.dateAndTime"
                    defaultMessage="Date and Time"
                  />
                </label>
                <div className="section-contents">
                  <div className="item-container">
                    <label className="item-name">
                      <FormattedMessage
                        id="CallOffShift.date"
                        defaultMessage="Date:"
                      />
                    </label>
                    <label className="item-value">{dateText}</label>
                  </div>
                  <div className="item-container">
                    <label className="item-name">
                      <FormattedMessage
                        id="CallOffShift.startTime"
                        defaultMessage="Start Time:"
                      />
                    </label>
                    <label className="item-value">{startTime}</label>
                  </div>
                  <div className="item-container">
                    <label className="item-name">
                      <FormattedMessage
                        id="CallOffShift.endTime"
                        defaultMessage="End Time:"
                      />
                    </label>
                    <label className="item-value">{endTime}</label>
                  </div>
                </div>
              </div>
              <div className="bottom-section-preview">
                <div className="name-container">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CallOffShift.myFullName"
                      defaultMessage="My Full Name"
                    />
                  </label>
                  <label className="bottom-item-value">{ownerName}</label>
                </div>
                <div className="job-container">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CallOffShift.previewJobTitle"
                      defaultMessage="Job Title"
                    />
                  </label>
                  <label className="bottom-item-value">{jobTitle}</label>
                </div>
                <div className="reason1-container">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CallOffShift.callOffReason1"
                      defaultMessage="Call Off Reason"
                    />
                  </label>
                  <label className="bottom-item-value">{selectedReason1}</label>
                </div>
                {callOffReason2 && <div className="reason2-container">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CallOffShift.callOffReason2"
                      defaultMessage="Use Protected Sick Leave?"
                    />
                  </label>
                  <label className="bottom-item-value">{selectedReason2}</label>
                </div>}
              </div>
              <div className="button-container">
                <button 
                className="button-preview"
                onClick={this._handleGoBackClick}>
                  <FormattedMessage
                    id="CallOffShift.goBackEdit"
                    defaultMessage="Go Back and Edit"
                  />
                </button>
                <button 
                className="button-preview"
                onClick={this._handleSubmitClick}>
                  <FormattedMessage
                    id="CallOffShift.submitCallOff"
                    defaultMessage="Submit Call Off"
                  />
                </button>
              </div>
             </div>
          </ModalWrapper>
        </div>
      );
    }
  }

}

CallOffShiftI18n.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  costCenterData: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
};

//export default injectIntl (CallOffShift);

const CallOffShift = injectIntl(CallOffShiftI18n);

export { CallOffShift };
