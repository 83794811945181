import React from 'react';
import PropTypes from 'prop-types';

import './ModalWrapper.scss';

class ModalWrapper extends React.PureComponent {
  render() {
    const style = {
      paddingTop: this.props.paddingTop,
    };

    return (
      <div className="modal-wrapper" style={style}>
        {this.props.children}
      </div>
    );
  }
}

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  paddingTop: PropTypes.string,
};

ModalWrapper.defaultProps = {
  paddingTop: '4rem',
};

export { ModalWrapper };
