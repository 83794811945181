import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { TIME_FORMAT } from '_helpers/formatting';
import styled from './styled';

const ShiftSegments = ({ segments }) => {
  const segmentRows = segments.map(segment => (
    <styled.Row>
      <styled.Col>
        <p className="modal-components__confirm-details">
          <strong>
            {`${moment(segment.start_at).format(TIME_FORMAT)} - ${moment(segment.end_at).format(TIME_FORMAT)}`}
          </strong>
        </p>
      </styled.Col>
      <styled.Col>
        <p className="modal-components__label">{segment.department}</p>
      </styled.Col>
      <styled.Col><p className="modal-components__label">{segment.job}</p></styled.Col>
    </styled.Row>
  ));

  return (
    <div className="modal-components__row">
      <div className="modal-components__col">
        <p className="modal-components__label">
          <FormattedMessage
            id="ShiftSegments.heading"
            defaultMessage="Schedule"
          />
        </p>
        <styled.Wrap>
          {segmentRows}
        </styled.Wrap>
      </div>
    </div>
  );
};

ShiftSegments.propTypes = {
  segments: PropTypes.shape([]).isRequired,
};

export default ShiftSegments;
