import React from 'react';
import PropTypes from 'prop-types';

class RadioOption extends React.PureComponent {
  _cloneElement = () =>
    React.cloneElement(this.props.children, {
      ...this.props,
    });

  render() {
    return (
      <>
        <label>
          {this._cloneElement()}
          <input
            className="radio-option"
            type="radio"
            value={this.props.value}
            name={this.props.name}
            onChange={this.props.onChange}
          />
        </label>
      </>
    );
  }
}

export { RadioOption };

RadioOption.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
