import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import { checkForPublicRouteMatch } from '_helpers/PrivateComponent';
import AuthenticationRoute from '_helpers/AuthenticationRoute';
import FetchComponent from '_services/FetchComponent/FetchComponent';
import { PropsRoute } from '_helpers/PropsRoute';
import { ThemeProvider } from 'styled-components';
import { MainTheme, GlobalStyle } from '@shyft/acorns';

// import routes
import { IndexPage } from 'IndexPage';
//import { ProfilePage } from 'ProfilePage/ProfilePage';
import  ProfilePage  from 'ProfilePage/ProfilePage';
import { LoginPage } from 'LoginPage';
import { SignupPage } from 'SignupPage';
import { SignupConfirmationPage } from 'SignupConfirmationPage';
import { SingleSignOnPage } from 'SingleSignOnPage';
import { SingleSignOnCodePage } from 'SingleSignOnCodePage';
import { AddLocationPage } from 'AddLocationPage';
import { AddLocationPageCode } from 'AddLocationPageCode';
import { ForgotPasswordPage } from 'ForgotPasswordPage';
import { ChangePasswordPage } from 'ChangePasswordPage';

import { ROLES } from '_services/user-roles';

import { Navbar } from '_components/GeneralComponents/Navbar';
import { Footer } from '_components/GeneralComponents/Footer';

import '_stylesheets/all.scss';
import './App.scss';

export const publicRoutes = [
  {
    path: '/login',
    exact: true,
    component: LoginPage,
    props: true,
    redirect: false,
  },
  {
    path: '/sign-up',
    exact: false,
    component: SignupPage,
    props: true,
    redirect: false,
  },
  {
    path: '/password-reset',
    exact: false,
    component: ForgotPasswordPage,
    props: false,
    redirect: false,
  },
  {
    path: '/users/password/edit',
    exact: false,
    component: ChangePasswordPage,
    props: false,
    redirect: false,
  },
  {
    path: '/users/confirmation',
    exact: false,
    component: SignupConfirmationPage,
    props: false,
    redirect: false,
  },
  {
    path: '/add-location/store',
    exact: false,
    component: AddLocationPage,
    props: true,
  },
  {
    path: '/login/sso/:company_id',
    exact: false,
    component: SingleSignOnCodePage,
    props: true,
  },
];

const showLoggedInView = (path, userRole) => (userRole !== ROLES.LOGGED_OUT.key) && !checkForPublicRouteMatch(path);

const App = (props) => {
  const { _setAppState, appState, history } = props;
  const appStateProps = {
    _setAppState,
    appState,
  };

  const publicRouteComponents = publicRoutes.map((element) => {
    if (element.props) {
      return (
        <PropsRoute
          {...appStateProps}
          key={element.path}
          path={element.path}
          exact={element.exact}
          component={element.component}
        />
      );
    }
    return (
      <Route
        key={element.path}
        path={element.path}
        exact={element.exact}
        redirect={element.redirect}
        component={element.component}
      />
    );
  });

  return (
    <ThemeProvider theme={MainTheme}>
      <Router>
        <>
          <GlobalStyle />
          <Navbar
            history={history}
            {...appStateProps}
            isLoggedIn={showLoggedInView(
              window.location.pathname,
              appState.userRole,
            )}
          />

          <Switch>
            {publicRouteComponents}
            <FetchComponent
              component={IndexPage}
              roles={['user', 'admin', 'pending_locations']}
              path="/"
              exact
              dataNeeded={['setup_actions']}
              dataNotCalled={appState.apisNeeded}
              {...appStateProps}
            >
              <AuthenticationRoute
                {...appStateProps}
                component={IndexPage}
                roles={['user', 'admin', 'pending_locations']}
                path="/"
                exact
              />
            </FetchComponent>

            <FetchComponent
              dataNeeded={['setup_actions']}
              dataNotCalled={appState.apisNeeded}
              {...appStateProps}
              component={ProfilePage}
              roles={['user', 'admin', 'pending_locations', 'sso']}
              path="/profile"
              exact
            >
              <AuthenticationRoute
                {...appStateProps}
                component={ProfilePage}
                roles={['user', 'admin', 'pending_locations', 'sso']}
                path="/profile"
                exact
              />
            </FetchComponent>

            <FetchComponent
              dataNeeded={['setup_actions']}
              dataNotCalled={appState.apisNeeded}
              {...appStateProps}
              component={SingleSignOnPage}
              roles={['sso']}
              path="/login/sso"
              exact
            >
              <AuthenticationRoute
                {...appStateProps}
                component={SingleSignOnPage}
                roles={['sso']}
                path="/login/sso"
                exact
              />
            </FetchComponent>

            <FetchComponent
              dataNeeded={['setup_actions']}
              dataNotCalled={appState.apisNeeded}
              {...appStateProps}
              exact
              path="/add-location"
              roles={[
                'no_locations',
                'sso',
                'user',
                'admin',
                'pending_locations',
              ]}
              component={AddLocationPage}
              redirect={path => <Redirect push to={path} />}
            >
              <AuthenticationRoute
                exact
                path="/add-location"
                roles={[
                  'no_locations',
                  'sso',
                  'user',
                  'admin',
                  'pending_locations',
                ]}
                component={AddLocationPage}
                redirect={path => <Redirect push to={path} />}
              />
            </FetchComponent>

            <FetchComponent
              dataNeeded={['setup_actions']}
              dataNotCalled={appState.apisNeeded}
              {...appStateProps}
              exact
              path="/add-location/code"
              roles={[
                'no_locations',
                'sso',
                'user',
                'admin',
                'pending_locations',
              ]}
              component={AddLocationPageCode}
              redirect={path => <Redirect push to={path} />}
            >
              <AuthenticationRoute
                exact
                path="/add-location/code"
                roles={[
                  'no_locations',
                  'sso',
                  'user',
                  'admin',
                  'pending_locations',
                ]}
                component={AddLocationPageCode}
                redirect={path => <Redirect push to={path} />}
              />
            </FetchComponent>
          </Switch>
          <Footer {...appStateProps} />
        </>
      </Router>
    </ThemeProvider>
  );
};

App.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  appState: PropTypes.shape({}).isRequired,
};

export default App;
