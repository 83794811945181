import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';

//import { breakpoints } from '../styled';

//import copyButton from '../assets/copy-button.svg';
//import copyButtonGrayedOut from '../assets/copy-button-grayed-out.svg'
//import deleteButton from '../assets/delete-button.svg';

const borderColor = '#DEDEDE';
const textColor = '#90959A';
const darkTextColor = '#64676A';
const shiftsPerRow = 500;

const WinContainer = styled.section`
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: 250px;
  height: 300px;
  position: absolute;
  background-color: white;
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 3;
  overflow-y: scroll;
  //border: 1px solid red;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 20px;
  border-bottom: 1px solid lightgrey;

   &:hover {
    background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    background-image: url('/icons/check-mark.svg');
    background-repeat: no-repeat;
    //background-position: right;
    //background-position: 90% 50%;
    background-position: 95% 50%;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }

  //border: 1px solid green;
`;

const Content = styled.label `
  //color: ${textColor};
  font-size: 14px;
`;

const StatusLabel = styled.label`
  width: 200px;
  height: 36px;
  border-radius: 18px;
  color: ${props => (props.color ? props.color : null)};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : null)};
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
  margin-left: 10px;


  &:hover {
    //color: black;
    //background-color: white;
    //border: 1px solid black;
  }

  //border: 1px solid red;
`;

export {
  WinContainer,
  MenuItem,
  Content,
  StatusLabel,
};
