import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { rgba } from 'polished';

import { StatusIcon } from '_components/GeneralComponents/ShiftStatus';
import { ModalTypes } from '_constants';
import {
  shiftStatuses,
  shiftOwner,
  shiftStatus,
} from '_constants/shift-status';
import { isNextDay } from '_helpers/formatting';
import {
  ActionButton, StatusMessage, CardAddress, Badge, RegionalBadge, CardProfileThumb, CardPosition,
} from '../ShiftCard/components';
import { GetUserRequestTimeOffBalance1 } from '_helpers/api-calls/rto-calls';
import coveredIcon from './assets/covered-icon.svg';
import chatIcon from './assets/chat-icon.svg';
import pendingIcon from './assets/pending-icon.svg';
import deniedIcon from './assets/rejected-icon.svg';
import approvedIcon from './assets/covered-icon.svg';
import defaultAvatar from './assets/default-profile-thumb.svg';
import { LoadingSpinner } from "_components/GeneralComponents/LoadingSpinner";

import '../ShiftCard/ShiftCard.scss';
import styled from './styled';
import './RTOCard.scss';

class RTOCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        timer: null,
        rtoParams: null,
        loadingData: false,
    };

    
    this._isMounted = false;
   
  }

  componentDidMount(){
    const { intl: { formatMessage }, setupActions, rto } = this.props;

    this._isMounted = true;

    GetUserRequestTimeOffBalance1(setupActions.meta.current_user_privilege.id, rto.id).then((data) =>{
      let rtoData = {
          id: '',
          category: '', 
          status: '',
          hours: 0, 
          balance: 0, 
          inQueue: 0,
          remaining: 0,
        };
      let balance = 0;
      let inQueue = 0;
      let remaining = 0;
      let hours = 0;
      if(!data.data.rto_balances) {
        console.log('data.data.rto_balances is null, abort.');
        return;
      }
      rtoData.id = rto.id;
      rtoData.status = rto.status;
      rtoData.category = rto.category;
      switch(rto.category){
        case 'rto_category_floating':
          balance = data.data.rto_balances ? data.data.rto_balances.rto_category_floating : 0;
          inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_floating : 0;
          break;
        case 'rto_category_diversity':
          balance = data.data.rto_balances ? data.data.rto_balances.rto_category_diversity : 0;
          inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_diversity : 0;
          break;
        case 'rto_category_pto':
          balance = data.data.rto_balances ? data.data.rto_balances.rto_category_pto : 0;
          inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_pto : 0;
          break;
      }
      hours = rto.day_hours * rto.days;
      if(inQueue == null){
        remaining = balance - hours;
      }else {
        remaining = balance - inQueue - hours;
      }
      
      rtoData.hours = hours.toFixed(2);
      rtoData.balance = balance.toFixed(2);
      rtoData.inQueue = inQueue ? inQueue.toFixed(2) : 0;
      rtoData.remaining = remaining.toFixed(2);
      this.setState({rtoParams: rtoData});
    }).catch((error) =>{
      console.log(error);
    });
  }

  componentDidUpdate(prevProps){
    const {costCenterData, shift} = this.props;

  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  tick = () => {
    const {shift, costCenterData } = this.props;

  };

  _handleCardClick = () => {
    const {rto, userCookie, _toggleModal, _updateSelectedRTO, setupActions, _setAppState } = this.props;
    const {rtoParams, } = this.state;

    if(rtoParams) {
      _updateSelectedRTO(rtoParams);
      _toggleModal(ModalTypes.REQUESTED_TIME_OFF);
    }else {
      // Show loading spinner
      this.setState({loadingData: true});

       GetUserRequestTimeOffBalance1(setupActions.meta.current_user_privilege.id, rto.id).then((data) =>{
        let rtoData = {
            id: '',
            category: '', 
            status: '',
            hours: 0, 
            balance: 0, 
            inQueue: 0,
            remaining: 0,
          };
        let balance = 0;
        let inQueue = 0;
        let remaining = 0;
        let hours = 0;
        if(!data.data.rto_balances) {
          console.log('data.data.rto_balances is null, abort.');
          return;
        }
        rtoData.id = rto.id;
        rtoData.status = rto.status;
        rtoData.category = rto.category;
        switch(rto.category){
          case 'rto_category_floating':
            balance = data.data.rto_balances ? data.data.rto_balances.rto_category_floating : 0;
            inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_floating : 0;
            break;
          case 'rto_category_diversity':
            balance = data.data.rto_balances ? data.data.rto_balances.rto_category_diversity : 0;
            inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_diversity : 0;
            break;
          case 'rto_category_pto':
            balance = data.data.rto_balances ? data.data.rto_balances.rto_category_pto : 0;
            inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_pto : 0;
            break;
        }
        hours = rto.day_hours * rto.days;
        if(inQueue == null){
          remaining = balance - hours;
        }else {
          remaining = balance - inQueue - hours;
        }
        
        rtoData.hours = hours.toFixed(2);
        rtoData.balance = balance.toFixed(2);
        rtoData.inQueue = inQueue ? inQueue.toFixed(2) : 0;
        rtoData.remaining = remaining.toFixed(2);
        this.setState({rtoParams: rtoData});

        this.setState({loadingData: false});
        _updateSelectedRTO(rtoData);
        _toggleModal(ModalTypes.REQUESTED_TIME_OFF);
      }).catch((error) =>{
        console.log(error);
        this.setState({loadingData: false});
      });
    }
  };

  
  _renderDates = (start_time, end_time, end_date) => {
    const startDate = moment(start_time).format('ddd, MMM D');
    const endDate = moment(end_date).format('ddd, MMM D');
    const duration =`${startDate} - ${endDate}`;
    const startTime = moment(start_time).format('hh:mm A');
    const endTime = moment(end_time).format('hh:mm A');
    const timeRange = `${startTime} - ${endTime}`;
    
    return (
      <>
        <span>
          {duration}
          <br />
          {timeRange}
        </span>
      </>
    );
  };

  render() {
    const {
      userCookie,
      location,
      locationSettings: {
        cfg__primary_job_and_department,
        cfg__inventory__convert,
      },
      _toggleModal,
      rto,
      appState,
    } = this.props;
    const {rtoParams, statusText} = this.state;

    const loading = (
      <div className="index-page index-page__loading">
        <LoadingSpinner color="blue" size="large" />
      </div>
    );

    const badgeText = (
       <FormattedMessage
        id="RTOCard.timeOff"
        defaultMessage="TIME OFF"
      />
    );

    const fullName = `${rto.first_name} ${rto.last_name}`;
    const approverName = `${rto.approver_first_name} ${rto.approver_last_name}`;
    /*
    const requestTimeOffString = (
        <FormattedMessage
          id="RTOCard.rtoString"
          defaultMessage="{name} has requested to take time off."
          values={{
            name: fullName,
          }}
        />
      );
    */
    const statusRequested = (
       <FormattedMessage
        id="RTOCard.requested"
        defaultMessage="Pending"
      />
    );
    const statusApproved = (
       <FormattedMessage
        id="RTOCard.approved"
        defaultMessage="Approved"
      />
    );
     const statusDenied = (
       <FormattedMessage
        id="RTOCard.denied"
        defaultMessage="Denied"
      />
    );
    const messageRequested = (
      <FormattedMessage
        id="RTOCard.takeRequestTimeOff"
        defaultMessage="has requested to take time off."
      />
    );
    const messageApproved = (
      <FormattedMessage
        id="RTOCard.approvedRequestTimeOff"
        defaultMessage="approved your request to take time off."
        /*
        values={{
            approverName: <b className="rto-text-bold">{approverName}</b>,
          }}
        */
      />
    );
    const messageDenied = (
      <FormattedMessage
        id="RTOCard.deniedRequestTimeOff"
        defaultMessage="denied your request to take time off."
        /*
        values={{
            approverName: <b className="rto-text-bold">{approverName}</b>,
          }}
        */
      />
    );


    const requestedColor = rgba(248,154,63,255);
    const requestedBackColor = rgba(255,237,216,255);
    const approvedColor = rgba(58,190,114,255);
    const approvedBackColor = rgba(220,245,229,255);
    const deniedColor = rgba(254,245,245,255);
    const deniedBackColor = rgba(233,100,94,255);

    //const approverName = `${rto.approver_first_name} ${rto.approver_last_name}`;

    if(this.state.loadingData) {
      return loading;
    }

    return (
      // eslint-disable-next-line
      <div className="shift-card" onClick={this._handleCardClick}>
        <div className="shift-card__badge-container">
          <span className="shift-card__badge shift-card__badge--time-off">
            {badgeText}
          </span>
          {/*<span className="shift-card__badge shift-card__badge--time-off">
            {statusText}
          </span>*/}
          {/*(rto.status === 'requested') && <styled.StatusBadge color={requestedColor} backgroundColor={requestedBackColor}>
            {statusRequested}
          </styled.StatusBadge>*/}
          {/*(rto.status === 'approved') && <styled.StatusBadge color={approvedColor} backgroundColor={approvedBackColor}>
            {statusApproved}
          </styled.StatusBadge>*/}
          {/*(rto.status === 'denied') && <styled.StatusBadge color={deniedColor} backgroundColor={deniedBackColor}>
            {statusDenied}
          </styled.StatusBadge>*/}
        </div>
        <span className="shift-card__expand" />
        <styled.ThumbWrap>
          <styled.UserAvatar src={userCookie.profile_image.thumb_url ? userCookie.profile_image.thumb_url : defaultAvatar }/>
        </styled.ThumbWrap>
        <p className="shift-card__date-time">
          {this._renderDates(rto.start_time, rto.end_time, rto.end_date)}
        </p>
        <styled.Wrap>  
          {userCookie.position}
        </styled.Wrap>
        <div className="shift-card__status-wrap">
          <div className="shift-card__status-icon-wrap">
            {rto.status === 'requested' && <img className={'status-icon'} src={pendingIcon} />}
            {rto.status === 'approved' && <img className={'status-icon'} src={approvedIcon} />}
            {rto.status === 'denied' && <img className={'status-icon'} src={deniedIcon} />}
          </div>
          <div>
              {rto.status === 'requested' ? <p className="shift-card__status-owner">{fullName}</p> :
              <p className="shift-card__status-owner">{approverName}</p>}
              {rto.status === 'requested' && <p className="shift-card__status-message">{messageRequested}</p>}
              {rto.status === 'approved' && <p className="shift-card__status-message">{messageApproved}</p>}
              {rto.status === 'denied' && <p className="shift-card__status-message">{messageDenied}</p>}
          </div>
        </div>
      </div>
    );
  }
}

RTOCard.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  shift: PropTypes.shape({}).isRequired,
  locationSettings: PropTypes.shape({}).isRequired,
  _toggleModal: PropTypes.func.isRequired,
  _updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (RTOCard);
