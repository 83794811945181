import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import verifiedBadge from '../../assets/verified-shield.svg';
import unVerifiedBadge from '../../assets/unverified-shield.svg';
import './VerifiedBadge.scss';

const VerifiedBadge = (props) => {
  const { status } = props;
  let text = '';
  let icon = '';
  let classModifier = '';

  switch (status) {
    case 'verified':
      text = (
        <FormattedMessage
          id="VerifiedBadge.verifiedLabel"
          defaultMessage="Verified"
        />
      );
      icon = verifiedBadge;
      classModifier = 'verified';
      break;
    case 'unverified':
      text = (
        <FormattedMessage
          id="VerifiedBadge.unverifiedLabel"
          defaultMessage="Unverified"
        />
      );
      icon = unVerifiedBadge;
      classModifier = 'unverified';
      break;
    default:
      break;
  }

  return (
    <div className="verified-badge">
      <span
        className={`verified-badge__label verified-badge__label--${classModifier}`}
      >
        {text}
      </span>
      <img className="verified-badge__shield" src={icon} alt="Shield" />
    </div>
  );
};

VerifiedBadge.propTypes = {
  status: PropTypes.oneOf(['verified', 'unverified', '']).isRequired,
};

export default VerifiedBadge;
