import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { SingleSignOnBoxComponent } from '_components/GeneralComponents/SingleSignOn/SingleSignOnBoxComponent';

const SingleSignOnPage = (props) => {
  const { appState } = props;

  if (appState.setupActions.setup_actions.filter(
    element => element.operation === 'action_sso_logout',
  ).length === 0) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <SingleSignOnBoxComponent
        setupActions={appState.setupActions.setup_actions}
      />
    </>
  );
};

SingleSignOnPage.propTypes = {
  appState: PropTypes.shape({}).isRequired,
};

export { SingleSignOnPage };
