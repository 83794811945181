import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from "classnames";
import { ModalTypes, shiftStatuses, shiftOwner, shiftStatus, shiftNames } from "_constants";
import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import { DateButton, TimeButton, DateTimePicker } from './components';
import { getUserInfo } from '_helpers/api-calls/user-calls';
import { postShift, postShiftInventory, getShift } from '_helpers/api-calls/shift-calls';
import { deleteShift, cancelShift } from "_helpers/api-calls/shift-calls";
import { addShift, removeShift } from '_helpers/app-state';
import { DataNames } from './constants';
import {
  createDateString,
  createTimeRangeString,
  isNextDay,
} from '_helpers/formatting';
import { formatDateforApi, getDefaultTimezone} from '_helpers/date-time';

import './ScheduledShift.scss';

class ScheduledShift extends React.Component {
	constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      dateString: {
        id: 'ScheduledShift.dateString',
        defaultMessage: 'Date',
      },
      timeRangeString: {
        id: 'ScheduledShift.timeRangeString',
        defaultMessage: 'Time',
      },
      pastShiftMessage: {
        id: 'ScheduledShift.pastShiftMessage',
        defaultMessage: 'You cannot post a shift that starts in the past!',
      },
    });

    this.state = {
        debug: false,

        showDatePicker: false,
        confirmPost: false,
        date: {},
        dateString: formatMessage(this.messages.dateString),
        reachValue: 'My Team',
        channel_id: '',
        submitting: false,
        times: [],
        timeRangeString: formatMessage(this.messages.timeRangeString),
        myJobTitle: '',
        inputJobTitle: '',
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { userPrivileges, userCookie, shifts, _setAppState } = this.props;

    this._isMounted = true;
    if(userCookie) {
      if(this._isMounted) {
        this.setState({myJobTitle: userCookie.position});
      }
    }
    this._updateState(DataNames.DATE, moment());
  };

  componentDidUpdate (prevProps) {

  };

  componentWillUnmount () {

    this._isMounted = false;

  };

  toggleDatePicker = () => {
    const { showDatePicker, } = this.state;
    if(this._isMounted) {
      this.setState({showDatePicker: !this.state.showDatePicker});
    }
  }

  dateString = (startDate) => {

    const start = moment(startDate);
    const dateString = `${start.format('ddd, MMM DD')}`;
    return dateString;
  }

  _updateState = (action, data) => {
    const {rtoTypes, } = this.state;

    if(!this._isMounted){
      return;
    }
    switch (action) {
      case 'REACH':
        break;
      case DataNames.REACH:
        break;
      case DataNames.DATE:
        this.setState({ date: data, dateString: this.dateString(data) });
        break;
      case DataNames.TIMES:
        if(this._isMounted) {
          this.setState({times: data, timeRangeString: createTimeRangeString(data),});
        }
        break;
      default:
        break;
    }
  };

  canProgress = () => {
    const { date, times, inputJobTitle} = this.state;
    const isDate = date !== null;
    const isTimes = times.length > 0;
    const isJobTitle = inputJobTitle.length > 0;
    const canProgress = isDate && isTimes && isJobTitle;

    return canProgress;
  };

  handleGoNext = (event) => {
    if(!this.canProgress()) {
      return;
    }
    event.preventDefault();
    if(this._isMounted) {
      this.setState({confirmPost: true, });
    }
  };

  handleJobTitleChange = (event) => {
    event.preventDefault();
    if(this._isMounted) {
      this.setState({inputJobTitle: event.target.value});
    }
  };

  handleGoBack = (event) => {
    event.preventDefault();
    if(this._isMounted) {
      this.setState({confirmPost: false, });
    }
  };

  handleSubmit = (event) => {
    const { times, date, inputJobTitle, } = this.state;
    const { _toggleModal, userCookie, shifts, _setAppState, } = this.props;

    this.setState({ submitting: true });

    times[0]
      .year(date.year())
      .month(date.month())
      .date(date.date());

    times[1]
      .year(date.year())
      .month(date.month())
      .date(date.date());

    const timeZone = getDefaultTimezone();
    const endAt = isNextDay(times) ? times[1].add(1, 'days') : times[1];
    const start = moment.tz(times[0], timeZone).format();
    const end = moment.tz(endAt, timeZone).format();
    console.log('start_at:' + start);
    console.log('endAt:' + end);

    const shiftData = {
      start_at: start,
      end_at: end,
      shift_content: '',
      location_id: userCookie.location_id,
      cached_primary_job: inputJobTitle,
    };

    postShift(shiftData)
      .then((response) => {
        const { _setAppState, shifts } = this.props;
        //convert to inventory (scheduled shift)
        cancelShift(response.data.schedule_element.id)
        .then(({ data }) => {
          addShift(data.schedule_element, shifts, _setAppState);
          _toggleModal();
          if(this._isMounted) {
            this.setState({ submitting: false });
          }
        }).catch((error) => {
          const msg = `ScheduledShift.handleSubmit: failed on cancelShift. error: ${error}`;
          console.log(msg);
          if(this._isMounted) {
            this.setState({ submitting: false });
          }
        });
      }).catch((error) => {
        const msg = `ScheduledShift.handleSubmit: failed on postShift. error: ${error}`;
        console.log(msg);
        if(this._isMounted) {
          this.setState({ submitting: false });
        }
      });
  };

  renderActionButtons = () => {
    const { showDatePicker, confirmPost, } = this.state;

    const submitBtnClass = classNames({
      "scheduled-shift__submit-btn": true,
      "scheduled-shift__submit-btn--active": this.canProgress(),
    });

    return (
        <button className={submitBtnClass} onClick={this.handleGoNext} type="button">
          <FormattedMessage 
            id="ScheduledShift.createShift" 
            defaultMessage="Create Scheduled Shift" 
          />
        </button>
    );
  };


  
  render () {
  	const { _toggleModal, locale, } = this.props;
    const {showDatePicker, myJobTitle, confirmPost, date, timeRangeString, inputJobTitle, submitting, } = this.state;

    const dateText = moment(date).format('dddd, MMM DD');
    
    const submitBtnClass = classNames({
      "modal-components__submit-btn": true,
      "modal-components__submit-btn--active": this.canProgress(),
    });

    if(confirmPost) {

      return (
        <div className="cover-shift">
          <ModalWrapper>
            <CloseModal _toggleModal={_toggleModal} dark />
            <div className="modal-components__container">
              <h1 className="scheduled-shift__header">
                <FormattedMessage
                  id="ScheduledShift.previewSubmit1"
                  defaultMessage="Preview & Submit"
                />
              </h1>
              <div className="modal-components__row">
                <div className="scheduled-shift__item-wrap">
                  <div className="item">
                    <label className="item__name">
                      <FormattedMessage
                        id="ScheduledShift.date"
                        defaultMessage="Date"
                      />
                    </label>
                    <label className="item__value">{dateText}</label>
                  </div>
                  <div className="item">
                    <label className="item__name">
                      <FormattedMessage
                        id="ScheduledShift.startEndTimes"
                        defaultMessage="Start Time & End Time"
                      />
                    </label>
                    <label className="item__value">{timeRangeString}</label>
                  </div>
                  <div className="item">
                    <label className="item__name">
                      <FormattedMessage
                        id="ScheduledShift.jobTitle"
                        defaultMessage="Job Title"
                      />
                    </label>
                    <label className="item__value">{inputJobTitle}</label>
                  </div>
                </div>
              </div>
              <div className="modal-components__row">
                <div className="scheduled-shift__item-wrap">
                  {!submitting && <button className="scheduled-shift__button" onClick={this.handleGoBack}>
                    <FormattedMessage
                      id="ScheduledShift.back"
                      defaultMessage="Back"
                    />
                  </button>}
                  {!submitting && <button className="scheduled-shift__button" onClick={this.handleSubmit}>
                    <FormattedMessage
                      id="ScheduledShift.confirmPost"
                      defaultMessage="Confirm and Post"
                    />
                  </button>}
                </div>
              </div>
            </div>
          </ModalWrapper>
        </div>
      );
    }
    
  	return (
      <div className="cover-shift">
        <ModalWrapper>
          <CloseModal _toggleModal={_toggleModal} dark />
          <div className="modal-components__container">
             <h1 className="scheduled-shift__header">
              <FormattedMessage
                id="ScheduledShift.addScheduledShift1"
                defaultMessage="Scheduled Shift"
              />
            </h1>
            <div className="modal-components__row">
              <div className="scheduled-shift__top-line-wrap">
                <div className="modal-components__col">
                  <div className="scheduled-shift__date-time-label-wrap">
                     <p className="scheduled-shift__label">
                      <FormattedMessage
                        id="ScheduledShift.dateAndTime"
                        defaultMessage="Date and Time"
                      />
                    </p>
                  </div>
                  <div className="scheduled-shift__date-time-button-wrap">
                    <DateButton 
                      _toggleDatePicker={this.toggleDatePicker}
                      dateString={this.state.dateString}
                    /> 
                    <TimeButton 
                       _toggleDatePicker={this.toggleDatePicker}
                       timeRangeString={this.state.timeRangeString}
                    />
                    {showDatePicker ?  <button className="up-arrow" onClick={this.toggleDatePicker}/>
                      : <button className="down-arrow" onClick={this.toggleDatePicker}/>}
                  </div>
                </div>
                <div className="modal-components__col">
                  <div className="scheduled-shift__date-time-label-wrap">
                    <p className="scheduled-shift__label">
                      <FormattedMessage
                        id="ScheduledShift.jobTitle"
                        defaultMessage="Job Title"
                      />
                    </p>
                  </div>
                  {/*<label className="scheduled-shift__job-title">{myJobTitle}</label>*/}
                  <input 
                    className="scheduled-shift__job-title"
                    value={this.state.inputJobTitle}
                    multiline={false}
                    onChange={this.handleJobTitleChange}
                    readOnly={false}
                  />
                </div>
              </div> 
            </div>
            {!this.state.showDatePicker && <div className="modal-components__row">
                <div className="modal-components__col modal-components__col--submit">
                  {this.renderActionButtons()}
                </div>

            </div>}
          </div>
        </ModalWrapper>
        {this.state.showDatePicker && <div className="scheduled-shift__date-picker-wrap">
          <DateTimePicker
            _updateState={this._updateState}
            toggleDatePicker={this.toggleDatePicker}
            getStartTime={this.getStartTime}
            getEndTime={this.getEndTime}
            clearDatesAndTimes={this.clearDatesAndTimes}
            date={this.state.date}
            locale={locale}
          />
        </div>}
      </div>
    );	
  }
}

ScheduledShift.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  _toggleModal: PropTypes.func.isRequired,
};

export default injectIntl (ScheduledShift);

