import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LanguageSelector } from '@shyft/acorns';

import { setLocaleCookie } from '_helpers/cookies';
import { updateUserAttributes } from '_helpers/api-calls';
import { handleLocaleChange } from '_helpers/app-state';
import { formatTermsLinkWithLocale, formatPrivacyLinkWithLocale } from '_helpers/formatting';
import { APP_VERSION } from '_constants';
import './Footer.scss';

const LanguageSelectorWrap = styled.div`
  display: inline-block;
  border-right: 1px solid #ababab;
  margin-right: 10px;
  padding-right: 15px;

  button {
    font-size: 12px;
  }
`;

class Footer extends React.PureComponent {
  _handleLocaleChange = (locale) => {
    const { _setAppState, appState: { user: { user_id } } } = this.props;

    _setAppState({
      key: 'locale',
      statement: { $set: locale },
    });

    if (user_id) {
      updateUserAttributes(user_id, { locale });
    }

    setLocaleCookie(locale);
    handleLocaleChange(_setAppState);
  }

  render() {
    const {
      appState: {
        setupActions,
        locale,
        supportedLocales,
      },
    } = this.props;
    const termsLink = formatTermsLinkWithLocale(setupActions, locale);
    const privacyLink = formatPrivacyLinkWithLocale(setupActions, locale);

    return (
      <footer className="footer">
        <section className="footer-detail--container">
          <span className="footer-detail--link">
            {/*<span className="footer-detail--link-ind">{`v${APP_VERSION}`}</span>*/}
            <LanguageSelectorWrap>
              <LanguageSelector
                _languageSelectHandler={this._handleLocaleChange}
                languagesArray={supportedLocales}
                currentLang={locale}
              />
            </LanguageSelectorWrap>
            <span className="footer-detail--link-ind">{`v${APP_VERSION}`}</span>
            <a
              className="footer-detail--link-ind"
              target="_blank"
              rel="noopener noreferrer"
              href={privacyLink}
            >
              <FormattedMessage
                id="Footer.privacyPolicy"
                defaultMessage="Privacy Policy"
              />
            </a>
            <a
              className="footer-detail--link-ind"
              target="_blank"
              rel="noopener noreferrer"
              href={termsLink}
            >
              <FormattedMessage
                id="Footer.termsConditions"
                defaultMessage="Terms of Service"
              />
            </a>
            <span className="footer-detail--link-ind">
              &copy;
              <FormattedDate
                value={Date.now()}
                year="numeric"
              />
              &nbsp;
              <FormattedMessage
                id="Footer.copyright"
                defaultMessage="All Rights Reserved. Shyft Technologies Inc."
              />
            </span>
          </span>
        </section>
      </footer>
    );
  }
}

export { Footer };
