import moment from 'moment-timezone';
import { defineMessages } from 'react-intl';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

let currentTimezone;

export const setDefaultTimezone = (timezone) => {
  if (timezone) {
    moment.tz.setDefault(timezone);
    currentTimezone = timezone;
  } else {
    moment.tz.setDefault();
    currentTimezone = moment.tz.guess();
  }
};

export const getDefaultTimezone = () => {
  return currentTimezone;
};

export const formatDateforApi = date => date.utc().format(DEFAULT_DATE_FORMAT);

const timeMessages = defineMessages({
  fewSecondsAgo: {
    id: 'timeMessages.fewSecondsAgo',
    defaultMessage: 'A few seconds ago',
  },
  secondsAgo: {
    id: 'timeMessages.secondsAgo',
    defaultMessage: '{time} seconds ago',
  },
  minutesAgo: {
    id: 'timeMessages.minutesAgo',
    defaultMessage: '{time, plural, one {{time} minute ago} other {{time} minutes ago}}',
  },
  hourAgo: {
    id: 'timeMessages.hourAgo',
    defaultMessage: '{time} hour ago',
  },
  todayAt: {
    id: 'timeMessages.todayAt',
    defaultMessage: 'Today at {time}',
  },
  yesterdayAt: {
    id: 'timeMessages.yesterdayAt',
    defaultMessage: 'Yesterday at {time}',
  },
  weekTime: {
    id: 'timeMessages.weekTime',
    defaultMessage: '{week} at {time}',
  },
});

export const formatTimeElapsed = (date, formatMessage) => {
  const now = moment();
  const managedTime = moment(date);
  const today = moment();
  const yesterday = today.subtract(1, 'day');
  //console.log(today);
  //console.log(yesterday);
  let text;

  if (now.diff(managedTime, 'seconds') < 30) {
    text = formatMessage(timeMessages.fewSecondsAgo);
  }

  if (
    now.diff(managedTime, 'seconds') > 29
    && now.diff(managedTime, 'seconds') < 60
  ) {
    text = formatMessage(timeMessages.secondsAgo, { time: now.diff(managedTime, 'seconds') });
  }

  if (
    now.diff(managedTime, 'minutes') >= 1
    && now.diff(managedTime, 'minutes') < 60
  ) {
    text = formatMessage(timeMessages.minutesAgo, { time: now.diff(managedTime, 'minutes') });
  }

  if (
    now.diff(managedTime, 'hours') >= 1
    && now.diff(managedTime, 'hours') < 2
  ) {
    text = formatMessage(timeMessages.hourAgo, { time: now.diff(managedTime, 'hours') });
  }

  if (
    managedTime.isSame(today, 'd')
  ) {
    text = formatMessage(timeMessages.todayAt, { time: managedTime.format('LT') });
  }

  if (managedTime.isSame(yesterday, 'd')) {
    text = formatMessage(timeMessages.yesterdayAt, { time: managedTime.format('LT') });
  }

  if (now.diff(managedTime, 'days') >= 2 && now.diff(managedTime, 'days') < 7) {
    text = formatMessage(timeMessages.weekTime, {
      week: managedTime.format('dddd'),
      time: managedTime.format('LT'),
    });
  }

  if (now.diff(managedTime, 'days') > 7) {
    text = `${managedTime.format('MM/DD/YYYY LT')}`;
  }

  return text;
};

export const sortShiftsByDate = shifts => shifts.sort((a, b) => {
  const date1 = moment(a.start_at);
  const date2 = moment(b.start_at);

  return date1.diff(date2);
});
