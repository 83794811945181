import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { validateEmail } from "_helpers/validation";
import { StartPasswordReset } from "_helpers/api-calls";

import messages from "./messages";
import "./ForgotPasswordPage.scss";

class ForgotPasswordPagei18n extends React.Component {
  state = {
    email: {
      value: "",
      validity: false,
      showValidity: false,
      submitted: false,
    },
    error: {
      value: "",
      showError: false,
    },
  };

  _handleEmailChange = (e) => {
    const { value } = e.target;
    const { email } = this.state;

    this.setState({
      email: Object.assign(email, {
        value,
        validity: validateEmail(value),
      }),
    });
  };

  _formSubmit = (e) => {
    const { email, error } = this.state;
    e.preventDefault();

    if (email.validity) {
      this._resetPassword();
    } else {
      this.setState({
        email: Object.assign(email, {
          showValidity: true,
        }),
        error: Object.assign(error, {
          value: "",
          showError: false,
        }),
      });
    }
  };

  _resetPassword = () => {
    const { intl } = this.props;
    const { email, error } = this.state;

    StartPasswordReset(email.value).then(
      (response) => {
        if (response.status === 201) {
          this.setState({
            email: Object.assign(email, {
              submitted: true,
            }),
          });
        } else {
          this.setState({
            error: Object.assign(error, {
              value: intl.formatMessage(messages.serverError),
              showError: true,
            }),
          });
        }
      },
      ({ response }) => {
        if (response.status === 422) {
          if (
            response.data.errors.email &&
            response.data.errors.email[0] === "not found"
          ) {
            this.setState({
              error: Object.assign(error, {
                value: intl.formatMessage(messages.emailError),
                showError: true,
              }),
            });
          }

          if (
            response.data.errors.base &&
            response.data.errors.base[0] ===
              "this account does not allow password to be changed."
          ) {
            this.setState({
              error: Object.assign(error, {
                value: intl.formatMessage(messages.companyAccountError),
                showError: true,
                showValidity: true,
              }),
            });
          }
        }

        if (response.status === 500) {
          this.setState({
            error: Object.assign(error, {
              value: intl.formatMessage(messages.serverError),
              showError: true,
            }),
          });
        }
      }
    );
  };

  _resendEmail = (e) => {
    e.preventDefault();
    this._resetPassword();
  };

  _errorToShow = () => {
    const { email, error } = this.state;

    if (error.showError && error.value.length > 0) {
      return error.value;
    }

    if (email.showValidity) {
      // ``;
      return (
        <FormattedMessage
          id="ForgotPasswordPage.validEmailError"
          defaultMessage="Please enter a valid email"
        />
      );
    }
    return "";
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { email } = this.state;

    if (email.submitted) {
      return (
        <article className="login">
          <div className="login__wrap">
            <h2 className="login__header">
              <FormattedMessage
                id="ForgotPasswordPage.successHeader"
                defaultMessage="Success!"
              />
            </h2>
            <section className="login__description-container">
              <p className="login__description">
                <FormattedMessage
                  id="ForgotPasswordPage.passwordResetMessage"
                  defaultMessage="We have sent an email with a link to reset your password. It should arrive in a minute or two."
                />
              </p>
              <span className="login__description inline">
                <FormattedMessage
                  id="ForgotPasswordPage.noEmailMessage"
                  defaultMessage="Didn't get an email?"
                />
              </span>
              <a className="login__link" href="" onClick={this._resendEmail}>
                <FormattedMessage
                  id="ForgotPasswordPage.resendButton"
                  defaultMessage="Resend It"
                />
              </a>
            </section>
          </div>
        </article>
      );
    }
    return (
      <>
        <article className="login">
          <div className="login__wrap">
            <h2 className="login__header">
              <FormattedMessage
                id="ForgotPasswordPage.resetPasswordHeader"
                defaultMessage="Reset your Password"
              />
            </h2>
            <form className="login__form" onSubmit={this._formSubmit}>
              <section className="login__form-group">
                <input
                  onChange={this._handleEmailChange}
                  name="email"
                  autoComplete="email"
                  placeholder={formatMessage(messages.emailPlaceholder)}
                  className="login__input-email"
                />
              </section>

              <section className="login__form-action">
                <button
                  className="login__form-submit button button-primary"
                  type="submit"
                >
                  <FormattedMessage
                    id="ForgotPasswordPage.submitButton"
                    defaultMessage="Submit"
                  />
                </button>
              </section>

              <section className="login__form-errors">
                <p className="login__form-errors--primary-red">
                  {this._errorToShow()}
                </p>
              </section>
            </form>
          </div>
        </article>
      </>
    );
  }
}

const ForgotPasswordPage = injectIntl(ForgotPasswordPagei18n);

export { ForgotPasswordPage };
