import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { Shiffy } from '_components/GeneralComponents/Shiffy';
import { trackAmplitudeEvent, amplitudeConstants } from '_helpers/tracking';
import { ModalTypes } from '_constants';
import LargeActionButton from './components/LargeActionButton';
import starsSvg from './assets/no-shift-stars.svg';
import './ShiftCard.scss';


class NoShiftCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        timer: null,
        rtoParams: null,
        loadingData: false,
    };

    
    this._isMounted = false;
   
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  render () {
    const {type} = this.props;

    return (
      <div className="no-shift-card">
        <h1 className="no-shift-card__big-text">
           <FormattedMessage
            id="NoShiftCard.noShiftsYet"
            defaultMessage="No Shifts Yet!"
          />
        </h1>
        {type === 'available' ? 
        <p className="no-shift-card__small-text">
           <FormattedMessage
            id="NoShiftCard.postedShifts"
            defaultMessage="Posted shifts will appear here."
          />
        </p>:
         <p className="no-shift-card__small-text">
           <FormattedMessage
            id="NoShiftCard.myShifts"
            defaultMessage="Scheduled shifts and shifts you cover will appear here."
          />
        </p>
        }
      </div>
    );
  }
}

NoShiftCard.propTypes = {
  //userCookie: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default injectIntl (NoShiftCard);
