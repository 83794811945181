import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button } from '@shyft/acorns';
import {
  ModalTypes, shiftNames,
} from '_constants';

export const StyledButton = styled(Button).attrs(({ deleteShift, cancelShift }) => ({
  modifiers: [
    'small',
    deleteShift || cancelShift ? 'danger' : 'primary',
  ],
}))`
  padding: 0.33rem 1.5rem;
  font-weight: 600;
  align-self: ${({ isInventory }) => (isInventory ? 'center' : 'flex-start')};
`;

const ActionButton = ({
  _toggleModal,
  _updateSelectedShift,
  shift,
  userCookie,
  allowPost,
}) => {
  let notCovered = false;
  let isInventory = false;
  let allowDelete = false;
  let allowCancel = false;
  let myCoveringShift = false;
  let myCoveringShift2 = false;
  let myNoCancelDeleteShift2 = false;

  if(shift && userCookie){
    notCovered = shift.coverer === null || shift.approver === null;
    isInventory = shift.name === shiftNames.SHIFT_INVENTORY;
    allowDelete = !isInventory && shift.allow_delete &&  shift.owner.id === userCookie.user_id;
    allowCancel = shift.allow_cancel && shift.owner.id === userCookie.user_id;
    myCoveringShift = shift.coverer !== null && shift.coverer.id === userCookie.user_id;
    myCoveringShift2 = shift.cover_mode === 'multi_applicants_picked_by_manager' && shift.current_applicant_status !== null
    myNoCancelDeleteShift2 = shift.cover_mode === 'multi_applicants_picked_by_manager' &&
                              !shift.allow_cancel &&
                              !shift.allow_delete &&
                              shift.owner.id === userCookie.user_id &&
                              shift.trade_status === 'posted' &&
                              !shift.current_applicant_status;
  }

  const clickHandler = (e) => {
    e.stopPropagation();

    if (isInventory) {
      _toggleModal(ModalTypes.POST_SHIFT);
      _updateSelectedShift(shift);
    } else if (allowCancel) {
      _toggleModal(ModalTypes.CANCEL_SHIFT);
      _updateSelectedShift(shift);
    } else if (allowDelete) {
      _toggleModal(ModalTypes.DELETE_SHIFT);
      _updateSelectedShift(shift);
    } else if (notCovered) {
      _toggleModal(ModalTypes.COVER_SHIFT);
      _updateSelectedShift(shift);
    }
  };

  const buttonText = () => {
    if (isInventory) {
      return (
        <FormattedMessage
          id="ActionButton.postInventory"
          defaultMessage="Post this Shift"
        />
      );
    }

    if (shift.name === shiftNames.SHIFT_VTO && !allowDelete) {
      return (
        <FormattedMessage
          id="ActionButton.takeVTO"
          defaultMessage="TAKE VTO"
        />
      );
    } 
    if (allowCancel) {
      return (
        <FormattedMessage
          id="ActionButton.cancelShift"
          defaultMessage="Cancel Shift Trade"
        />
      );
    }
    if (shift.name === shiftNames.SHIFT_VTO && allowDelete) {
      return (
        <FormattedMessage
          id="ActionButton.deleteVTO"
          defaultMessage="DELETE VTO"
        />
      );
    }
    if (allowDelete) {
      return (
        <FormattedMessage
          id="ActionButton.deleteShift"
          defaultMessage="DELETE MY SHIFT"
        />
      );
    }
    return (
      <FormattedMessage
        id="ActionButton.coverShift"
        defaultMessage="COVER"
      />
    );
  };

  //myCoveringShift2 is for shift 2.0
  if (myCoveringShift || myCoveringShift2 || myNoCancelDeleteShift2) {
    return null;
  }
  /*
  if (notCovered || allowDelete || isInventory) {
    return (
      <StyledButton
        onClick={clickHandler}
        deleteShift={allowDelete}
        cancelShift={allowCancel}
        isInventory={isInventory}
      >
        {buttonText()}
      </StyledButton>
    );
  }
  */

  if(isInventory) {
    if(allowPost){
      return (
        <StyledButton
          onClick={clickHandler}
          deleteShift={allowDelete}
          cancelShift={allowCancel}
          isInventory={isInventory}
        >
          {buttonText()}
        </StyledButton>
      );
    }
  }else {
    if (notCovered || allowDelete) {
      return (
        <StyledButton
          onClick={clickHandler}
          deleteShift={allowDelete}
          cancelShift={allowCancel}
          isInventory={isInventory}
        >
          {buttonText()}
        </StyledButton>
      );
    }
  }

  return null;
};

ActionButton.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  shift: PropTypes.shape({}).isRequired,
  _toggleModal: PropTypes.func.isRequired,
  _updateSelectedShift: PropTypes.func.isRequired,
};

export default ActionButton;
