import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';

import { PropsRoute } from '_helpers/PropsRoute';
import { ROLES } from '_services/user-roles';
import { purgeAuthenticationCookie } from '_helpers/cookies';

const reRoutes = {
  [ROLES.LOGGED_OUT.key]: '/login',
  [ROLES.NO_LOCATIONS.key]: '/add-location',
  [ROLES.PENDING_LOCATIONS.key]: '/',
  [ROLES.SSO.key]: '/login/sso',
  [ROLES.USER.key]: '/',
  [ROLES.ADMIN.key]: '/',
};

/**
 * @param  { String } currentRole - The role of the current user
 * @param  { Array } permissions - possible roles that the current path accepts
 */
const checkRolesWithPermissions = (currentRole, permissions) => (
  permissions.some(element => currentRole === element)
);

class AuthenticationRoute extends Component {
  idleTimerRef = React.createRef();

  componentDidMount() {
    this._checkAndRedirect();
  }

  componentDidUpdate() {
    this._checkAndRedirect();
  }

  _checkAndRedirect = () => {
    const { appState, roles, _redirect } = this.props;

    if (!checkRolesWithPermissions(appState.userRole, roles)) {
      _redirect(reRoutes[appState.userRole]);
    }
  };

  _onIdleHandler = () => {
    alert('You\'ve been away for a while… It\'s time to log back in.');
    // Logout user
    purgeAuthenticationCookie();
    window.location.pathname = '/login';
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={this.idleTimerRef}
          onIdle={this._onIdleHandler}
          timeout={1000 * 60 * 15}
        />

        <div className="index-page">
          <PropsRoute {...this.props} />
        </div>
      </>
    );
  }
}

export default AuthenticationRoute;
