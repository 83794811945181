import { all } from 'redux-saga/effects';

// import individual sagas from ducks folders
// import homeSaga from 'app/home/duck'

export default function* rootSaga() {
  yield all([
    // homeSaga()
  ]);
}
