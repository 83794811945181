import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from "classnames";
import { ModalTypes, shiftStatuses, shiftOwner, shiftStatus, shiftNames } from "_constants";
import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import { getUserInfo, getUserInfoNonAdmin } from '_helpers/api-calls/user-calls';

import '../CallOffShift/CallOffShift.scss';

class CalledOffShift extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
        callOffReason1: null,
        callOffReason2: null,
        jobTitle: '',
    };

    this.messages = defineMessages({
      header: {
        id: 'CalledOffShfit.scheduledShift',
        defaultMessage: 'Scheduled Shift',
      },
    });
  }

  componentDidMount() {
    const {costCenterData, selectedShift, } = this.props;

    if(selectedShift) {
      if(selectedShift.primary_job) {
        this.setState({jobTitle: selectedShift.primary_job});
      }else {
        getUserInfoNonAdmin(selectedShift.owner.id, selectedShift.location_id).then((response) => {
          this.setState({jobTitle: response.data.user.current_position});
        }).catch((error) =>{
           const msg = `CallOffedShift.componentDidUpdate: call getUserInfoNonAdmin failed. error: ${error}`;
           console.log(msg);
        });
      }
    }

    if(costCenterData){
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_1'){
          this.setState({callOffReason1: costCenterData.tag_groups[i].tags})
        }
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_2'){
          this.setState({callOffReason2: costCenterData.tag_groups[i].tags})
        }
      }
    }else {
      console.log('CalledOffShift: costCenterData not found.')
    }
  }

  handleButtonClick = (event) => {
    const {_toggleModal} = this.props;

    _toggleModal(null);
  };

  handleDeleteClick = (event) => {
    const {_toggleModal, selectedShift} = this.props;

     _toggleModal(ModalTypes.DELETE_SHIFT, selectedShift.id);
  };

  
  render () {
    const {callOffReason1, callOffReason2, jobTitle} = this.state;
    const { 
      _toggleModal, 
      shifts, 
      selectedShift, 
      reason1, 
      reason2, 
      intl} = this.props;

    let dateText = '';
    let timeText = '';
    //let jobTitle = '';
    let startTime = '';
    let endTime = '';
    let ownerName = '';
    let reason1_content = '';
    let reason2_content = '';
    let reasons = '';

    if(selectedShift){
      //dateText = `${selectedShift.start_at}`;
      const startDateTime = moment(selectedShift.start_at);
      const endDateTime = moment(selectedShift.end_at);
      dateText = startDateTime.format('MMM Do');
      const start = startDateTime.format('h:mm A');
      startTime = start;
      const end = endDateTime.format('h:mm A');
      endTime = end;
      timeText = `${start} to ${end}`;
      //jobTitle = selectedShift.owner.current_position;
      const firstName = selectedShift.owner.first_name
      //console.log('ere');
      ownerName = `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`;
    }

    if(callOffReason2) {
      if(selectedShift && callOffReason1 && callOffReason2) {
        const idx = callOffReason1.findIndex(
          (reason) => reason.tag_key === selectedShift.call_off_reasons_1_tag_key
        );
        if(idx >=0){
          reason1_content = callOffReason1[idx].tag_content;
        }
        const idx2 = callOffReason2.findIndex(
            (reason) => reason.tag_key === selectedShift.call_off_reasons_2_tag_key
        );
        if(idx2 >=0){
          reason2_content = callOffReason2[idx2].tag_content;
        }
        if(reason2_content && selectedShift.call_off_reasons_2_tag_key !== 'call_off_reason_protected_sick_leave_1_no') {
          reasons = `${reason1_content} . ${reason2_content}`;
        }else {
          reasons = reason1_content;
        }
      } else {
        console.log('CalledOffShift: callOffReason1 or callOffReason2 not found.')
      }
    }else {
      if(selectedShift && callOffReason1) {
        const idx = callOffReason1.findIndex(
          (reason) => reason.tag_key === selectedShift.call_off_reasons_1_tag_key
        );
        if(idx >=0){
          reason1_content = callOffReason1[idx].tag_content;
        }
        reasons = reason1_content;
      } else {
        console.log('CalledOffShift: callOffReason1 not found.')
      }
    }
  	
  	return (
  		<div className="cover-shift">
  			<ModalWrapper>
  				 <CloseModal _toggleModal={_toggleModal} dark />
            <div className="modal-components__container">
              <h1>{intl.formatMessage(this.messages.header)}</h1>
              <div className="called-off-top-section">
                <div className="left-section">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CalledOffShift.myFullName"
                      defaultMessage="My Full Name"
                    />
                  </label>
                  <label className="bottom-item-value">{ownerName}</label>
                </div>
                <div className="right-section">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CalledOffShift.dateAndTime"
                      defaultMessage="Date and Time"
                    />
                  </label>
                  <div className="date-time-container">
                    <label className="call-off-item-name">
                      <FormattedMessage
                        id="CalledOffShift.date"
                        defaultMessage="Date:"
                      />
                    </label>
                    <label className="call-off-item-value">{dateText}</label>
                    <label className="call-off-item-name">
                      <FormattedMessage
                        id="CalledOffShift.startTime"
                        defaultMessage="Start Time:"
                      />
                    </label>
                    <label className="call-off-item-value">{startTime}</label>
                    <label className="call-off-item-name">
                       <FormattedMessage
                        id="CalledOffShift.endTime"
                        defaultMessage="End Time:"
                      />
                    </label>
                    <label className="call-off-item-value">{endTime}</label>
                  </div>
                </div>

              </div>
              <div className="called-off-bottom-section">
                <div className="left-section">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CalledOffShift.jobTitle"
                      defaultMessage="Job Title"
                    />
                  </label>
                  <label className="bottom-item-value">{jobTitle}</label>
                </div>
                <div className="right-section">
                  <label className="bottom-item-name">
                    <FormattedMessage
                      id="CalledOffShift.description"
                      defaultMessage="Description"
                    />
                  </label>
                  <div className="description-container">
                    <label className="call-off-item-name">{reasons}</label>
                    
                  </div>
                </div>
              </div>
              <button className="button-called-off" onClick={this.handleButtonClick}>
                <FormattedMessage
                  id="CalledOffShift.calledOff"
                  defaultMessage="Called Off"
                />
              </button>
            </div>
            <div className="delete-btn-wrap">
              {(selectedShift.allow_delete && selectedShift.trade_status === 'called_off') && <button className="button-delete-shift" onClick={this.handleDeleteClick}>
                <FormattedMessage
                  id="CalledOffShift.delete"
                  defaultMessage="Delete"
                />
              </button>}
            </div>
  			</ModalWrapper>
  		</div>
  	);
  }
}

CalledOffShift.propTypes = {
  //userCookie: PropTypes.shape({}).isRequired,
  //costCenterData: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (CalledOffShift);

