import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TimeInput extends React.PureComponent {
  state = {
    selectedShift: null,
  };

  _isMounted = false;
  timeInput = React.createRef();

  _blurTimeInput = () => {
    this.timeInput.current.blur();
  };

  _focusTimeInput = () => {
    this.timeInput.current.focus();
  };

  componentDidMount () {
    const { selectedShift,} = this.props;
    this._isMounted = true;
    this.setState({selectedShift: selectedShift});
  };

  componentDidUpdate (prevProps) {
    const { selectedShift, } = this.props;

    if(prevProps.selectedShift !== selectedShift) {
      if(this._isMounted) {
        this.setState({selectedShift: selectedShift});
      }
    }
  };


  render() {
    const {
      _handleBlur,
      _handleChange,
      name,
      min,
      max,
      value,
      error,
      selectedShift,
    } = this.props;
    const classes = classNames({
      'time-picker__time-input': true,
      'time-picker__time-input--error': error,
    });
    /*
    if(selectedShift) {
      return (
        <input
          className={classes}
          onBlur={_handleBlur}
          onChange={_handleChange}
          name={name}
          min={min}
          max={max}
          type="number"
          value={value}
          ref={this.timeInput}
          readOnly={true}
        />
      );
    }
    */
    return (
      <input
        className={classes}
        onBlur={_handleBlur}
        onChange={_handleChange}
        name={name}
        min={min}
        max={max}
        type="number"
        value={value}
        ref={this.timeInput}
      />
    );
  }
}

TimeInput.propTypes = {
  _handleBlur: PropTypes.func.isRequired,
  _handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TimeInput;
