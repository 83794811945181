import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';

const UserAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 40px;
  width: 40px;
  border-radius: 50%;

`;

const ThumbWrap = styled.div`
  text-align: center;
  margin-bottom: 0.25rem;
`;

const Wrap = styled.p`
  /* font-family: ${({ theme }) => theme.fonts.primary}; */
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.palette.label};
  margin-bottom: 0.88rem;
`;

const StatusBadge = styled.span`
  color: ${props => (props.color ? props.color : null)};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : null)};
  padding: 3px 10px;
  margin-left: 15px;
  margin-right: 3px;
  line-height: 1.5;
  border-radius: 10px;
  font-weight: 700;
  

`;

export default {
	UserAvatar,
	ThumbWrap,
	Wrap,
  StatusBadge,
};