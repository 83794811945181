import { validateForSSO } from '_helpers/validation';

export const ROLES = {
  LOGGED_OUT: {
    key: 'logged_out',
  },
  NO_LOCATIONS: {
    key: 'no_locations',
  },
  PENDING_LOCATIONS: {
    key: 'pending_locations',
  },
  SSO: {
    key: 'sso',
  },
  USER: {
    key: 'user',
  },
  ADMIN: {
    key: 'admin',
  },
};

// checks for no locations
const noLocations = ({ current_user_privilege }) => !current_user_privilege;

// checks to see if user is a manager
const isManager = ({ current_user_privilege }) => current_user_privilege.is_admin;

/**
 * Main function for determining user role on the web app. Refer to top of file
 * for possible roles
 * @param  { Object } appState - Full appState in order to find setupActions
 * @param  { Object } _setAppState - Function that updates top level appState, need it
 * just in case setupActions is not present
 */
export const FindRole = (data) => {
  if (!data.meta) {
    // logged out
    return ROLES.LOGGED_OUT.key;
  }

  if (noLocations(data.meta)) {
    // redirects to "add-location"
    return ROLES.NO_LOCATIONS.key;
  }

  if (!data.meta.current_user_privilege.is_approved) {
    // User pending approval for selected location
    return ROLES.PENDING_LOCATIONS.key;
  }

  if (validateForSSO(data.setup_actions)) {
    // redirect to SSO
    return ROLES.SSO.key;
  }

  if (isManager(data.meta)) {
    // no redirect
    return ROLES.ADMIN.key;
  }

  // normal user logged in
  return ROLES.USER.key;
};
