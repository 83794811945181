import React from 'react';
import PropTypes from 'prop-types';

class LargeActionButton extends React.PureComponent {
  render() {
    const {
      text, className, _clickHandler, children,
    } = this.props;
    return (
      <button
        className={`shift-card__large-btn ${className}`}
        onClick={_clickHandler}
        type="button"
      >
        {children}
        {text}
      </button>
    );
  }
}

LargeActionButton.propTypes = {
  _clickHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
};

LargeActionButton.defaultProps = {
  className: '',
  text: '',
};

export default LargeActionButton;
