import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";

import { ProfileThumb } from "@shyft/acorns";
import Styled from "../styled";
import "./CommentForm.scss";

class CommentFormI18n extends React.Component {
  state = {
    value: ""
  };

  messages = defineMessages({
    commentPlaceholder: {
      id: "CommentForm.commentPlaceholder",
      defaultMessage: "Write a comment"
    }
  });

  _handleChange = e => {
    this.setState({ value: e.target.value });
  };

  _handleSubmit = e => {
    const { _handleCommentSubmit } = this.props;
    const { value } = this.state;
    e.preventDefault();
    
    if(!value) {
      return;
    }

    _handleCommentSubmit(value);
    this.setState({ value: "" });
  };

  render() {
    const {
      userCookie,
      intl: { formatMessage }
    } = this.props;
    const { value } = this.state;
    const submitBtnClasses = classNames({
      "comment-form__submit-btn": true,
      "comment-form__submit-btn--active": value
    });

    return (
      <div className="comment-form">
        <Styled.ThumbWrap>
          <ProfileThumb
            thumbUrl={
              userCookie.profile_image
                ? userCookie.profile_image.thumb_url
                : null
            }
          />
        </Styled.ThumbWrap>
        <form onSubmit={this._handleSubmit}>
          <div className="comment-form__form-group">
            <input
              className="comment-form__input"
              onChange={this._handleChange}
              placeholder={formatMessage(this.messages.commentPlaceholder)}
              type="text"
              value={value}
            />
            <button className={submitBtnClasses} type="submit">
              <FormattedMessage
                id="CommentForm.sendBtn"
                defaultMessage="Send"
              />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
CommentFormI18n.propTypes = {
  _handleCommentSubmit: PropTypes.func.isRequired,
  userCookie: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired
};

const CommentForm = injectIntl(CommentFormI18n);

export { CommentForm };
