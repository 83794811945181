import { shiftNames } from '_constants';

export const shiftStatuses = {
  AVAILABLE: 'AVAILABLE',
  YOUR_POSTED: 'YOUR_POSTED',
  YOUR_UNPOSTED: 'YOUR_UNPOSTED',
  YOUR_APPROVED: 'YOUR_APPROVED',
  YOUR_COVERED: 'YOUR_COVERED',
  YOUR_PENDING: 'YOUR_PENDING',
  YOUR_REJECTED: 'YOUR_REJECTED',
  YOUR_SHIFT: 'YOUR_SHIFT',
  YOUR_CALLED_OFF: 'YOUR_CALLED_OFF',
  COVERING_APPROVED: 'COVERING_APPROVED',
  COVERING_PENDING: 'COVERING_PENDING',
  COVERING_SHIFT: 'COVERING_SHIFT',
  COVERING_REJECTED: 'COVERING_REJECTED',
  COVERING_VTO: 'COVERING_VTO',
  YOUR_VTO: 'YOUR_VTO',
  VTO_APPROVED: 'VTO_APPROVED',
  VTO_PENDING: 'VTO_PENDING',
  VTO_REJECTED: 'VTO_REJECTED',
};

export const shiftStatus = (shift, user) => {
  let myShift = false;
  let coveringShift = false;
  let vtoShift = false;
  let approved = false;
  let rejected = false;
  let covered = false;
  let pending = false;
  let posted = false;
  let calledOff = false;
  let myAppliedShift = false;
  let myAvailableShift = false;
  let myRejectedShift = false;
  let shiftHasApplicantNotMe = false;

  if(shift && shift.owner && user){
    if(shift.id === 3950029) {
      //console.log('here');
    }
    if(shift.current_applicants_pending_user_ids && shift.current_applicants_pending_user_ids.length > 0) {
      for(let i=0; i<shift.current_applicants_pending_user_ids.length; i++) {
        if(shift.current_applicants_pending_user_ids[i] !== user.user_id) {
          shiftHasApplicantNotMe = true;
          break;
        }
      }
    }

    myShift = shift.owner.id === user.user_id && shift.name !== 'shift_vto'
   
    if(shift.cover_mode === 'multi_applicants_picked_by_manager') {
      if(shift.current_applicant_status === 'applied' || shift.current_applicant_status === 'picked') {
        coveringShift = true;
      }
    }else if(shift.cover_mode === 'default') {
      if(shift.coverer !== null) {
        coveringShift = true;
      }else {
        if(shift.current_applicant_status === 'picked') {
          coveringShift = true;
        }
      }
    }

    vtoShift = shift.name === shiftNames.SHIFT_VTO;
    
    if(shift.cover_mode === 'multi_applicants_picked_by_manager') {
      if(shift.current_applicant_status === 'applied') {
        myAppliedShift = true;
      }
    }else if(shift.cover_mode === 'default') {
      if(shift.current_applicant_status === 'nominated') {
        myAppliedShift = true;
      }
    }
    if(shift.cover_mode === 'multi_applicants_picked_by_manager') {
      if(shift.current_applicant_status && shift.current_applicant_status === 'denied') {
        myRejectedShift = true;
      }
    }else if(shift.cover_mode === 'default') {
      if(shift.coverer && shift.coverer.id !== user.user_id) {
        myRejectedShift = true;
      }else {
        if(shift.current_applicant_status === 'denied') {
          myRejectedShift = true;
        }
      }
    }

    if(shift.name === 'shift') {
      if(shift.owner.id === user.user_id) {
        myAvailableShift = false;
      }else {
        if(shift.trade_status !== 'approved' && shift.trade_status !== 'covered') {
          if(!shift.current_applicant_status) {
            myAvailableShift = true;
          }else {
            if(shift.current_applicant_status !== 'denied' && shift.current_applicant_status !== 'approved') {
              myAvailableShift = true;
            }
          }
        }
      }
    }else {
      if(shift.cover_mode === 'multi_applicants_picked_by_manager') {
        if(!shift.current_applicant_status && !shift.coverer){
          if(shift.current_applicants_pending_user_ids.length > 0) {
            const idx = shift.current_applicants_pending_user_ids.findIndex((id) => id === user.user_id);
            if(idx < 0) {
              myAvailableShift = true;
            }
          }else {
            if(shift.available_shifts_covered < shift.total_shifts_available) {
              myAvailableShift = true;
            }
          } 
        }
      }
    }

    //approved = shift.trade_status === 'approved' || (shift.current_applicant_status && shift.current_applicant_status === 'picked'); 
    if(shift.trade_status === 'approved' || shift.trade_status === 'posted') {
      if((shift.current_applicant_status === 'picked') || shift.coverer || shift.total_shifts_available === shift.available_shifts_covered) {
        approved = true;
      }
    }else {
      if(shift.trade_status === 'covered') {
        if(shift.current_applicant_status && shift.current_applicant_status === 'picked') {
          approved = true;
        }
      }
    }
    rejected = shift.trade_status === 'rejected' || (shift.current_applicant_status && shift.current_applicant_status === 'denied');
    //covered = shift.trade_status === 'covered' || shift.available_shifts_covered === shift.total_shifts_available;
    if(shift.trade_status === 'covered') {
      covered = true;
    }else if(shift.available_shifts_covered && shift.total_shifts_available && shift.available_shifts_covered === shift.total_shifts_available) {
      covered = true;
    }
    pending = shift.trade_status === 'pending' || 
            (shift.current_applicants_pending_user_ids && shift.current_applicants_pending_user_ids.length > 0);
    posted = shift.trade_status === 'posted' && shift.owner.id === user.user_id;
    calledOff = shift.trade_status === 'called_off';

    if (vtoShift && approved && !myRejectedShift) {
      // Your posted shift is approved
      return shiftStatuses.VTO_APPROVED;
    }
    if (vtoShift && pending && !shiftHasApplicantNotMe) {
      // Your posted shift is covered without needing approval
      return shiftStatuses.VTO_PENDING;
    }
    if (vtoShift && posted && !covered) {
      if(shift.owner.id === user.user_id) {
        if(pending || shiftHasApplicantNotMe) {
          return shiftStatuses.VTO_PENDING;
        }else {
          return shiftStatuses.AVAILABLE;
        }
      }else {
        return shiftStatuses.AVAILABLE;
      }
    }
    if(vtoShift && covered &&!myRejectedShift) {
      return shiftStatuses.VTO_APPROVED;
    }
    if(vtoShift && myAvailableShift) {
      return shiftStatuses.AVAILABLE;
    }
    if (vtoShift && rejected) {
      // Your posted shift was rejected
      return shiftStatuses.VTO_REJECTED;
    }

    if (myShift && approved) {
      // Your posted shift is approved
      return shiftStatuses.YOUR_APPROVED;
    }
    if (myShift && covered) {
      // Your posted shift is covered without needing approval
      return shiftStatuses.YOUR_COVERED;
    }
    if (coveringShift && approved ) {
      // Shift is approved and you are covering
      return shiftStatuses.COVERING_APPROVED;
    }
    if (myShift && rejected) {
      // Your posted shift was rejected
      return shiftStatuses.YOUR_REJECTED;
    }
    if (coveringShift && rejected) {
      // Your covering shift was rejected
      return shiftStatuses.COVERING_REJECTED;
    }
    if (myShift && pending) {
      // Your posted shift is pending approval
      return shiftStatuses.YOUR_PENDING;
    }
    if (coveringShift && pending) {
      // Your covering shift is pending approval
      return shiftStatuses.COVERING_PENDING;
    }
    if (myShift && posted &&!shiftHasApplicantNotMe) {
      // Your shift is posted but not covered
      return shiftStatuses.YOUR_POSTED;
    }
    if (myShift && !posted && !calledOff) {
      // Your shift but not posted yet
      return shiftStatuses.YOUR_UNPOSTED;
    }
    if (shift.owner && shift.cover_mode === 'default' && shift.owner.id !== user.user_id && !shift.current_applicant_status) {
      // Shift is coverable by you
      return shiftStatuses.AVAILABLE;
    }
    if (myShift && calledOff) {
      // Your shift but not posted yet
      return shiftStatuses.YOUR_CALLED_OFF;
    }
    if(myAppliedShift && shift.trade_status === 'posted') {
      // Your applied shift is pending approval
      return shiftStatuses.COVERING_PENDING;
    }
    if(myAvailableShift && shift.trade_status === 'posted') {
      // Your available shift
      return shiftStatuses.AVAILABLE;
    }
    if(myRejectedShift) {
       // Your covering shift was rejected
      return shiftStatuses.COVERING_REJECTED;
    }
    if(shiftHasApplicantNotMe) {
      // Your covering shift is pending approval
      return shiftStatuses.COVERING_PENDING;
    }

    return null;
  }
};

export const shiftOwner = (shift, user) => {
  const myShift = shift.owner && shift.owner.id === user.user_id;
  //const coveringShift = shift.coverer && shift.coverer.id === user.user_id;
  /*
  const coveringShift = (shift.coverer && shift.coverer.id === user.user_id) ||
                    (shift.cover_mode === 'multi_applicants_picked_by_manager' && shift.current_applicant_status !== null);
  */
  let coveringShift = false;
  if(shift.coverer) {
    if(shift.coverer.id === user.user_id) {
      coveringShift = true;
    }
  }else {
    if(shift.cover_mode === 'multi_applicants_picked_by_manager') {
      if(shift.current_applicant_status !== null) {
        coveringShift = true;
      }
    }else {
      if(shift.current_applicants_approved_user_ids) {
        const idx = shift.current_applicants_approved_user_ids.findIndex((id) => id === user.user_id);
        if(idx >= 0) {
          coveringShift = true;
        }
      }else {
        if(shift.current_applicant_status !== null) {
          coveringShift = true;
        }
      }
    }
  }
  const coveringVTO = coveringShift && shift.name === 'shift_vto';
  const myVTO = myShift && shift.name === 'shift_vto';
  const  myAppliedShift = shift.cover_mode === 'multi_applicants_picked_by_manager' && 
                    shift.current_applicant_status === 'applied';
  const  myDeniedShift = shift.cover_mode === 'multi_applicants_picked_by_manager' && 
                    shift.current_applicant_status === 'denied';

  if (coveringVTO) {
    // VTO shift you are covering
    return shiftStatuses.COVERING_VTO;
  }
  if (myVTO) {
    // VTO shift you posted as a manager
    return shiftStatuses.YOUR_VTO;
  }
  if (myShift) {
    return shiftStatuses.YOUR_SHIFT;
  }
  if (coveringShift) {
    return shiftStatuses.COVERING_SHIFT;
  }
  if(myAppliedShift) {
    return shiftStatuses.COVERING_SHIFT;
  }
  if(myDeniedShift) {
    return shiftStatuses.COVERING_REJECTED;
  }
  return null;
};
