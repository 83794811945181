import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { getComments, createComment, getFreePosts, createFreePost } from '_helpers/api-calls/comments';
import { getUserInfo, getUserInfoNonAdmin, getUserInfo2, } from '_helpers/api-calls/user-calls';
import { apiCallError } from '_helpers/errors';
import Comment  from './components/Comment/Comment';
import { CommentForm } from './components/CommentForm';
import './CommentsSection.scss';
import DeleteComment  from "./components/Comment/DeleteComment";
import Modal from './components/Comment/Modal';
import styled from './components/styled';


class CommentsSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.commentsSectionRef = React.createRef();
    this.innerWrapRef = React.createRef();

    this.state = {
      comments: [],
      freePosts: [],
      ownerFullName: '',
      showDeleteWin: false,
      commentId: 0,
    };

    this.tempPosts = [];
    this.postIndex = 0;
    this._isMounted = false;

  }

  componentDidMount() {
    const { selectedShift, locationSettings, userCookie, _toggleModal, } = this.props;
    this._isMounted = true;

    let ownerFullName = '';
    if(selectedShift && selectedShift.owner) {
      ownerFullName = `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`;
      this.setState({ownerFullName: ownerFullName});
    }
    
    this._getComments();
    if(selectedShift && selectedShift.post_id) {
      getFreePosts(selectedShift.post_id)
        .then((response) => {
          let posts = [];
          let i = 0;
          for(i=0; i<response.data.posts.length; i++){
            let commentObj = {content:'', 
                            created_at: '', 
                            id: null,
                            owner_id: null, 
                            owner: null, 
                            source_id: null};
            commentObj.content = response.data.posts[i].content;
            commentObj.created_at = response.data.posts[i].created_at;
            commentObj.id = response.data.posts[i].id;
            commentObj.source_id = response.data.posts[i].postable_id;
            commentObj.owner_id = response.data.posts[i].owner_id; 
            if(i === 0) {
              getUserInfoNonAdmin(response.data.posts[i].owner_id, selectedShift.location_id).then((response1) => {
                this._handleOwnerInfo(response1);
              }).catch((error1) =>{
                if(response.data.posts && response.data.posts.length > 0) {
                  this._handleOwnerError(error1, response.data.posts[i].owner_id, selectedShift.location_id);
                }
              });
            }
            posts.push(commentObj);
          }
          this.setState({freePosts: posts});
        })
        .catch((error) => {
          apiCallError(error);
      });
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  _getComments = () => {
    const { selectedShift } = this.props;

    if(selectedShift.parent_id > 0) {
      if(selectedShift.parent_post_id) {
        getComments(selectedShift.parent_post_id)
        .then((response) => {
          this.setState({
            comments: response.data.comments,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
      }
    }else {
      if(selectedShift.post_id) {
        getComments(selectedShift.post_id)
        .then((response) => {
          this.setState({
            comments: response.data.comments,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
      }
    }
  };

  _handleCommentSubmit = (commentText) => {
    const { selectedShift } = this.props;
    const { comments } = this.state;
    /*
    if(selectedShift.parent_id > 0) {
      createComment(selectedShift.parent_post_id, commentText)
        .then((response) => {
          this.setState({
            comments: [...comments, response.data.comment],
          });

          const innerWrap = this.innerWrapRef.current;
          const commentSection = this.commentsSectionRef.current;

          anime({
            targets: commentSection,
            scrollTop: innerWrap.clientHeight,
            duration: 4000,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
    }else {
      createComment(selectedShift.post_id, commentText)
        .then((response) => {
          this.setState({
            comments: [...comments, response.data.comment],
          });

          const innerWrap = this.innerWrapRef.current;
          const commentSection = this.commentsSectionRef.current;

          anime({
            targets: commentSection,
            scrollTop: innerWrap.clientHeight,
            duration: 4000,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
    }
    */

    if(selectedShift.parent_id > 0) {
      createFreePost(selectedShift.parent_post_id, commentText)
        .then((response) => {
          this.setState({
            comments: [...comments, response.data.post],
          });

          const innerWrap = this.innerWrapRef.current;
          const commentSection = this.commentsSectionRef.current;

          anime({
            targets: commentSection,
            scrollTop: innerWrap.clientHeight,
            duration: 4000,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
    }else {
      createFreePost(selectedShift.post_id, commentText)
        .then((response) => {
          this.setState({
            comments: [...comments, response.data.post],
          });

          const innerWrap = this.innerWrapRef.current;
          const commentSection = this.commentsSectionRef.current;

          anime({
            targets: commentSection,
            scrollTop: innerWrap.clientHeight,
            duration: 4000,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
    }
  };



   _sortCreatedWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.created_at === b.created_at) // identical? return 0
        return 0;
      else if (a.created_at === null)  // a is null? last 
        return 1;
      else if (b.created_at === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.created_at.localeCompare(b.created_at) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  _renderComments = () => {
    const { selectedShift, userCookie, _toggleModal, } = this.props;
    const { comments, freePosts, } = this.state;
    // Filter comments to address duplicate reason bug in API
    let filteredComments = comments.filter(comment => comment.content !== selectedShift.content && comment.created_at !== selectedShift.created_at);
    if(freePosts.length > 0){
      filteredComments = filteredComments.concat(freePosts)
    }
    // sort by created_at
    this._sortCreatedWithNullValues(filteredComments, true);
    const commentElements = filteredComments.map(comment => (
      <div
        className="modal-components__row modal-components__row--comment"
        key={comment.id}
      >
        <div className="modal-components__col">
          <Comment 
          comment={comment} 
          userCookie={userCookie}
          _toggleModal={_toggleModal}
          handleShowDeleteWin={this.handleShowDeleteWin}
          sub />
        </div>
      </div>
    ));

    return commentElements;
  };

  _handleOwnerInfo = (response) => {
    const { selectedShift, } = this.props;

    let owner = {first_name: '', last_name: '', profile_image: null};
    let posts = [...this.state.freePosts];
    owner.first_name = response.data.user.first_name;
    owner.last_name = response.data.user.last_name;
    owner.profile_image = response.data.user.profile_image;
    posts[this.postIndex].owner = owner;
    this.setState({freePosts: posts});
    this.postIndex += 1;
    if(this.postIndex < posts.length) {
      getUserInfoNonAdmin(posts[this.postIndex].owner_id, selectedShift.location_id).then((response1) => {
        this._handleOwnerInfo(response1);
      }).catch((error1) =>{
        this._handleOwnerError(error1, posts[this.postIndex].owner_id, selectedShift.location_id);
      });
    }
  }

  _handleOwnerError = (error, ownerId, locationId) => {
    const { selectedShift, userCookie, } = this.props;

    let msg = `getUserInfoNonAdmin failed. Ower id: ${ownerId}, location id: ${locationId}`;
    console.log(msg);
    //possible reason is that the user is not on currrent location. Try to call api without location
    this.findOwnerIgnoreLocation(ownerId, this.postIndex);

    let posts = [...this.state.freePosts];
    this.postIndex += 1;
    if(this.postIndex < posts.length) {
      getUserInfoNonAdmin(posts[this.postIndex].owner_id, selectedShift.location_id).then((response) => {
        this._handleOwnerInfo(response);
      }).catch((error) => {
         this._handleOwnerError(error, posts[this.postIndex].owner_id, selectedShift.location_id);
      });
    }
  }

  findOwnerIgnoreLocation = (ownerId, index) => {
    let msg = '';

    let owner = {first_name: '', last_name: '', profile_image: null};
    let posts = [...this.state.freePosts];
    getUserInfo2(ownerId).then((data) => {
      owner.first_name = data.data.user.first_name;
      owner.last_name = data.data.user.last_name;
      owner.profile_image = data.data.user.profile_image;
      posts[index].owner = owner;
      this.setState({freePosts: posts});
    }).catch((error2) => {
      msg = `getUserInfo2 failed. Owner id: ${ownerId},Error: ${error2}`;
      console.log(msg);
    });
  }

  handleShowDeleteWin = (show, commentId) => {
    const { freePosts, comments, } = this.state;

    if(show) {
      this.setState({showDeleteWin: true, commentId: commentId});
    }else {
      this.setState({showDeleteWin: false});
      if(commentId > 0) {
        const filteredPosts = freePosts.filter((post) => post.id !== commentId);
        this.setState({freePosts: filteredPosts});

        const filteredComments = comments.filter((comment) => comment.id !== commentId);
        this.setState({comments: filteredComments});
      }
    }
  };

  render() {
    const { selectedShift, size, userCookie, locationSettings, _toggleModal, } = this.props;
    const { ownerFullName, showDeleteWin, commentId, } = this.state;
    let message = '';
    let defaultMessage = '';

    const msgPostedShift =(<FormattedMessage
            id="CommentsSection.postedTeamShift"
            defaultMessage="Is looking for someone to cover this shift."
          />);
    const msgPostedOpenShift =(<FormattedMessage
            id="CommentsSection.postedOpenShift"
            defaultMessage="Is looking for someone to cover this open shift."
          />);
    const msgPostedVTO =(<FormattedMessage
            id="CommentsSection.postedVTO"
            defaultMessage="Is looking for volunteers to take time off."
          />);
    
    switch(selectedShift.name) {
      case 'open_shift':
        message = '';
        defaultMessage = msgPostedOpenShift;
        break;
      case 'shift':
        message = selectedShift.content;
        defaultMessage = msgPostedShift;
        break;
      case 'shift_vto':
        message = '';
        defaultMessage = msgPostedVTO;
        break;
    }

    const initialComment = {
      created_at: selectedShift.created_at,
      content: message,
      owner: selectedShift.owner,
      default: defaultMessage,
    };
    const wrapClasses = classNames({
      'comments-section__comment-wrap': true,
      [`comments-section__comment-wrap--${size}`]: true,
    });

    let showComments = 0;
    if(locationSettings && locationSettings.cfg__free_post__shift_post_comments !== undefined) {
      showComments = locationSettings.cfg__free_post__shift_post_comments;
    }

    return (
      <>
        <div className="modal-components__row">
          <div className="modal-components__col">
            <Comment comment={initialComment} />
          </div>
        </div>
        <div className={wrapClasses} ref={this.commentsSectionRef}>
          <div className="comments-section__inner-wrap" ref={this.innerWrapRef}>
            {showComments === 1 && this._renderComments()}
          </div>
        </div>

        {showComments === 1 && <div className="modal-components__row">
          <div className="modal-components__col">
            <CommentForm
              _handleCommentSubmit={this._handleCommentSubmit}
              userCookie={userCookie}
            />
          </div>
        </div>}
        {showDeleteWin && 
          <DeleteComment
            _toggleModal={_toggleModal}
            locationSettings={locationSettings}
            handleShowDeleteWin={this.handleShowDeleteWin}
            commentId={commentId}
          />
        }
        <styled.Overlay display={showDeleteWin ? 'block' : 'none'} />
      </>
    );
  }
}

CommentsSection.propTypes = {
  selectedShift: PropTypes.shape({}).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  userCookie: PropTypes.shape({}).isRequired,
};

CommentsSection.defaultProps = {
  size: 'medium',
};

export { CommentsSection };
