import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const RegionalBadge = (props) => {
  const { regional } = props;

  if (regional) {
    return (
      <span className="shift-card__badge shift-card__badge--card-type">
        <FormattedMessage
          id="RegionalBadge.regionalShift"
          defaultMessage="REGIONAL"
        />
      </span>
    );
  }

  return null;
};

RegionalBadge.propTypes = {
  userCookie: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
};

export default RegionalBadge;
