import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import TimeInput from '../TimeInput';
import PeriodButton from '../PeriodButton/PeriodButton';
import { canSubmit, makeTimeObjs } from './helpers';
import { DataNames } from '../../constants';

import './TimePicker.scss';

class TimePicker extends React.Component {
  startMinRef = React.createRef();

  endMinRef = React.createRef();

  endHourRef = React.createRef();

  state = {
    start: {
      hour: '',
      min: '',
      period: 'am',
    },
    end: {
      hour: '',
      min: '',
      period: 'pm',
    },
    errors: {
      startHourError: false,
      startMinError: false,
      endHourError: false,
      endMinError: false,
    },
  };

  _checkHourError = (hour) => {
    const { errors } = this.state;

    if ((hour && hour.value < 0) || hour.value > 24) {
      switch (hour.name) {
        case 'startHour':
          errors.startHourError = true;
          this.setState({ errors });
          break;
        case 'endHour':
          errors.endHourError = true;
          this.setState({ errors });
          break;
        default:
          break;
      }
    } else if (hour) {
      switch (hour.name) {
        case 'startHour':
          errors.startHourError = false;
          this.setState({ errors });
          break;
        case 'endHour':
          errors.endHourError = false;
          this.setState({ errors });
          break;
        default:
          break;
      }
    }
  };

  _checkMinError = (min) => {
    const { errors } = this.state;

    if ((min && min.value < 0) || min.value > 59 || min.value.length < 2) {
      switch (min.name) {
        case 'startMin':
          errors.startMinError = true;
          this.setState({ errors });
          break;
        case 'endMin':
          errors.endMinError = true;
          this.setState({ errors });
          break;
        default:
          break;
      }
    } else if (min) {
      switch (min.name) {
        case 'startMin':
          errors.startMinError = false;
          this.setState({ errors });
          break;
        case 'endMin':
          errors.endMinError = false;
          this.setState({ errors });
          break;
        default:
          break;
      }
    }
  };

  _handleClear = (e) => {
    e.preventDefault();
    const { start, end } = this.state;
    const { _updateState } = this.props;

    start.hour = '';
    start.min = '';
    start.period = 'am';
    end.hour = '';
    end.min = '';
    end.period = 'pm';

    _updateState(DataNames.DATE, null);
    _updateState(DataNames.TIMES, []);

    this.setState({
      start,
      end,
      errors: {
        startHourError: false,
        startMinError: false,
        endHourError: false,
        endMinError: false,
      },
    });
  };

  _handleEndPeriod = (period) => {
    const { end } = this.state;
    end.period = period;

    this.setState({ end });
  };

  _handleStartPeriod = (period) => {
    const { start } = this.state;
    start.period = period;

    this.setState({ start });
  };

  _handleHourBlur = (e) => {
    const hour = e.target;

    this._checkHourError(hour);
  };

  _handleMinBlur = (e) => {
    const min = e.target;

    this._checkMinError(min);
  };

  _handleTimeChange = (e) => {
    const { start, end } = this.state;
    const changeHourFocusCondition = e.target.value.length > 1 && e.target.value <= 12;
    const changeMinFocusCondition = e.target.value.length > 1 && e.target.value <= 59;

    if (e.target.value.length < 3) {
      switch (e.target.name) {
        case 'startHour':
          start.hour = e.target.value;
          this.setState({ start });
          if (changeHourFocusCondition) {
            this.startMinRef.current._focusTimeInput();
          }
          break;
        case 'startMin':
          start.min = e.target.value;
          this.setState({ start });
          if (changeMinFocusCondition) {
            this.endHourRef.current._focusTimeInput();
          }
          break;
        case 'endHour':
          end.hour = e.target.value;
          this.setState({ end });
          if (changeHourFocusCondition) {
            this.endMinRef.current._focusTimeInput();
          }
          break;
        case 'endMin':
          end.min = e.target.value;
          this.setState({ end });
          if (changeMinFocusCondition) {
            this.endMinRef.current._blurTimeInput();
          }
          break;
        default:
          break;
      }
    }
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    const { _toggleDatePicker, _updateState, date } = this.props;
    const times = makeTimeObjs(this.state);

    if (canSubmit(this.state, date)) {
      _updateState(DataNames.TIMES, times);
      _toggleDatePicker();
    }
  };

  _renderStartError = () => {
    const { errors } = this.state;
    if (errors.startHourError || errors.startMinError) {
      return (
        <p className="time-picker__time-error">
          <FormattedMessage
            id="TimePicker.startTimeError"
            defaultMessage="Invalid time"
          />
        </p>
      );
    }
    return null;
  };

  _renderEndError = () => {
    const { errors } = this.state;
    if (errors.endHourError || errors.endMinError) {
      return (
        <p className="time-picker__time-error">
          <FormattedMessage
            id="TimePicker.endTimeError"
            defaultMessage="Invalid time"
          />
        </p>
      );
    }
    return null;
  };

  render() {
    const { date, locale } = this.props;
    const { errors, start, end } = this.state;
    const applyBtnClasses = classNames({
      'time-picker__action-btn time-picker__action-btn--apply': true,
      'time-picker__action-btn--apply--inactive': !canSubmit(this.state, date),
    });

    return (
      <div className="time-picker">
        <form onSubmit={this._handleSubmit}>
          <div className="time-picker__row">
            <p className="time-picker__label">
              <FormattedMessage
                id="TimePicker.startTimeLabel"
                defaultMessage="Start Time"
              />
            </p>
            <div className="time-picker__time-wrap">
              <TimeInput
                _handleBlur={this._handleHourBlur}
                _handleChange={this._handleTimeChange}
                error={errors.startHourError}
                min="0"
                max="24"
                name="startHour"
                value={start.hour}
              />
              <span className="time-picker__colon">:</span>
              <TimeInput
                _handleBlur={this._handleMinBlur}
                _handleChange={this._handleTimeChange}
                error={errors.startMinError}
                min="0"
                max="59"
                name="startMin"
                ref={this.startMinRef}
                value={start.min}
              />
              <div className="time-picker__period-wrap">
                {locale === 'fr' || (
                  <PeriodButton
                    _handlePeriodClick={this._handleStartPeriod}
                    active={start.period}
                  />
                )}
              </div>
              {this._renderStartError()}
            </div>
          </div>
          <div className="time-picker__row">
            <p className="time-picker__label">
              <FormattedMessage
                id="TimePicker.endTimeLabel"
                defaultMessage="End Time"
              />
            </p>
            <div className="time-picker__time-wrap">
              <TimeInput
                _handleBlur={this._handleHourBlur}
                _handleChange={this._handleTimeChange}
                error={errors.endHourError}
                min="0"
                max="24"
                name="endHour"
                ref={this.endHourRef}
                value={end.hour}
              />
              <span className="time-picker__colon">:</span>
              <TimeInput
                _handleBlur={this._handleMinBlur}
                _handleChange={this._handleTimeChange}
                error={errors.endMinError}
                min="0"
                max="59"
                name="endMin"
                ref={this.endMinRef}
                value={end.min}
              />
              <div className="time-picker__period-wrap">
                {locale === 'fr' || (
                  <PeriodButton
                    _handlePeriodClick={this._handleEndPeriod}
                    active={end.period}
                  />
                )}
              </div>
              {this._renderEndError()}
            </div>
          </div>
          <div className="time-picker__row time-picker__row--footer">
            <div className="time-picker__footer">
              <button
                className="time-picker__action-btn time-picker__action-btn--clear"
                onClick={this._handleClear}
                type="button"
              >
                <FormattedMessage
                  id="TimePicker.clearTime"
                  defaultMessage="Clear"
                />
              </button>
              <button className={applyBtnClasses} type="submit">
                <FormattedMessage
                  id="TimePicker.submitTime"
                  defaultMessage="Apply"
                />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

TimePicker.propTypes = {
  _updateState: PropTypes.func.isRequired,
  _toggleDatePicker: PropTypes.func.isRequired,
  date: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
};

export default TimePicker;
