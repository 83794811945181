import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { CommentsSection } from "_components/GeneralComponents/Comments";
import { createDateString, createTimeString } from "_helpers/formatting";

class CoverConfirm2 extends React.Component {

  constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      to: {
        id: 'CoverComments2.to',
        defaultMessage: 'to',
      },
      
    });

    this.state = {
      showDatePicker: false,
      
    };
  }

  renderAddress = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const currentLocation = locations.filter(
      (l) => l.id === selectedShift.location_id
    );

    if (currentLocation.length) {
      return `${currentLocation[0].location_name}, ${currentLocation[0].formatted_address}`;
    }

    return "";
  }

  renderTitle = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    if (selectedShift.name === "shift_vto") {
      return (
        <FormattedMessage
          id="CoverConfirm2.coverShiftForVTO"
          defaultMessage="Take Voluntary Time Off?"
        />
      );
    }
    
    return (
      <FormattedMessage
        id="CoverConfirm2.coverShiftFor"
        defaultMessage="Cover This Shift for {name}?"
        values={{
          name: selectedShift && selectedShift.owner ? `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`: '',
        }}
      />
    );
  }

  renderType = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const teamShift = (
              <FormattedMessage
                id="CoverConfirm2.teamShift"
                defaultMessage="Team Shift"
              />);
     const openShift = (
              <FormattedMessage
                id="CoverConfirm2.openShift"
                defaultMessage="Open Shift"
              />);
     const vto = (
              <FormattedMessage
                id="CoverConfirm2.vto"
                defaultMessage="Voluntary Time Off"
              />);

    let typeName = '';
    switch(selectedShift.name) {
      case 'shift':
        typeName = teamShift;
        break;
      case 'open_shift':
        typeName = openShift;
        break;
      case 'shift_vto':
        typeName = vto;
        break;
    }
    return typeName;
  }

  renderJobTitle = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    return selectedShift.primary_job;
  }

  renderDate = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const startDate = moment(selectedShift.start_at).format('ddd, MMM, DD');
    return startDate;
  }

  renderTime = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, intl } = this.props;

    const startTime = moment(selectedShift.start_at).format('h:mm A');
    const endTime = moment(selectedShift.end_at).format('h:mm A');
    const timeRange = `${startTime} ${intl.formatMessage(this.messages.to)} ${endTime}`;
    return timeRange;
  }

  renderOwner = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, intl } = this.props;

    const ownerName =  selectedShift && selectedShift.owner ? `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`: '';
    return ownerName;
  }

  renderButtonText = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    if (selectedShift.name === "shift_vto") {
      return (
        <FormattedMessage id="CoverConfirm2.takeVTO" defaultMessage="TAKE VTO" />
      );
    }

    return (
      <FormattedMessage
        id="CoverConfirm2.coverThisShift"
        defaultMessage="Cover This Shift"
      />
    );
  }

  renderTotalShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    if(selectedShift.name === 'shift') {
      return '1';
    }else {
      if(selectedShift.parent_card) {
        return selectedShift && selectedShift.total_shifts_available ? selectedShift.total_shifts_available : ' ';
      }else {
        return '1'; 
      }
    }
  }

  renderAppliedShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    if(selectedShift) {
      if(selectedShift.current_applicants_pending_user_ids) {
        //return selectedShift.current_applicants_pending_user_ids.length;
        //Fixed Bug WS-1833: applied applicants should be all applicants.
        const totalApplicants = selectedShift.current_applicants_pending_user_ids.length 
                                  + selectedShift.current_applicants_approved_user_ids.length
                                  + selectedShift.current_applicants_denied_user_ids.length
                                  + selectedShift.current_applicants_nominated_user_ids.length;
        return totalApplicants;
      }else {
        return '0';
      }
      
    }
  }

  renderApprovedShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    //return selectedShift.available_shifts_covered;
    if(selectedShift) {
      if(selectedShift.current_applicants_approved_user_ids) {
        return selectedShift.current_applicants_approved_user_ids.length;
      }else {
        if(selectedShift.available_shifts_covered) {
          return selectedShift.available_shifts_covered;
        }else {
          return '0';
        }
      }
    }
  }

  renderLocation = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    if(selectedShift) {
      if(selectedShift.location) {
        return selectedShift.location.formatted_address;
      }else {
        return '';
      }
    }
    //return selectedShift.location.formatted_address;
  }

  renderShiftSkills = () => {
    const { selectedShift, locationSkills, } = this.props;
    let shiftSkills = [];
    if(selectedShift && selectedShift.skill_ids && selectedShift.skill_ids.length > 0) {
      if(locationSkills && locationSkills.length > 0) {
        for(let i=0; i<selectedShift.skill_ids.length; i++) {
          for(let j=0; j<locationSkills.length; j++) {
            if(selectedShift.skill_ids[i] === locationSkills[j].id) {
              shiftSkills.push(locationSkills[j])
            }
          }
        }
      }
    }
    if(shiftSkills.length > 0) {
      const skills = shiftSkills.map((skill) => (
        <label className="cover-comments2__skill">
          {skill.skill_content}
        </label>
      ));
      if(skills && skills[0]) {
        return skills;
      }else {
        return null;
      }
    }else {
      return (
        <label className="cover-comments2__skill">
          <FormattedMessage
            id="CoverConfirm2.noSkillAssigned1"
            defaultMessage="No skills assigned to this shift"
          />
        </label>
      );
    }
  }

  render () {
    const { _toggleScreen, userCookie, selectedShift, locations, _coverShiftAction, locationSettings, } = this.props;

    const showSkillSection = locationSettings.cfg__skill === 1 && selectedShift.name !== 'shift';
    return (
      <>
      <h1>{this.renderTitle()}</h1>
      <div className="cover-comments2__top-row">
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.type"
                defaultMessage="Type"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderType()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.jobTitle"
                defaultMessage="Job Title"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderJobTitle()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.date"
                defaultMessage="Date"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderDate()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.time"
                defaultMessage="Time"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderTime()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.postedBy"
                defaultMessage="Posted By"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderOwner()}
          </label>
        </div>
      </div>
      <div className="cover-comments2__top-row">
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.shifts"
                defaultMessage="Shifts"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderTotalShifts()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.applied"
                defaultMessage="Applied"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderAppliedShifts()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.approved"
                defaultMessage="Approved"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderApprovedShifts()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.location"  
                defaultMessage="Location"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderLocation()}
          </label>
        </div>
      </div>
      {showSkillSection && <div className="cover-comments2__top-row">
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverConfirm2.shiftSkills"  
                defaultMessage="Skills"
              />
          </label>
          <div className="cover-comments2__skill-wrap">
            {this.renderShiftSkills()}
          </div>
        </div>
      </div>}
      <div className="modal-components__row">
         <div className="modal-components__col modal-components__col--submit">
          <button
            className="modal-components__submit-btn modal-components__submit-btn--back"
            onClick={_toggleScreen}
            type="button"
          >
            <FormattedMessage
              id="CoverConfirm2.back"
              defaultMessage="BACK"
            />
          </button>
          <button
            className="modal-components__submit-btn modal-components__submit-btn--post"
            onClick={_coverShiftAction}
            type="button"
          >
             <FormattedMessage
              id="CoverConfirm2.confirm"
              defaultMessage="Confirm"
            />
          </button>
        </div>
      </div>
    </>
    );
  }

}

CoverConfirm2.propTypes = {
  //userCookie: PropTypes.shape({}).isRequired,
  //costCenterData: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (CoverConfirm2);
