import moment from 'moment';

export function makeTimeStrs(state) {
  const start = { ...state.start };
  const end = { ...state.end };
  const startStr = `${start.hour}:${start.min}${start.period}`;
  const endStr = `${end.hour}:${end.min}${end.period}`;

  return [startStr, endStr];
}

export function makeTimeObjs(state) {
  const times = makeTimeStrs(state);
  const TIME_FORMAT = 'LT';

  const startTime = moment(times[0], TIME_FORMAT);
  const endTime = moment(times[1], TIME_FORMAT);

  return [startTime, endTime];
}

export function canSubmit(state, date) {
  const errors = { ...state.errors };
  const start = { ...state.start };
  const end = { ...state.end };

  // Check for errors and empty inputs
  const noErrors = Object.keys(errors).every(key => errors[key] === false);
  const noEmptyStart = Object.keys(start).every(key => start[key] !== '');
  const noEmptyEnd = Object.keys(end).every(key => end[key] !== '');

  // Check if end time is before start time
  // const startBeforeEnd = times[0].isBefore(times[1]);

  // Returns true if user can submit
  // return noErrors && noEmptyStart && noEmptyEnd && startBeforeEnd && date;
  return noErrors && noEmptyStart && noEmptyEnd && date;
}
