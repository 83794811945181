import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { FormattedMessage } from "react-intl";

import { ModalTypes } from "_constants";
import { getUserCookie } from "_helpers/cookies";
import { apiCallError } from "_helpers/errors";
import { deleteShift, cancelShift } from "_helpers/api-calls/shift-calls";
import {
  toggleModal,
  removeShift,
  updateSelectedShift,
  addShift,
  setCalledOffShift,
  updateCanceledShift,
} from "_helpers/app-state";
import { callBackgroundData } from "_helpers/api-calls/concurrent-calls";
import { ROLES } from "_services/user-roles";
import { checkForPublicRouteMatch } from "_helpers/PrivateComponent";
import { setDefaultTimezone } from "_helpers/date-time";
import { callRequiredData } from "_helpers/api-calls";
import { alphaCompare } from "_helpers/formatting";

import { ShiftsPage } from "ShiftsPage";
import { Modal } from "_components/GeneralComponents/Modal";
import { PostShift } from "_components/ModalComponents/PostShift";
import ConfirmShift  from "_components/ModalComponents/ConfirmShift/ConfirmShift";
import { CoverShift } from "_components/ModalComponents/CoverShift";
import { DeleteShift } from "_components/ModalComponents/DeleteShift";
import { CancelShift } from "_components/ModalComponents/CancelShift";
import { LoadingSpinner } from "_components/GeneralComponents/LoadingSpinner";
import { CallOffShift }  from "_components/ModalComponents/CallOffShift";
import CalledOffShift  from "_components/ModalComponents/CalledOffShift/CalledOffShift";
import RequestTimeOff  from "_components/ModalComponents/RequestTimeOff/RequestTimeOff";
import RequestedTimeOff  from "_components/ModalComponents/RequestTimeOff/RequestedTimeOff";
import ScheduledShift from "_components/ModalComponents/ScheduledShift/ScheduledShift";
import ShiftForCallOff from "_components/ModalComponents/ShiftForCallOff/ShiftForCallOff";
import ShiftDetails from "_components/ModalComponents/ShiftDetails/ShiftDetails";
import DeleteComment  from "_components/GeneralComponents/Comments/components/Comment/DeleteComment";

import "./IndexPage.scss";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.reason1 = '';
    this.reason2 = '';

    this._isMounted = false;
  }


  componentDidMount() {
    this._isMounted = true;
    this._loadApis();
    if (!this._checkForPublicRoute()) {
      this._callDataForLoggedInUser();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _callDataForLoggedInUser = () => {
    const { _setAppState, appState } = this.props;
    const userCookie = getUserCookie();

    // Set timezone for moment.js
    setDefaultTimezone(userCookie.time_zone);

    // Show loading spinner
    _setAppState({ key: "loading", statement: { shifts: { $set: true } } });

    // Load data required for app to function
    if(userCookie && userCookie.location_id && 
      appState.setupActions && appState.setupActions.meta &&
      appState.setupActions.meta.current_user_privilege && appState.setupActions.meta.current_user_privilege.id){
      callRequiredData(
        userCookie.user_id,
        userCookie.location_id,
        _setAppState,
        appState.setupActions.meta.current_user_privilege.id
      );
    }
  };

  _loadApis = () => {
    const { _setAppState } = this.props;
    const userCookie = getUserCookie();

    // Load other data not required immediately
    callBackgroundData(userCookie.location_id)
      .then(
        axios.spread((locationFeed, customFeed, regionFeed) => {
          const customChannels = customFeed.data.channels;
          //customChannels.sort(alphaCompare("channel_name"));
          const sortedCustomChannels = this.sortChannelNameWithNullValues(customChannels, true)
          const locationChannels = locationFeed.data.channels;
          const regionChannels = regionFeed.data.channels;
          _setAppState({
            key: "channels",
            statement: {
              location_feed: { $set: locationChannels },
              //custom_feed: { $set: customChannels },
              custom_feed: { $set: sortedCustomChannels },
              region_feed: { $set: regionChannels },
            },
          });
        })
      )
      .catch((error) => {
        apiCallError(error);
      });
  };

   sortChannelNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;
    /*
    for(let i=0; i<arr.length; i++) {
      const msg = `before sorting: ${arr[i].channel_name}`;
      console.log(msg);
    }
    */
    const sorter = function(a, b) {
      if (a.channel_name === b.channel_name) // identical? return 0
        return 0;
      else if (a.channel_name === null)  // a is null? last 
        return 1;
      else if (b.channel_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.channel_name.localeCompare(b.channel_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  };

  _checkForPublicRoute = () =>
    checkForPublicRouteMatch(window.location.pathname);

  _deleteShift = (location_id, shift_id) => {
    const { appState, _setAppState } = this.props;

    deleteShift(location_id, shift_id)
      .then(() => {
        // Success
        const index = appState.shifts.findIndex(
          (shift) => shift.id === shift_id
        );

        removeShift(index, _setAppState);
        this._toggleModal(null, null);
      })
      .catch((error) => {
        apiCallError(error);
      });
  };

  _handleCancel = (shift_id) => {
    const { appState, _setAppState } = this.props;

    cancelShift(shift_id)
      .then(({ data }) => {
        const index = appState.shifts.findIndex(
          (shift) => shift.id === shift_id
        );

        //removeShift(index, _setAppState);
        //addShift(data.schedule_element, appState.shifts, _setAppState);
        updateCanceledShift(data.schedule_element, appState.shifts, _setAppState);
        this._toggleModal(null, null);
      })
      .catch((error) => {
        apiCallError(error);
      });
  };

  _toggleModal = (modalContent, shiftId) => {
    const { _setAppState } = this.props;
    toggleModal(modalContent, _setAppState);
    updateSelectedShift(shiftId, _setAppState);
  };

  _handleCallOffShift = (shift, reason1_tag, reason2_tag, reason1, reason2) => {
    const {_setAppState, appState:{shifts},} = this.props;

    //setCalledOffShift(shift, shifts, reason1_tag, reason2_tag, _setAppState);
    this.reason1 = reason1;
    this.reason2 = reason2;
    this._toggleModal();
  }

  _renderModal = () => {
    let contentComponent = null;
    const {
      _setAppState,
      appState: {
        selectedShift,
        shifts,
        user,
        visibleModal,
        channels,
        locale,
        locations,
        costCenterData,
        setupActions,
        rtos,
        selectedRTO,
        userPrivileges,
        locationChannels,
        locationSettings,
        locationSkills,
        locationUserSkillIds,
      },
    } = this.props;

    const findSelectedShift = () =>
      shifts.find((shift) => shift.id === selectedShift);
    const currentLocation = locations.find(
      (location) => location.id === parseInt(user.location_id)
    );
    const props = {
      _setAppState,
      _toggleModal: this._toggleModal,
      currentLocation,
      selectedShift: findSelectedShift(),
      selectedRTO,
      userCookie: user,
      shifts,
      locations,
      costCenterData,
      setupActions,
      rtos,
      userPrivileges,
      locationChannels,
      locationSettings,
      locationSkills,
      locationUserSkillIds,
    };

    switch (visibleModal) {
      case ModalTypes.MY_SHIFT:
      case ModalTypes.MY_COVERING_SHIFT:
        contentComponent = <ConfirmShift {...props} />;
        break;
      case ModalTypes.COVER_SHIFT:
        contentComponent = <CoverShift {...props} shifts={shifts} userPrivileges={userPrivileges} />;
        break;
      case ModalTypes.DELETE_SHIFT:
        contentComponent = (
          <DeleteShift {...props} _deleteShift={this._deleteShift} />
        );
        break;
      case ModalTypes.CANCEL_SHIFT:
        contentComponent = (
          <CancelShift {...props} _handleCancel={this._handleCancel} />
        );
        break;
      case ModalTypes.POST_SHIFT:
          contentComponent = (
            <PostShift
              {...props}
              channels={channels}
              locale={locale}
              shifts={shifts}
            />
          );
        break;
      case ModalTypes.CALL_OFF_SHIFT:
        contentComponent = <CallOffShift 
        {...props} 
        shifts={shifts}
        selectedShift={selectedShift} 
        _handleCallOffShift={this._handleCallOffShift}
        locale={locale}
        />;
        break;
      case ModalTypes.CALLED_OFF:
         contentComponent = <CalledOffShift 
        {...props} 
        shifts={shifts}
        selectedShift={selectedShift} 
        reason1={this.reason1} 
        reason2={this.reason2}
        locale={locale}/>;
        break;
      case ModalTypes.REQUEST_TIME_OFF:
        contentComponent = <RequestTimeOff
        {...props} 
        rtos={rtos}
        locationSettings={locationSettings}
        locale={locale}
        />;
        break;
      case ModalTypes.REQUESTED_TIME_OFF:
        contentComponent = <RequestedTimeOff
        {...props} 
        rtos={rtos}
        selectedRTO={selectedRTO}
        locationSettings={locationSettings}
        locale={locale}
        />;
        break;
      case ModalTypes.SCHEDULED_SHIFT:
        contentComponent = <ScheduledShift
        {...props} 
        shifts={shifts}
        locationSettings={locationSettings}
        locale={locale}
        />;
        break;
      case ModalTypes.SHIFT_FOR_CALL_OFF:
        contentComponent = <ShiftForCallOff
        {...props} 
        shifts={shifts}
        locationSettings={locationSettings}
        _handleCallOffShift={this._handleCallOffShift}
        locale={locale}
        />;
        break;
      case ModalTypes.SHIFT_DETAILS:
        contentComponent = <ShiftDetails
        {...props} 
        shifts={shifts}
        locationSettings={locationSettings}
        />;
        break;
      case ModalTypes.DELETE_COMMENT:
        contentComponent = <DeleteComment
        {...props} 
        commentId={selectedShift} //this is selected comment id
        locationSettings={locationSettings}
        />;
        break;
      default:
        break;
    }

    if (visibleModal && this._isMounted) {
      return <Modal _toggleModal={this._toggleModal}>{contentComponent}</Modal>;
    }

    return null;
  };

  render() {
    const { _setAppState, appState } = this.props;
    const loading = (
      <div className="index-page index-page__loading">
        <LoadingSpinner color="red" size="large" />
      </div>
    );
    const routeComponent = (
      <>
        {this._renderModal()}
        <ShiftsPage
          appState={appState}
          _setAppState={_setAppState}
          _toggleModal={this._toggleModal}
        />
      </>
    );

    if (appState.userRole === ROLES.PENDING_LOCATIONS.key) {
      return (
        <div className="index-page__wrapper index-page__pending-location">
          <h1>
            <FormattedMessage
              id="IndexPage.pendingLocationHeader"
              defaultMessage="Hold tight! We're waiting for your manager to approve your request."
            />
          </h1>
          <p>
            <FormattedMessage
              id="IndexPage.pendingLocationText"
              defaultMessage="This location requires approval to join. We've notified an admin of your request to join."
            />
          </p>
        </div>
      );
    }

    return <>{appState.loading.shifts ? loading : routeComponent}</>;
  }
}

IndexPage.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  appState: PropTypes.shape({}).isRequired,
};

export { IndexPage };
