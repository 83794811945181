import { defineMessages } from 'react-intl';

const messages = defineMessages({
  emailError: {
    id: 'ProfilePage.emailError',
    defaultMessage: 'Please enter a valid email',
  },
  firstNameError: {
    id: 'ProfilePage.firstNameError',
    defaultMessage: 'Required',
  },
  lastNameError: {
    id: 'ProfilePage.lastNameError',
    defaultMessage: 'Required',
  },
  phoneNumberError: {
    id: 'ProfilePage.phoneNumberError',
    defaultMessage: 'Please enter a valid phone number',
  },
  positionError: {
    id: 'ProfilePage.positionError',
    defaultMessage: 'Required',
  },
  firstNamePlaceholder: {
    id: 'ProfilePage.firstNamePlaceholder',
    defaultMessage: 'First Name',
  },
  lastNamePlaceholder: {
    id: 'ProfilePage.lastNamePlaceholder',
    defaultMessage: 'Last Name',
  },
  jobTitlePlaceholder: {
    id: 'ProfilePage.jobTitlePlaceholder',
    defaultMessage: 'Position',
  },
  employeeIdPlaceholder: {
    id: 'ProfilePage.employeeIdPlaceholder',
    defaultMessage: 'Employee ID',
  },
  skillsPlaceholder: {
    id: 'ProfilePage.skillsPlaceholder',
    defaultMessage: 'Skills',
  },
  departmentsPlaceholder: {
    id: 'ProfilePage.departmentsPlaceholder',
    defaultMessage: 'Departments',
  },
  emailPlaceholder: {
    id: 'ProfilePage.emailPlaceholder',
    defaultMessage: 'Email',
  },
  phoneNumberPlaceholder: {
    id: 'ProfilePage.phoneNumberPlaceholder',
    defaultMessage: 'Phone Number',
  },
  profileUpdated: {
    id: 'ProfilePage.profileUpdated',
    defaultMessage: 'Profile successfully updated!',
  },
  profileError: {
    id: 'ProfilePage.profileError',
    defaultMessage: 'Please see errors above.',
  },
});

export default messages;
