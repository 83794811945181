import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import TimeItem from './TimeItem';
import moment from 'moment';

import styled from './styled';

class DropDownMenu extends React.Component {
	constructor(props){
    super(props);
    this.state = {
                  tags: [],
                  firstEndTime: '',
                  hourStepPto: 0,
                  hourStepFloating: 0,
                  hourStepDiversity: 0,
                  timeItems:
                    [
                    '9:00 AM','9:15 AM','9:30 AM','9:45 AM',
                    '10:00 AM','10:15 AM','10:30 AM','10:45 AM',
                    '11:00 AM','11:15 AM','11:30 AM','11:45 AM',
                    '12:00 PM','12:15 PM','12:30 PM','12:45 PM',
                    '1:00 PM','1:15 PM','1:30 PM','1:45 PM',
                    '2:00 PM','2:15 PM','2:30 PM','2:45 PM',
                    '3:00 PM','3:15 PM','3:30 PM','3:45 PM',
                    '4:00 PM','4:15 PM','4:30 PM','4:45 PM',
                    '5:00 PM','5:15 PM','5:30 PM','5:45 PM',
                    '6:00 PM','6:15 PM','6:30 PM','6:45 PM',
                    '7:00 PM','7:15 PM','7:30 PM','7:45 PM',
                    '8:00 PM','8:15 PM','8:30 PM','8:45 PM',
                    '9:00 PM','9:15 PM','9:30 PM','9:45 PM',
                    '10:00 PM','10:15 PM','10:30 PM','10:45 PM',
                    '11:00 PM','11:15 PM','11:30 PM','11:45 PM',
                    '12:00 AM','12:15 AM','12:30 AM','12:45 AM',
                    '1:00 AM','1:15 AM','1:30 AM','1:45 AM',
                    '2:00 AM','2:15 AM','2:30 AM','2:45 AM',
                    '3:00 AM','3:15 AM','3:30 AM','3:45 AM',
                    '4:00 AM','4:15 AM','4:30 AM','4:45 AM',
                    '5:00 AM','5:15 AM','5:30 AM','5:45 AM',
                    '6:00 AM','6:15 AM','6:30 AM','6:45 AM',
                    '7:00 AM','7:15 AM','7:30 AM','7:45 AM',
                    '8:00 AM','8:15 AM','8:30 AM','8:45 AM',
                    ],
                  };
    this.endTimeItems = [];
  }

  componentDidMount() {
    const {location, costCenterData} = this.props;

    if(costCenterData) {
      const idx = costCenterData.tag_groups.findIndex(
        (group) => group.group_type === 'rto_categories'
      );
      if(idx >= 0) {
        this.setState({tags: costCenterData.tag_groups[idx].tags});
        for(let i=0; i<costCenterData.tag_groups[idx].tags.length; i++) {
          const tag = costCenterData.tag_groups[idx].tags[i];
          switch(tag.tag_key) {
            case 'rto_category_pto':
              this.setState({hourStepPto: tag.tag_meta.hour_step});
              break;
            case 'rto_category_floating':
              this.setState({hourStepFloating: tag.tag_meta.hour_step});
              break;
            case 'rto_category_diversity':
              this.setState({hourStepDiversity: tag.tag_meta.hour_step});
              break;
          }
        }
      }
    }

  }

  componentDidUpdate(prevProps) {
    const {location, costCenterData} = this.props;

    if(prevProps.costCenterData !== costCenterData){
      console.log('changed');
    }
  }

  getMinutes = (timeItem) => {
    if(!timeItem || timeItem.length === 0) {
      return '';
    }
    const s = timeItem.toString();
    const idx1 = s.indexOf(':')
    const idx2 = s.indexOf(' ');
    let min = '';
    if(idx1 >=0 && idx2 >=0) {
      min = s.substring(idx1+1, idx2);
    }else {
      return '';
    }

    return min;
  }

  handleMenuItemClick = (selectedItem) => {
    const {
      handleMenuItemClick, 
      toggleTimePicker, 
      callbackEndTimeItems, 
      endTimeItems, 
      selectedIndex,
      callbackSelectedHourStep} = this.props;
    const {tags, hourStepPto, hourStepFloating, hourStepDiversity, timeItems} = this.state;

    //parent callback
    handleMenuItemClick(selectedItem);
    toggleTimePicker();

    let hourStep = 1;
    switch(selectedIndex) {
      case 0:
        hourStep = this.state.hourStepPto;
        break;
      case 1:
        hourStep = this.state.hourStepFloating;
        break;
      case 2:
        hourStep = this.state.hourStepDiversity;
        break;
    }
    callbackSelectedHourStep(hourStep, hourStepPto, hourStepFloating, hourStepDiversity);
    const start = selectedItem.replace(/ /g, '');
    const start1 = moment(start, 'h:mm a');
    //The increment comes from backend.
    const firstEndTime = start1.add(hourStep, 'hours').format('h:mm A');
    //alert(firstEndTime);
    this.setState({firstEndTime: firstEndTime});

    let timeItemsForEndTime = [];
    let timeItemsBeforeStartTime = [];
    if(!endTimeItems) {
      if (firstEndTime) {
        const idx = timeItems.findIndex(
          (item) => item === firstEndTime
        );
        if(idx<0){
          console.log('First end time item not found.');
          return;
        }

        const startTimeIdx = timeItems.findIndex(
          (item) => item === selectedItem 
        );
        if(startTimeIdx < 0) {
          console.log('start time not found.');
          return;
        }

        const min = this.getMinutes(firstEndTime);
        if(min.length > 0) {
          for(let i=idx; i<timeItems.length; i++) {
            const m1 = this.getMinutes(timeItems[i]);
            //1. end time and start time must be on the same date
            //2. end time must be in 1 hour increment on start time
            if(m1 === min) {
              timeItemsForEndTime.push(timeItems[i]);
            }
          }
          for(let j=0; j<startTimeIdx; j++) {
            const m2 = this.getMinutes(timeItems[j]);
            //1. end time and start time must be on the same date
            //2. end time must be in 1 hour increment on start time
            if(m2 === min) {
              timeItemsBeforeStartTime.push(timeItems[j]);
            }
          }
        }
        const totalItems = [...timeItemsForEndTime, ...timeItemsBeforeStartTime];
        callbackEndTimeItems(totalItems);
      }
    }
  }

  renderTimeItems = () => {
    const {location, endTimeItems} = this.props;
    const {tags, firstEndTime, timeItems} = this.state;

    if(endTimeItems && endTimeItems.length > 0) {
      const menuItems = endTimeItems.map((item, index) =>(
        <TimeItem
            key={index}
            item={item}
            handleMenuItemClick={this.handleMenuItemClick}
          />
      ));
      return menuItems;
    }

    const menuItems = timeItems.map((item, index) =>(
      <TimeItem
          key={index}
          item={item}
          handleMenuItemClick={this.handleMenuItemClick}
        />
    ));
    return menuItems;
  }

  render() {
    const {top, left, intl, endTimeItems} = this.props;
    const {tags,} = this.state;
    
    return(
      <styled.WinContainer top={top} left={left}>
        {this.renderTimeItems()}
      </styled.WinContainer>
    );

  }

}

DropDownMenu.propTypes={
  
}

export default injectIntl(DropDownMenu);