import React from 'react';
import { FormattedMessage } from 'react-intl';

import './SingleSignOnBoxComponent.scss';

const SingleSignOnBoxComponent = ({ setupActions }) => {
  const company = setupActions.filter(
    element => element.operation === 'action_sso_logout',
  )[0];
  const { options } = company;
  // const url = options.url.split('mobile').join('web');

  return (
    <article className="single-sign-on-box">
      <header className="single-sign-on-box__header">
        <img
          src={options.sso_provider_settings.sso_image_url_android}
          alt={options.sso_provider_settings.company_name}
        />
      </header>
      <section className="single-sign-on-box__body">
        <img
          src={options.sso_provider_settings.company_logo_url_android}
          alt={options.sso_provider_settings.company_name}
          width="120"
        />
        <h2 className="single-sign-on-box__body__header">
          {options.sso_provider_settings.login_title}
        </h2>
        <p className="single-sign-on-box__body--text">
          {options.sso_provider_settings.login_detail}
        </p>
      </section>
      <footer className="single-sign-on-box__footer">
        <a
          href={options.url}
          rel="noopener noreferrer"
          target="_blank"
          className="single-sign-on-box__button"
        >
          <FormattedMessage
            id="SingleSignOnBoxComponent.logIn"
            defaultMessage="Log In"
          />
        </a>
      </footer>
    </article>
  );
};

export { SingleSignOnBoxComponent };
