import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { ConfirmShiftTemplate } from '_components/ModalComponents/_templates/ConfirmShiftTemplate';
import { CloseModal } from '_components/ModalComponents/CloseModal';
import { CommentsSection } from '_components/GeneralComponents/Comments';
import { ModalTypes, shiftStatuses, shiftOwner, shiftStatus, shiftNames } from "_constants";
import { StatusIcon, StatusText, } from "_components/GeneralComponents/ShiftStatus";
import { ModalWrapper } from '../ModalWrapper';
import './ShiftDetails.scss';

class ShiftDetails extends React.Component {
  constructor(props) {
    super(props);

    const { intl: { formatMessage } } = this.props;

    this.state = {
      timer: null,
      findCallOffReason1: false,
      findCallOffReason2: false,
      enableCallOff: false,
      enableConvert: false,
    };

    this.messages = defineMessages({
      to: {
        id: 'ShiftDetails.to',
        defaultMessage: 'to',
      },
      
    });


    this._isMounted = false;
  }
  componentDidMount () {
    const {shifts, costCenterData, locationSettings, selectedShift, } = this.props;

    this._isMounted = true;
    let myTimer = setInterval(this.tick, 3000); //unit: ms, 3000 = 3 seconds
    this.setState({timer: myTimer});

    if(locationSettings.cfg__inventory__convert) {
      if(this._isMounted) {
        this.setState({enableConvert: true});
      }
    }
  }

  componentDidUpdate (prevProps) {
    const {shifts, selectedShift, } = this.props;

    if(prevProps.selectedShift !== selectedShift) {
      console.log('here')
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  tick = () => {
    const {selectedShift, costCenterData, locationSettings } = this.props;

    if(costCenterData){
      for(let i=0; i< costCenterData.tag_groups.length; i++) {
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_1'){
          this.setState({findCallOffReason1: true})
        }
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_2'){
          this.setState({findCallOffReason2: true})
        }
      }
    }
    //check if the shift expired every 100 seconds (1 minutes 40 seconds)
    const startDateTime = moment(selectedShift.start_at);
    const endDateTime = moment(selectedShift.end_at);
    let current = moment();
    let startToNow = moment.duration(startDateTime.diff(current));
    let endToNow = moment.duration(endDateTime.diff(current));
    let minutesStartToNow = parseInt(startToNow.asMinutes());
    let minutesEndToNow = parseInt(endToNow.asMinutes());
    //if the shift starts within 24 hours and the shift has not ended yet
    //the shift can be called off
    if(locationSettings && locationSettings.cfg__allow__call_off) {
      if(selectedShift.trade_status === 'inventory') {
        if(this.state.findCallOffReason1){
          if(minutesStartToNow < 1440 && minutesEndToNow > 0){
            this.setState({enableCallOff: true});
          }
        }
      }
    }
  };

  updateSelectedShift = (selectedShift) => {
    const { _setAppState } = this.props;

    _setAppState({
      key: 'selectedShift',
      statement: {
        $set: selectedShift.id,
      },
    });
  };

  handleCallOffClick = (event) => {
    const {
      selectedShift,
      userCookie,
      _toggleModal,
    } = this.props;
    //because this button is on the shift card, both the button and the card get clicked. 
    //The stopPropagation() method allows you to prevent propagation of the current event.
    event.stopPropagation();
    // important: parameter 'selectedShift' has to be there to get 'selectedShift' in CallOffShift
    _toggleModal(ModalTypes.CALL_OFF_SHIFT, selectedShift);
    //this.updateSelectedShift(selectedShift);
    event.preventDefault()
  };

  handleCoverShiftClick = (event) => {
     const {
      selectedShift,
      userCookie,
      _toggleModal,
    } = this.props;
    //because this button is on the shift card, both the button and the card get clicked. 
    //The stopPropagation() method allows you to prevent propagation of the current event.
    event.stopPropagation();
    // important: parameter 'selectedShift' has to be there to get 'selectedShift' in CallOffShift
    _toggleModal(ModalTypes.POST_SHIFT);
    this.updateSelectedShift(selectedShift);
    event.preventDefault()
  }

  renderJobTitle = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    return selectedShift && selectedShift.primary_job ?  selectedShift.primary_job : '--';
  }

  renderDate = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const startDate = moment(selectedShift.start_at).format('ddd, MMM, DD');
    return startDate;
  }

  renderTime = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, intl } = this.props;

    const startTime = moment(selectedShift.start_at).format('h:mm A');
    const endTime = moment(selectedShift.end_at).format('h:mm A');
    const timeRange = `${startTime} ${intl.formatMessage(this.messages.to)} ${endTime}`;
    return timeRange;
  }



  render() {
    const { _toggleModal, _setAppState, selectedShift, userCookie, shifts, } = this.props;
    const { enableCallOff, enableConvert, } = this.state;

    const owner = shiftOwner(selectedShift, userCookie);
    
    let notCovered = false;
    let isInventory = false;
    let allowDelete = false;
    let allowCancel = false;
    let myCoveringShift2 = false;
    let myNoCancelDeleteShift2 = false;

    if(selectedShift && userCookie) {
      const shift = selectedShift;
      isInventory = shift.name === 'inventory';
      allowDelete = !isInventory && shift.allow_delete &&  shift.owner.id === userCookie.user_id;
      
    }


    const deleteRow = (
      <div className="modal-components__row">
        <div className="modal-components__col modal-components__col--submit">
          <button
            className="shift-details__btn-delete"
            onClick={() => {
              _toggleModal(ModalTypes.DELETE_SHIFT, selectedShift.id);
            }}
            type="button"
          >
            <FormattedMessage
              id="ShiftDetails.deleteShift"
              defaultMessage="Delete Shift"
            />
          </button>
        </div>
      </div>
    );

    const buttons = (
      <div className="modal-components__row">
        <div className="modal-components__col modal-components__col--submit">
          {enableCallOff && <button
            className="shift-details__btn-call-off"
            onClick={this.handleCallOffClick}
            type="button"
          >
            <FormattedMessage
                id="ShiftDetails.callOffShift"
                defaultMessage="Call Off Shift"
              />
          </button>}
          {enableConvert && <button
            className="shift-details__btn-cover-shift"
            onClick={this.handleCoverShiftClick}
            type="button"
          >
            <FormattedMessage
                id="ShiftDetails.getShiftCovered"
                defaultMessage="Get my Shift Covered"
              />
          </button>}
        </div>
      </div>
    );

    const headerText = (
      <FormattedMessage
        id="ShiftDetails.yourShift"
        defaultMessage="Your Shift"
      />
    );
    


    return (
      <div className="confirm-shift">
        <ModalWrapper>
          <CloseModal _toggleModal={_toggleModal} dark />
          <div className="modal-components__container">
            <h1>{headerText}</h1>
            <div className="confirm-shift__top-row">
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ShiftDetails.type"
                      defaultMessage="Type"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  <FormattedMessage
                    id="ShiftDetails.scheduledShift"
                    defaultMessage="Scheduled Shift"
                  />
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ShiftDetails.jobTitle"
                      defaultMessage="Job Title"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderJobTitle()}
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ShiftDetails.date"
                      defaultMessage="Date"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderDate()}
                </label>
              </div>
               <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ShiftDetails.time"
                      defaultMessage="Time"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderTime()}
                </label>
              </div>
            </div>
            {/*<CommentsSection
              selectedShift={selectedShift}
              size="small"
              userCookie={userCookie}
            />*/}
            {buttons}
            {(selectedShift.allow_delete && selectedShift.owner.id === userCookie.user_id) && deleteRow}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

ShiftDetails.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
  _setAppState: PropTypes.func.isRequired,
  selectedShift: PropTypes.shape({}),
  userCookie: PropTypes.shape({}).isRequired,
  shifts: PropTypes.shape([]).isRequired,
};

ShiftDetails.defaultProps = {
  selectedShift: {},
};

//export { ConfirmShift };
export default injectIntl (ShiftDetails);

