import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { trackAmplitudeEvent, amplitudeConstants } from '_helpers/tracking';
import { ModalTypes } from '_constants';
import postBtnSvg from '../assets/post-btn.svg';
import { Dropdown } from '_components/GeneralComponents/Dropdown';
import Styled from '../styled';
import '../Navbar.scss';

class PostShiftButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        disabled: null,
        
    };


    this.handlePostClick = this.handlePostClick.bind(this);
  }

  componentDidMount() {
    const {appState,} = this.props;
    if (appState.locationSettings) {
      this.setState({disabled: appState.locationSettings.cfg__action_menu === 0})
    }

  }

  componentDidUpdate(prevProps) {
    const {appState,} = this.props;
    if(prevProps.appState !== appState) {
      if (appState.locationSettings) {
        let msg = `cfg__action_menu = ${appState.locationSettings.cfg__action_menu}`;
        console.log(msg);
        this.setState({disabled: appState.locationSettings.cfg__action_menu === 0})
      }
    }
  }

  handlePostClick = (event) => {
    const {disabled} = this.state;
    const {_toggleModal, appState} = this.props;
  
    if (!disabled) {
      trackAmplitudeEvent(amplitudeConstants.postShiftMain);
      _toggleModal(ModalTypes.POST_SHIFT);
    }
  }

  handleRTOClick = (event) => {
    const {_toggleModal, appState} = this.props;
    _toggleModal(ModalTypes.REQUEST_TIME_OFF);
  }

  handleScheduleShiftClick = (event) => {
    const {_toggleModal, appState} = this.props;
     _toggleModal(ModalTypes.SCHEDULED_SHIFT);
  }

  handleCallOffShiftClick = (event) => {
    const {_toggleModal, appState} = this.props;
     _toggleModal(ModalTypes.SHIFT_FOR_CALL_OFF);
  }



  dropdownItems = () => {
    const {_toggleModal, appState} = this.props;

    let items = [];
    const itemPostShift = {
      clickHandler: this.handlePostClick,
      label: (
        <FormattedMessage
          id="PostShiftButton.button"
          defaultMessage="Post a Shift"
        />
      ),
    };
    const itemPostRTO = {
      clickHandler: this.handleRTOClick,
      label: (
        <FormattedMessage
          id="PostShiftButton.rto"
          defaultMessage="Request Time Off"
        />
      ),
    };
    const itemScheduledShift = {
      clickHandler: this.handleScheduleShiftClick,
      label: (
        <FormattedMessage
          id="PostShiftButton.scheduledShift"
          defaultMessage="Scheduled Shift"
        />
      ),
    };

    const itemCallOffShift = {
      clickHandler: this.handleCallOffShiftClick,
      label: (
        <FormattedMessage
          id="PostShiftButton.callOffShift"
          defaultMessage="Call Off Shift"
        />
      ),
    };

    if(appState.locationSettings.cfg__shift__post_new) {
      //cfg__rto values: 0-disabled, 1-enabled, 8-create_disabled
      if(appState.locationSettings.cfg__rto === 1) {
        if(appState.locationSettings.cfg__inventory__create) {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemScheduledShift, itemPostShift, itemPostRTO, itemCallOffShift];
          }else {
            items = [itemScheduledShift, itemPostShift, itemPostRTO];
          }
        }else {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemPostShift, itemPostRTO, itemCallOffShift];
          }else {
            items = [itemPostShift, itemPostRTO];
          }
        }
      }else {
        if(appState.locationSettings.cfg__inventory__create) {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemScheduledShift, itemPostShift, itemCallOffShift];
          }else {
            items = [itemScheduledShift, itemPostShift];
          }
        }else {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemPostShift, itemCallOffShift];
          }else {
            items = [itemPostShift];
          }
        }
      }
    }else {
      //cfg__rto values: 0-disabled, 1-enabled, 8-create_disabled
      if(appState.locationSettings.cfg__inventory__create) {
        if(appState.locationSettings.cfg__rto === 1) {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemScheduledShift, itemPostRTO, itemCallOffShift];
          }else {
            items = [itemScheduledShift, itemPostRTO];
          }
        }else {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemScheduledShift, itemCallOffShift];
          }else {
            items = [itemScheduledShift];
          }
        }
      }else {
        if(appState.locationSettings.cfg__rto === 1) {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemPostRTO, itemCallOffShift];
          }else {
            items = [itemPostRTO];
          }
        }else {
          if(appState.locationSettings.cfg__allow__call_off === 1) {
            items = [itemCallOffShift];
          }else {
            items = [];
          }
        }
      }
    }
    return items;
  }

  render() {
    const {_toggleModal, appState } = this.props;
    const {disabled} = this.state;

    return (
      <div className={`header__post-shift-btn ${disabled ? 'disabled' : ''}`}>
        {/*<button
          onClick={this.handleButtonClick}
          disabled={disabled}
          type="button"
        >
          <img
            className={`${disabled ? 'disabled' : ''}`}
            src={postBtnSvg}
            alt="Post shift button"
          />
          <p>
            <FormattedMessage
              id="PostShiftButton.button"
              defaultMessage="Post a Shift"
            />
          </p>
        </button>*/}
        <Dropdown direction="right" imageSize="large" items={this.dropdownItems()}>
          <img
              className=""
              src={postBtnSvg}
              alt="Post shift button"
          />
          <p>
            <FormattedMessage
              id="PostShiftButton.post"
              defaultMessage="Post"
            />
            {' '}
            <span className="icon-dropdown-arrow" />
          </p>
        </Dropdown>
      </div>
    );
  }
}

PostShiftButton.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
};


export default injectIntl (PostShiftButton);
