import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from "classnames";
import { ModalTypes, shiftStatuses, shiftOwner, shiftStatus, shiftNames } from "_constants";
import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import { DateButton, TimeButton, DateTimePicker } from './components';
import { RadioButton, } from "../PostShift/components";
import { getUserInfo } from '_helpers/api-calls/user-calls';
import { postShift, postShiftInventory, getShift, postShiftCalledOff, } from '_helpers/api-calls/shift-calls';
import { deleteShift, cancelShift } from "_helpers/api-calls/shift-calls";
import { callOffShift } from '_helpers/api-calls/shift-calls';
//import { addShift, removeShift } from '_helpers/app-state';
import { DataNames } from './constants';
import {
  createDateString,
  createTimeRangeString,
  isNextDay,
} from '_helpers/formatting';
import {
  toggleModal,
  removeShift,
  updateSelectedShift,
  addShift,
  setCalledOffShift,
  setCalledOffShiftNew,
  updateCanceledShift,
} from "_helpers/app-state";
import { formatDateforApi, getDefaultTimezone} from '_helpers/date-time';
import InventoryShiftCard from './InventoryShiftCard';

import './ShiftForCallOff.scss';

class ShiftForCallOff extends React.Component {
	constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      dateString: {
        id: 'ShiftForCallOff.dateString',
        defaultMessage: 'Date',
      },
      timeRangeString: {
        id: 'ShiftForCallOff.timeRangeString',
        defaultMessage: 'Time',
      },
      
    });

    this.state = {
        debug: false,

        showDatePicker: false,
        confirmPost: false,
        date: {},
        dateString: formatMessage(this.messages.dateString),
        reachValue: 'My Team',
        channel_id: '',
        submitting: false,
        times: [],
        timeRangeString: formatMessage(this.messages.timeRangeString),
        myJobTitle: '',
        inputJobTitle: '',
        callOffReason1: null,
        callOffReason2: null,
        selectedReason1: '',
        channel_id: '',
        selectedShift: null,
        invalidStartTime: false,
        debugInfo: '',
        shiftDataChanged: false,
        shiftsToShow: null,
    };
    this.newShiftButtonRef = React.createRef();
    this.foundShift = false;
    this._isMounted = false;
  }

  componentDidMount() {
    const { userPrivileges, userCookie, shifts, _setAppState, costCenterData, } = this.props;
    const { date, } = this.state;

    this._isMounted = true;
    if(userCookie) {
      if(this._isMounted) {
        this.setState({myJobTitle: userCookie.position});
      }
    }
    this._updateState(DataNames.DATE, moment());

    if(costCenterData){
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_1'){
          this.setState({callOffReason1: costCenterData.tag_groups[i].tags})
        }
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_2'){
          this.setState({callOffReason2: costCenterData.tag_groups[i].tags})
        }
      }
    }
  };

  componentDidUpdate (prevProps, prevState) {
    const { userPrivileges, userCookie, shifts, _setAppState, costCenterData, } = this.props;
    const { date, shiftDataChanged, times, timeRangeString, showDatePicker, } = this.state;

    if(prevState.showDatePicker !== showDatePicker) {
      if(showDatePicker ) {
        if(this._isMounted) {
          if(this.newShiftButtonRef && this.newShiftButtonRef.current) {
           this.newShiftButtonRef.current.focus();
          }
          this.setState({invalidStartTime: false});
        }
      }
    }
    
    if(prevState.date !== date) {
      const s = date ? `date: ${date.format('MM-DD h:mma')}` : '';
      console.log(s);
      if(shifts.length > 0) {
        let validShifts = [];
        const inventoryShifts = shifts.filter((shift) => {
          return shift.name === 'shift_inventory' && shift.trade_status === 'inventory'
        });
        const shiftCards = inventoryShifts.map((shift, i) => {
          const regional = parseInt(userCookie.location_id) !== shift.location_id;
          const startDay = moment(shift.start_at).format('DD');
          const selectedDay = moment(date).format('DD');
          const startDateTime = moment(shift.start_at);
          const endDateTime = moment(shift.end_at);
          const current = moment();
      
          
          const startToNow = moment.duration(startDateTime.diff(current));
          const hoursStartToNow = parseInt(startToNow.asHours())
          const endToNow = moment.duration(endDateTime.diff(current));
          const hoursEndToNow = parseInt(endToNow.asHours());
          

          
          const startLimit = moment().add(24, 'hours');
          const s = date ? `date: ${date.format('MM-DD h:mma')}, startLimit: ${startLimit.format('MM-DD h:mma')}` : '';
          console.log(s);
          // start time  is within 24 hours and end time is before now
          if(startDay === selectedDay && startDateTime.isBefore(startLimit) && endDateTime.isAfter(current)) {
            validShifts.push(shift);
          }else {
            let msg = '';
            if(startDateTime.isAfter(startLimit)) {
              msg = `shift: ${shift.id}, current: ${current.format('DD h:mma')}, start time is ${startDateTime.format('DD h:mma')}, more than 24 hours now`;
            }
            if(endDateTime.isBefore(current)) {
              msg = `shift: ${shift.id}, current: ${current.format('DD h:mma')}, end time is ${endDateTime.format('DD h:mma')}, expired.`;
            }
            console.log(msg);
          }
        });
        const sortedShifts = validShifts.sort(
          (a, b) => new Date(a.start_at) - new Date(b.start_at)
        );
        if(this._isMounted) {
          this.setState({shiftsToShow: validShifts});
        }
      }

      if(this._isMounted) {
        if(this.newShiftButtonRef && this.newShiftButtonRef.current) {
         this.newShiftButtonRef.current.focus();
        }
      }
    }

  };

  componentWillUnmount () {

    this._isMounted = false;

  };

  renderReason1 = () => {
    const { channels, _updateState } = this.props;
    const {callOffReason1, selectedReason1} = this.state;

    const reasons = callOffReason1 && callOffReason1.map((reason) => (
      <div
        key={reason.id}
        className="post-shift__radio-item post-shift__radio-item--groups"
      >
        <RadioButton
          checked={selectedReason1 === reason.tag_content}
          id={reason.id}
          label={reason.tag_content}
          onChange={this._updateState}
        />
      </div>
    ));

    if (reasons) {
      return reasons;
    }

    return (
      <div className="post-shift__radio-item post-shift__radio-item--groups">
        <div className="post-shift__team-list__member">
          <FormattedMessage id="CallOffShift.noReason" defaultMessage="Reason is not available" />
        </div>
      </div>
    );
  };

  renderReason2 = () => {
    const { channels,  _updateState } = this.props;
    const {callOffReason2, selectedReason2} = this.state;

    const reasons = callOffReason2 && callOffReason2.map((reason) => (
      <div
        key={reason.id}
        className="post-shift__radio-item post-shift__radio-item--groups"
      >
        <RadioButton
          checked={selectedReason2 === reason.tag_content}
          id={reason.id}
          label={reason.tag_content}
          onChange={this._updateState}
        />
      </div>
    ));

    if (reasons) {
      return reasons;
    }

    return (
      <div className="post-shift__radio-item post-shift__radio-item--groups">
        <div className="post-shift__team-list__member">
          <FormattedMessage id="CallOffShift.noReason" defaultMessage="Reason is not available" />
        </div>
      </div>
    );
  };

  renderInventoryShifts = () => {
    const { shifts, userCookie, locationSettings, } = this.props;
    const { date, shiftsToShow, } = this.state;

    const shiftCards = shiftsToShow && shiftsToShow.map((shift, i) => {
      const regional = parseInt(userCookie.location_id) !== shift.location_id;
       return (
          <InventoryShiftCard
            key={shift.id.toString()}
            userCookie={userCookie}
            shift={shift}
            regional={regional}
            locationSettings={locationSettings}
            handleCardClick={this.handleCardClick}
          />
        );
    });
    
    if(shiftCards && shiftCards[0]) {
      this.foundShift = true;
    }else {
      this.foundShift = false;
    }
    return shiftCards;
  }

  toggleDatePicker = () => {
    const { showDatePicker, } = this.state;
    if(this._isMounted) {
      this.setState({showDatePicker: !this.state.showDatePicker});
    }
  }

  dateString = (startDate) => {

    const start = moment(startDate);
    const dateString = `${start.format('ddd, MMM DD')}`;
    return dateString;
  }

  _updateState = (action, data) => {
    const {rtoTypes, callOffReason1, callOffReason2, selectedShift, } = this.state;

    if(!this._isMounted){
      return;
    }
    switch (action) {
      case DataNames.REACH:
        const idx1 = callOffReason1.findIndex(
          (reason) => reason.tag_content === data.reachValue
        );
        if(idx1 >= 0) {
          this.setState({
            selectedReason1: data.reachValue,
            channel_id: data.channel_id,
          });
        }else {
          const idx2 = callOffReason2.findIndex(
            (reason) => reason.tag_content === data.reachValue
          );
          if(idx2 >= 0) {
            this.setState({
              selectedReason2: data.reachValue,
              channel_id: data.channel_id,
            });
          }
        }
        break;
      case DataNames.DATE:
        if(this._isMounted) {
          this.setState({ date: data, dateString: this.dateString(data) });
          this.setState({invalidStartTime: false});
          this.setState({selectedShift: null});
          this.setState({inputJobTitle: ''});
        }
        break;
      case DataNames.TIMES:
        let start = { hour: '', min: '', period: ''};
        let end = { hour: '', min: '', period: ''};
        let start1 = { hour: '', min: '', period: ''};
        let end1 = { hour: '', min: '', period: ''};
        const startTime = moment(data[0]);
        const endTime = moment(data[1]);
        start1.hour = moment(startTime).format('h');
        start1.min = moment(startTime).format('mm');
        start1.period = moment(startTime).format('a');
        end1.hour = moment(endTime).format('h');
        end1.min = moment(endTime).format('mm');
        end1.period = moment(endTime).format('a');
        if(selectedShift) {
          start.hour = moment(selectedShift.start_at).format('h');
          start.min = moment(selectedShift.start_at).format('mm');
          start.period = moment(selectedShift.start_at).format('a');
          end.hour = moment(selectedShift.end_at).format('h');
          end.min = moment(selectedShift.end_at).format('mm');
          end.period = moment(selectedShift.end_at).format('a');
        
          const changed = start.hour !== start1.hour ||
                        start.min !== start1.min ||
                        start.period !== start1.period ||
                        end.hour !== end1.hour ||
                        end.min !== end1.min ||
                        end.period !== end1.period
          if(changed) {
            if(this._isMounted) {
              this.setState({selectedShift: null});
              if(this.newShiftButtonRef && this.newShiftButtonRef.current) {
               //this.newShiftButtonRef.current.focus();
              }
            }
          }
        }

        if(this.verifyTimes(data)) {
          if(this._isMounted) {
            this.setState({times: data, timeRangeString: createTimeRangeString(data),});
            this.setState({invalidStartTime: false});
          }
        }else {
          if(this._isMounted) {
            this.setState({invalidStartTime: true});
          }
        }
        break;
      default:
        break;
    }
  };

  canProgress = () => {
    const { date, times, inputJobTitle, callOffReason1, callOffReason2, selectedReason1, selectedReason2,} = this.state;
    const isDate = date !== null;
    const isTimes = times.length > 0;
    const isJobTitle = inputJobTitle.length > 0;
    let canProgress = false;
    if(callOffReason2) {
      canProgress = selectedReason1 && selectedReason2 && isDate && isTimes && isJobTitle; 
    }else {
      canProgress = selectedReason1 && isDate && isTimes && isJobTitle
    }

    return canProgress;
  };

  verifyTimes = (times) => {
    const { date, debugInfo, } = this.state;

    if(!times || times.length !== 2) {
      return false;
    }
    times[0]
      .year(date.year())
      .month(date.month())
      .date(date.date());

    times[1]
      .year(date.year())
      .month(date.month())
      .date(date.date());
    const startDateTime = moment(times[0]);
    let endDateTime = moment(times[1]);
    const current = moment();
    const startBeforeEnd = isNextDay(times);
    if(startBeforeEnd) {
      endDateTime = endDateTime.add(1, 'days');
      //console.log('after ' + endDateTime.format('YYYY-MM-DD h:mma'));
    }

    const startToNow = moment.duration(startDateTime.diff(current));
    const endToNow = moment.duration(endDateTime.diff(current));
    const hoursStartToNow = parseInt(startToNow.asHours())
    const hoursEndToNow = parseInt(endToNow.asHours());

    const startLimit = moment().add(24, 'hours');
    
    // start time  is within 24 hours and end time is before now
    if(startDateTime.isBefore(startLimit) && endDateTime.isAfter(current)) {
      return true;
    }else {
      const msg = `Time error! now: ${current.format('DD h:mma')}, start time limit: ${startLimit.format('DD h:mma')} , end time: ${endDateTime.format('DD h:mma')}`;
      console.log(msg);
      return false;
    }
  };

  handleGoNext = (event) => {
    if(!this.canProgress()) {
      return;
    }
    event.preventDefault();
    if(this._isMounted) {
      this.setState({confirmPost: true, });
    }
  };

  handleJobTitleChange = (event) => {
    const { selectedShift, } = this.state;
    event.preventDefault();
    if(this._isMounted) {
      this.setState({inputJobTitle: event.target.value});
    }
    if(selectedShift) {
      if(event.target.value !== selectedShift.primary_job) {
        if(this._isMounted) {
          //this.setState({selectedShift : null});
          this.setState({shiftDataChanged: true});
        }
      }
    }
  };

  handleGoBack = (event) => {
    event.preventDefault();
    if(this._isMounted) {
      this.setState({confirmPost: false, });
    }
  };

  handleCardClick = (shift) => {
    if(this._isMounted) {
      this.setState({selectedShift: shift});
      this.setState({invalidStartTime: false});
      this.setState({shiftDataChanged: false});
      this.setState({timeRangeString: ''});
    }
    if(shift.primary_job) {
      if(this._isMounted) {
        this.setState({inputJobTitle: shift.primary_job});
      }
    }
  };

  handleNewShiftClick = (event) => {
    event.preventDefault();
    if(this._isMounted) {
        this.setState({selectedShift: null});
        this.setState({shiftDataChanged: false});
        this.setState({invalidStartTime: false});
        this.setState({inputJobTitle: ''});
        this.setState({timeRangeString: ''});
    }
  };

  handleTimeChanged = (Object) => {
    const { selectedShift, } = this.state;
    if(selectedShift) {
      if(this._isMounted) {
        this.setState({selectedShift: null});
        this.setState({shiftDataChanged: true});
      }
    }
    if(this._isMounted) {
      this.setState({invalidStartTime: false});
    }
  };

  handleSubmit = (event) => {
    const { _toggleModal, _handleCallOffShift, shifts, _setAppState, userCookie, } = this.props;
    const { times, date, inputJobTitle, selectedShift, callOffReason1, callOffReason2, selectedReason1, selectedReason2, } = this.state;

    this.setState({ submitting: true });

    times[0]
      .year(date.year())
      .month(date.month())
      .date(date.date());

    times[1]
      .year(date.year())
      .month(date.month())
      .date(date.date());

    const timeZone = getDefaultTimezone();
    const endAt = isNextDay(times) ? times[1].add(1, 'days') : times[1];
    const start = moment.tz(times[0], timeZone).format();
    const end = moment.tz(endAt, timeZone).format();
    let reason1_tag = '';
    let reason2_tag = '';
    //find tag keys for reason 1 and reason 2
    const idx = callOffReason1.findIndex(
        (reason) => reason.tag_content === selectedReason1
    );
    if(idx >=0){
      reason1_tag = callOffReason1[idx].tag_key;
    }
    if(callOffReason2) {
      const idx2 = callOffReason2.findIndex(
          (reason) => reason.tag_content === selectedReason2
      );
      if(idx2 >=0){
        reason2_tag = callOffReason2[idx2].tag_key;
      }
    }

    if(selectedShift) {
      if(callOffReason2) {
        if(reason1_tag && reason2_tag) {
          callOffShift({
            shift_id: selectedShift.id,
            call_off_reasons_1_tag_key: reason1_tag,
            call_off_reasons_2_tag_key: reason2_tag,
          }).then((response) => {
            const data = response.data;
            const id = data.schedule_element.id;
            const location = data.schedule_element.location_id;
            const owner = `${data.schedule_element.owner.first_name} ${data.schedule_element.owner.last_name}`;
            const msg = `Call off shift: id: ${id}, location: ${location}, owner: ${owner}`;
            //console.log(msg);
            setCalledOffShiftNew(data.schedule_element, shifts, _setAppState);
            _toggleModal();
            _handleCallOffShift(selectedShift, reason1_tag, reason2_tag, selectedReason1, selectedReason2);
          }).catch((error) => {
            console.log(error);
          });
        }else {
          alert('reason 1 tag or reason 2 tag not found.');
        }
      }else {
        if(reason1_tag) {
          callOffShift({
            shift_id: selectedShift.id,
            call_off_reasons_1_tag_key: reason1_tag,
          }).then((response) => {
            const data = response.data;
            const id = data.schedule_element.id;
            const location = data.schedule_element.location_id;
            const owner = `${data.schedule_element.owner.first_name} ${data.schedule_element.owner.last_name}`;
            const msg = `Call off shift: id: ${id}, location: ${location}, owner: ${owner}`;
            //console.log(msg);
            setCalledOffShiftNew(data.schedule_element, shifts, _setAppState);
            _toggleModal();
            _handleCallOffShift(selectedShift, reason1_tag, reason2_tag, selectedReason1, selectedReason2);
          }).catch((error) => {
            console.log(error);
          });
        }else {
          alert('reason 1 tag not found.');
        }
      }
    }else {
      let shiftData = {start_at: '', end_at: '', shift_content: '', name: '', trade_status: '', call_off_reasons_1_tag_key: '',
                      call_off_reasons_2_tag_key: '', location_id: 0, cached_primary_job: '',};
      if(callOffReason2) {
        shiftData = {
          start_at: start,
          end_at: end,
          shift_content: '',
          call_off_reasons_1_tag_key: reason1_tag,
          call_off_reasons_2_tag_key: reason2_tag,
          location_id: userCookie.location_id,
          cached_primary_job: inputJobTitle,
        };
      }else {
        shiftData = {
          start_at: start,
          end_at: end,
          shift_content: '',
          call_off_reasons_1_tag_key: reason1_tag,
          location_id: userCookie.location_id,
          cached_primary_job: inputJobTitle,
        };
      }
      postShiftCalledOff(shiftData)
        .then((response) => {
          const { _setAppState, shifts } = this.props;
          addShift(response.data.schedule_element, shifts, _setAppState);
          _toggleModal();
          _handleCallOffShift(response.data.schedule_element, reason1_tag, reason2_tag, selectedReason1, selectedReason2);
        }).catch((error) => {
          const msg = `ShiftForCallOff.handleSubmit: failed on postShiftCallOff. error: ${error}`;
          console.log(msg);
          if(this._isMounted) {
            this.setState({ submitting: false });
          }
        });
    };
  }

  renderActionButtons = () => {
    const { showDatePicker, confirmPost, } = this.state;

    const submitBtnClass = classNames({
      "shift-call-off__submit-btn": true,
      "shift-call-off__submit-btn--active": this.canProgress(),
    });

    return (
        <button className={submitBtnClass} onClick={this.handleGoNext} type="button">
          <FormattedMessage 
            id="ShiftForCallOff.next" 
            defaultMessage="Next" 
          />
          &nbsp;
          <span className="icon-submit-arrow" />
        </button>
    );
  };


  
  render () {
  	const { _toggleModal, userCookie, locale, } = this.props;
    const {showDatePicker, myJobTitle, confirmPost, date, times, timeRangeString, inputJobTitle, submitting, 
      selectedReason1, selectedReason2, invalidStartTime, selectedShift, callOffReason1, callOffReason2, shiftsToShow, } = this.state;

    const dateText = moment(date).format('dddd, MMM DD');
    const timeZone = getDefaultTimezone();
    let endAt = '';
    let start = '';
    let end = '';
    let msg = '';
    let myFullName = '';
    if(times.length === 2) {
      endAt = isNextDay(times) ? times[1].add(1, 'days') : times[1];
      //start = moment.tz(times[0], timeZone).format('h:mm A');
      //end = moment.tz(endAt, timeZone).format('h:mm A');
      if(locale === 'fr') {
        start = moment.tz(times[0], timeZone).format('HH:mm');
        end = moment.tz(endAt, timeZone).format('HH:mm');
      }else {
        start = moment.tz(times[0], timeZone).format('h:mm A');
        end = moment.tz(endAt, timeZone).format('h:mm A');
      }
    }
    if(selectedShift) {
      myFullName = `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`;
    }else {
      if(userCookie) {
        myFullName = `${userCookie.first_name} ${userCookie.last_name}`;
      }
    }
    
    const submitBtnClass = classNames({
      "modal-components__submit-btn": true,
      "modal-components__submit-btn--active": this.canProgress(),
    });

    const startTimeWrong = (<FormattedMessage
                              id="ShiftForCallOff.errorStartTime"
                              defaultMessage="*Shift start time is not within 24 hours"
                              />);
    const within24Hours = (<FormattedMessage
                              id="ShiftForCallOff.within24Hours"
                              defaultMessage="* Only shifts starting within 24 hours may be called off."
                              />);

    if(confirmPost) {
      return (
        <div className="cover-shift">
          <ModalWrapper>
            <CloseModal _toggleModal={_toggleModal} dark />
            <div className="modal-components__container">
              <h1 className="shift-call-off__header">
                <FormattedMessage
                  id="ShiftForCallOff.previewSubmit1"
                  defaultMessage="Preview & Submit"
                />
              </h1>
              <div className="shift-call-off__line-wrap">
                <div className="modal-components__row">
                  <div className="shift-call-off__item-wrap">
                    <div className="item">
                      <label className="item__name">
                        <FormattedMessage
                          id="ShiftForCallOff.dateTime"
                          defaultMessage="Date and Time"
                        />
                      </label>
                      <label className="item__value">{dateText}</label>
                    </div>
                  </div>
                  <div className="shift-call-off__item-h-wrap">
                    <div className="item-h">
                      <label className="item-h__name">
                          <FormattedMessage
                            id="ShiftForCallOff.startTime"
                            defaultMessage="Start Time:"
                          />
                        </label>
                        <label className="item-h__value">{start}</label>
                    </div>
                  </div>
                  <div className="shift-call-off__item-h-wrap">
                    <div className="item-h">
                      <label className="item-h__name">
                          <FormattedMessage
                            id="ShiftForCallOff.endTime"
                            defaultMessage="End Time:"
                          />
                        </label>
                        <label className="item-h__value">{end}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shift-call-off__line-wrap">
              <div className="modal-components__row">
                <div className="shift-call-off__item-wrap">
                  <div className="item">
                    <label className="item__name">
                      <FormattedMessage
                        id="ShiftForCallOff.fullName"
                        defaultMessage="My Full Name"
                      />
                    </label>
                    <label className="item__value">{myFullName}</label>
                  </div>
                  <div className="item">
                    <label className="item__name">
                      <FormattedMessage
                        id="ShiftForCallOff.jobTitle"
                        defaultMessage="Job Title"
                      />
                    </label>
                    <label className="item__value">{inputJobTitle}</label>
                  </div>
                  <div className="item">
                    <label className="item__name">
                      <FormattedMessage
                        id="ShiftForCallOff.callOffReason"
                        defaultMessage="Call Off Reason"
                      />
                    </label>
                    <label className="item__value">{selectedReason1}</label>
                  </div>
                  {callOffReason2 && <div className="item">
                    <label className="item__name">
                      <FormattedMessage
                        id="ShiftForCallOff.useSickLeave"
                        defaultMessage="Use Protected Sick Leave?"
                      />
                    </label>
                    <label className="item__value">{selectedReason2}</label>
                  </div>}
                </div>
              </div>
              </div>
              <div className="modal-components__row">
                <div className="shift-call-off__reason-wrap">
                 
                </div>
              </div>
              <div className="modal-components__row">
                <div className="shift-call-off__item-wrap">
                  <button className="shift-call-off__button" onClick={this.handleGoBack}>
                    <FormattedMessage
                      id="ShiftForCallOff.back"
                      defaultMessage="Back"
                    />
                  </button>
                  {!submitting && <button className="shift-call-off__button" onClick={this.handleSubmit}>
                    <FormattedMessage
                      id="ShiftForCallOff.confirmPost"
                      defaultMessage="Submit Call Off"
                    />
                  </button>}
                </div>
              </div>
            </div>
          </ModalWrapper>
        </div>
      );
    }
    
  	return (
      <div className="cover-shift">
        <ModalWrapper>
          <CloseModal _toggleModal={_toggleModal} dark />
          <div className="modal-components__container">
             <h1 className="shift-call-off__header">
              <FormattedMessage
                id="ShiftForCallOff.callOffShift"
                defaultMessage="Call Off Shift"
              />
            </h1>
            <div className="modal-components__row">
              <div className="shift-call-off__line-wrap">
                <div className="modal-components__col">
                  <div className="shift-call-off__date-time-label-wrap">
                     <p className="shift-call-off__label">
                      <FormattedMessage
                        id="ShiftForCallOff.selectDateTime"
                        defaultMessage="Select Shift Date and Time"
                      />
                    </p>
                  </div>
                  <div className="shift-call-off__date-time-button-wrap">
                    <DateButton 
                      _toggleDatePicker={this.toggleDatePicker}
                      dateString={this.state.dateString}
                    /> 
                    <TimeButton 
                       _toggleDatePicker={this.toggleDatePicker}
                       timeRangeString={this.state.timeRangeString}
                    />
                    {showDatePicker ?  <button className="up-arrow" onClick={this.toggleDatePicker}/>
                      : <button className="down-arrow" onClick={this.toggleDatePicker}/>}
                  </div>
                  {invalidStartTime && <label className="shift-call-off__start-time-error">{startTimeWrong}</label>}
                  {this.state.debug && <label className="">{this.state.debugInfo}</label>}
                </div>
                <div className="modal-components__col">
                  <div className="shift-call-off__date-time-label-wrap">
                    <p className="shift-call-off__label">
                      <FormattedMessage
                        id="ShiftForCallOff.jobTitle"
                        defaultMessage="Job Title"
                      />
                    </p>
                  </div>
                  {/*<label className="shift-call-off__job-title">{myJobTitle}</label>*/}
                  <input
                    className="shift-call-off__job-title"
                    value={this.state.inputJobTitle}
                    multiline={false}
                    onChange={this.handleJobTitleChange}
                    readOnly={false}
                  />
                </div>
              </div> 
            </div>
            {!this.state.showDatePicker && <div className="modal-components__row">
              <div className="shift-call-off__reason-wrap">
                <label>
                  <FormattedMessage
                    id="ScheduledShift.callOffReason"
                    defaultMessage="Call Off Reason"
                  />
                </label>
                <div className="shift-call-off__reasons-1">
                  {this.renderReason1()}
                </div>
              </div>
              {callOffReason2 && <div className="shift-call-off__reason-wrap">
                <label>
                  <FormattedMessage
                    id="ShiftForCallOff.useSickLeave"
                    defaultMessage="Use Protected Sick Leave?"
                  />
                </label>
                <div className="shift-call-off__reasons-2">
                  {this.renderReason2()}
                </div>
              </div>}
            </div>}
            {!this.state.showDatePicker && <div className="modal-components__row">
                <div className="modal-components__col modal-components__col--submit">
                  {this.renderActionButtons()}
                </div>
            </div>}
          </div>
        </ModalWrapper>
        {this.state.showDatePicker && 
        <div className="shift-call-off__date-picker-and-span">
          <div className="shift-call-off__date-picker-wrap">
            <DateTimePicker
              _updateState={this._updateState}
              toggleDatePicker={this.toggleDatePicker}
              getStartTime={this.getStartTime}
              getEndTime={this.getEndTime}
              clearDatesAndTimes={this.clearDatesAndTimes}
              date={this.state.date}
              selectedShift={this.state.selectedShift}
              handleTimeChanged={this.handleTimeChanged}
              shiftDataChanged={this.state.shiftDataChanged}
              locale={locale}
            />
          </div>
          <div className="shift-call-off__bottom-wrap">
            {shiftsToShow && shiftsToShow.length > 0 && <label>
              <FormattedMessage
                id="ShiftForCallOff.selectShift"
                defaultMessage="Select Existing Shift"
              />
            </label>}
            <label>{within24Hours}</label>
            <div className="shift-call-off__shift-cards-wrap">
              {this.renderInventoryShifts()}
              <button className="shift-call-off__button-create" onClick={this.handleNewShiftClick} ref={this.newShiftButtonRef}>
                <FormattedMessage
                  id="ShiftForCallOff.createShift"
                  defaultMessage="Create a new shift to Call Off"
                />
              </button>
            </div>
          </div>
        </div>}
      </div>
    );	
  }
}

ShiftForCallOff.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  _toggleModal: PropTypes.func.isRequired,
  shifts: PropTypes.shape({}).isRequired,
};

export default injectIntl (ShiftForCallOff);

