import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingSpinner } from '_components/GeneralComponents/LoadingSpinner';
import { getQueryStringParameter } from '_helpers/browser-interactions';
import { RenewSSOsession } from '_helpers/api-calls';
import { updateUserPosition } from '_helpers/api-calls/user-calls';
import { getLoginURL } from '_helpers/cookies';
import { BASE_URL } from '_constants/index';

/**
 * SSO SOLUTIONS - after logging in w/ SSO identity provider and redirected
 * 1.) If user_uuid is present in response, everything is done
 * 2.) If no user_uuid, but sso_mession_id is present, must call PUT
 *     /api/customer/user_role/sso_sessions/{{sso_session_id}}/link
 * 3.) If neither user_uuid or sso_mession_id are present,
 *     code should be available. Call SSO renew session API
 *
 * NOTE: Gap solution is always solution #3
 * NOTE: Lbrand can be any of these
 * NOTE: #3 can work for all brands - code is always returned
 *
 * For now, solution #3 is implemented and will the only one implemented until
 * we encounter a time where solutions #1-2
 */

class SingleSignOnCodePage extends Component {
  state = {
    loading: true,
    path: null,
  };

  componentDidMount() {
    this._callSessionRenewal();
  }

  _callSessionRenewal = () => {
    const { match } = this.props;
    const oauthCode = this._getoauthCode(match.params.company_id);
    const sso_provider = getQueryStringParameter('sso_provider');

    let company;

    if (match.params.company_id === 'gapoauth') {
      company = 'sso_gap';
    }

    if (match.params.company_id === 'lbrandoauth') {
      company = 'sso_lbrand';
    }

    if (match.params.company_id === 'aramark_auth') {
      company = 'sso_aramark';
    }

    if (match.params.company_id === 'xpo_auth') {
      company = 'sso_xpo';
    }

    if (match.params.company_id === 'employee_auth') {
      company = sso_provider;
    }

    if (oauthCode && company) {
      RenewSSOsession(company, oauthCode).then(
        this._handleRenewalSuccessResponse,
        this._handleRenewalErrorResponse,
      );
    }
  };

  /**
   * Check for login URL and production/test environment
   * Redirect accordingly
   */
  _determineRedirectionAfterSuccess = () => {
    let url = 'localhost:3000';
    const loginURL = getLoginURL();

    if (loginURL && BASE_URL === 'app.myshyft.com') {
      url = `https://${loginURL}`;
    } else if (loginURL && BASE_URL !== 'app.myshyft.com') {
      url = `https://${loginURL}`;
    } else {
      url = 'localhost:3000';
    }

    window.location.replace(url);
  }


  /**
   * if user uuid and current user id are matched
   * then the user is logged in
   */
  _handleRenewalSuccessResponse = (response) => {
    const { status, data: { position } } = response;
    if (status === 200 || status === 204) {
      const urlParams = new URLSearchParams(window.location.search);
      const userPrivilegeId = urlParams.get('user_privilege_id');

      if (position) {
        // Set profile position with api call
        updateUserPosition(userPrivilegeId, { position })
          .then(() => {
            // Redirect after successfully
            // updating profile with position
            this._determineRedirectionAfterSuccess();
          });
      } else {
        this._determineRedirectionAfterSuccess();
      }
    }
  };

  /**
   * if user uuid and current user id are matched
   * then the user is logged in
   */
  _handleRenewalErrorResponse = ({ response }) => {
    if (response && (response.status === 401 || response.status === 403 || response.status === 500 || response.status === 422)) {
      this.setState({
        path: '/login/sso',
      });
    }
  };

  /**
   * @param companyId { String } - company name so we can check for correct
   * string parameter
   * @return code | false
   */
  _getoauthCode = (companyId) => {
    switch (companyId) {
      case 'gapoauth':
        return getQueryStringParameter('code');

      default:
        return getQueryStringParameter('code');
    }
  };

  render() {
    const { loading, path } = this.state;
    if (path) {
      return <Redirect to={path} exact />;
    }
    if (loading) {
      return (
        <div className="index-page index-page__loading">
          <LoadingSpinner color="red" size="large" />
        </div>
      );
    }
    return (
      <>
        <div className="">oauth</div>
      </>
    );
  }
}

export { SingleSignOnCodePage };
