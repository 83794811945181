import { defineMessages } from 'react-intl';

const messages = defineMessages({
  passwordError1: {
    id: 'ChangePasswordPage.passwordError1',
    defaultMessage: 'Please enter a valid password. Your password must contain 1 uppercase, 1 lowercase and 1 special character and must be at least 8 characters long.',
  },
  passwordError2: {
    id: 'ChangePasswordPage.passwordError2',
    defaultMessage: 'Your passwords must match.',
  },
  formError1: {
    id: 'ChangePasswordPage.formError1',
    defaultMessage: 'Sorry, that appears to be the wrong password reset URL. Please confirm you have the correct URL from the email we sent you or go back to the password reset page to start again.',
  },
  formError2: {
    id: 'ChangePasswordPage.formError2',
    defaultMessage: 'Something went wrong while processing the information. Please try again.',
  },
  passwordPlaceholder: {
    id: 'ChangePasswordPage.passwordPlaceholder',
    defaultMessage: 'Password',
  },
  confirmPasswordPlaceholder: {
    id: 'ChangePasswordPage.confirmPasswordPlaceholder',
    defaultMessage: 'Confirm Password',
  },
});

export default messages;
