import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "_helpers/findIndex-polyfill";
import { FormattedMessage, injectIntl } from "react-intl";

import { validateEmail } from "_helpers/validation";
import messages from "./messages";
import EmailConfirmation from "./EmailConfirmation/EmailConfirmation";
import PhoneConfirmation from "./PhoneConfirmation/PhoneConfirmation";

import "./LoginSecondStage.scss";

class LoginSecondStage extends React.Component {
  constructor(props) {
    super(props);

    const { email, phone } = this.props;

    this.state = {
      validity: true,
      email,
      phone,
      password: "",
      mobilePin: ""
    };
  }

  _emailChange = e => {
    const [value] = e.target;

    this.setState({
      validity: validateEmail(value)
    });
  };

  _passwordChange = e => {
    const { value } = e.target;

    this.setState({
      password: value
    });
  };

  _mobilePinChange = val => {
    this.setState({
      mobilePin: val
    });
  };

  _phoneChange = status => {
    this.setState({
      validity: status
    });
  };

  _submitForm = e => {
    const { type, _passwordSubmit, _pinSubmit } = this.props;
    const { email, password, mobilePin } = this.state;

    e.preventDefault();
    if (type === "email") {
      _passwordSubmit(email, password);
    } else if (type === "phone") {
      _pinSubmit(mobilePin);
    }
  };

  _loginWithEmail = () => {
    const { _passwordSubmit } = this.props;
    const { password } = this.state;
    // api call with email/password combination
    _passwordSubmit(password);
  };

  _loginWithPhone = pin => {
    const { _pinSubmit } = this.props;
    // api call with phone/pin combination
    _pinSubmit(pin);
  };

  _renderConfirmationComponent = () => {
    const { type, mobilePinError } = this.props;
    const { email, validity, phone } = this.state;

    if (type === "email") {
      return (
        <EmailConfirmation
          value={email}
          validity={validity}
          inputChange={this._emailChange}
          passwordChange={this._passwordChange}
        />
      );
    }
    if (type === "phone") {
      return (
        <PhoneConfirmation
          value={phone}
          validity={validity}
          inputChange={this.phoneChange}
          _pinChange={this._mobilePinChange}
          mobilePinError={mobilePinError}
        />
      );
    }
    return null;
  };

  render() {
    const {
      mobilePinError,
      emailPasswordError,
      _backButtonText,
      back,
      intl: { formatMessage }
    } = this.props;
    const errorClass = classNames({
      "login__form-errors": true,
      show: mobilePinError || emailPasswordError
    });

    // const passwordErrorClass = classNames({
    //   show: emailPasswordError.length > 0,
    // });

    const errors = (
      <section className={errorClass}>
        <p
          className={`login__form-error-text login__form-error-text--center ${errorClass}`}
        >
          {mobilePinError || emailPasswordError}
        </p>
      </section>
    );

    return (
      <form className="login__form" onSubmit={this._submitForm}>
        {this._renderConfirmationComponent()}

        <section className="login__form-action">
          <p className="login__back">
            {_backButtonText || formatMessage(messages.backButtonLabel)}
            <br />
            <button
              className="login__link"
              onClick={back}
              href=""
              type="button"
            >
              <FormattedMessage
                id="LoginSecondStage.backButtonText"
                defaultMessage="Click here"
              />
            </button>
          </p>
        </section>

        {mobilePinError || emailPasswordError ? errors : null}

        <section className="login__form-action">
          <button
            className="login__form-submit button button-primary"
            type="submit"
          >
            <FormattedMessage
              id="LoginSecondStage.loginBtn"
              defaultMessage="Log In"
            />
          </button>
        </section>
      </form>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });

LoginSecondStage.propTypes = {
  type: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  _pinSubmit: PropTypes.func.isRequired,
  mobilePinError: PropTypes.string,
  emailPasswordError: PropTypes.string,
  _backButtonText: PropTypes.string,
  intl: intlShape.isRequired
};

LoginSecondStage.defaultProps = {
  email: "",
  phone: "",
  mobilePinError: "",
  emailPasswordError: "",
  _backButtonText: ""
};

export default injectIntl(LoginSecondStage);
