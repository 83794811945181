import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ProfileThumb } from '@shyft/acorns';
import { shiftNames, shiftStatus, shiftStatuses } from '_constants';

const ThumbWrap = styled.div`
  text-align: center;
  margin-bottom: 0.25rem;
`;

const StyledProfileThumb = styled(ProfileThumb).attrs({
  width: '48px',
  height: '48px',
})`
  display: inline-block;

  &:first-of-type {
    margin-right: 0.5rem;
  }
`;

const CardProfileThumb = ({ shift, userId }) => {
  const { coverer, owner, name } = shift;

  const thumbProps = (shiftName, profileImgObj) => {
    const thumbUrl = profileImgObj ? profileImgObj.thumb_url : null;

    return {
      icon: shiftName === 'shift_vto' ? 'vto' : null,
      thumbUrl,
    };
  };

  const secondThumbProps = (shiftName, profileImgObj) => {
    let icon = null;
    let thumbUrl = profileImgObj ? profileImgObj.thumb_url : null;

    if (shiftName === shiftNames.SHIFT_VTO && shiftStatus(shift, { id: userId }) === shiftStatuses.AVAILABLE) {
      icon = 'vto';
      thumbUrl = 'null';
    }

    return {
      icon,
      thumbUrl,
    };
  };

  const secondThumb = () => {
    if (shift.name === 'shift_vto' && coverer) {
      return (
        <StyledProfileThumb
          {...secondThumbProps(name, coverer.profile_image)}
        />
      );
    }
    if (coverer && coverer.id !== userId) {
      return (
        <StyledProfileThumb
          {...secondThumbProps(name, coverer.profile_image)}
        />
      );
    }

    return false;
  };

  return (
    <ThumbWrap>
      <StyledProfileThumb
        {...thumbProps(name, owner.profile_image)}
      />
      {secondThumb()}
    </ThumbWrap>
  );
};

CardProfileThumb.propTypes = {
  shift: PropTypes.shape({

  }).isRequired,
  userId: PropTypes.number.isRequired,
};

export default CardProfileThumb;
