import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import { Redirect } from "react-router-dom";
import { validatePasswordStrength } from "_helpers/validation";
import { getQueryStringParameter } from "_helpers/browser-interactions";
import { ConfirmPasswordReset } from "_helpers/api-calls";

import { Navbar } from "_components/GeneralComponents/Navbar";
import messages from "./messages";
import "./ChangePasswordPage.scss";

class ChangePasswordPagei18n extends React.Component {
  state = {
    token: "",
    firstPassword: {
      value: "",
      validity: false,
      errorMessage: "",
    },
    secondPassword: {
      value: "",
      validity: false,
      errorMessage: "",
    },
    successful: false,
    error: false,
    errorMessage: "",
    path: null,
  };

  componentDidMount() {
    if (getQueryStringParameter("reset_password_token")) {
      this.setState({
        token: getQueryStringParameter("reset_password_token"),
      });
    } else {
      this.setState({
        path: {
          pathname: "/login",
        },
      });
    }
  }

  login = () => {
    this.setState({
      path: {
        pathname: "/login",
      },
    });
  };

  _handleFirstPasswordChange = (e) => {
    const { value } = e.target;
    this.setState({
      firstPassword: {
        value,
        validity: validatePasswordStrength(value),
        errorMessage: "",
      },
    });
  };

  _handleSecondPasswordChange = (e) => {
    const { firstPassword } = this.state;
    const { value } = e.target;
    this.setState({
      secondPassword: {
        value,
        validity: firstPassword.value === value,
        errorMessage: "",
      },
    });
  };

  _validateForm = () => {
    const { intl } = this.props;
    const { firstPassword, secondPassword } = this.state;
    let validity = true;
    if (!firstPassword.validity) {
      validity = false;
      this.setState({
        firstPassword: Object.assign(firstPassword, {
          errorMessage: intl.formatMessage(messages.passwordError1),
        }),
      });
    }

    if (!secondPassword.validity) {
      validity = false;
      this.setState({
        secondPassword: Object.assign(secondPassword, {
          errorMessage: intl.formatMessage(messages.passwordError2),
        }),
      });
    }

    return validity;
  };

  _formSubmit = (e) => {
    const { intl } = this.props;
    const { firstPassword, secondPassword, token } = this.state;
    e.preventDefault();
    this.setState({
      error: false,
      errorMessage: "",
    });

    if (this._validateForm()) {
      ConfirmPasswordReset(
        firstPassword.value,
        secondPassword.value,
        token
      ).then(
        (response) => {
          if (response.status === 204) {
            this.setState({
              successful: true,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.setState({
              error: true,
              errorMessage: intl.formatMessage(messages.formError1),
            });
          } else {
            this.setState({
              error: true,
              errorMessage: intl.formatMessage(messages.formError2),
            });
          }
        }
      );
    }
  };

  render() {
    const { history, intl } = this.props;
    const {
      firstPassword,
      secondPassword,
      error,
      errorMessage,
      path,
      successful,
    } = this.state;
    const FirstPasswordErrorMessage = () => {
      if (firstPassword.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {firstPassword.errorMessage}
          </p>
        );
      }
      return null;
    };

    const SecondPasswordErrorMessage = () => {
      if (secondPassword.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {secondPassword.errorMessage}
          </p>
        );
      }
      return null;
    };

    const ApiErrorMessage = () => {
      if (error && errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">{errorMessage}</p>
        );
      }
      return null;
    };

    if (path) {
      return <Redirect to={path} />;
    }
    if (successful) {
      return (
        <>
          <Navbar history={history} />
          <article className="login">
            <div className="confirmation-container">
              <h1 className="login__header">Success!</h1>
              <p className="confirmation-description">
                <FormattedMessage
                  id="ChangePasswordPage.success"
                  defaultMessage="Your password has been changed! Now please {login}"
                  values={{
                    login: (
                      <a href="" className="login__link" onClick={this.login}>
                        <FormattedMessage
                          id="ChangePasswordPage.loginLink"
                          defaultMessage="Login"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </article>
        </>
      );
    }
    return (
      <>
        <Navbar history={history} />
        <article className="login">
          <h2 className="login__header">
            <FormattedMessage
              id="ChangePasswordPage.enterPasswordHeader"
              defaultMessage="Enter your new password"
            />
          </h2>
          <form className="login__form" onSubmit={this._formSubmit}>
            <section className="login__form-group">
              <input
                onChange={this._handleFirstPasswordChange}
                type="password"
                name="password"
                placeholder={intl.formatMessage(messages.passwordPlaceholder)}
                className="login__input-password"
              />
            </section>

            <section className="login__form-group">
              <input
                onChange={this._handleSecondPasswordChange}
                type="password"
                name="password_confirmation"
                placeholder={intl.formatMessage(
                  messages.confirmPasswordPlaceholder
                )}
                className="login__input-password"
              />
            </section>

            <section className="login__form-action">
              <button
                className="login__form-submit button button-primary"
                type="submit"
              >
                <FormattedMessage
                  id="ChangePasswordPage.submitButton"
                  defaultMessage="Submit"
                />
              </button>
            </section>

            <section className="login__form-errors">
              <FirstPasswordErrorMessage />
              <SecondPasswordErrorMessage />
              <ApiErrorMessage />
            </section>
          </form>
        </article>
      </>
    );
  }
}
const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
ChangePasswordPagei18n.propTypes = {
  history: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired,
};

const ChangePasswordPage = injectIntl(ChangePasswordPagei18n);

export { ChangePasswordPage };
