import React from 'react';

const CardAddress = (props) => {
  const { regional, location } = props;

  if (regional) {
    let address = '';
    if(location) {
      address = `${location.location_name}, ${location.formatted_address}`;
    }
    
    const cut = address.length > 40;

    return (
      <span className="shift-card__address" title={address}>
        {cut ? `${`${address}`.substring(0, 40)}...` : address}
      </span>
    );
  }

  return null;
};

export default CardAddress;
