import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '_components/GeneralComponents/Dropdown';
import { handleLocationChange } from '_helpers/app-state';

class LocationDropdown extends React.Component {
  _locationDropdownItems = () => {
    const { _setAppState, locations, userCookie } = this.props;
    const availableLocations = locations.filter(
      location => location.id !== userCookie.location_id,
    );
    const locationDropdownItems = availableLocations.map(item => ({
      clickHandler: () => {
        handleLocationChange(item, _setAppState);
      },
      label: item.location_name,
    }));

    return locationDropdownItems;
  };

  _renderLocationName = () => {
    const { locations, userCookie } = this.props;
    if (locations[0]) {
      const currentLocation = locations.filter(
        location => location.id === parseInt(userCookie.location_id, 10),
      );
      return currentLocation[0] ? currentLocation[0].location_name : locations[0].location_name;
    }
    return locations;
  };

  _renderLocationIcon = () => {
    const { locations } = this.props;
    return locations.length > 0 ? <span className="icon-location" /> : null;
  }

  render() {
    const { direction } = this.props;

    return (
      <Dropdown
        buttonClass="header__location-btn"
        direction={direction}
        imageSize="regular"
        items={this._locationDropdownItems()}
      >
        {this._renderLocationIcon()}
        {this._renderLocationName()}
        &nbsp;
        <span className="icon-dropdown-arrow" />
      </Dropdown>
    );
  }
}

LocationDropdown.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  locations: PropTypes.shape([]).isRequired,
  userCookie: PropTypes.shape({}).isRequired,
};

LocationDropdown.defaultProps = {
  direction: 'center',
};

export { LocationDropdown };
