import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { toggleModal, handleLocationChange, loadLocations } from '_helpers/app-state';
import { purgeAuthenticationCookie, setUserCookie } from '_helpers/cookies';
import { getUserInfo, getUserInfoNonAdmin } from '_helpers/api-calls/user-calls';
import { ModalTypes } from '_constants';
import { Dropdown } from '_components/GeneralComponents/Dropdown';
import { LocationDropdown } from '_components/GeneralComponents/LocationDropdown';
import { ShyftLogo } from '_components/GeneralComponents/ShyftLogo';
import { truncateString } from '_helpers/formatting';
import { ROLES } from '_services/user-roles';
import { PostShiftButton, Hamburger } from './components';
import { handleUserProfileImageChanged } from '_helpers/app-state';

import Styled from './styled';
import './Navbar.scss';

class NavbarWithoutRouter extends React.Component {
  componentDidMount() {
    const { appState, userPrivileges, _setAppState } = this.props;

    if (appState && appState.user && appState.user.user_id) {
      this._loadLocations(appState.user.user_id);
    }

    if(appState && appState.user) {
      getUserInfoNonAdmin(appState.user.user_id, appState.user.location_id).then((response) => {
        let user = {
          id: appState.user.user_id,
          profile_image: response.data.user.profile_image,
        };
        if(appState.user.profile_image !== response.data.user.profile_image) {
          handleUserProfileImageChanged(user, _setAppState)
        }
      }).catch((error) =>{
         const msg = `CallOffedShift.componentDidUpdate: call getUserInfoNonAdmin failed. error: ${error}`;
         console.log(msg);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { appState, userPrivileges, } = this.props;

    if (appState && appState.user && appState.user.user_id !== prevProps.appState.user.user_id) {
      this._loadLocations(appState.user.user_id);
    }
  }

  /**
   * Loads new locations
   * @param { String } userId - the new user
   * @returns { Boolean }
   */
  _loadLocations = (userId) => {
    const { _setAppState } = this.props;
    if (userId) {
      loadLocations(userId, _setAppState);
    }
  };

  _logout = () => {
    purgeAuthenticationCookie();
    window.location.pathname = '/login';
  };

  _locationDropdownItems = () => {
    const { _setAppState, appState } = this.props;
    const availableLocations = appState.locations.filter(
      location => location.id !== appState.user.location_id,
    );
    const locationDropdownItems = availableLocations.map(item => ({
      clickHandler: () => {
        handleLocationChange(item, _setAppState);
      },
      label: item.location_name,
    }));

    return locationDropdownItems;
  };

  _toggleModal = (modalContent) => {
    const { _setAppState } = this.props;

    toggleModal(modalContent, _setAppState);
  };

  _userDropdownItems = () => {
    const { appState, history } = this.props;
    const postShiftItem = {
      className: 'header__post-shift-dropdown-item',
      clickHandler: () => {
        this._toggleModal(ModalTypes.POST_SHIFT);
      },
      label: (
        <FormattedMessage
          id="Navbar.postShift"
          defaultMessage="Post a Shift"
        />
      ),
    };
    const items = [
      {
        clickHandler: () => {
          history.push('/profile');
        },
        label: (
          <FormattedMessage
            id="Navbar.editProfile"
            defaultMessage="Edit Profile"
          />
        ),
      },
      {
        clickHandler: () => {
          history.push('/add-location');
        },
        label: (
          <FormattedMessage
            id="Navbar.joinLocation"
            defaultMessage="Join a Location"
          />
        ),
      },
      {
        clickHandler: this._logout,
        label: (
          <FormattedMessage
            id="Navbar.logOut"
            defaultMessage="Log Out"
          />
        ),
      },
    ];
    /*
    if (appState.locationSettings.cfg__shift__post_new === 1) {
      items.unshift(postShiftItem);
    }
    */

    if (appState.userRole === ROLES.PENDING_LOCATIONS.key) {
      // remove post shift button from dropdown
      items.shift();
    }

    return items;
  };

  render() {
    const {
      _setAppState,
      appState,
      appState: { locationSettings },
      isLoggedIn,
      history,
      location,
    } = this.props;
    let greeting = null;
    let controls = null;
    let hamburger = null;

    if (isLoggedIn && appState.user && appState.user.first_name) {
      const truncFirstName = appState.user.first_name
        ? truncateString.apply(appState.user.first_name, [12, false])
        : null;

      greeting = (
        <div className="header__greeting">
          <h1>
            <FormattedMessage
              id="Navbar.greeting"
              defaultMessage="Hi, {name}!"
              values={{ name: truncFirstName }}
            />
          </h1>
          <div className="header__location">
            <LocationDropdown
              _setAppState={_setAppState}
              locations={appState.locations}
              userCookie={appState.user}
            />
          </div>
        </div>
      );
      
      //cfg__rto values: 0-disabled, 1-enabled, 8-create_disabled
      controls = (
        <div className="header__controls header__controls--logged-in">
          {appState.userRole !== ROLES.PENDING_LOCATIONS.key && 
            location.pathname === '/' && appState.locationSettings.cfg__action_menu === 1 &&
            (locationSettings.cfg__shift__post_new !== 0 || locationSettings.cfg__rto === 1)  ? (
            <PostShiftButton _toggleModal={this._toggleModal} appState={appState} />
          ) : null}
          <Dropdown direction="right" imageSize="regular" items={this._userDropdownItems()}>
            <Styled.ProfileThumb
              thumbUrl={
                appState.user.profile_image
                  ? appState.user.profile_image.thumb_url
                  : null
              }
            />
            <p>
              {truncateString.apply(appState.user.first_name, [12, false])}
              {' '}
              <span className="icon-dropdown-arrow" />
            </p>
          </Dropdown>
        </div>
      );
      
      hamburger = (
        <div className="header__controls header__controls--hamburger">
          <Dropdown direction="right" imageSize="regular" items={this._userDropdownItems()}>
            <Hamburger />
          </Dropdown>
        </div>
      );
    }
    /*
    if(locationSettings.cfg__shift__post_new){
      console.log(locationSettings.cfg__shift__post_new);
    }else {
      console.log('not found');
    }
    */
    return (
      <header className="header">
        <div className="header__wrap">
          <div className="header__controls header__controls--logo">
            <button
              className="header__logo-btn"
              onClick={() => {
                history.push('/');
              }}
              type="button"
            >
              <ShyftLogo />
            </button>
          </div>
          {greeting}
          {controls}
          {/*hamburger*/}
        </div>
      </header>
    );
  }
}

NavbarWithoutRouter.propTypes = {
  _setAppState: PropTypes.func,
  appState: PropTypes.shape({}),
  history: PropTypes.shape({}).isRequired,
  isLoggedIn: PropTypes.bool,
};

NavbarWithoutRouter.defaultProps = {
  _setAppState: () => {},
  appState: {},
  isLoggedIn: false,
};

const Navbar = withRouter(NavbarWithoutRouter);
export { Navbar, NavbarWithoutRouter };
