import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { shiftNames } from "../../../../_constants";

const Wrap = styled.p`
  /* font-family: ${({ theme }) => theme.fonts.primary}; */
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.palette.label};
  margin-bottom: 0.88rem;
`;

const vtoString = (
  <FormattedMessage
    id="ShiftCardVTO.CardPosition"
    defaultMessage="Voluntary Time Off"
  />
);

const CardPosition = ({
  shift: { name_is, owner, primary_department, primary_job },
  showJobDepo
}) => {
  const depoJob =
    primary_department &&
    showJobDepo &&
    `${primary_department && primary_department} - ${primary_job &&
      primary_job}`;
  const positionText =
    owner.current_position && showJobDepo && owner.current_position;

  const positionText1 = primary_job;
  /*
  return (
    <Wrap>
      {name_is === shiftNames.SHIFT_VTO ? (
        vtoString
      ) : (
        <>
          {depoJob || primary_job}
          {depoJob && positionText && <br />}
          {positionText}
        </>
      )}
    </Wrap>
  );
  */
  return (
    <Wrap>
      {name_is === shiftNames.SHIFT_VTO ? (
        vtoString
      ) : (
        <>
          {depoJob || primary_job}
          {depoJob && positionText1 && <br />}
        </>
      )}
    </Wrap>
  );
};

CardPosition.propTypes = {
  shift: PropTypes.shape({}).isRequired,
  showJobDepo: PropTypes.number
};

CardPosition.defaultProps = {
  showJobDepo: 0
};

export default CardPosition;
