import axios from 'axios';
import { axiosInstance } from '../api-calls';
import { formatOnlyProps } from '_helpers/formatting';
import { getLocaleCookie } from '../cookies';

/**
 * Gets all inner department for current location.
 *
 * @param  { String | Int } {location_id}
 */
export const GetLocationInnerDepartments = (location_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/inner_departments`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      per_page: 500,
      total: true,
    },
  });
};

 /**
 * Gets departments for current user.
 *
 * @param  { String | Int } {location_id}
 */
export const getMyDepartments = (location_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    //url: `/api/customer/user_role/locations/${location_id}/usable_skills`,
    url: `/api/customer/user_role/departments/mine`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
    },
  });
};






