import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from "classnames";
import Datetime from 'react-datetime';

import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import { RadioButton, DateTimeButton, } from "../PostShift/components";
//import { DateTimePicker} from './components';
import { DateButton, TimeButton, DateTimePicker } from './components';
import { DataNames } from '../PostShift/constants';
import {
  createDateString,
  createTimeRangeString,
  isNextDay,
} from '_helpers/formatting';
import { GetUserRequestTimeOffBalance, PostRequestTimeOff } from '_helpers/api-calls/rto-calls';
import { formatDateforApi, getDefaultTimezone} from '_helpers/date-time';
import TimePickerComponent from './TimePickerComponent';
import { setRequestTimeOff, addRequestTimeOff, } from "_helpers/app-state";

import './RequestTimeOff.scss';

class RequestTimeOff extends React.Component {
	constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      dateString: {
        id: 'RequestTimeOff.dateString',
        defaultMessage: 'Date',
      },
      timeRangeString: {
        id: 'RequestTimeOff.timeRangeString',
        defaultMessage: 'Time',
      },
      paidTimeOff: {
        id: 'RequestTimeOff.paidTimeOff',
        defaultMessage: 'Paid Time Off',
      },
      floating: {
        id: 'RequestTimeOff.floating',
        defaultMessage: 'Floating Holiday',
      },
      diversity: {
        id: 'RequestTimeOff.diversity',
        defaultMessage: 'Diversity Days',
      },
      hours: {
        id: 'RequestTimeOff.unitHours',
        defaultMessage: 'hours',
      },
      pending: {
        id: 'RequestTimeOff.inQueue',
        defaultMessage: 'pending',
      },
    });

    this.state = {
      showDatePicker: false,
      showTimePicker: false,
      commentsValue: '',
      confirmPost: false,
      date: {},
      dateString: formatMessage(this.messages.dateString),
      reachValue: 'My Team',
      channel_id: '',
      submitting: false,
      times: [],
      timeRangeString: formatMessage(this.messages.timeRangeString),
      rtoTypes: [],
      selectedType: '',
      selectedIndex: 0,
      rtoStartDate: '',
      rtoEndDate: '',
      rtoStartTime: '',
      rtoEndTime: '',
      rtoData: '',
      rtoDays: 0,
      rtoDayHours: 0,
      rtoHours: 0,
      rtoBalance: 0,
      rtoInQueue: 0,
      rtoRemaining: 0,
      rtoBalance_pto: 0,
      rtoBalance_floating: 0,
      rtoBalance_diversity: 0,
      rtoInQueue_pto: 0,
      rtoInQueue_floating: 0,
      rtoInQueue_diversity: 0,
      rtoAvailables: [],
      confirmRTO: false,
      endTimeCrossedMidnight: false,
      tags: null,
      organicRTO: false,
    };
    
    this._isMounted = false;
    this._validDate = current => current.isAfter(this.yesterday);
    this.winTop = '0px';
    this.winLeft = '0px';
    this.timeButton = React.createRef();
  }

  componentDidMount() {
    const { intl: { formatMessage }, setupActions, costCenterData, locationSettings, } = this.props;
    const {selectedType, rtoTypes, rtoHours} = this.state;

    this._isMounted = true;

    if(costCenterData) {
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'rto_categories'){
          if(this._isMounted) {
            this.setState({tags: costCenterData.tag_groups[i].tags});
          }
          if(costCenterData.tag_groups[i].tags.length > 0) {
            let types = [];
            let availables = [];
            for(let j=0; j<costCenterData.tag_groups[i].tags.length; j++) {
              let type = {index: 0, key: '', content: ''};
              let available = {index: 0, key: '', label: ''};
              type.index = j;
              type.key = costCenterData.tag_groups[i].tags[j].tag_key;
              available.index = j;
              available.key = costCenterData.tag_groups[i].tags[j].tag_key;
              type.content = costCenterData.tag_groups[i].tags[j].tag_content;

              /*
              switch(costCenterData.tag_groups[i].tags[j].tag_key) {
                case 'rto_category_pto':
                  type.content = `${formatMessage(this.messages.paidTimeOff)}`;
                  available.label = `${this.state.rtoBalance_pto} ${formatMessage(this.messages.hours)} 
                    (${this.state.rtoInQueue_pto} h ${formatMessage(this.messages.pending)})`;
                  break;
                case 'rto_category_floating':
                  type.content = `${formatMessage(this.messages.floating)}`;
                  available.label = `${this.state.rtoBalance_floating} ${formatMessage(this.messages.hours)}
                    (${this.state.rtoInQueue_floating} h ${formatMessage(this.messages.pending)})`;
                  break;
                case 'rto_category_diversity':
                  type.content = `${formatMessage(this.messages.diversity)}`;
                  available.label = `${this.state.rtoBalance_diversity} ${formatMessage(this.messages.hours)}
                    (${this.state.rtoInQueue_diversity} h ${formatMessage(this.messages.pending)})`;
                  break;

              }
              */
              types.push(type);
              availables.push(available)
            }
            if(this._isMounted) {
              this.setState({rtoTypes: types});
              this.setState({rtoAvailables: availables});
            }
          }
        }
      }
    }

    //cfg__rto_balance = 0 means this is an organic rto location.
    if(locationSettings) {
      if(locationSettings.cfg__rto_balance === 0) {
        if(this._isMounted) {
          this.setState({organicRTO: true});
        }
        return;
      }
    }

    GetUserRequestTimeOffBalance(setupActions.meta.current_user_privilege.id).then((data) =>{ 
        if(!data.data.rto_balances) {
          console.log('RequestTimeOff.componentDidMount: data.data.rto_balances is null, abort.');
          if(this.state.tags.length > 0) {
            let availables = [];
            for(let i=0; i<this.state.tags.length; i++) {
              let available = {index: 0, key: '', label: ''};
              available.index = i;
              available.key = this.state.tags[i].tag_key;
              available.label = `0 ${formatMessage(this.messages.hours)} 
                    (0 h ${formatMessage(this.messages.pending)})`;
              
              availables.push(available)
            }
            if(this._isMounted) {
              this.setState({rtoAvailables: []});
              this.setState({rtoAvailables: availables});
            }
          }
          return;
        }
        
        if(this._isMounted) {
          this.setState({rtoData: data});
        }

        let balance = 0;
        let inQueue = 0;
        let remaining = 0;

        const balance_pto = data.data.rto_balances ? data.data.rto_balances.rto_category_pto : 0;
        //const inQueue_pto = data.data.rto_in_queue === null ? data.data.rto_in_queue.rto_category_pto : 0;
        const inQueue_pto  = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_pto : 0;
        const balance_floating = data.data.rto_balances ? data.data.rto_balances.rto_category_floating : 0;
        //const inQueue_floating = data.data.rto_in_queue === null ? data.data.rto_in_queue.rto_category_floating : 0;
        const inQueue_floating = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_floating : 0;
        const balance_diversity = data.data.rto_balances ? data.data.rto_balances.rto_category_diversity : 0;
        //const inQueue_diversity = data.data.rto_in_queue === null ? data.data.rto_in_queue.rto_category_diversity : 0;
        const inQueue_diversity = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_diversity : 0;
        const available_pto = balance_pto - inQueue_pto;
        const available_floating = balance_floating - inQueue_floating;
        const available_diversity = balance_diversity - inQueue_diversity;
        if(this._isMounted) {
          this.setState({
            rtoBalance_pto: balance_pto ? balance_pto.toFixed(2) : 0,
            rtoBalance_floating: balance_floating ? balance_floating.toFixed(2) : 0,
            rtoBalance_diversity: balance_diversity ? balance_diversity.toFixed(2) : 0,
            rtoInQueue_pto: inQueue_pto ? inQueue_pto.toFixed(2) : 0,
            rtoInQueue_floating: inQueue_floating ? inQueue_floating.toFixed(2) : 0,
            rtoInQueue_diversity: inQueue_diversity ? inQueue_diversity.toFixed(2) : 0,
          });
        }
        

        if(this.state.tags.length > 0) {
          let availables = [];
          for(let i=0; i<this.state.tags.length; i++) {
            let available = {index: 0, key: '', label: ''};
            available.index = i;
            available.key = this.state.tags[i].tag_key;
            available.label = `${data.data.rto_balances[available.key]} ${formatMessage(this.messages.hours)} 
                  (${data.data.rto_in_queue[available.key] ? data.data.rto_in_queue[available.key] : '0'} h ${formatMessage(this.messages.pending)})`;
            
            availables.push(available)
          }
          if(this._isMounted) {
            this.setState({rtoAvailables: []});
            this.setState({rtoAvailables: availables});
          }
        }
        
      }).catch((error) =>{
        console.log(error);
      });
  }

  componentDidUpdate(prevProps) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleDatePicker = () => {
    if(this._isMounted) {
      this.setState({showDatePicker: !this.state.showDatePicker});
    }
  }

  toggleTimePicker = () => {
    /*
    this.setState({showTimePicker: !this.state.showTimePicker});
    if(this.state.rtoStartTime && this.state.rtoEndTime) {
       // if both start and end time set, selecting time again, reset
      this.setState({rtoEndTime: ''});
      this.setState({rtoStartTime: ''});
      this.setState({timeRangeString: ''});
    }
    */
  }

  createDateRangeString = (startDate, endDate) => {
    let dateRange;

    const start = moment(startDate);
    const end = moment(endDate);
    if(!endDate) {
      dateRange = `${start.format('ddd, MMM DD')}`;
      return dateRange;
    }

    dateRange = `${start.format('ddd, MMM DD')} - ${end.format('ddd, MMM DD')}`;
    if(start && end) {
      const duration = moment.duration(end.diff(start));
      const days = parseInt(duration.asDays()) + 1;
      if(this._isMounted) {
        this.setState({rtoDays: days});
      }
    }
   
    return dateRange;
  }

  createTimeRangeString = (startTime, endTime) => {
    const { rtoStartDate, rtoEndDate, } = this.state;

    let timeRange;

    if(!endTime) {
      timeRange = startTime;
      return timeRange;
    }

    timeRange = `${startTime} - ${endTime}`;
    const start = startTime.replace(/ /g, '');
    const end = endTime.replace(/ /g, '')

    const start1 = moment.utc(start, 'h:mm A');
    const end1 = moment.utc(end, 'h:mm A');
    const duration = moment.duration(end1.diff(start1));
    const hours = parseInt(duration.asHours());

    let newEndDate = null;

    if(hours > 0) {
      if(this._isMounted) {
        this.setState({rtoDayHours: hours});
        this.setState({endTimeCrossedMidnight: false});
      }
    }else {
      //end time crossed midnight
      newEndDate = moment(rtoStartDate).add(1, 'Days');
      if(this._isMounted) {
         this.setState({endTimeCrossedMidnight: true});
      }
      if(moment(rtoStartDate).isSame(moment(rtoEndDate))) {
        if(this._isMounted) {
          //this.setState({rtoEndDate: newEndDate});
          this.setState({rtoDayHours: 24+hours});
        }
        //this.setState({endTimeCrossedMidnight: true});
      }else {
        if(moment(rtoEndDate).isAfter(moment(rtoStartDate))) {
          /*
          start1
            .year(rtoStartDate.year())
            .month(rtoStartDate.month())
            .date(rtoStartDate.date());
          end1
            .year(rtoStartDate.year())
            .month(rtoStartDate.month())
            .date(rtoStartDate.date() +1);

          //console.log('TimePickerDropDown pos1: ' + start1.format('YYYY-MM-DD hh:mm A'));
          //console.log('TimePickerDropDown pos1: ' + end1.format('YYYY-MM-DD hh:mm A'));
          const start2 = moment(start1);
          const end2 = moment(end1);
          const duration = moment.duration(end2.diff(start2));
          const hours = parseInt(duration.asHours());
          //const msg = `TimePickerDropDown pos1: duration: ${hours}`;
          //console.log(msg);
          if(hours > 0) {
            this.setState({rtoDayHours: hours});
          }
          */
          if(rtoEndDate && newEndDate) {
            if(newEndDate.isSame(rtoEndDate) || rtoEndDate.isAfter(newEndDate)) {
              if(this._isMounted) {
                this.setState({rtoDayHours: 24+hours});
              }
            }
          }
        }
      }
      
    }
    return timeRange;
  }

  _updateState = (action, data) => {
    const {rtoTypes, } = this.state;

    if(!this._isMounted){
      return;
    }
    switch (action) {
      case 'REACH':
        if(this._isMounted) {
          this.setState({selectedType: data.reachValue});
        }
        const idx = rtoTypes.findIndex(
          (type) => type.content === data.reachValue
        );
        if(idx >= 0) {
          if(this._isMounted) {
            this.setState({selectedIndex: idx});
          }
        }
        break;
      case DataNames.REACH:
        if(this._isMounted) {
          this.setState({
            reachValue: data.reachValue,
            channel_id: data.channel_id,
          });
        }
        break;
      case DataNames.DATE:
        if(!this.state.rtoStartDate) {
          if(this._isMounted) {
            this.setState({rtoStartDate: data});
            this.setState({ date: data, dateString: this.createDateRangeString(data)});
          }
        }else if(!this.state.rtoEndDate) {
          if(this._isMounted) {
            this.setState({rtoEndDate: data});
            this.setState({ date: data, dateString: this.createDateRangeString(this.state.rtoStartDate, data)});
          }
        }else {
          //if both start and end date set and selecting date again, reset
          this.clearDatesAndTimes();
          if(this._isMounted) {
            this.setState({rtoEndDate: ''});
            this.setState({rtoStartDate: data});
            this.setState({ date: data, dateString: this.createDateRangeString(data)});
          }
        }
        break;
      case DataNames.TIMES:
        if(this._isMounted) {
          this.setState({
            times: data,
            timeRangeString: createTimeRangeString(data),
          });
        }
        break;
      default:
        break;
    }
  };

  canProgress = () => {
    const { 
      rtoStartDate, 
      rtoEndDate, 
      rtoStartTime, 
      rtoEndTime, 
      selectedType, 
      selectedIndex,
      rtoBalance_pto,
      rtoBalance_floating,
      rtoBalance_diversity,
      rtoInQueue_pto,
      rtoInQueue_floating,
      rtoInQueue_diversity,
      rtoDays,
      rtoDayHours, 
      rtoTypes, 
      rtoData,
      organicRTO,
    } = this.state;
    
    const isDate =  rtoStartDate !== null && rtoEndDate !== null 
    && (moment(rtoStartDate).isSame(moment(rtoEndDate)) || moment(rtoEndDate).isAfter(moment(rtoStartDate)));
    const isTimes = rtoStartTime.length >0  && rtoEndTime.length > 0;
    const isType = selectedType.length > 0;
   
    //remove spaces in string
    const start = rtoStartTime.replace(/ /g, '');
    const end = rtoEndTime.replace(/ /g, '')

    const start1 = moment.utc(start, 'h:mm A');
    const end1 = moment.utc(end, 'h:mm A');
    const duration = moment.duration(end1.diff(start1));
    const minutes = parseInt(duration.asMinutes());
    
    let isValidTimeRange = false;
    let newEndDate = null;

    if(minutes > 0) {
      isValidTimeRange = true;
    }
    else {
      //end time crossed midnight
      newEndDate = moment(rtoStartDate).add(1, 'Days');
      if(moment(rtoStartDate).isSame(moment(rtoEndDate))) {
        isValidTimeRange = false;
      }else {
        if(rtoEndDate && newEndDate) {
          if(newEndDate.isSame(rtoEndDate) || rtoEndDate.isAfter(newEndDate)) {
            isValidTimeRange = true;
          }
        }
      }
    }
    let validHours = false;
    let validHours2 = false;
    let sum = 0;
    const hours = rtoDays * rtoDayHours;
    if(selectedType.length > 0) {
      const idx = rtoTypes.findIndex(
        (type) => type.index === selectedIndex
      );
      if(idx < 0) {
        console.log(`RTO type ${rtoTypes[idx]} not found.`)
        return;
      }
      if(rtoData) {
        const balance = rtoData.data.rto_balances[rtoTypes[idx].key];
        const inQueue = rtoData.data.rto_in_queue[rtoTypes[idx].key] ? rtoData.data.rto_in_queue[rtoTypes[idx].key] : 0;
        if(balance > 0 && hours <= balance) {
          validHours = true;
          sum = parseFloat(inQueue) + hours;
          if(sum <= balance) {
            validHours2 = true;
          }
        }
      }
    }
    let canProgress = false;
    if(organicRTO) {
      //canProgress = isDate && isTimes && isType && isValidTimeRange;
      canProgress = isDate && isTimes && isType;
    }else {
      //canProgress = isDate && isTimes && isType && isValidTimeRange && validHours && validHours2;
      canProgress = isDate && isTimes && isType && validHours && validHours2;
    }
    return canProgress;
  };

  renderRequestTypes = () => {
    const { channels, _updateState } = this.props;
    const {rtoTypes, selectedType, organicRTO, } = this.state;
    let className = '';
    if(organicRTO) {
      className="request-time-off__radio-item-organic"
    }else {
      className="request-time-off__radio-item"
    }

    const types = rtoTypes.map((type) => (
      <div
        key={type.content}
        className={className}
      >
        <RadioButton
          checked={selectedType === type.content}
          id={type.content}
          label={type.content}
          onChange={this._updateState}
        />
      </div>
    ));

    if (types) {
      return types;
    }

    return (
      <div className="request-time-off__radio-item">
        <div>
          <FormattedMessage 
            id="RequestTimeOff.noType" 
            defaultMessage="Type is not available" 
          />
        </div>
      </div>
    );
  };

  renderTypeBalances = () => {
    const { channels, _updateState } = this.props;
    const {rtoTypes, selectedType, rtoAvailables} = this.state;

    const balances = rtoAvailables.map((balance) => (
      <div
        key={balance.label}
        className="request-time-off__balance-item"
      >
        <label className="balance-of-types" >
          {balance.label}
        </label>
      </div>
    ));

    if (balances) {
      return balances;
    }

    return (
      <div className="request-time-off__radio-item">
        <div>
          <FormattedMessage 
            id="RequestTimeOff.noBalance" 
            defaultMessage="Balance is not available" 
          />
        </div>
      </div>
    );
  };

  handleMenuItemClick = (selectedItem) => {
    const {rtoStartTime, rtoEndTime} = this.state;

    this.toggleTimePicker();
    if(!this.state.rtoStartTime){
      if(this._isMounted) {
        this.setState({rtoStartTime: selectedItem});
        this.setState({timeRangeString: this.createTimeRangeString(selectedItem)});
      }
    }else if(!this.state.rtoEndTime) {
      if(this._isMounted) {
        this.setState({rtoEndTime: selectedItem});
        this.setState({timeRangeString: this.createTimeRangeString(rtoStartTime, selectedItem)});
      }
    }else {
      // if both start and end time set, selecting time again, reset
      if(this._isMounted) {
        this.setState({rtoEndTime: ''});
        this.setState({timeRangeString: ''});
        this.setState({rtoStartTime: selectedItem});
        this.setState({timeRangeString: this.createTimeRangeString(selectedItem)});
      }
    }

  }

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  getStartTime = (startTime) => {
    if(this._isMounted) {
      this.setState({rtoStartTime: startTime});
      this.setState({timeRangeString: this.createTimeRangeString(startTime)});
    }
  }

  getEndTime = (endTime) => {
    const {rtoStartTime, rtoEndTime} = this.state;
    if(this._isMounted) {
      this.setState({rtoEndTime: endTime});
      this.setState({timeRangeString: this.createTimeRangeString(rtoStartTime, endTime)});
    }
  }

  clearDatesAndTimes = () => {
    if(this._isMounted) {
      this.setState({dateString: '', timeRangeString: ''});
      this.setState({rtoStartDate: '', rtoEndDate: ''});
    }
  }

  handleGoNext = () => {
    const { intl: { formatMessage }, setupActions } = this.props;
    const {selectedType, selectedIndex, rtoTypes, rtoHours, rtoData, rtoDays, rtoDayHours, organicRTO, } = this.state;

    if(!this.canProgress()) {
      console.log('canProgress = false, return.')
      return;
    }

    const hours = rtoDays * rtoDayHours;
    if(this._isMounted) {
       this.setState({rtoHours: hours});
    }

    if(rtoData) {
      let balance = 0;
      let inQueue = 0;
      let remaining = 0;
      const idx = rtoTypes.findIndex(
        (type) => type.index === selectedIndex
      );

      if(idx < 0) {
        console.log('Selected type not found.')
        return;
      }
      /*
      switch(rtoTypes[idx].key) {
        case 'rto_category_pto':
          balance = rtoData.data.rto_balances ? rtoData.data.rto_balances.rto_category_pto : 0;
          inQueue = rtoData.data.rto_in_queue ? rtoData.data.rto_in_queue.rto_category_pto : 0;
          break;
        case 'rto_category_floating':
          balance = rtoData.data.rto_balances ? rtoData.data.rto_balances.rto_category_floating : 0;
          inQueue = rtoData.data.rto_in_queue ? rtoData.data.rto_in_queue.rto_category_floating : 0;
          break;
        case 'rto_category_diversity':
          balance = rtoData.data.rto_balances ? rtoData.data.rto_balances.rto_category_diversity : 0;
          inQueue = rtoData.data.rto_in_queue ? rtoData.data.rto_in_queue.rto_category_diversity : 0;
          break;
      }
      */
      balance = rtoData.data.rto_balances[rtoTypes[idx].key];
      inQueue = rtoData.data.rto_in_queue[rtoTypes[idx].key] ? rtoData.data.rto_in_queue[rtoTypes[idx].key] : 0;

      if(inQueue == null){
        remaining = balance - hours;
      }else {
        remaining = balance - inQueue - hours;
      }
      if(this._isMounted) {
        this.setState({
          rtoBalance: balance,
          rtoInQueue: inQueue ? inQueue.toFixed(2) : 0,
          rtoRemaining: remaining ? remaining.toFixed(2) : 0,
        });
      }

    }else {
      if(!organicRTO) {
        GetUserRequestTimeOffBalance(setupActions.meta.current_user_privilege.id).then((data) =>{
          let balance = 0;
          let inQueue = 0;
          let remaining = 0;
          
          if(!data.data.rto_balances) {
            console.log('RequestTimeOff.handleGoNext: data.data.rto_balances is null, abort.');
            return;
          }

          const idx = rtoTypes.findIndex(
            (type) => type.index === selectedIndex
          );

          if(idx < 0) {
            console.log('Selected index not found.')
            return;
          }

          const hours = rtoDays * rtoDayHours;
          /*
          switch(rtoTypes[idx].key) {
            case 'rto_category_pto':
              balance = data.data.rto_balances ? data.data.rto_balances.rto_category_pto : 0;
              inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_pto : 0;
              break;
            case 'rto_category_floating':
              balance = data.data.rto_balances ? data.data.rto_balances.rto_category_floating : 0;
              inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_floating : 0;
              break;
            case 'rto_category_diversity':
              balance = data.data.rto_balances ? data.data.rto_balances.rto_category_diversity : 0;
              inQueue = data.data.rto_in_queue ? data.data.rto_in_queue.rto_category_diversity : 0;
              break;
          }
          */
          balance = data.data.rto_balances[rtoTypes[idx].key];
          inQueue = data.data.rto_in_queue[rtoTypes[idx].key] ? data.data.rto_in_queue[rtoTypes[idx].key] : 0;
          if(inQueue == null){
            remaining = balance - hours;
          }else {
            remaining = balance - inQueue - hours;
          }
          if(this._isMounted) {
            this.setState({
              rtoBalance: balance,
              rtoInQueue: inQueue,
              rtoRemaining: remaining.toFixed(2),
            });
          }
          
        }).catch((error) =>{
          console.log(error);
        });
      }
    }
    if(this._isMounted) {
       this.setState({confirmRTO: true});
    }
  }

  handleGoBackClick = () => {
    if(this._isMounted) {
       this.setState({confirmRTO: false});
    }
  }

  handleSubmitClick = () => {
    const {selectedType, selectedIndex, rtoStartDate, rtoEndDate, rtoStartTime, rtoEndTime, 
          endTimeCrossedMidnight, rtoTypes,} = this.state;
    const {userCookie, _toggleModal,} = this.props;

    const timeZone = getDefaultTimezone();

    const start = rtoStartTime.replace(/ /g, '');
    const end = rtoEndTime.replace(/ /g, '')

    //const start1 = moment.utc(start, 'h:mm A');
    //const end1 = moment.utc(end, 'h:mm A');

    const start1 = moment(start, 'h:mm a');
    const end1 = moment(end, 'h:mm a');

    if(!endTimeCrossedMidnight) {
      start1
        .year(rtoStartDate.year())
        .month(rtoStartDate.month())
        .date(rtoStartDate.date());
      end1
        .year(rtoStartDate.year())
        .month(rtoStartDate.month())
        .date(rtoStartDate.date());
    }else {
      const dateForEndTime = moment(rtoStartDate).add(1, 'Days');
      start1
        .year(rtoStartDate.year())
        .month(rtoStartDate.month())
        .date(rtoStartDate.date());
      end1
        .year(dateForEndTime.year())
        .month(dateForEndTime.month())
        .date(dateForEndTime.date());
    }

    const start2 = moment.tz(start1, timeZone).format('YYYY-MM-DD HH:mm:ss');
    const end2 = moment.tz(end1, timeZone).format('YYYY-MM-DD HH:mm:ss');

    const category1 = rtoTypes[selectedIndex].key
    const msg = `Submit: selectedIndex: ${selectedIndex}, category1: ${rtoTypes[selectedIndex].key}`;
    console.log(msg);

    const RequestTimeOffData = {
      location_id: userCookie.location_id,
      start_date: rtoStartDate.format('YYYY-MM-DD'),
      end_date: rtoEndDate.format('YYYY-MM-DD'),
      start_time: start2,
      end_time: end2,
      category: category1,
      time_off_reason: 'family event',
    };

    
    PostRequestTimeOff(RequestTimeOffData).then((response) => {
      const { _setAppState, rtos } = this.props;
      const data = response.data;
      const id = data.request_time_off.id;
      const location = data.request_time_off.location_id;
      const owner = `${data.request_time_off.first_name} ${data.request_time_off.last_name}`;
      const msg = `Request time off: id: ${id}, location: ${location}, owner: ${owner}`;
      console.log(msg);

      //setRequestTimeOff(data.request_time_off, rtos, _setAppState);
      addRequestTimeOff(data.request_time_off, rtos, _setAppState);
      _toggleModal();
      if(this._isMounted) {
        this.setState({ submitting: false });
      }
    })
    .catch((error) => {
      console.log(error);
      if(this._isMounted) {
        this.setState({ submitting: false });
      }
    });
    
  }

  render () {
  	const { _toggleModal, shifts, intl, userCookie, locale, costCenterData} = this.props;
  	const { showDatePicker, 
            showTimePicker, 
            date,
            dateString, 
            timeRangeString, 
            rtoHours,
            selectedType,
            selectedIndex,
            confirmRTO,
            rtoBalance,
            rtoInQueue,
            rtoRemaining,
          } = this.state;

  	const dpWrapClass = classNames({
      "post-shift__dtp-wrap": true,
      "post-shift__dtp-wrap--show": showDatePicker,
    });

    const tpWrapClass = classNames({
      "request-time-off__tp-wrap": true,
      "request-time-off__tp-wrap--show": showTimePicker,
    });

    const submitBtnClass = classNames({
      "modal-components__submit-btn": true,
      "modal-components__submit-btn--active": this.canProgress(),
    });


    const winSize = this.getWindowDimensions();

    if(this.timeButton && this.timeButton.current) {
      this.winTop = `${this.timeButton.current.parentElement.offsetTop
        - this.timeButton.current.offsetTop}px`;
      this.winLeft = `${this.timeButton.current.parentElement.offsetLeft}px`;

      if(winSize.height - this.costCenterElement.current.parentElement.offsetTop < 50){
        this.winTop = `${winSize.height - 100}px`;
      }
    }

    this.winTop = `0px`; 
    this.winLeft = `252px`; 

    if(!confirmRTO) {
      return (
        <div className="cover-shift">
          <ModalWrapper>
           <CloseModal _toggleModal={_toggleModal} dark />
           <div className="modal-components__container">
            <h1>
              <FormattedMessage
                id="RequestTimeOff.requestTimeOff"
                defaultMessage="Request Time Off"
              />
            </h1>
            <div className="modal-components__row">
              <div className="modal-components__col">
                <div className="request-time-off__date-time-label-wrap">
                  <p className="request-time-off__label">
                    <FormattedMessage
                      id="RequestTimeOff.dateRange"
                      defaultMessage="Date Range"
                    />
                  </p>
                  <p className="request-time-off__label">
                    <FormattedMessage
                      id="RequestTimeOff.startEndTime"
                      defaultMessage="Start Time & End Time"
                    />
                  </p>
                </div>
                <div className="request-time-off__date-time-button-wrap">
                  <DateButton 
                    _toggleDatePicker={this.toggleDatePicker}
                    dateString={this.state.dateString}
                  /> 
                  <TimeButton 
                    _toggleTimePicker={this.toggleTimePicker}
                    timeRangeString={this.state.timeRangeString}
                    ref={this.timeButton}
                  />
                  {showDatePicker ? <button className="up-arrow" onClick={this.toggleDatePicker} />:
                  <button className="down-arrow" onClick={this.toggleDatePicker}/>}
                </div>
                <div className={dpWrapClass}>
                   <DateTimePicker
                      _updateState={this._updateState}
                      toggleDatePicker={this.toggleDatePicker}
                      getStartTime={this.getStartTime}
                      getEndTime={this.getEndTime}
                      clearDatesAndTimes={this.clearDatesAndTimes}
                      date={date}
                      locale={locale}
                      rtoStartDate={this.state.rtoStartDate.toString()} //it was found rtoStartDate changes!
                      rtoEndDate={this.state.rtoEndDate.toString()}
                      costCenterData={costCenterData}
                      selectedIndex={selectedIndex}
                    />
                </div>
                <div className={tpWrapClass}>
                  <TimePickerComponent
                    top={this.winTop} 
                    left={this.winLeft}
                    handleMenuItemClick={this.handleMenuItemClick}/>
                </div>
              </div>
            </div>
             <p className="request-time-off__label">
                <FormattedMessage
                  id="RequestTimeOff.type"
                  defaultMessage="Type"
                />
              </p>
              {this.state.organicRTO ?
                <div>
                  <div className="modal-components__column">
                    {this.renderRequestTypes()}
                    <div className="request-time-off__space"></div>
                  </div>
                </div>
                : <div className="request-time-off__radio-items-wrap">
                <div className="modal-components__column">
                  {this.renderRequestTypes()}
                </div>
                 <div className="modal-components__column">
                  {this.renderTypeBalances()}
                </div>
              </div>}
              <div className="modal-components__row">
                <div className="modal-components__col modal-components__col--submit">
                  <button
                    className={submitBtnClass}
                    onClick={this.handleGoNext}
                    type="button"
                  >
                    <FormattedMessage id="RequestTimeOff.nextButton" defaultMessage="Next" />
                    &nbsp;
                    <span className="icon-submit-arrow" />
                  </button>
                </div>
              </div>
           </div>
          </ModalWrapper>
        </div>
      );
    } // end of if(!confirmRTO)

    if(confirmRTO) {
      return (
         <div className="cover-shift">
          <ModalWrapper>
            <CloseModal _toggleModal={_toggleModal} dark />
            <h1>
              <FormattedMessage
                id="RequestTimeOff.previewSubmit"
                defaultMessage="Preview & Submit"
              />
            </h1>
            <div className="modal-components__row">
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.dateRange"
                    defaultMessage="Date Range"
                  />
                </label>
                <label className="item__value">{dateString}</label>
              </div>
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.startEndTimes"
                    defaultMessage="Start Time & End Time"
                  />
                </label>
                <label className="item__value">{timeRangeString}</label>
              </div>
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.type"
                    defaultMessage="Type"
                  />
                </label>
                <label className="item__value">{selectedType}</label>
              </div>
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.hours"
                    defaultMessage="Hours"
                  />
                </label>
                <label className="item__value">
                  <FormattedMessage
                    id="RequestTimeOff.hoursValue"
                    defaultMessage="{rtoHours} {rtoHours, plural, one {hour} other {hours}}"
                    values={{rtoHours,}}
                  />
                </label>
              </div>
            </div>
            {!this.state.organicRTO && <div className="modal-components__row">
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.available"
                    defaultMessage="Available as of today"
                  />
                </label>
                <label className="item__value">
                  <FormattedMessage
                    id="RequestTimeOff.balance"
                    defaultMessage="{rtoBalance} {rtoBalance, plural, one {hour} other {hours}}"
                    values={{rtoBalance,}}
                  />
                </label>
              </div>
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.thisRequest"
                    defaultMessage="This Request"
                  />
                </label>
                <label className="item__value">
                  <FormattedMessage
                    id="RequestTimeOff.hoursValue"
                    defaultMessage="{rtoHours} {rtoHours, plural, one {hour} other {hours}}"
                    values={{rtoHours,}}
                  />
                </label>
              </div>
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.pending"
                    defaultMessage="Pending/Approved Request"
                  />
                </label>
                <label className="item__value">
                  <FormattedMessage
                    id="RequestTimeOff.pendingValue"
                    defaultMessage="({rtoInQueue} {rtoInQueue, plural, one {hour} other {hours}})"
                    values={{rtoInQueue,}}
                  />
                </label>
              </div>
              <div className="item">
                <label className="item__name">
                  <FormattedMessage
                    id="RequestTimeOff.remaining"
                    defaultMessage="Remaining as of Today"
                  />
                </label>
                <label className="item__value">
                  <FormattedMessage
                    id="RequestTimeOff.remainingValue"
                    defaultMessage="{rtoRemaining} {rtoRemaining, plural, one {hour} other {hours}}"
                    values={{rtoRemaining,}}
                  />
                </label>
              </div>
            </div>}
            <div className="button-container">
              <button 
                className="button-preview"
                onClick={this.handleGoBackClick}>
                  <FormattedMessage
                    id="RequestTimeOff.goBackEdit"
                    defaultMessage="Go Back and Edit"
                  />
              </button>
              {rtoRemaining >= 0 && <button 
                className="button-preview"
                onClick={this.handleSubmitClick}>
                  <FormattedMessage
                    id="RequestTimeOff.confirmSubmit"
                    defaultMessage="Confirm & Submit"
                  />
              </button>}
            </div>
          </ModalWrapper>
         </div>
      );
    }
  }

}

RequestTimeOff.propTypes = {
  //userCookie: PropTypes.shape({}).isRequired,
  //costCenterData: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (RequestTimeOff);

