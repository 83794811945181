import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl, FormattedMessage, defineMessages } from "react-intl";
import { EmailPhoneInput } from "@shyft/acorns";

import { validateEmail, validatePhoneNumber } from "_helpers/validation";
import HoneyPotInput from "_components/GeneralComponents/HoneyPotInput/HoneyPotInput";

import "./LoginFirstStage.scss";

const FormElement = styled.div`
  margin-bottom: 4rem;
`;

class LoginFirstStage extends React.Component {
  state = {
    emailPhone: {
      dialCode: "1",
      value: "",
      error: ""
    },
    honeyPotValidity: true
  };

  messages = defineMessages({
    error1: {
      id: "LoginFirstStage.error1",
      defaultMessage: "Please enter a valid email or phone number"
    },
    emailPhonePlaceholder: {
      id: "LoginFirstStage.emailPhonePlaceholder",
      defaultMessage: "Email or phone number"
    }
  });

  _handleChange = e => {
    const { emailPhone } = this.state;

    this.setState({
      emailPhone: {
        ...emailPhone,
        value: e.target.value
      }
    });
  };

  _handleCountryChange = dialCode => {
    const { emailPhone } = this.state;

    this.setState({
      emailPhone: {
        ...emailPhone,
        dialCode
      }
    });
  };

  _handleHoneyPotInputChange = validity => {
    this.setState({
      honeyPotValidity: validity
    });
  };

  _handleFormSubmit = e => {
    const {
      _formSubmit,
      intl: { formatMessage }
    } = this.props;
    const { emailPhone, honeyPotValidity } = this.state;
    e.preventDefault();

    if (!honeyPotValidity) {
      return false;
    }

    if (validatePhoneNumber(emailPhone.value)) {
      // Input is phone number
      _formSubmit({
        type: "phone",
        value: emailPhone.dialCode + emailPhone.value
      });

      return null;
    }

    if (validateEmail(emailPhone.value.trim())) {
      // Input is email
      _formSubmit({
        type: "email",
        value: emailPhone.value.trim()
      });

      return null;
    }

    // Input is invalid
    this.setState({
      emailPhone: {
        ...emailPhone,
        error: formatMessage(this.messages.error1)
      }
    });

    return null;
  };

  render() {
    const {
      error,
      intl: { formatMessage }
    } = this.props;
    const { emailPhone } = this.state;

    return (
      <form className="login__form" onSubmit={this._handleFormSubmit}>
        <section className="login__form-action">
          <FormElement>
            <EmailPhoneInput
              onChange={this._handleChange}
              onCountryChange={this._handleCountryChange}
              error={emailPhone.error || error}
              value={emailPhone.value}
              placeholder={formatMessage(this.messages.emailPhonePlaceholder)}
            />
            <HoneyPotInput handleChange={this._handleHoneyPotInputChange} />
          </FormElement>
          <button
            className="login__form-submit button button-primary"
            type="submit"
          >
            <FormattedMessage
              id="LoginFirstStage.loginBtn"
              defaultMessage="Login"
            />
          </button>
        </section>
      </form>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
LoginFirstStage.propTypes = {
  _formSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  error: PropTypes.string
};

LoginFirstStage.defaultProps = {
  error: ""
};

export default injectIntl(LoginFirstStage);
