import moment from 'moment';

export const TIME_FORMAT = 'LT';
export const DATE_FORMAT = 'MMMM D';

export const obfuscatePhoneNumber = (phoneNumber) => {
  const newString = phoneNumber.substr(phoneNumber.length - 4, 4);

  return `*******${newString}`;
};

// usage
// truncateString.apply(yourString, [11, true]); outputs => not very...
export function truncateString(n, useWordBoundary) {
  if (this.length <= n) {
    return this;
  }
  const cleanString = this.replace(/(\r\n|\n|\r)/gm, '');
  const subString = cleanString.substr(0, n - 1);

  return `${
    useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString
  }...`;
}

export function createDateString(momentDate) {
  if (momentDate) {
    return moment(momentDate).format('MMMM D');
  }

  return 'Date';
}

export function createTimeString(date) {
  if (date) {
    return moment(date).format('LT');
  }

  return 'Time';
}

export function isNextDay(times) {
  if(times.length === 2) {
    return (
      times[1].isBefore(times[0])
      || times[0].isSame(times[1])
      || times[1].isAfter(times[0], 'date')
    );
  }else {
    return null;
  }
}

export function createTimeRangeString(times) {
  if (times && times.length > 0) {
    const startTimeText = moment(times[0]).format('LT');
    const endTimeText = moment(times[1]).format('LT');
    const startBeforeEnd = isNextDay(times);

    return `${startTimeText} to ${endTimeText} ${
      startBeforeEnd ? '(Next Day)' : ''
    }`;
  }

  return 'Time';
}

export function createFullName(user) {
  return user ? `${user.first_name} ${user.last_name}` : null;
}

export function createProfileImageUrl(profileImageObj) {
  return profileImageObj ? profileImageObj.full_url : 'https://s3.amazonaws.com/shyftassets/avatar1.png';
}

export function isEmptyObj(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const isEmptyCookie = string => string === 'undefined' || string === 'null' || string === undefined;

export function alphaCompare(key) {
  return (a, b) => {
    const aName = a[key].toUpperCase();
    const bName = b[key].toUpperCase();

    if (aName < bName) {
      return -1;
    }

    if (aName > bName) {
      return 1;
    }

    return 0;
  };
}

function checkForTermsUrl(setupActions) {
  // pp_url
  if (setupActions && setupActions.meta && setupActions.meta.current_location && setupActions.meta.current_location.tos_url) {
    return setupActions.meta.current_location.tos_url;
  }

  return null;
}

export function formatTermsLinkWithLocale(setupActions, locale) {
  const url = checkForTermsUrl(setupActions) ? checkForTermsUrl(setupActions) : undefined;

  return url || `https://myshyft.com/terms.${locale}.html`;
}

export function formatPrivacyLinkWithLocale(setupActions, locale) {
  return `https://myshyft.com/privacy.${locale}.html`;
}

// For formatting response[only] props before sending to api
export const formatOnlyProps = props => props.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s/g, ''); // Strips string of linebreaks
