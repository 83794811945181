import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';


const DetailedWinContainer = styled.section`
  
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white;
  //box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  //border-radius: 8px;
  z-index: 1;
  padding-top: 48px;
  padding-left: 90px;
  overflow: scroll;

  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  
  @media screen and (min-width: 1280px) {
    //margin-left: 10px;
  }
  @media screen and (min-width: 1440px) {
    //margin-left: 90px;
  }
  @media screen and (min-width: 1680px) {
    //margin-left: 215px;
  }
  @media screen and (min-width: 1920px) {
    //margin-left: 250px;
  }
  
`;
const Header = styled.div `
  display: flex;
  flex-direction: row;

  border: 1px solid red;
`;

const CloseButton = styled.button`
  /*
  background-color: none;
  text-align: center;
  border-radius: 30px;
  padding: 5px 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 0.5rem;
  */

  @extend %button-reset;
  position: absolute;
  top: 4rem;
  right: 4rem;
  z-index: 2;

  @media (max-width: $shifts-md) {
    top: 2rem;
    right: 2rem;
  }

  &:hover {
    color: black;
    //background-color: ${({ theme }) => theme.palette.primary[0]};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;
const Title = styled.label `
  width: 30%;
  border: 1px solid red;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 20px;
  border-bottom: 1px solid lightgrey;

  &:hover {
    background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    background-image: url('/icons/check-mark.svg');
    background-repeat: no-repeat;
    //background-position: right;
    //background-position: 90% 50%;
    background-position: 95% 50%;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }

  //border: 1px solid green;
`;

export default {
  DetailedWinContainer,
  CloseButton,
  Header,
  Title,
  MenuContainer,
};