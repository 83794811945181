import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 3rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border};

  &:last-child {
    border-bottom: none;
  }
`;

const Col = styled.div`
  margin-right: 2rem;
  flex: 1;
`;

export default {
  Wrap,
  Row,
  Col,
};
