import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { rgba } from 'polished';

import { StatusIcon } from '_components/GeneralComponents/ShiftStatus';
import { ModalTypes } from '_constants';
import {
  shiftStatuses,
  shiftOwner,
  shiftStatus,
} from '_constants/shift-status';
import { isNextDay } from '_helpers/formatting';
import {
  ActionButton, StatusMessage, CardAddress, Badge, RegionalBadge, CardProfileThumb, CardPosition,
} from '../ShiftCard/components';
import { GetUserRequestTimeOffBalance1 ,GetLocationRequestTimeOffs } from '_helpers/api-calls/rto-calls';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import coveredIcon from './assets/covered-icon.svg';
import chatIcon from './assets/chat-icon.svg';
import pendingIcon from './assets/pending-icon.svg';
import deniedIcon from './assets/rejected-icon.svg';
import approvedIcon from './assets/covered-icon.svg';
import defaultAvatar from './assets/default-profile-thumb.svg';
import { LoadingSpinner } from "_components/GeneralComponents/LoadingSpinner";

import '../ShiftCard/ShiftCard.scss';
import styled from './styled';
import './RTOCard.scss';

class RTOCardNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        debug: false,
        debugInfo: '',
        timer: null,
        rtoParams: null,
        loadingData: false,
        ownerAvatar: '',
        covererAvatar: '',
        posterIsAdmin: false,
        organicRTO: false,
        tags: [],
        rtoTypes: [],
        rtoAvailables: [],
    };

    
    this._isMounted = false;
   
  }

  componentDidMount(){
    const { intl: { formatMessage }, setupActions, rto, userPrivileges, locationSettings, costCenterData, } = this.props;

    this._isMounted = true;
    let types = [];
    let availables = [];
    this._isMounted = true;
    if(costCenterData) {
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'rto_categories'){
          if(this._isMounted) {
            this.setState({tags: costCenterData.tag_groups[i].tags});
          }
          if(costCenterData.tag_groups[i].tags.length > 0) {
            for(let j=0; j<costCenterData.tag_groups[i].tags.length; j++) {
              let type = {index: 0, key: '', content: ''};
              let available = {index: 0, key: '', label: ''};
              type.index = j;
              type.key = costCenterData.tag_groups[i].tags[j].tag_key;
              available.index = j;
              available.key = costCenterData.tag_groups[i].tags[j].tag_key;
              type.content = costCenterData.tag_groups[i].tags[j].tag_content;
              types.push(type);
              availables.push(available)
            }
            if(this._isMounted) {
              this.setState({rtoTypes: types});
              this.setState({rtoAvailables: availables});
            }
          }
        }
      }
    }

    if(rto) {
      const debugInfo = `Id: ${rto.id}, start: ${rto.start_time}, end: ${rto.end_time}, poster: ${rto.first_name} ${rto.last_name}
               location: ${rto.location_id}`;
      
      const ownerAvatar = rto.profile_image_thumb_url ? 
                        rto.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
      /*
      const covererAvatar = (shift.coverer && shift.coverer.profile_image) ? 
                          shift.coverer.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
      */
      if(this._isMounted) {
        this.setState({
          debugInfo: debugInfo,
          ownerAvatar: ownerAvatar,
        });
      }
      if(userPrivileges && userPrivileges.length > 0) {
        const idx = userPrivileges.findIndex((priv) => priv.owner_id === rto.user_id);
        if(idx >= 0) {
          if(userPrivileges[idx].is_admin) {
            if(this._isMounted) {
              this.setState({posterIsAdmin: true});
            }
          }
        }
      }else {
        if(rto) {
          getUserInfoNonAdmin(rto.user_id, rto.location_id).then((response) => {
            if(this._isMounted) {
              this.setState({posterIsAdmin: response.data.user.current_is_admin});
            }
          }).catch((error) =>{
             const msg = `RTOCardNew.componentDidUpdate: call getUserInfoNonAdmin failed. error: ${error}`;
             console.log(msg);
          });
        }
      }
     
    }

    //cfg__rto_balance = 0 means this is an organic rto location.
    if(locationSettings) {
      if(locationSettings.cfg__rto_balance === 0) {
        if(this._isMounted) {
          this.setState({organicRTO: true});
        }
      }
    }

    GetUserRequestTimeOffBalance1(setupActions.meta.current_user_privilege.id, rto.id).then((data) =>{
      let rtoData = {
          id: '',
          category: '', 
          status: '',
          hours: 0, 
          balance: 0, 
          inQueue: 0,
          remaining: 0,
        };
      let balance = 0;
      let inQueue = 0;
      let remaining = 0;
      let hours = 0;
      if(!data.data.rto_balances) {
        GetLocationRequestTimeOffs(rto.location_id).then((response) =>{
          const rtos = response.data.request_time_offs;
          const idx = rtos.findIndex((item) => item.id === rto.id);
          if(idx >= 0) {
            const foundRTO = rtos[idx];
            rtoData.id = rto.id;
            rtoData.status = rto.status;
            rtoData.category = rto.category;
            
            hours = foundRTO.day_hours * foundRTO.days;
            if(foundRTO  && foundRTO.balances_at_closed && foundRTO.balances_at_closed.rto_balances && foundRTO.balances_at_closed.rto_in_queue) {
              if(types) {
                const idx = types.findIndex((type) => type.key === rto.category);
                if(idx >= 0) {
                  balance = foundRTO.balances_at_closed.rto_balances[types[idx].key];
                  inQueue = foundRTO.balances_at_closed.rto_in_queue[types[idx].key] ? foundRTO.balances_at_closed.rto_in_queue[types[idx].key] : 0;
                  const msg = `idx: ${idx}, category: ${types[idx].key}, balance: ${balance}, inQueue ${inQueue}`;
                  console.log(msg);
                }
              }
            }
  
            if(inQueue == null){
              remaining = balance - hours;
            }else {
              remaining = balance - inQueue - hours;
            }
            rtoData.hours = hours;
            rtoData.balance = balance;
            rtoData.inQueue = inQueue;
            rtoData.remaining = remaining;
            if(this._isMounted) {
              this.setState({rtoParams: rtoData});
            }
          }
        }).catch((error) =>{
          const msg = `RTOCardNew.componentDidMount: failed on GetLocationRequestTimeOffs. error: ${error}`;
          console.log(msg);
        })
      }else {
        rtoData.id = rto.id;
        rtoData.status = rto.status;
        rtoData.category = rto.category;
        if(rto) {
          if(types) {
            const idx = types.findIndex((type) => type.key === rto.category);
            if(idx >= 0) {
              balance = data.data.rto_balances[types[idx].key];
              inQueue = data.data.rto_in_queue[types[idx].key] ? data.data.rto_in_queue[types[idx].key] : 0;
              const msg = `idx: ${idx}, category: ${types[idx].key}, balance: ${balance}, inQueue ${inQueue}`;
              console.log(msg);
            }
          }
        }
        hours = rto.day_hours * rto.days;

        if(inQueue == null){
          remaining = balance - hours;
        }else {
          remaining = balance - inQueue - hours;
        }
        rtoData.hours = hours;
        rtoData.balance = balance;
        rtoData.inQueue = inQueue;
        rtoData.remaining = remaining;
        if(this._isMounted) {
          this.setState({rtoParams: rtoData});
        }
      }
    }).catch((error) =>{
      console.log(error);
    });
  }

  componentDidUpdate(prevProps){
    const {costCenterData, rto, userPrivileges} = this.props;

    if(prevProps.userPrivileges !== userPrivileges) {
      if(userPrivileges && userPrivileges.length > 0) {
        const idx = userPrivileges.findIndex((priv) => priv.owner_id === rto.user_id);
        if(idx >= 0) {
          if(userPrivileges[idx].is_admin) {
            if(this._isMounted) {
              this.setState({posterIsAdmin: true});
            }
          }
        }
      }else {
        if(rto) {
          getUserInfoNonAdmin(rto.user_id, rto.location_id).then((response) => {
            if(this._isMounted) {
              this.setState({posterIsAdmin: response.data.user.current_is_admin});
            }
          }).catch((error) =>{
             const msg = `RTOCardNew.componentDidUpdate: call getUserInfoNonAdmin failed. error: ${error}`;
             console.log(msg);
          });
        }
      }
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  tick = () => {
    const {shift, costCenterData } = this.props;

  };

  _handleCardClick = () => {
    const {rto, userCookie, _toggleModal, _updateSelectedRTO, setupActions, _setAppState } = this.props;
    const {rtoParams, organicRTO, rtoTypes, } = this.state;

    let rtoData = {
            id: '',
            category: '', 
            status: '',
            hours: 0, 
            balance: 0, 
            inQueue: 0,
            remaining: 0,
          };
    let balance = 0;
    let inQueue = 0;
    let remaining = 0;
    let hours = 0;
    
    if(rtoParams) {
      _updateSelectedRTO(rtoParams);
      _toggleModal(ModalTypes.REQUESTED_TIME_OFF);
    }else {
      // Show loading spinner
      if(this._isMounted) {
        this.setState({loadingData: true});
      }
      GetUserRequestTimeOffBalance1(setupActions.meta.current_user_privilege.id, rto.id).then((data) =>{
        if(!data.data.rto_balances) {
          GetLocationRequestTimeOffs(rto.location_id).then((response) =>{
            const rtos = response.data.request_time_offs;
            const idx = rtos.findIndex((item) => item.id === rto.id);
            if(idx >= 0) {
              const foundRTO = rtos[idx];
              rtoData.id = rto.id;
              rtoData.status = rto.status;
              rtoData.category = rto.category;
              
              hours = foundRTO.day_hours * foundRTO.days;
              if(foundRTO  && foundRTO.balances_at_closed && foundRTO.balances_at_closed.rto_balances && foundRTO.balances_at_closed.rto_in_queue) {
                if(rtoTypes) {
                  const idx = rtoTypes.findIndex((type) => type.key === rto.category);
                  if(idx >= 0) {
                    balance = foundRTO.rto_balances[rtoTypes[idx].key];
                    inQueue = foundRTo.rto_in_queue[rtoTypes[idx].key] ? foundRTO.rto_in_queue[rtoTypes[idx].key] : 0;
                    const msg = `idx: ${idx}, category: ${rtoTypes[idx].key}, balance: ${balance}, inQueue ${inQueue}`;
                    console.log(msg);
                  }
                }
              }
              if(inQueue == null){
                remaining = balance - hours;
              }else {
                remaining = balance - inQueue - hours;
              }
              rtoData.hours = hours;
              rtoData.balance = balance;
              rtoData.inQueue = inQueue;
              rtoData.remaining = remaining;
              if(this._isMounted) {
                this.setState({rtoParams: rtoData});
                this.setState({loadingData: false});
              }
              _updateSelectedRTO(rtoData);
              _toggleModal(ModalTypes.REQUESTED_TIME_OFF);
            }else {
              this.setState({loadingData: false});
              rtoData.id = rto.id;
              rtoData.status = rto.status;
              rtoData.category = rto.category;
              hours = rto.day_hours * rto.days;
              if(rto.balances_at_closed) {
                if(rtoTypes) {
                  for(let i=0; i<rtoTypes.length; i++) {
                    if(rtoTypes[i].key === rto.category) {
                      if(rto.balances_at_closed.rto_balances) {
                        balance = rto.balances_at_closed.rto_balances[rtoTypes[i].key];
                      }
                      if(rto.balances_at_closed.rto_in_queue) {
                        inQueue = rto.balances_at_closed.rto_in_queue[rtoTypes[i].key] ? rto.balances_at_closed.rto_in_queue[rtoTypes[i].key] : 0;
                      }
                      const msg = `idx: ${i}, category: ${rtoTypes[i].key}, balance: ${balance}, inQueue ${inQueue}`;
                      console.log(msg);
                      break;
                    }
                  }
                }
              }
              if(inQueue == null){
                remaining = balance - hours;
              }else {
                remaining = balance - inQueue - hours;
              }
              rtoData.hours = hours;
              rtoData.balance = balance;
              rtoData.inQueue = inQueue;
              rtoData.remaining = remaining;
              _updateSelectedRTO(rtoData);
              _toggleModal(ModalTypes.REQUESTED_TIME_OFF);
            }
          }).catch((error) =>{
            if(this._isMounted) {
              this.setState({loadingData: false});
            }
            const msg = `RTOCardNew.handleCardClick: failed on GetLocationRequestTimeOffs. error: ${error}`;
            console.log(msg);
          })
        }else {
          rtoData.id = rto.id;
          rtoData.status = rto.status;
          rtoData.category = rto.category;

          if(rtoTypes) {
            const idx = rtoTypes.findIndex((type) => type.key === rto.category);
            if(idx >= 0) {
              balance = data.data.balances_at_closed.rto_balances[rtoTypes[idx].key];
              inQueue = data.data.balances_at_closed.rto_in_queue[rtoTypes[idx].key] ? data.data.balances_at_closed.rto_in_queue[rtoTypes[idx].key] : 0;
              const msg = `idx: ${idx}, category: ${rtoTypes[idx].key}, balance: ${balance}, inQueue ${inQueue}`;
              console.log(msg);
            }
          }

          hours = rto.day_hours * rto.days;
          if(inQueue == null){
            remaining = balance - hours;
          }else {
            remaining = balance - inQueue - hours;
          }
          
          rtoData.hours = hours;
          rtoData.balance = balance;
          rtoData.inQueue = inQueue;
          rtoData.remaining = remaining;
          if(this._isMounted) {
            this.setState({rtoParams: rtoData});
            this.setState({loadingData: false});
          }
          _updateSelectedRTO(rtoData);
          _toggleModal(ModalTypes.REQUESTED_TIME_OFF);
        }
      }).catch((error) =>{
        console.log(error);
        if(this._isMounted) {
          this.setState({loadingData: false});
        }
      });
    }
  };

  renderDate = () => {
    const { rto } = this.props;

    const startDate = moment(rto.start_date).format('ddd, MMM DD');
    return rto.start_date ? startDate.toUpperCase() : '';
  }

  renderTime = () => {
    const { rto, userCookie, intl } = this.props;

    const startTime = moment(rto.start_time).format('h:mma');
    const endTime = moment(rto.end_time).format('h:mma');
    //const timeRange = `${startTime} - ${endTime}`;
    const start = startTime.slice(0, startTime.length-1);
    const end = endTime.slice(0, endTime.length-1);
    const timeRange = `${start} - ${end}`;
    return timeRange;
  }

  tradeStatus = () => {
    const { rto, userCookie} = this.props;
    const {ownerFullName, covererFullName, pendingApplicants, 
          approvedApplicants, deniedApplicants, firstApplicantName, currentUserFullName, firstDeniedApplicantName } = this.state;
    const applicantFullName = rto ? `${rto.first_name} ${rto.last_name}` : '';
    const approverFullName = rto ? `${rto.approver_first_name} ${rto.approver_last_name}` : '';

    const statusPending = (<FormattedMessage
                id="RTOCardNew.tradeStatuspending"
                defaultMessage="Pending" />);
    const statusApproved = (<FormattedMessage
                id="RTOCardNew.tradeStatusApproved"
                defaultMessage="Approved" />);
    const statusDenied = (<FormattedMessage
                id="RTOCardNew.tradeStatusDenied"
                defaultMessage="Denied" />);
    const statusRequested = (<FormattedMessage
                id="RTOCardNew.tradeStatusRequested"
                defaultMessage="Requested" />);
    const messagePending = (<FormattedMessage
        id="RTOCardNew.msgPending"
        defaultMessage="{applicant}'s request to take time off is waiting for approval." 
        values={{applicant: applicantFullName,}}/>);
    const messageApproved = (<FormattedMessage
        id="RTOCardNew.msgApproved"
        defaultMessage="{approver} has approved your request to take time off." 
        values={{approver: approverFullName,}}/>);
    const messageDenied = (<FormattedMessage
        id="RTOCardNew.msgDenied"
        defaultMessage="{approver} has denied your request to take time off." 
        values={{approver: approverFullName,}} />);
    const messageRequested = (<FormattedMessage
        id="RTOCardNew.msgRequested"
        defaultMessage="{applicant} requested to take time off." 
        values={{applicant: applicantFullName,}} />);
    const messageApprovedNoName = (<FormattedMessage
        id="RTOCardNew.msgApprovedNoName"
        defaultMessage="Your request to take time off has been approved." />);
    const messageDeniedNoName = (<FormattedMessage
        id="RTOCardNew.msgDeniedNoName"
        defaultMessage="Your request to take time off has been denied." />);

    let obj = {status: '', statusClassName: '', statusMessage: '',};
    if(rto.id === 101) {
      //console.log('here');
    }
    switch (rto.status) {
      case 'approved':
        obj.status = statusApproved;
        obj.statusClassName = 'shift-card-new__trade-status-approved';
        if(approverFullName) {
          obj.statusMessage = messageApproved;
        }else {
          obj.statusMessage = messageApprovedNoName;
        }
        break;
      case 'denied':
        obj.status = statusDenied;
        obj.statusClassName = 'shift-card-new__trade-status-rejected';
        if(approverFullName) {
          obj.statusMessage = messageDenied;
        }else {
          obj.statusMessage = messageDeniedNoName;
        }
        
        break;
      case 'pending':
      case 'requested':
        obj.status = statusPending;
        obj.statusClassName = 'shift-card-new__trade-status-pending';
        obj.statusMessage = messageRequested;
        break;
      /*
      case 'requested':
        obj.status = statusRequested;
        obj.statusClassName = 'shift-card-new__trade-status-pending';
        obj.statusMessage = messageRequested;
        break;
      */
      default:
        break;
    }
    return obj;
  }
  
  _renderDates = (start_time, end_time, end_date) => {
    const startDate = moment(start_time).format('ddd, MMM D');
    const endDate = moment(end_date).format('ddd, MMM D');
    const duration =`${startDate} - ${endDate}`;
    const startTime = moment(start_time).format('hh:mm A');
    const endTime = moment(end_time).format('hh:mm A');
    const timeRange = `${startTime} - ${endTime}`;
    
    return (
      <>
        <span>
          {duration}
          <br />
          {timeRange}
        </span>
      </>
    );
  };

  render() {
    const {
      userCookie,
      location,
      locationSettings: {
        cfg__primary_job_and_department,
        cfg__inventory__convert,
      },
      _toggleModal,
      rto,
      appState,
    } = this.props;
    const {rtoParams, statusText, debug, debugInfo, ownerAvatar, posterIsAdmin, } = this.state;

    const obj = this.tradeStatus();
    let rtoTradeStatus = obj.status;
    let tradeStatusClassName = obj.statusClassName;
    let message = obj.statusMessage;
    let showCovererAvatar = false;

    const loading = (
      <div className="index-page index-page__loading">
        <LoadingSpinner color="blue" size="large" />
      </div>
    );

    const badgeText = (<FormattedMessage
        id="RTOCardNew.timeOffRequest"
        defaultMessage="Time Off Request" />);

    if(this.state.loadingData) {
      return loading;
    }

    return (
      // eslint-disable-next-line
      <div className="shift-card-wrap">
        {debug && <label>{debugInfo}</label>}
        <label className="shift-card-date">{this.renderDate()}</label>
        <div className="shift-card-new" onClick={this._handleCardClick}>
          <div className="shift-card-new__top-wrap">
            <span className="rto-card-new__status-bar-orange"/>
            <div className="shift-card-new__top-text-wrap">
              <div className="shift-card-new__title-type-wrap">
                <label className="shift-card-new__jobTitle">{badgeText}</label>
              </div>
              <label className="shift-card-new__Time-text">{this.renderTime()}</label>
            </div>
              <label className={tradeStatusClassName}>{rtoTradeStatus}</label>
          </div>
          <div className="shift-card-new__bottom-wrap">
            {showCovererAvatar ? <div className="shift-card-new__avatars-wrap">
              <div className="shift-card-new__thumb_wrap">
                <img className="shift-card-new__avatar" src={ownerAvatar}/>
              </div>
              <div className="shift-card-new__thumb-wrap">
                <img className="shift-card-new__avatar" src={covererAvatar}/>
              </div>
              {posterIsAdmin && <img className="shift-card-new__verified-badge" src='/icons/verified-badge-icon.svg'/>}
              {covererIsAdmin && <img className="shift-card-new__verified-badge-right" src='/icons/verified-badge-icon.svg'/>}
            </div>
            :
            <div className="shift-card-new__avatars-wrap-single">
              <div className="">
                <img className="shift-card-new__avatar" src={ownerAvatar}/>
              </div>
              {posterIsAdmin && <img className="shift-card-new__verified-badge" src='/icons/verified-badge-icon.svg'/>}
            </div>
            }
            <label className="shift-card-new__message">{message}</label>
          </div>
        </div>
      </div>
    );
  }
}

RTOCardNew.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  shift: PropTypes.shape({}).isRequired,
  locationSettings: PropTypes.shape({}).isRequired,
  _toggleModal: PropTypes.func.isRequired,
  _updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (RTOCardNew);
