// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './AddLocationPage.scss';

import locationCodeIcon from './assets/binary-code-loading-symbol.svg';

/**
 * I need a split page - there will be two routes
 * and then we can search with location codes
 * or store names
 */
class AddLocationPage extends React.Component {
  state = {
    location: null,
  };

  back = () => {};

  _handleSwiftCodeClick = (e) => {
    const { redirect } = this.props;
    e.preventDefault();
    this.setState({
      location: redirect('/add-location/code'),
    });
  };

  _handleStoreNameClick = (e) => {
    const { redirect } = this.props;
    e.preventDefault();

    this.setState({
      location: redirect('/add-location/name'),
    });
  };

  render() {
    const { location } = this.state;
    if (location) {
      return location;
    }
    return (
      <>
        <article className="location-page">
          <section className="location-page__row">
            <div className="location-type__column">
              <h2 className="location-page__header">
                <FormattedMessage
                  id="AddLocationPage.pageHeader"
                  defaultMessage="Find Your Store"
                />
              </h2>
              <p className="location-page__header-subtext">
                <FormattedMessage
                  id="AddLocationPage.chooseMethod"
                  defaultMessage="Select how you'd like to locate your team"
                />
              </p>
            </div>
          </section>
          <section className="location-page__row">
            <div className="location-type__column location-type__column--center">
              <button
                className="location-type__button"
                onClick={this._handleSwiftCodeClick}
                type="button"
              >
                <header className="location-type__button__header">
                  <FormattedMessage
                    id="AddLocationPage.useLocationCode"
                    defaultMessage="Use Location Code"
                  />
                </header>
                <div className="location-type__button__icon">
                  <img
                    src={locationCodeIcon}
                    alt="Location Code Icon"
                    height="50"
                    width="50"
                  />
                </div>
                <button
                  className="button button-primary button-small location-type__next-btn"
                  type="button"
                >
                  <FormattedMessage
                    id="AddLocationPage.nextButton"
                    defaultMessage="Next"
                  />
                </button>
              </button>
            </div>
          </section>
        </article>
      </>
    );
  }
}

export { AddLocationPage };
