import React from 'react';
import PropTypes from 'prop-types';

import closeIcon from './assets/close-modal.svg';
import closeIconDark from './assets/close-modal-dark.svg';
import './CloseModal.scss';

const CloseModal = props => (
  <button
    className="close-modal"
    onClick={() => {
      props._toggleModal(null);
    }}
  >
    <img src={props.dark ? closeIconDark : closeIcon} alt="Close icon" />
  </button>
);

CloseModal.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
  dark: PropTypes.bool,
};

CloseModal.defaultProps = {
  dark: false,
};

export { CloseModal };
