import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import './Dropdown.scss';

class Dropdown extends React.Component {
  dropdownRef = null;

  state = {
    showDropdown: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  setDropdownRef = (element) => {
    this.dropdownRef = element;
  };

  _handleButtonClick = () => {
    this.setState(prevState => ({
      showDropdown: !prevState.showDropdown,
    }));
  };

  _handleClickOutside = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.setState({ showDropdown: false });
    }
  };


  _handleItemClick = (clickHandler) => {
    clickHandler();
    this.setState({ showDropdown: false });
  };

  _renderItems = () => {
    const { items, direction, imageSize } = this.props;
    const contentClasses = className({
      dropdown__content: true,
      'dropdown__content--left': direction === 'left',
      'dropdown__content--right': direction === 'right',
    });

    if (items.length > 0) {
      const dropdownItems = items.map(item => (
        <a
          className={item.className}
          tabIndex="0"
          role="button"
          key={item.label}
          onClick={(e) => {
            e.preventDefault();
            this._handleItemClick(item.clickHandler);
          }}
        >
          {item.label}
        </a>
      ));

      return <div className={contentClasses}>{dropdownItems}</div>;
    }

    return null;
  };

  render() {
    const { showDropdown } = this.state;
    const { children, items, imageSize } = this.props;

    let buttonClass;
    /*
    buttonClass = className({
      dropdown: true,
      'dropdown--disabled': items.length === 0,
    });
    */

    
    if(imageSize === 'regular') {
      buttonClass = className({
        dropdown: true,
        'dropdown--disabled': items.length === 0,
      });
    }else{
       buttonClass = className({
        'dropdown--enlarged': imageSize === 'large',
      });
    }
    
    return (
      <div className={buttonClass} ref={this.setDropdownRef}>
        <button
          className={buttonClass}
          onClick={this._handleButtonClick}
          type="button"
        >
          {children}
        </button>
        {showDropdown ? this._renderItems() : null}
      </div>
    );
  }
}

Dropdown.propTypes = {
  buttonClass: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  items: PropTypes.arrayOf(PropTypes.object),
};

Dropdown.defaultProps = {
  buttonClass: '',
  direction: 'center',
};

export { Dropdown };
