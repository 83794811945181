import React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


import ProfileInputWrap from '../ProfileInputWrap/ProfileInputWrap';

import './ProfileInputText.scss';

const formatPhoneNumber = (number) => {
  if (number.substring(0, 1) === '1') {
    const phoneNumber = parsePhoneNumberFromString(`+${number}`);
    if(phoneNumber) {
      return `+1 ${phoneNumber.format('NATIONAL')}`;
    }else {
      return '';
    }
    
  }

  return number;
};

const ProfileInputText = (props) => {
  const {
    _changeHandler,
    error,
    icon,
    name,
    placeholder,
    inputValue,
    disabled,
    verifiedStatus,
  } = props;


  //const phoneNumber = inputValue && formatPhoneNumber(inputValue, 'INTERNATIONAL');
  let displayText = '';
  if(name === 'phone_number') {
    displayText = inputValue && formatPhoneNumber(inputValue, 'INTERNATIONAL');
  }else {
    displayText = inputValue;
  }

  return (
    <ProfileInputWrap error={error} icon={icon} verifiedStatus={verifiedStatus}>
      <input
        className="profile-input-text"
        name={name}
        onChange={_changeHandler}
        type="text"
        placeholder={placeholder}
        value={displayText}
        disabled={disabled}
      />
    </ProfileInputWrap>
  );
};

ProfileInputText.propTypes = {
  _changeHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  verifiedStatus: PropTypes.string,
};

ProfileInputText.defaultProps = {
  error: '',
  verifiedStatus: '',
  disabled: false,
};

export default ProfileInputText;
