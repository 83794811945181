import axios from 'axios';

import { axiosInstance } from '../api-calls';

// User object can include:
// first_name, last_name, locale,
// email, and password
export const updateUserAttributes = (user_id, user) => {
  const cleanUser = { ...user };
  delete cleanUser.position;

  return axiosInstance.request({
    url: `/api/customer/user_role/users/${user_id}?response[put_return_resource]=true`,
    method: 'PUT',
    data: {
      user: cleanUser,
    },
  });
};

export const updateUserPosition = (current_user_privilege_id, user) => axiosInstance.request({
  url: `/api/customer/user_role/user_privileges/${current_user_privilege_id}`,
  method: 'PUT',
  data: {
    user_privilege: {
      position: user.position,
    },
  },
});

export const updateProfile = (user_id, current_user_privilege_id, user) => axios.all([
  updateUserAttributes(user_id, user),
  updateUserPosition(current_user_privilege_id, user),
]);

export const getUserInfo = (user_id, location_id) =>
  axiosInstance.request({
    url: `/api/organization/users/${user_id}`,
    method: 'GET',
    params: {
      user_id,
      location_id,
      'response[put_return_resource]': true,
    },
  });

export const getUserInfo1 = (user_privilege_id) =>
  axiosInstance.request({
    url: `api/customer/user_role/user_privileges/${user_privilege_id}`,
    method: 'GET',
    params: {
      user_privilege_id,
      'response[put_return_resource]': true,
    },
  });


export const getUserInfo2 = (user_id) =>
  axiosInstance.request({
    url: `api/customer/user_role/users/${user_id}`,
    method: 'GET',
    params: {
      user_id,
      'response[put_return_resource]': true,
    },
  });

export const getUserInfoNonAdmin = (user_id, location_id) =>
  axiosInstance.request({
    url: `api/customer/user_role/users/${user_id}`,
    method: 'GET',
    params: {
      user_id,
      location_id,
      'response[put_return_resource]': true,
    },
  });

