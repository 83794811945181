import { formatOnlyProps } from '_helpers/formatting';
import { axiosInstance } from '../api-calls';

const commentsProps = formatOnlyProps(`
  content,
  created_at,
  id,
  owner[profile_image, first_name, last_name],
  source_id
`);

export const getComments = post_id => axiosInstance.request({
  url: 'api/customer/user_role/comments',
  method: 'GET',
  params: {
    'response[only]': commentsProps,
    post_id,
  },
});

export const createComment = (post_id, content) => axiosInstance.request({
  url: '/api/customer/user_role/comments',
  method: 'POST',
  data: {
    comment: {
      source_id: post_id,
      content,
    },
    'response[only]': commentsProps,
  },
});

export const getFreePosts = post_id => axiosInstance.request({
  url: `/api/compact/posts/${post_id}/free_posts`,
  method: 'GET',
  params: {
    'response[only]': commentsProps,
    post_id,
  },
});

export const createFreePost = (post_id, content) => axiosInstance.request({
  url: '/api/customer/user_role/posts',
  method: 'POST',
  data: {
    post: {
      post_type: 22,
      postable_type: 'Post',
      postable_id: post_id,
      title: 'Free Post',
      content,
    },
    'response[only]': commentsProps,
  },
});

export const deleteFreePost = (postId) => axiosInstance.request({
  url: `/api/customer/user_role/posts/${postId}`,
  method: 'DELETE',
});

export const deleteComment = (commentId) => axiosInstance.request({
  url: `/api/customer/user_role/comments/${commentId}`,
  method: 'DELETE',
});
