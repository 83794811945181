import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import styled from './styled';


class DateButton extends React.PureComponent {
  constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;
  }

  render () {
    const {disabled, _toggleDatePicker, dateString,} = this.props;
    return (
       <styled.DateTimeBtn
      disabled={disabled}
      onClick={_toggleDatePicker}
      type="button"
    >
      <styled.DateTimeSection>
        <span className="icon-calendar" />
        &nbsp;&nbsp;&nbsp;
        <styled.DateTimeText>{dateString}</styled.DateTimeText>
      </styled.DateTimeSection>
    </styled.DateTimeBtn>
    );
  }
}

DateButton.propTypes = {
  _toggleDatePicker: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default injectIntl (DateButton);
