import React from 'react';
import update from 'immutability-helper';

import {
  setUserCookie,
  getUserCookie,
  checkForAuthentication,
  getLocaleCookie,
} from '_helpers/cookies';
import { API_SECTIONS } from '_constants/index';
import { getBrowserLanguage } from '_helpers/localization';

import IntlContainer from './IntlContainer';
import App from './App';

class AppState extends React.Component {
  state = {
    apisNeeded: API_SECTIONS,
    channels: {
      location_feed: [],
      custom_feed: [],
    },
    locale: getLocaleCookie() || getBrowserLanguage(),
    supportedLocales: [
      {
        name: 'English',
        lang: 'en',
      },
      {
        name: 'Español',
        lang: 'es',
      },
      {
        name: 'Français',
        lang: 'fr',
      },
    ],
    loading: {
      shifts: true,
    },
    locations: [],
    associatedLocations: {},
    locationSettings: {},
    user: checkForAuthentication() ? getUserCookie() : {},
    userRole: false,
    selectedShift: null,
    setupActions: false,
    shifts: [],
    visibleModal: '',
    currentLocation: {},
    currentConfiguration: {},
    calledOffShifts: [],

  };

  componentDidMount() {
    // const locale = getBrowserLanguage();
    // const user = checkForAuthentication() ? getUserCookie() : {};

    // this._setAppState([
    //   {
    //     key: 'locale',
    //     statement: { $set: locale },
    //   },
    //   {
    //     key: 'user',
    //     statement: { $set: user },
    //   },
    // ]);

    // Add event listener to save userCookie state to cookie
    // when user leaves/refreshes page
    window.addEventListener('beforeunload', this._syncUserCookie);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this._syncUserCookie);
    // Sets if component has chance to unmount
    this._syncUserCookie();
  }

  _setAppState = (updater) => {
    // updater should be object or array of objects containing state key and
    // immutability-helper statement
    this.setState((prevState) => {
      if (Array.isArray(updater)) {
        // Convert array of updaters into one object
        const newState = updater.reduce((acc, cur) => {
          const { key } = cur;
          acc[key] = update(prevState[key], cur.statement);
          return acc;
        }, {});

        // Set state with new object
        return newState;
      }
      if (Object.prototype.toString.call(updater) === '[object Object]') {
        // Updater is single object
        const newState = {
          [updater.key]: update(prevState[updater.key], updater.statement),
        };

        return newState;
      }
      // Updater is neither array or object
      console.error('updater argument not type array or object!');

      return {};
    });
  };

  _syncUserCookie = () => {
    const { user } = this.state;

    if (Object.keys(user).length > 0 && user.constructor !== Object) {
      user.id = user.user_id;
      user.recent_user_privilege = {
        is_admin: user.is_admin,
        location: {
          id: user.location_id,
          time_zone: user.time_zone,
        },
        phone_number: user.phone_number,
        position: user.position,
      };

      setUserCookie(user);
    }
  };

  render() {
    const { locale } = this.state;

    return (
      <IntlContainer locale={locale}>
        <App _setAppState={this._setAppState} appState={this.state} />
      </IntlContainer>
    );
  }
}

export default AppState;
