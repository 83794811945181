import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DataNames } from '../../constants';
import DropDownMenu from './DropDownMenu';
import './TimePickerDropDown.scss';
import styled from './styled';

class TimePickerDropDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showStartTimePicker: false,
      showEndTimePicker: false,
      startTime: '',
      endTime: '',
      endTimeItems: [],
      selectedHourStep: 0,
      hourStepPto: 0,
      hourStepFloating: 0,
      hourStepDiversity: 0,
    };

    this.winTop = '0px';
    this.winLeft = '0px';
    this.winTop1 = '0px';
    this.winLeft1 = '0px';
  }

  componentDidMount () {

  }
  componentDidUpdate(prevProps) {
    const {selectedIndex, getEndTime, rtoEndDate, } = this.props;
    const { selectedHourStep, hourStepPto, hourStepFloating, hourStepDiversity} = this.state;

    if(prevProps.selectedIndex !== selectedIndex) {
      switch(selectedIndex) {
        case 0:
          if(selectedHourStep !== hourStepPto) {
            this.setState({endTime: ''});
            getEndTime('');
          }
          break;
        case 1:
          if(selectedHourStep !== hourStepFloating) {
            this.setState({endTime: ''});
            getEndTime('');
          }
          break;
        case 2:
          if(selectedHourStep !== hourStepDiversity) {
            this.setState({endTime: ''});
            getEndTime('');
          }
          break;
      }
    }
    // user reset start date and end date
    if(prevProps.rtoEndDate !== rtoEndDate) {
      if(rtoEndDate === '') {
        this.setState({startTime: '', endTime: ''});
      }
    }

  }

  componentWillUnmount() {
    
  }
 


  _handleClear = (e) => {
    const { startTime, endTime } = this.state;
    const { _updateState, clearDatesAndTimes } = this.props;

    _updateState(DataNames.DATE, null);
    this.setState({
      startTime: '',
      endTime: '',
    });
    clearDatesAndTimes();
    e.preventDefault();
  };


  _handleSubmit = (e) => { 
    const { toggleDatePicker,} = this.props;
    e.preventDefault();
    if(!this.canProgress()) {
      return;
    }
    toggleDatePicker();
  };

  toggleTimePicker = () => {
    this.setState({showStartTimePicker: !this.state.showStartTimePicker});
  }

  toggleTimePicker1 = () => {
    this.setState({showEndTimePicker: !this.state.showEndTimePicker});
  }

  handleStartTimeClick = (selectedItem) => {
    const {getStartTime, getEndTime} = this.props;
    this.setState({startTime: selectedItem});
    this.setState({endTime: ''});
    
    //parent call back
    getEndTime('');
    getStartTime(selectedItem);
    this.toggleTimePicker();

  }

  handleEndTimeClick = (selectedItem) => {
    const {getEndTime} = this.props;
    this.setState({endTime: selectedItem});
    //parent call back
    getEndTime(selectedItem);
    this.toggleTimePicker1();

  }

  callbackEndTimeItems = (endTimeItems) => {
    this.setState({endTimeItems: endTimeItems});
  }

  callbackSelectedHourStep = (hourStep, hourStepPto, hourStepFloating, hourStepDiversity) => {
    const { selectedIndex, getEndTime, } = this.props;

    this.setState({
      selectedHourStep: hourStep,
      hourStepPto: hourStepPto,
      hourStepFloating: hourStepFloating,
      hourStepDiversity: hourStepDiversity,
    });
  }


   canProgress = () => {
    
    const {rtoStartDate, rtoEndDate} = this.props;
    const { startTime, endTime } = this.state;

    const isDate =  rtoStartDate !== null && rtoEndDate !== null 
    && (moment(rtoStartDate).isSame(moment(rtoEndDate)) || moment(rtoEndDate).isAfter(moment(rtoStartDate)));
    const isTimes = startTime.length >0  && endTime.length > 0;
    
   
    //remove spaces in string
    const start = startTime.replace(/ /g, '');
    const end = endTime.replace(/ /g, '');
    
    const start1 = moment.utc(start, 'h:mm A');
    const end1 = moment.utc(end, 'h:mm A');
    const duration = moment.duration(end1.diff(start1));
    const minutes = parseInt(duration.asMinutes());

    let isValidTimeRange = false;
    let newEndDate = null;

    if(minutes > 0) {
      isValidTimeRange = true;
    }
    else {
      //end time crossed midnight
      newEndDate = moment(rtoStartDate).add(1, 'Days');
      if(moment(rtoStartDate).isSame(moment(rtoEndDate))) {
        //newEndDate = moment(rtoStartDate).add(1, 'Days');
        isValidTimeRange = true;
      }else {
        if(rtoEndDate && newEndDate) {
          if(newEndDate.isSame(moment(rtoEndDate)) || moment(rtoEndDate).isAfter(newEndDate)) {
            isValidTimeRange = true;
          }
        }
      }
    }
    const canProgress = isDate && isTimes && isValidTimeRange;
    return canProgress;
  };

  render() {
    const { date, locale, _toggleDatePicker, timeString, costCenterData, selectedIndex} = this.props;
    const { errors, start, end, showStartTimePicker, showEndTimePicker } = this.state;
    const applyBtnClasses = classNames({
      'time-picker__action-btn time-picker__action-btn--apply': true,
      'time-picker__action-btn--apply--inactive': !this.canProgress(),
    });

    const tpWrapClass = classNames({
      "request-time-off__tp-wrap": true,
      "request-time-off__tp-wrap--show": showStartTimePicker,
    });

     const tpWrapClass1 = classNames({
      "request-time-off__tp-wrap": true,
      "request-time-off__tp-wrap--show": showEndTimePicker,
    });

    this.winTop = `0px`; 
    this.winLeft = `0px`; 
    this.winTop1 = `8px`; 
    this.winLeft1 = `0px`; 

    return (
      <div className="time-picker">
        <form onSubmit={this._handleSubmit}>
          <div className="time-picker__row">
            <p className="time-picker__label">
              <FormattedMessage
                id="TimePickerDropDown.startTimeLabel"
                defaultMessage="Start Time"
              />
            </p>
            <styled.DateTimeBtn
              onClick={this.toggleTimePicker}
              type="button"
            >
              <styled.DateTimeSection>
                <styled.DateTimeText>{this.state.startTime}</styled.DateTimeText>
              </styled.DateTimeSection>
            </styled.DateTimeBtn>
            <div className={tpWrapClass}>
              <DropDownMenu
                top={this.winTop} 
                left={this.winLeft}
                handleMenuItemClick={this.handleStartTimeClick}
                toggleTimePicker={this.toggleTimePicker} 
                callbackEndTimeItems={this.callbackEndTimeItems}
                costCenterData={costCenterData}
                selectedIndex={selectedIndex}
                callbackSelectedHourStep={this.callbackSelectedHourStep}
                />
            </div>
          </div>
          <div className="time-picker__row">
            <p className="time-picker__label">
              <FormattedMessage
                id="TimePickerDropDown.endTimeLabel"
                defaultMessage="End Time"
              />
            </p>
            <styled.DateTimeBtn
              onClick={this.toggleTimePicker1}
              type="button"
            >
              <styled.DateTimeSection>
                <styled.DateTimeText>{this.state.endTime}</styled.DateTimeText>
              </styled.DateTimeSection>
            </styled.DateTimeBtn>
            <div className={tpWrapClass1}>
              <DropDownMenu
                top={this.winTop1} 
                left={this.winLeft1}
                handleMenuItemClick={this.handleEndTimeClick}
                toggleTimePicker={this.toggleTimePicker1}
                endTimeItems={this.state.endTimeItems}
                costCenterData={costCenterData}
                selectedIndex={selectedIndex}
                callbackSelectedHourStep={this.callbackSelectedHourStep}
                />
            </div>
          </div>
          <div className="time-picker__row time-picker__row--footer">
            <div className="time-picker__footer">
              <button
                className="time-picker__action-btn time-picker__action-btn--clear"
                onClick={this._handleClear}
                type="button"
              >
                <FormattedMessage
                  id="TimePickerDropDown.clearTime"
                  defaultMessage="Clear"
                />
              </button>
              <button className={applyBtnClasses} type="submit">
                <FormattedMessage
                  id="TimePickerDropDown.submitTime"
                  defaultMessage="Apply"
                />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

TimePickerDropDown.propTypes = {
  _updateState: PropTypes.func.isRequired,
  _toggleDatePicker: PropTypes.func.isRequired,
  date: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
};

export default TimePickerDropDown;
