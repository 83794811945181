import { getUserCookie } from '_helpers/cookies';

export const amplitudeConstants = {
  postShiftMain: 'post_a_shift',
  postShiftInsideFeed: 'post_shift',
};

export const trackAmplitudeEvent = (eventString) => {
  const eventProperties = {
    is_admin: getUserCookie().is_admin,
  };

  if (window.location.host === 'app.myshyft.com') {
    amplitude.getInstance().logEvent(eventString, eventProperties);
  }
};
