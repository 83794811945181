export const validatePhoneNumber = (number) => {
  if (typeof number !== 'string') return false;
  // strips '-', '(', ')', and spaces
  const charactersToReplace = /[-()\s+]/g;
  const stringToValidate = number.replace(charactersToReplace, '');
  const regex = /([0-9]{10})/;

  return regex.test(stringToValidate) || number === 'NC:';
};

export const validateEmail = (email) => {
  if (typeof email !== 'string') return false;
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

/**
 * 1 lowercase
 * 1 uppercase
 * 1 numeric
 * 1 special character
 * 8 characters or longer
 * @param {password} password
 */
export const validatePasswordStrength = (password) => {
  if (typeof password !== 'string') return false;
  const regex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})',
  );
  return regex.test(password);
};

export const validateName = (name) => {
  if (typeof name !== 'string') return false;
  return name.length > 1;
};

/**
 * Checks the setupActions array for an sso_logout
 * so that the app can then redirect to the sso page
 */
export const validateForSSO = (setupActions) => {
  if (setupActions.length === 0) {
    return false;
  }

  const ssoSetupActions = setupActions.filter((action) => {
    if (action.operation === 'action_sso_logout') {
      return action;
    }

    return false;
  });

  if (ssoSetupActions.length > 0) {
    return ssoSetupActions;
  }
  // eslint-disable-next-line
  return false;
};
