import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import classNames from "classnames";
import Datetime from 'react-datetime';

import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import { RadioButton, DateTimeButton, DateTimePicker } from "../PostShift/components";
import { DateButton, TimeButton } from './components';
import { DataNames } from '../PostShift/constants';
import {
  createDateString,
  createTimeRangeString,
  isNextDay,
} from '_helpers/formatting';
import { GetUserRequestTimeOffBalance, DeleteRequestTimeOff } from '_helpers/api-calls/rto-calls';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import { formatDateforApi, getDefaultTimezone} from '_helpers/date-time';
import TimePickerComponent from './TimePickerComponent';
import { setRequestTimeOff, removeRequestTimeOff} from "_helpers/app-state";
import { rgba } from 'polished';

import './RequestTimeOff.scss';
import * as styled from './styled';

class RequestedTimeOff extends React.Component {
	constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      paidTimeOff: {
        id: 'RequestedTimeOff.paidTimeOff',
        defaultMessage: 'Paid Time Off',
      },
      floating: {
        id: 'RequestedTimeOff.floating',
        defaultMessage: 'Floating Holiday',
      },
      diversity: {
        id: 'RequestedTimeOff.diversity',
        defaultMessage: 'Diversity Days',
      },
      hours: {
        id: 'RequestedTimeOff.unitHours',
        defaultMessage: 'hours',
      },
    });

    this.state = {
      dateString: '',
      submitting: false,
      timeRangeString: '',
      selectedType: '',
      rtoData: '',
      rtoStatus: '',
      rtoHours: 0,
      rtoBalance: 0,
      rtoInQueue: 0,
      rtoRemaining: 0,
      rtoBalance_pto: 0,
      rtoBalance_floating: 0,
      rtoBalance_diversity: 0,
      rtoInQueue_pto: 0,
      rtoInQueue_floating: 0,
      rtoInQueue_diversity: 0,
      confirmDelete: false,
      statusInfo: '',
      showDeleteButton: false,
      statusColor: null,
      statusBackColor: null,
      organicRTO: false,
      myPrivilege: null,
      myJobTitle: '',
      tags: [],
      rtoTypes: [],
      rtoAvailables: [],
    };
    
    this._isMounted = false;
    this._validDate = current => current.isAfter(this.yesterday);
    this.winTop = '0px';
    this.winLeft = '0px';
    this.timeButton = React.createRef();

  }

  componentDidMount() {
    const { intl: { formatMessage }, setupActions, selectedRTO, rtos, locationSettings, userPrivileges, costCenterData, } = this.props;
    

    this._isMounted = true;
    let types = [];
    let availables = [];
    if(costCenterData) {
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'rto_categories'){
          if(this._isMounted) {
            this.setState({tags: costCenterData.tag_groups[i].tags});
          }
          if(costCenterData.tag_groups[i].tags.length > 0) {
            for(let j=0; j<costCenterData.tag_groups[i].tags.length; j++) {
              let type = {index: 0, key: '', content: ''};
              let available = {index: 0, key: '', label: ''};
              type.index = j;
              type.key = costCenterData.tag_groups[i].tags[j].tag_key;
              available.index = j;
              available.key = costCenterData.tag_groups[i].tags[j].tag_key;
              type.content = costCenterData.tag_groups[i].tags[j].tag_content;
              types.push(type);
              availables.push(available)
            }
            if(this._isMounted) {
              this.setState({rtoTypes: types});
              this.setState({rtoAvailables: availables});
            }
          }
        }
      }
    }

     //cfg__rto_balance = 0 means this is an organic rto location.
    if(locationSettings) {
      if(locationSettings.cfg__rto_balance === 0) {
        if(this._isMounted) {
          this.setState({organicRTO: true});
        }
      }
    }
   
    if(selectedRTO){
      const idx = rtos.findIndex(
          (rto) => rto.id === selectedRTO.id
      );
      if(idx >= 0) {
        const foundRTO = rtos[idx];
        if(userPrivileges && userPrivileges.length > 0) {
          const idx1 = userPrivileges.findIndex((priv) => priv.owner_id === rtos[idx].user_id);
          if(idx1 >= 0) {
            if(this._isMounted) {
              this.setState({myPrivilege: userPrivileges[idx1]});
            }
          }
        }else {
          if(rtos[idx]) {
            getUserInfoNonAdmin(rtos[idx].user_id, rtos[idx].location_id).then((response) => {
              if(this._isMounted) {
                this.setState({myJobTitle: response.data.user.current_position});
              }
            }).catch((error) =>{
               const msg = `RequestedTimeOff.componentDidMount: call getUserInfoNonAdmin failed. error: ${error}`;
               console.log(msg);
            });
          }
        }
        if(this._isMounted) {
          this.setState({dateString: this.createDateRangeString(rtos[idx].start_date, rtos[idx].end_date)});
        }

        let type = '';
        let balance = 0;
        let inQueue = 0;
        let remaining = 0;
        let hours = 0;
        if(foundRTO  && foundRTO.balances_at_closed && foundRTO.balances_at_closed.rto_balances && foundRTO.balances_at_closed.rto_in_queue) {
          if(types) {
            const n = types.findIndex((type) => type.key === foundRTO.category);
            if(n >= 0) {
              this.setState({selectedType: types[n].content});
              balance = foundRTO.balances_at_closed.rto_balances[types[n].key];
              inQueue = foundRTO.balances_at_closed.rto_in_queue[types[n].key] ? foundRTO.balances_at_closed.rto_in_queue[types[n].key] : 0;
              hours = foundRTO.day_hours * foundRTO.days;
              const msg = `n: ${n}, category: ${types[n].key}, balance: ${balance}, inQueue ${inQueue}`;
              console.log(msg);
            }
          }
        }else {
          balance = selectedRTO.balance;
          inQueue = selectedRTO.inQueue;
          hours = selectedRTO.hours;
          if(types) {
            const n = types.findIndex((type) => type.key === selectedRTO.category);
            if(n >= 0) {
              this.setState({selectedType: types[n].content});
            }
          }
        }
        const startTime = moment(foundRTO.start_time).format('hh:mm A');
        const endTime = moment(foundRTO.end_time).format('hh:mm A');
        const timeRange = `${startTime} - ${endTime}`;
        if(inQueue == null){
          remaining = balance - hours;
        }else {
          remaining = balance - inQueue - hours;
        }
        if(this._isMounted) {
          this.setState({timeRangeString: timeRange});
          this.setState({rtoStatus: foundRTO.status});
          //this.setState({rtoBalance: selectedRTO.balance});
          //this.setState({rtoInQueue: selectedRTO.inQueue});
          this.setState({rtoBalance: balance.toFixed(2)});
          this.setState({rtoInQueue: inQueue.toFixed(2)});

        }
       
        if(this._isMounted) {
          this.setState({rtoHours: hours.toFixed(2)});
          this.setState({rtoRemaining: remaining.toFixed(2)});
        }

        let statusString = '';
        let showButton = false;
        let color = null;
        let backColor = null;
        const requestedColor = rgba(248,154,63,255);
        const requestedBackColor = rgba(255,237,216,255);
        const approvedColor = rgba(58,190,114,255);
        const approvedBackColor = rgba(220,245,229,255);
        const deniedColor = rgba(254,245,245,255);
        const deniedBackColor = rgba(233,100,94,255);

        switch(foundRTO.status) {
          case 'requested':
            statusString = (
               <FormattedMessage
                  id="RequestedTimeOff.statusPending"
                  defaultMessage="Request is pending"
                />
            );
            showButton = true;
            color = requestedColor;
            backColor = requestedBackColor;
            break;
          case 'approved':
            statusString = (
               <FormattedMessage
                  id="RequestedTimeOff.statusApproved"
                  defaultMessage="Request is approved"
                />
            );
            showButton = true;
            color = approvedColor;
            backColor = approvedBackColor;
            break;
          case 'denied':
             statusString = (
               <FormattedMessage
                  id="RequestedTimeOff.statusDenied"
                  defaultMessage="Request is denied"
                />
            );
            showButton = true;
            color = deniedColor;
            backColor = deniedBackColor;
            break;
        }
        if(this._isMounted) {
          this.setState({statusInfo: statusString})
          this.setState({showDeleteButton: showButton});
          this.setState({statusColor: color});
          this.setState({statusBackColor: backColor});
        }
      }
    }else {
      console.log('componentDidMount: selectedRTO not found');
    }
  }

  componentDidUpdate(prevProps) {
    const { intl: { formatMessage }, setupActions, selectedRTO, rtos, userPrivileges, } = this.props;

    if(prevProps.selectedRTO !== selectedRTO) {
      if(selectedRTO){
        const idx = rtos.findIndex(
            (rto) => rto.id === selectedRTO.id
        );
        if(idx >= 0) {
          const foundRTO = rtos[idx];
          if(userPrivileges && userPrivileges.length > 0) {
            const idx1 = userPrivileges.findIndex((priv) => priv.owner_id === rtos[idx].user_id);
            if(idx1 >= 0) {
              if(this._isMounted) {
                this.setState({myPrivilege: userPrivileges[idx1]});
              }
            }
          }
          if(this._isMounted) {
            this.setState({dateString: this.createDateRangeString(rtos[idx].start_date, rtos[idx].end_date)});
          }
          /*
          const startTime = moment(rtos[idx].start_time).format('hh:mm A');
          const endTime = moment(rtos[idx].end_time).format('hh:mm A');
          const timeRange = `${startTime} - ${endTime}`;
          if(this._isMounted) {
            this.setState({timeRangeString: timeRange});
            this.setState({rtoStatus: rtos[idx].status});
            this.setState({rtoBalance: selectedRTO.balance});
            this.setState({rtoInQueue: selectedRTO.inQueue});
          }
          let type = '';
         
          if(rtoTypes) {
            const n = rtoTypes.findIndex((item) => item.key === rtos[idx].category);
            if(n >= 0) {
              type = rtoTypes[n].content;
            }
          }

          if(this._isMounted) {
            this.setState({selectedType: type});
            this.setState({rtoHours: selectedRTO.hours});
            this.setState({rtoRemaining: selectedRTO.remaining});
          }
          */
          let type = '';
          let balance = 0;
          let inQueue = 0;
          let remaining = 0;
          let hours = 0;
          if(foundRTO  && foundRTO.balances_at_closed && foundRTO.balances_at_closed.rto_balances && foundRTO.balances_at_closed.rto_in_queue) {
            if(types) {
              const n = types.findIndex((type) => type.key === foundRTO.category);
              if(n >= 0) {
                this.setState({selectedType: types[n].content});
                balance = foundRTO.balances_at_closed.rto_balances[types[n].key];
                inQueue = foundRTO.balances_at_closed.rto_in_queue[types[n].key] ? foundRTO.balances_at_closed.rto_in_queue[types[n].key] : 0;
                hours = foundRTO.day_hours * foundRTO.days;
                const msg = `n: ${n}, category: ${types[n].key}, balance: ${balance}, inQueue ${inQueue}`;
                console.log(msg);
              }
            }
          }else {
            balance = selectedRTO.balance;
            inQueue = selectedRTO.inQueue;
            hours = selectedRTO.hours;
            if(types) {
              const n = types.findIndex((type) => type.key === selectedRTO.category);
              if(n >= 0) {
                this.setState({selectedType: types[n].content});
              }
            }
          }
          const startTime = moment(foundRTO.start_time).format('hh:mm A');
          const endTime = moment(foundRTO.end_time).format('hh:mm A');
          const timeRange = `${startTime} - ${endTime}`;
          if(inQueue == null){
            remaining = balance - hours;
          }else {
            remaining = balance - inQueue - hours;
          }
          if(this._isMounted) {
            this.setState({timeRangeString: timeRange});
            this.setState({rtoStatus: foundRTO.status});
            //this.setState({rtoBalance: selectedRTO.balance});
            //this.setState({rtoInQueue: selectedRTO.inQueue});
            this.setState({rtoBalance: balance});
            this.setState({rtoInQueue: inQueue});

          }
         
          if(this._isMounted) {
            this.setState({rtoHours: hours});
            this.setState({rtoRemaining: remaining});
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  sortDateWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.start_date === b.start_date) // identical? return 0
        return 0;
      else if (a.start_date === null)  // a is null? last 
        return 1;
      else if (b.start_date === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.start_date.localeCompare(b.start_date) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }


  createDateRangeString = (startDate, endDate) => {
    let dateRange;

    const start = moment(startDate);
    const end = moment(endDate);
    if(!endDate) {
      dateRange = `${start.format('ddd, MMM DD')}`;
      return dateRange;
    }

    dateRange = `${start.format('ddd, MMM DD')} - ${end.format('ddd, MMM DD')}`;
    return dateRange;
  }
  

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  handleGoNext = () => {
    if(this._isMounted) {
       this.setState({confirmDelete: true});
    }
  }


  handleGoBackClick = () => {
    if(this._isMounted) {
      this.setState({confirmDelete: false});
    }
  }

  handleSubmitClick = () => {
    const {selectedType, rtoStartDate, rtoEndDate, rtoStartTime, rtoEndTime,} = this.state;
    const {userCookie, _toggleModal, selectedRTO, _setAppState, rtos} = this.props;

    DeleteRequestTimeOff(selectedRTO.id).then((response) => {
      const data = response.data;
      const id = data.request_time_off.id;
      const location = data.request_time_off.location_id;
      const owner = `${data.request_time_off.first_name} ${data.request_time_off.last_name}`;
      const msg = `Request time off: id: ${id}, location: ${location}, owner: ${owner}`;
      //console.log(msg);

      removeRequestTimeOff(data.request_time_off, rtos, _setAppState);
      _toggleModal();
    }).catch((error) => {
       console.log(error);
    });
    
  }

  render () {
  	const { _toggleModal, rtos, intl, userCookie, locale, selectedRTO} = this.props;
  	const { showDatePicker, 
            showTimePicker, 
            date,
            dateString, 
            timeRangeString, 
            rtoHours,
            selectedType,
            confirmDelete,
            rtoBalance,
            rtoInQueue,
            rtoRemaining,
            statusInfo,
            showDeleteButton,
            organicRTO,
            myPrivilege,
            myJobTitle,
          } = this.state;

    let jobTitle = '';
    let submittedTime = '';

    if(myPrivilege) {
      jobTitle = myPrivilege.position;
    }else {
      jobTitle = myJobTitle;
    }

    if(selectedRTO) {
      const idx = rtos.findIndex((item) => item.id === selectedRTO.id);
      if(idx >= 0) {
        const submit = moment(rtos[idx].created_at);
        submittedTime = `${submit.format('MMM DD, YYYY @ h:mm A')}`;
      }
    }
    return (
       <div className="cover-shift">
        <ModalWrapper>
          <CloseModal _toggleModal={_toggleModal} dark />
          <div className="title-wrap">
          <h1>
            <FormattedMessage
              id="RequestedTimeOff.timeOffRequest"
              defaultMessage="Time Off Request"
            />
          </h1>
          <p className="submitted-time">
            <FormattedMessage
              id="RequestTimeOff.submitTime1"
              defaultMessage="Submitted: {time} "
              values={{time: submittedTime}}
            />
          </p>
          </div>
          <div className="modal-components__row">
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.dateRange"
                  defaultMessage="Date Range"
                />
              </label>
              <label className="item__value">{dateString}</label>
            </div>
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.startEndTimes"
                  defaultMessage="Start Time & End Time"
                />
              </label>
              <label className="item__value">{timeRangeString}</label>
            </div>
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.jobTitle"
                  defaultMessage="Job Title"
                />
              </label>
              <label className="item__value">{jobTitle}</label>
            </div>
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.type"
                  defaultMessage="Type"
                />
              </label>
              <label className="item__value">{selectedType}</label>
            </div>
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.hours"
                  defaultMessage="Hours"
                />
              </label>
              <label className="item__value">
                <FormattedMessage
                  id="RequestedTimeOff.hoursValue"
                  defaultMessage="{rtoHours} {rtoHours, plural, one {hour} other {hours}}"
                  values={{rtoHours,}}
                />
              </label>
            </div>
          </div>
          {!organicRTO && <div className="modal-components__row">
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.available"
                  defaultMessage="Available as of today"
                />
              </label>
              <label className="item__value">
                <FormattedMessage
                  id="RequestedTimeOff.balance"
                  defaultMessage="{rtoBalance} {rtoBalance, plural, one {hour} other {hours}}"
                  values={{rtoBalance,}}
                />
              </label>
            </div>
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.thisRequest"
                  defaultMessage="This Request"
                />
              </label>
              <label className="item__value">
                <FormattedMessage
                  id="RequestedTimeOff.hoursValue"
                  defaultMessage="{rtoHours} {rtoHours, plural, one {hour} other {hours}}"
                  values={{rtoHours,}}
                />
              </label>
            </div>
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.pending"
                  defaultMessage="Pending/Approved Request"
                />
              </label>
              <label className="item__value">
                <FormattedMessage
                  id="RequestedTimeOff.pendingValue"
                  defaultMessage="({rtoInQueue} {rtoInQueue, plural, one {hour} other {hours}})"
                  values={{rtoInQueue,}}
                />
              </label>
            </div>
            <div className="item">
              <label className="item__name">
                <FormattedMessage
                  id="RequestedTimeOff.remaining"
                  defaultMessage="Remaining as of Today"
                />
              </label>
              <label className="item__value">
                <FormattedMessage
                  id="RequestedTimeOff.remainingValue"
                  defaultMessage="{rtoRemaining} {rtoRemaining, plural, one {hour} other {hours}}"
                  values={{rtoRemaining,}}
                />
              </label>
            </div>
          </div>}
          {confirmDelete ? <div className="button-container">
            <button 
            className="button-delete"
            onClick={this.handleGoBackClick}>
              <FormattedMessage
                id="RequestedTimeOff.back"
                defaultMessage="Back"
              />
            </button>
            <button 
            className="button-confirm"
            onClick={this.handleSubmitClick}>
              <FormattedMessage
                id="RequestedTimeOff.confirmCancel"
                defaultMessage="Confirm Cancel"
              />
            </button>
          </div> : 
          <div className="button-container">
            {showDeleteButton && <button 
            className="button-delete"
            onClick={this.handleGoNext}>
              <FormattedMessage
                id="RequestedTimeOff.deleteRTO"
                defaultMessage="Cancel & Delete Request"
              />
            </button>}
            <styled.StatusLabel color={this.state.statusColor} backgroundColor={this.state.statusBackColor}>
              {statusInfo}
            </styled.StatusLabel>
          </div>}
        </ModalWrapper>
       </div>
    ); 
  }
}

RequestedTimeOff.propTypes = {
  //userCookie: PropTypes.shape({}).isRequired,
  //costCenterData: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (RequestedTimeOff);

