import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@shyft/acorns';
import { parsePhoneNumber } from 'libphonenumber-js';
import Pinput from './Pinput/Pinput';

const PhoneConfirmation = ({ _pinChange, value }) => {
  const phoneNumber = parsePhoneNumber(`+${value}`);

  return (
    <>
      <TextInput
        disabled
        value={phoneNumber.formatNational()}
        icon="./icons/phone-icon.svg"
      />

      <section className="login__form-pin-input">
        <Pinput inputChange={_pinChange} />
      </section>
    </>
  );
};

export default PhoneConfirmation;

PhoneConfirmation.propTypes = {
  value: PropTypes.string.isRequired,
  _pinChange: PropTypes.func.isRequired,
};
