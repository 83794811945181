import React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import ProfileInputText from '../ProfileInputText/ProfileInputText';


import ProfileInputWrap from '../ProfileInputWrap/ProfileInputWrap';

import './ProfileSkills.scss';


class ProfileSkills extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userSkillIds: null,
      locationSkills: null,
    };

    this._isMounted = false;
    
  }

  componentDidMount () {
    const { locationSkills, user, } = this.props;

    this._isMounted = true;
    this.setState({ locationSkills, locationSkills});
    
    if(user) {
      getUserInfoNonAdmin(user.user_id, user.location_id).then((response) => {
        const output = this.getUserSkills(response.data.user.skill_ids);
        const msg = `user: ${user.user_id}, location: ${user.location_id}, ${output}`;
        console.log(msg);
        if(this._isMounted) {
          this.setState({userSkillIds: response.data.user.skill_ids});
        }
      }).catch((error) =>{
         const msg = `ProfileSkills.componentDidMount: call getUserInfoNonAdmin failed. error: ${error}`;
         console.log(msg);
      });
    }
  }

  componentDidUpdate (prevProps) {
    const { locationSkills, user, } = this.props;
    const { userSkillIds, } = this.state;

    if(prevProps.locationSkills !== locationSkills) {
      console.log('here');
      if(locationSkills && locationSkills.length > 0) {
        this.setState({ locationSkills: locationSkills});
      }
    }

    if(prevProps.user !== user) {
      if(user) {
        if(!userSkillIds) {
          getUserInfoNonAdmin(user.user_id, user.location_id).then((response) => {
            if(this._isMounted) {
              this.setState({userSkillIds: response.data.user.skill_ids});
            }
          }).catch((error) =>{
             const msg = `ProfileSkills.componentDidMount: call getUserInfoNonAdmin failed. error: ${error}`;
             console.log(msg);
          });
        }
      }
    }

  }

  componentWillUnmount () {
    this._isMounted = false;
  };

  getUserSkills = (userSkillIds) => {
    const { locationSkills, } = this.props;
    let skills = '';
    if(!locationSkills || locationSkills.length === 0) {
      return 'No location skills';
    }
    if(!userSkillIds || userSkillIds.length === 0) {
      return 'No user skill ids';
    }
    for(let i=0; i<userSkillIds.length; i++) {
      for(let j=0; j<locationSkills.length; j++) {
        if(userSkillIds[i] === locationSkills[j].id) {
          skills += `user skill id: ${userSkillIds[i]} ${locationSkills[j].skill_content},`;
        }
      }
    }
    return skills;
  };

  renderUserSkills = () => {
    const { locationSkills,} = this.props;
    const { userSkillIds, } = this.state;

    let userSkills = [];
    if(locationSkills && locationSkills.length > 0 && userSkillIds && userSkillIds.length > 0) {
      for(let i=0; i<userSkillIds.length; i++) {
        for(let j=0; j<locationSkills.length; j++) {
          if(userSkillIds[i] === locationSkills[j].id) {
            userSkills.push(locationSkills[j])
          }
        }
      }
    }
    this.sortSkillContentWithNullValues(userSkills, true);
    if(userSkills.length > 0) {
      const skills = userSkills.map((skill) => (
        <label className="profile-skills__skill">
          {skill.skill_content}
        </label>
      ));
      if(skills && skills[0]) {
        return skills;
      }else {
        return null;
      }
    }else {
      return null;
    }
  }

  sortSkillContentWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  render () {
    const { icon, placeholder, } = this.props;

    const results = this.renderUserSkills();
    if(results) {
      return (
        <div className="profile-skills">
          <div className="profile-skills__wrap">
            <img className="profile-skills__icon" src={icon} alt="Skill Icon" />
            <label className="profile-skills__label">{results}</label>
          </div>
        </div>
      );
    }

    return (
       <ProfileInputText
          icon={icon}
          name="skills"
          placeholder={placeholder}
          inputValue=''
          disabled={true}
        />
    );
  }
}



ProfileSkills.propTypes = {
  _changeHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  verifiedStatus: PropTypes.string,
};

ProfileSkills.defaultProps = {
  error: '',
  verifiedStatus: '',
  disabled: false,
};

//export default ProfileSkills;
export default injectIntl (ProfileSkills);
