import styled from 'styled-components';

const DateTimeBtn = styled.button`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.border};

  &:disabled {
    cursor: default;
  }
`;

const DateTimeSection = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
  font-size: 12px;
  min-width: 250px;
  text-align: left;
  padding: 0.66rem 1rem;

  &:last-child {
    border-left: 1px solid ${({ theme }) => theme.palette.border};
  }

  .icon-calendar, .icon-clock {
    font-size: 15px;
    color: ${({ theme }) => theme.palette.border};
  }
`;

const DateTimeText = styled.span`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
  font-size: 1.14rem;

  ${DateTimeBtn}:disabled & {
    color: ${({ theme }) => theme.palette.label};
  }
`;

export default {
  DateTimeBtn,
  DateTimeSection,
  DateTimeText,
};
