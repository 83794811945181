import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  RadioGroup,
  RadioOption,
} from '_components/GeneralComponents/RadioGroup';
import { ExistingAccountComponent } from './components/ExistingAccountComponent/ExistingAccountComponent';

import './ExistingAccountsComponent.scss';

class ExistingAccountsComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    const { users } = this.props;

    this.state = {
      currentPhoneNumber: users[0].phone_number,
    };
  }

  _handleChange = (event) => {
    this.setState({
      currentPhoneNumber: event.target.value,
    });
  };

  _renderAccounts = users => users.map(element => (
    <RadioOption
      key={element.phone_number}
      name="existingAccounts"
      value={element.phone_number}
      onChange={this._handleChange}
    >
      <ExistingAccountComponent user={element} value={element.phone_number} />
    </RadioOption>
  ));

  render() {
    const { users, _signup, _updateEmail } = this.props;
    const { currentPhoneNumber } = this.state;

    return (
      <article className="existing-accounts__container">
        <h2 className="existing-accounts__header">
          <FormattedMessage
            id="ExistingAccountsComponent.header"
            defaultMessage="It looks like there are some existing accounts associated with your information."
          />
        </h2>
        <h4 className="existing-accounts__sub-header">
          <FormattedMessage
            id="ExistingAccountsComponent.subHeader"
            defaultMessage="Do any of these phone numbers match yours? If so, we'll verify it and add your email to the existing account."
          />
        </h4>
        <RadioGroup
          name="existingAccounts"
          value={currentPhoneNumber}
          onChange={this._handleChange}
        >
          {this._renderAccounts(users)}
        </RadioGroup>
        <section className="form-actions">
          <button
            onClick={(e) => {
              e.preventDefault();
              _signup();
            }}
            className="button button-primary button-inverse signup-button"
            type="button"
          >
            <FormattedMessage
              id="ExistingAccountsComponent.declineButton"
              defaultMessage="I Don't See My Number"
            />
          </button>
          <button
            className="button button-primary"
            onClick={() => {
              _updateEmail(currentPhoneNumber);
            }}
            type="button"
          >
            <FormattedMessage
              id="ExistingAccountsComponent.submitButton"
              defaultMessage="Submit"
            />
          </button>
        </section>
      </article>
    );
  }
}

export { ExistingAccountsComponent };

ExistingAccountsComponent.propTypes = {
  users: PropTypes.shape([]).isRequired,
  _signup: PropTypes.func.isRequired,
};
