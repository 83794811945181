import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { signUpConfirmation } from '_helpers/api-calls';
import { getQueryStringParameter } from '_helpers/browser-interactions';

import './SignupConfirmationPage.scss';
import 'LoginPage/LoginPage.scss';

class SignupConfirmationPagei18n extends React.Component {
  state = {
    loading: true,
    path: null,
    error: false,
    errorMessage: '',
  };

  messages = defineMessages({
    error1: {
      id: 'SignupConfirmationPage.error1',
      defaultMessage: 'Oops! This confirmation code is not authorized to confirm an account. Please try another.',
    },
    error2: {
      id: 'SignupConfirmationPage.error2',
      defaultMessage: 'Something went wrong while processing the information. Please try again.',
    },
  })

  componentDidMount() {
    this._confirmAccount(getQueryStringParameter('confirmation_token'));
  }

  login = () => {
    this.setState({
      path: {
        pathname: '/login',
      },
    });
  };

  _confirmAccount = () => {
    const { intl } = this.props;
    signUpConfirmation(getQueryStringParameter('confirmation_token')).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
          });
        }
      },
      (error) => {
        if (error.response.status === 422) {
          this.login();
        } else if (
          error.response.status === 401
          || error.response.status === 403
        ) {
          this.setState({
            loading: false,
            error: true,
            errorMessage: intl.formatMessage(this.messages.error1),
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMessage: intl.formatMessage(this.messages.error2),
          });
        }
      },
    );
  };

  render() {
    const {
      loading, error, errorMessage, path,
    } = this.state;
    const CurrentComponent = () => {
      if (loading) {
        return (
          <div className="loading">
            <img
              className="loading-gif"
              src="/loader.gif"
              alt="Loading spinner"
            />
          </div>
        );
      }
      if (error && !loading) {
        return (
          <div className="confirmation-container">
            <h1 className="login__header">Error!</h1>
            <p className="confirmation-description">
              <FormattedMessage
                id="SignupConfirmationPage.loginError"
                defaultMessage="{error} or {loginButton}"
                values={{
                  error: errorMessage,
                  loginButton: (
                    <button className="login__link" onClick={this.login} type="button">
                      <FormattedMessage
                        id="SignupConfirmationPage.loginButton"
                        defaultMessage="Login"
                      />
                    </button>
                  ),
                }}
              />
            </p>
          </div>
        );
      }
      return (
        <div className="confirmation-container">
          <h1 className="login__header">
            <FormattedMessage
              id="SignupConfirmationPage.successHeader"
              defaultMessage="Success!"
            />
          </h1>
          <p className="confirmation-description">
            <FormattedMessage
              id="SignupConfirmationPage.confirmedAccountMessage"
              defaultMessage=" Your account is confirmed! Now please {loginButton}"
              values={{
                loginButton: (
                  <button href="" className="login__link" onClick={this.login} type="button">
                    <FormattedMessage
                      id="SignupConfirmationPage.loginButton"
                      defaultMessage="Login"
                    />
                  </button>
                ),
              }}
            />
          </p>
        </div>
      );
    };
    if (path) {
      return <Redirect to={path} />;
    }
    return (
      <>
        <article className="login">
          <CurrentComponent />
        </article>
      </>
    );
  }
}

const SignupConfirmationPage = injectIntl(SignupConfirmationPagei18n);

export { SignupConfirmationPage };
