export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const COUNTERPART_URL = process.env.REACT_APP_COUNTERPART_URL;
export const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
export const COOKIE_DOMAIN = process.env.NODE_ENV === 'production' ? 'myshyft.com' : undefined;
export const API_SERVER_VERSION = process.env.REACT_APP_API_SERVER_VERSION;
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const ModalTypes = {
  MY_SHIFT: 'MY_SHIFT',
  MY_COVERING_SHIFT: 'MY_COVERING_SHIFT',
  AVAILABLE_SHIFT: 'AVAILABLE_SHIFT',
  COVER_SHIFT: 'COVER_SHIFT',
  DELETE_SHIFT: 'DELETE_SHIFT',
  CANCEL_SHIFT: 'CANCEL_SHIFT',
  POST_SHIFT: 'POST_SHIFT',
  CALL_OFF_SHIFT: 'CALL_OFF_SHIFT',
  CALLED_OFF: 'CALLED_OFF',
  REQUEST_TIME_OFF: 'REQUEST_TIME_OFF',
  REQUESTED_TIME_OFF: 'REQUESTED_TIME_OFF',
  SCHEDULED_SHIFT: 'SCHEDULED_SHIFT',
  SHIFT_FOR_CALL_OFF: 'SHIFT_FOR_CALL_OFF',
  SHIFT_DETAILS: 'SHIFT_DETAILS',
  DELETE_COMMENT: 'DELETE_COMMENT'
};

export const userCookieKeys = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  LOCATION_ID: 'location_id',
  PROFILE_IMAGE: 'profile_image',
  TIME_ZONE: 'time_zone',
  USER_ID: 'user_id',
};

export const API_SECTIONS = ['index', 'setup_actions'];

export * from './shift-status';

export * from './mock.app.data';

export * from './shift-names';
