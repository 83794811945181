import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ConfirmShiftTemplate } from '_components/ModalComponents/_templates/ConfirmShiftTemplate';

class Confirm extends React.PureComponent {
  render() {
    const {
      date, times, commentsValue, userCookie, reachValue, _toggleScreen, 
      submitting, _submitPost, _setAppState, shifts, jobTitle, channel_id, channels,
    } = this.props;

    const selectedShift = {
      start_at: times[0]
        .year(date.year())
        .month(date.month())
        .date(date.date()),
      end_at: times[1]
        .year(date.year())
        .month(date.month())
        .date(date.date()),
      content: commentsValue,
      owner: { id: userCookie.user_id },
      channel_name: reachValue,
    };

    let members = 0;
    if(channel_id && channels) {
      const twoTypeChannels = channels.location_feed.concat(channels.custom_feed);
      const allTypeChannels = twoTypeChannels.concat(channels.region_feed);
      if(channel_id && allTypeChannels.length > 0) {
        const idx = allTypeChannels.findIndex(
          (channel) => channel.id === channel_id
        );
        if(idx >= 0) {
          members = allTypeChannels[idx].member_count;
        }
      }
    }
    const postToTeamMemebers = (<FormattedMessage
                  id="Confirm.toTeamMember"
                  defaultMessage="Post to {number, plural, one {{number} Team Member} other {{number} Team Memebers}}" 
                  values={{number: members}}/>);

    return (
      <>
        <h1>
          <FormattedMessage
            id="Confirm.confirmPostLabel"
            defaultMessage="Are you sure you want to post this shift?"
          />
        </h1>
        <ConfirmShiftTemplate
          {...this.props}
          _setAppState={_setAppState}
          shifts={shifts}
          selectedShift={selectedShift}
          userCookie={userCookie}
        />
        <div className="modal-components__row">
          <div className="modal-components__col modal-components__col--submit">
            <button
              className="modal-components__submit-btn modal-components__submit-btn--back"
              onClick={_toggleScreen}
              type="button"
            >
              <FormattedMessage
                id="Confirm.editPost"
                defaultMessage="Go Back and Edit"
              />
            </button>
            <button
              className="modal-components__submit-btn modal-components__submit-btn--post"
              disabled={submitting}
              onClick={_submitPost}
              type="button"
            >
              {/*<FormattedMessage
                id="Confirm.confirmPost"
                defaultMessage="Post"
              />*/}
              {postToTeamMemebers}
            </button>
          </div>
        </div>
      </>
    );
  }
}

Confirm.propTypes = {
  _submitPost: PropTypes.func.isRequired,
  _toggleScreen: PropTypes.func.isRequired,
  _setAppState: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  userCookie: PropTypes.shape({}).isRequired,
  shifts: PropTypes.shape([]).isRequired,
};

export default Confirm;
