import React from 'react';
import PropTypes from 'prop-types';
//import {FormattedMessage,} from 'react-intl';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { shiftStatuses, shiftStatus } from '_constants';

import StatusText from '_components/GeneralComponents/ShiftStatus/StatusText/StatusText';

/*
const StatusMessage = ({ shift, userCookie }) => {
  const status = shiftStatus(shift, userCookie);
  const ownerFullName = `${shift.owner.first_name} ${shift.owner.last_name}`;
  const covererFullName = shift.coverer ? `${shift.coverer.first_name} ${shift.coverer.last_name}` : '';
  let statusOwner = '';

  const vtoOwnerString = (
    <FormattedMessage
      id="StatusMessage.coveringVto"
      defaultMessage="{name} has offered to take VTO"
      values={{
        name: covererFullName,
      }}
    />
  );

  const yourOwnerString = (
    <FormattedMessage
      id="StatusMessage.coveringForYou"
      defaultMessage="{name} is covering for you"
      values={{
        name: covererFullName,
      }}
    />
  );

   const yourCalledOffString = (
    <FormattedMessage
      id="StatusMessage.callcedOff"
      defaultMessage="{name} has called off this shift."
      values={{
        name: ownerFullName,
      }}
    />
  );

  switch (status) {
    case shiftStatuses.VTO_APPROVED:
    case shiftStatuses.VTO_PENDING:
      statusOwner = vtoOwnerString;
      break;

    case shiftStatuses.VTO_REJECTED:
      statusOwner = vtoOwnerString;
      break;
    case shiftStatuses.AVAILABLE:
    case shiftStatuses.YOUR_POSTED:
      statusOwner = ownerFullName;
      break;

    case shiftStatuses.YOUR_COVERED:
    case shiftStatuses.YOUR_APPROVED:
    case shiftStatuses.YOUR_PENDING:
    case shiftStatuses.YOUR_REJECTED:
      statusOwner = yourOwnerString;
      break;

    case shiftStatuses.COVERING_APPROVED:
    case shiftStatuses.COVERING_PENDING:
    case shiftStatuses.COVERING_REJECTED:
      statusOwner = ownerFullName;
      break;
    case shiftStatuses.YOUR_CALLED_OFF:
      statusOwner = ownerFullName;
      break;
    default:
      break;
  }

  return (
    <div>
      <p className="shift-card__status-owner">{statusOwner}</p>
      <p className="shift-card__status-message">
        <StatusText
          selectedShift={shift}
          userCookie={userCookie}
        />
      </p>
    </div>
  );
};

StatusMessage.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  shift: PropTypes.shape({}).isRequired,
};

export default StatusMessage;

*/

class StatusMessage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentStatus: '',
      currentShift: null,
    }

    this.messages = defineMessages({
      calledOff: {
        id: 'StatusMessage.callOff',
        defaultMessage: 'has called off this shift.',
      },
    });

    this._isMounted = false;
  }

  componentDidMount() {
    const {shift, userCookie} = this.props;


    this._isMounted = true;
    this.setState({currentShift: shift});
  }

  componentDidUpdate(prevProps) {
    const {shift, userCookie} = this.props;
      //const msg = `shift updated -- id: ${shift.id}, trade_status = ${shift.trade_status}`;
      //console.log(msg);
   
    if(prevProps.shift !== shift){
      this.setState({currentShift: shift});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const {shift, userCookie} = this.props;
    const {currentShift} = this.state;

    //const status = shiftStatus(shift, userCookie);
    //const ownerFullName = `${shift.owner.first_name} ${shift.owner.last_name}`;
    //const covererFullName = shift.coverer ? `${shift.coverer.first_name} ${shift.coverer.last_name}` : '';
    let statusOwner = '';
    let status = '';
    let ownerFullName = '';
    let covererFullName = '';

    if(shift && userCookie){
      status = shiftStatus(shift, userCookie);
      ownerFullName = `${shift.owner.first_name} ${shift.owner.last_name}`;
      covererFullName = shift.coverer ? `${shift.coverer.first_name} ${shift.coverer.last_name}` : '';
      if(shift.coverer) {
        covererFullName = `${shift.coverer.first_name} ${shift.coverer.last_name}`;
      }else {
        if(shift.cover_mode === 'multi_applicants_picked_by_manager' && shift.current_applicant_status) {
          covererFullName = `${userCookie.first_name} ${userCookie.last_name}`;
        }
      }

      const vtoOwnerString = (
        <FormattedMessage
          id="StatusMessage.coveringVto"
          defaultMessage="{name} has offered to take VTO"
          values={{
            name: covererFullName,
          }}
        />
      );

      const yourCoveringString = (
        <FormattedMessage
          id="StatusMessage.coveringForYou"
          defaultMessage="{name} is covering for you"
          values={{
            name: covererFullName,
          }}
        />
      );

      const yourRejectedString = (
        <FormattedMessage
          id="StatusMessage.deniedCoveringForYou"
          defaultMessage="{name} has been denied covering this shift"
          values={{
            name: covererFullName,
          }}
        />
      );

       const yourCalledOffString = (
        <FormattedMessage
          id="StatusMessage.callcedOff"
          defaultMessage="{name} has called off this shift."
          values={{
            name: ownerFullName,
          }}
        />
      );

      switch (status) {
        case shiftStatuses.VTO_APPROVED:
        case shiftStatuses.VTO_PENDING:
          statusOwner = vtoOwnerString;
          break;

        case shiftStatuses.VTO_REJECTED:
          statusOwner = vtoOwnerString;
          break;
        case shiftStatuses.AVAILABLE:
        case shiftStatuses.YOUR_POSTED:
          statusOwner = ownerFullName;
          break;

        case shiftStatuses.YOUR_COVERED:
          statusOwner = ownerFullName;
          break;
        case shiftStatuses.YOUR_APPROVED:
          statusOwner = ownerFullName;
          break;
        case shiftStatuses.YOUR_PENDING:
        case shiftStatuses.YOUR_REJECTED:
          statusOwner = ownerFullName;
          break;
        case shiftStatuses.COVERING_APPROVED:
        case shiftStatuses.COVERING_PENDING:
        case shiftStatuses.COVERING_REJECTED:
          statusOwner = ownerFullName;
          break;
        case shiftStatuses.YOUR_CALLED_OFF:
          statusOwner = ownerFullName;
          break;
        default:
          break;

      }
      return (
        <div>
          <p className="shift-card__status-owner">{statusOwner}</p>
          <p className="shift-card__status-message">
            <StatusText
              selectedShift={currentShift}
              userCookie={userCookie}
              applicants={this.props.applicants}
            />
          </p>
      </div>
      );
    }
    return null;
  }
}

StatusMessage.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  shift: PropTypes.shape({}).isRequired,
};

export default injectIntl(StatusMessage);
