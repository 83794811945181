import React from 'react';

import { RadioOption } from './RadioOption';

class RadioGroup extends React.PureComponent {
  onChange = event => {
    console.log('clicked', event);
  };

  render() {
    return React.Children.map(this.props.children, child => {
      if (child.type === RadioOption) {
        return React.cloneElement(child, {
          ...child.props,
          name: this.props.name,
          onChange: this.props.onChange,
          isChecked: this.props.value === child.props.value,
        });
      }
    });
  }
}

export { RadioGroup };
