import React from 'react';
import PropTypes from 'prop-types';

import { CloseModal } from '_components/ModalComponents/CloseModal';
import { coverShiftAction, getShift } from '_helpers/api-calls/shift-calls';
import { loadShifts } from '_helpers/app-state';
import { apiCallError } from '_helpers/errors';
import { removeShift, addShift, updateCoveredShift } from '_helpers/app-state';
import { CoverComments, CoverConfirm } from './screens';
import CoverComments2  from './screens/CoverComments2';
import CoverConfirm2  from './screens/CoverConfirm2';
import { ModalWrapper } from '../ModalWrapper';
import { getLocationCode, getLocaleCookie, checkForAuthentication } from '_helpers/cookies';

import './CoverShift.scss';

class CoverShift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmCover: false,
      error: null,
    };
    this.localStorageKey = '';
    this.myCovered = '';
  }


  componentDidMount () {
    const { userCookie, locationUserSkillIds, userPrivileges, locationSettings, } = this.props;

    this.localStorageKey = `${userCookie.user_id}-${userCookie.location_id}`;
    let msg = `CoverShift.componentDidMount: localStorageKey: ${this.localStorageKey}`;
    console.log(msg);
    this.myCovered = localStorage.getItem(this.localStorageKey);
    msg = `CoverShift.componentDidMount: localStorage.getItem: ${this.myCovered}`;
    console.log(msg);
  }

  _toggleScreen = () => {
    // Progress to next screen
    this.setState(prevState => ({
      confirmCover: !prevState.confirmCover,
    }));
  };

  _findShiftIndex = (id, shifts) => {
    let shiftIndex;

    shifts.forEach((element, index) => {
      if (element.id === id) {
        shiftIndex = index;
      }
    });

    return shiftIndex;
  }

  _handleCoveredShift = (id, response) => {
    const {
      _setAppState,
      selectedShift,
      shifts,
      userCookie,
    } = this.props;
    const shiftIndex = this._findShiftIndex(id, shifts);

    // check for schedule element & call get shift API if the shift is not present in the response payload
    if (response.data.schedule_element) {
      //updateCoveredShift(response.data.schedule_element, shifts, _setAppState);
      addShift(response.data.schedule_element, shifts, _setAppState);
      removeShift(shiftIndex, _setAppState);
    } else {
      getShift(selectedShift.id).then((payload) => {
        //updateCoveredShift(payload.data.schedule_element, shifts, _setAppState);
        addShift(payload.data.schedule_element, shifts, _setAppState);
        removeShift(shiftIndex, _setAppState);
    
        //for parent card shift. need to load child card after covered
        //if(payload.data.schedule_element.parent_card) {
          const location_id = getLocationCode();
          if (location_id && userCookie.user_id) {
            //_setAppState({ key: 'loading', statement: { shifts: { $set: true } } });
            loadShifts(userCookie.user_id, location_id, _setAppState);
          }
        //}
        //Save appliced shifts in local storage
        if(this.myCovered && this.myCovered.length > 0) {
          this.myCovered += payload.data.schedule_element.id.toString() + ',';
        }else {
          this.myCovered = payload.data.schedule_element.id.toString() + ',';
        }
        //localStorage.setItem(this.localStorageKey, '');
        localStorage.setItem(this.localStorageKey, this.myCovered);
        const msg = `CoverShift.handleCoverShift, saved to local storage. key: ${this.localStorageKey}, value: ${this.myCovered}`;
        console.log(msg);
      }).catch((error) => {
        apiCallError(error);
      });
    }
  }

  _coverShiftAction = () => {
    const { selectedShift, currentLocation, _toggleModal } = this.props;

    this.setState({
      error: null,
    });

    coverShiftAction({
      shift_id: selectedShift.id,
      config_id: (currentLocation.configuration ? currentLocation.configuration.id : null),
    })
      .then((response) => {
        // Success
        _toggleModal();
        this._handleCoveredShift(selectedShift.id, response);
      })
      .catch((error) => {
        apiCallError(error);
        let errorMessage = null;


        if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.forbidden) {
          errorMessage = error.response.data.errors.forbidden[0];
        }
        this.setState({
          error: errorMessage,
        });
      });
  };

  render() {
    const { _toggleModal, selectedShift, userPrivileges, } = this.props;
    const { confirmCover, error } = this.state;
    const comments = (
      <CoverComments {...this.props} _toggleScreen={this._toggleScreen} />
    );
    const comments2 = (
      <CoverComments2 {...this.props} _toggleScreen={this._toggleScreen} />
    );
    // this is old confirm screen including customer text
    const confirm = (
      <CoverConfirm
        {...this.props}
        errorMessage={error}
        _coverShiftAction={this._coverShiftAction}
        _toggleScreen={this._toggleScreen}
      />
    );
    //this is new confirm screen, no customer text, including skills
    const confirm2 = (
      <CoverConfirm2
        {...this.props}
        errorMessage={error}
        _coverShiftAction={this._coverShiftAction}
        _toggleScreen={this._toggleScreen}
      />
    );

    return (
      <div className="cover-shift">
        <ModalWrapper>
          <CloseModal _toggleModal={_toggleModal} dark />
          <div className="modal-components__container">
            {/*confirmCover ? confirm : comments*/} 
            {/*confirmCover ? confirm : comments2*/}
            {confirmCover ? confirm2 : comments2}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

CoverShift.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
  _setAppState: PropTypes.func.isRequired,
  selectedShift: PropTypes.object,
  userCookie: PropTypes.object.isRequired,
  shifts: PropTypes.shape([]).isRequired,
};

CoverShift.defaultProps = {
  selectedShift: {},
};

export { CoverShift };
