import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';

import { postShift, postShiftInventory, getShift } from '_helpers/api-calls/shift-calls';
import { deleteShift, cancelShift } from "_helpers/api-calls/shift-calls";
import { apiCallError } from '_helpers/errors';
import { formatDateforApi, getDefaultTimezone} from '_helpers/date-time';
import {
  createDateString,
  createTimeRangeString,
  isNextDay,
} from '_helpers/formatting';
import { addShift, removeShift } from '_helpers/app-state';

import { shiftNames } from '_constants';
import { ModalWrapper } from '../ModalWrapper';
import { CloseModal } from '_components/ModalComponents/CloseModal';
import { Post, Confirm } from './screens';
import { ShiffyHeader } from './components';
import { DataNames } from './constants';
import './PostShift.scss';

class PostShiftI18n extends React.Component {
  constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      dateString: {
        id: 'PostShift.dateString',
        defaultMessage: 'Date',
      },
      timeRangeString: {
        id: 'PostShift.timeRangeString',
        defaultMessage: 'Time',
      },
      pastShiftMessage: {
        id: 'PostShift.pastShiftMessage',
        defaultMessage: 'You cannot post a shift that starts in the past!',
      },
    });

    this.state = {
      commentsValue: '',
      confirmPost: false,
      date: {},
      dateString: formatMessage(this.messages.dateString),
      reachValue: 'My Team',
      channel_id: '',
      submitting: false,
      times: [],
      timeRangeString: formatMessage(this.messages.timeRangeString),
      jobTitle: '',
      tip: '',
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { selectedShift, channels } = this.props;

    this._isMounted = true;

    if (selectedShift) {
      const start = moment(selectedShift.start_at);
      const end = moment(selectedShift.end_at);

      this._updateState(DataNames.DATE, start);
      this._updateState(DataNames.TIMES, [start, end]);
      if(channels) {
        const idx = channels.region_feed.findIndex(channel => channel.id === selectedShift.channel_id);
        if(idx >= 0) {
          this.setState({
            reachValue: channels.region_feed[idx].channel_name,
            channel_id: channels.region_feed[idx].id,
          });
        }else {
          const idx1 = channels.custom_feed.findIndex(channel => channel.id === selectedShift.channel_id);
          if(idx1 >= 0) {
            this.setState({
              reachValue: channels.custom_feed[idx1].channel_name,
              channel_id: channels.custom_feed[idx1].id,
            });
          }else {
            this.setState({
              reachValue: channels.location_feed[0].channel_name,
              channel_id: channels.location_feed[0].id,
            });
          }
        }
      }
      if(this._isMounted) {
        this.setState({jobTitle: selectedShift.primary_job});
      }
      if(this._isMounted) {
        const tipText = selectedShift.tip_amount > 0 ? `$${selectedShift.tip_amount}` : '$';
        this.setState({tip: tipText});
      }
    } else {
      this._updateState(DataNames.DATE, moment());
      if(channels && channels.location_feed[0]) {
        this.setState({reachValue: channels.location_feed[0].channel_name,
                        channel_id: channels.location_feed[0].id});
      }
      if(this._isMounted) {
        this.setState({tip: '$'});
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedShift } = this.props;
    if(prevProps.selectedShift !== selectedShift) {
      console.log('here');
    }
  };


  componentWillUnmount() {
    this._isMounted = false;
  };

  _updateState = (action, data) => {

    if(!this._isMounted){
      return;
    }
    switch (action) {
      case DataNames.COMMENTS:
        this.setState({ commentsValue: data });
        break;
      case DataNames.REACH:
        this.setState({
          reachValue: data.reachValue,
          channel_id: data.channel_id,
        });
        break;
      case DataNames.DATE:
        this.setState({ date: data, dateString: createDateString(data) });
        break;
      case DataNames.TIMES:
        this.setState({
          times: data,
          timeRangeString: createTimeRangeString(data),
        });
        break;
      case DataNames.JOB:
        this.setState({jobTitle: data})
        break;
      case DataNames.TIP:
        const firstChar = data.toString().charAt(0);
        if(firstChar !== '$') {
          console.log('here');
          const str = `$${data}`;
          this.setState({tip: str})
        }else {
          this.setState({tip: data})
        }
        break;
      default:
        break;
    }
  };

  _canProgress = () => {
    const { date, times, commentsValue, jobTitle } = this.state;
    const isDate = date !== null;
    const isTimes = times.length > 0;
    const isComments = commentsValue !== '';
    //const canProgress = isComments && isDate && isTimes;
    const isJobTitle = jobTitle && jobTitle.length > 0;
    const canProgress = isDate && isTimes && isJobTitle;

    return canProgress;
  };

  _toggleScreen = () => {
    const { intl: { formatMessage } } = this.props;
    const { times, date } = this.state;

    if (this._canProgress()) {
      times[0]
        .year(date.year())
        .month(date.month())
        .date(date.date());

      if (isNextDay([times[0], moment()])) {
        // Progress to next screen
        this.setState(prevState => ({
          confirmPost: !prevState.confirmPost,
        }));
      } else if (times[0] && isNextDay([moment(), times[0]])) {
        alert(formatMessage(this.messages.pastShiftMessage));
      }
    }
  };

  _createDateString = (date) => {
    if (date) {
      return moment(date).format('MMMM D');
    }

    return 'Date';
  };

  _createTimeRangeString = (times) => {
    if (times && times.length > 0) {
      const startTimeText = moment(times[0]).format('LT');
      const endTimeText = moment(times[1]).format('LT');
      const startBeforeEnd = isNextDay(times);

      return `${startTimeText} to ${endTimeText} ${
        startBeforeEnd ? '(Next Day)' : ''
      }`;
    }

    return 'Time';
  };

  _submitPost = () => {
    const {
      times, date, commentsValue, channel_id, jobTitle, tip,
    } = this.state;
    const {
      _toggleModal, userCookie, selectedShift, channels,
    } = this.props;

    this.setState({ submitting: true });

    times[0]
      .year(date.year())
      .month(date.month())
      .date(date.date());

    times[1]
      .year(date.year())
      .month(date.month())
      .date(date.date());

    const timeZone = getDefaultTimezone();
    const endAt = isNextDay(times) ? times[1].add(1, 'days') : times[1];
    const start = moment.tz(times[0], timeZone).format();
    const end = moment.tz(endAt, timeZone).format();
    const tip_amount = tip.substring(1, tip.length);
    console.log('start_at:' + start);
    console.log('endAt:' + end);

    /*
    const shiftData = {
      start_at: formatDateforApi(times[0]),
      end_at: formatDateforApi(
        isNextDay(times) ? times[1].add(1, 'days') : times[1],
      ),
      shift_content: commentsValue,
      location_id: userCookie.location_id,
      channel_id,
    };
    */

    //This is most recent requirement
    const shiftData = {
      start_at: start,
      end_at: end,
      shift_content: commentsValue,
      location_id: userCookie.location_id,
      channel_id: channel_id,
      cached_primary_job: jobTitle,
      tip_amount: tip_amount,
    };

    if (selectedShift && selectedShift.name === shiftNames.SHIFT_INVENTORY) {
      // Make shift inventory call
      /*
      postShiftInventory({
        inventory_id: selectedShift.id,
        shift_content: shiftData.shift_content,
        channel_id: shiftData.channel_id,
      }).then((response) => {
          // Success
          const { _setAppState, shifts } = this.props;

          if (response.data.schedule_element) {
            const shiftIndex = shifts.findIndex(shift => shift.id === response.data.schedule_element.id);
            removeShift(shiftIndex, _setAppState);
            addShift(response.data.schedule_element, shifts, _setAppState);

          } else {
            getShift(selectedShift.id).then((payload) => {
              const shiftIndex = shifts.findIndex(shift => shift.id === payload.data.schedule_element.id);
              removeShift(shiftIndex, _setAppState);
              addShift(payload.data.schedule_element, shifts, _setAppState);
              
            }).catch((error) => {
              apiCallError(error);
            });
          }
          _toggleModal();
          this.setState({ submitting: false });
        })
        .catch((error) => {
          // Failure
          apiCallError(error);
          this.setState({ submitting: false });
        });
        */
      deleteShift(selectedShift.location_id, selectedShift.id).then(() => {
        const { _setAppState, shifts } = this.props;
        const index = shifts.findIndex(
          (shift) => shift.id === selectedShift.id
        );
        if(index >= 0) {
          removeShift(index, _setAppState);
        }
        postShift(shiftData).then((response) => {
          const { _setAppState, shifts } = this.props;
          addShift(response.data.schedule_element, shifts, _setAppState);
          _toggleModal();
          this.setState({ submitting: false });
        }).catch((error1) => {
          const msg = `PostShift._submitPost: failed on calling postShift. error: ${error1}`;
          console.log(msg);
          this.setState({ submitting: false });
        });

      })
      .catch((error) => {
        const msg = `PostShift._submitPost: failed on calling deleteShift. error: ${error}`;
        console.log(msg);
        this.setState({ submitting: false });
      });
    } else {
      // Make normal post shift call
      postShift(shiftData)
        .then((response) => {
          // Success
          const { _setAppState, shifts } = this.props;

          addShift(response.data.schedule_element, shifts, _setAppState);
          _toggleModal();
          this.setState({ submitting: false });
        })
        .catch((error) => {
          // Failure
          apiCallError(error);
          this.setState({ submitting: false });
        });
    }
  };

  render() {
    const { confirmPost, submitting } = this.state;
    const {
      _toggleModal, channels, userCookie, locale, selectedShift, costCenterData, locationSettings,
    } = this.props;
    const post = (
      <Post
        {...this.state}
        _canProgress={this._canProgress}
        _handleSubmit={this._toggleScreen}
        _updateState={this._updateState}
        _toggleModal={_toggleModal}
        channels={channels}
        userCookie={userCookie}
        locale={locale}
        disablePicker={selectedShift ? selectedShift.name === shiftNames.SHIFT_INVENTORY : false}
        selectedShift={selectedShift}
        costCenterData={costCenterData}
        locationSettings={locationSettings}
      />
    );
    const confirm = (
      <Confirm
        {...this.state}
        {...this.props}
        _submitPost={this._submitPost}
        _toggleScreen={this._toggleScreen}
        submitting={submitting}
        userCookie={userCookie}
      />
    );

    return (
      <div className="post-shift">
        {/*<ShiffyHeader _toggleModal={_toggleModal} />*/}
        <ModalWrapper paddingTop="2rem">
          <CloseModal _toggleModal={_toggleModal} dark />
          <div className="modal-components__container">
            {confirmPost ? confirm : post}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

PostShiftI18n.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  _toggleModal: PropTypes.func.isRequired,
  channels: PropTypes.shape({}).isRequired,
  userCookie: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  shifts: PropTypes.shape([]).isRequired,
};

const PostShift = injectIntl(PostShiftI18n);

export { PostShift };
