import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { StatusIcon } from '_components/GeneralComponents/ShiftStatus';
import { ModalTypes } from '_constants';
import {
  shiftStatuses,
  shiftOwner,
  shiftStatus,
} from '_constants/shift-status';
import { isNextDay } from '_helpers/formatting';
import {
  ActionButton, StatusMessage, CardAddress, Badge, RegionalBadge, CardProfileThumb, CardPosition,
} from './components';

import { getShiftApplicants } from '_helpers/api-calls/shift-calls';

import './ShiftCard.scss';

class ShiftCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        timer: null,
        enableCallOff: false,
        showCallOffLabel: false,
        firstIncentiveAmount: '',
        findCallOffReason1: false,
        findCallOffReason2: false,
        currentShift: null,
        applicants: [],

        debug: false,
    };

    //this._handleCardClick = this._handleCardClick.bind(this);
    //this.handleCallOffClick = this.handleCallOffClick.bind(this);

    this._isMounted = false;
    this.winTop = '100px';
    this.winLeft = '100px';
  }

  componentDidMount(){
    const {shift, costCenterData} = this.props;

    this._isMounted = true;

    let myTimer = setInterval(this.tick, 10000); //unit: ms, 10000 = 10 seconds
    this.setState({timer: myTimer});
    
    //Team shift, get applicant info to see who appliced to cover
    if(shift.name === 'shift') {
      getShiftApplicants(shift.id).then(({ data }) => {
        this.setState({applicants: data.applicants});
      }).catch((error) =>{
        const msg = `ShiftCard.componentDidMount: ${shift.id}: getShiftApplicants returns error = ${error}`;
        console.log(msg);
      });
    }


    if(shift.trade_status ==='called_off'){
      this.setState({showCallOffLabel: true});
      //console.log('called off shift: ' + shift.id);
    }

    this.setState({currentShift: shift});

    if(costCenterData && costCenterData.tag_groups){
      //const msg = `componentDidMount: ${costCenterData ? costCenterData.tag_groups.length: null}`;
      //console.log(msg);
      let abort = false;
      for(let i=0; i<costCenterData.tag_groups.length && !abort; i++) {
        if(costCenterData.tag_groups[i].group_type === 'xpo_cost_centers'){
          for(let j=0; j<costCenterData.tag_groups[i].tags.length && !abort; j++){
            if(costCenterData.tag_groups[i].tags[j].tag_key === shift.cost_center) {
              if(costCenterData.tag_groups[i].tags[j].incentives.length>0){
                for(let k=0; k<costCenterData.tag_groups[i].tags[j].incentives.length && !abort; k++){
                  /*
                   const msg = `tag ${j} incentives ${k}: 
                                    amount: ${costCenterData.tag_groups[i].tags[j].incentives[k].amount}
                                    id: ${costCenterData.tag_groups[i].tags[j].incentives[k].id}
                                    start_time: ${costCenterData.tag_groups[i].tags[j].incentives[k].start_time}
                                    end_time: ${costCenterData.tag_groups[i].tags[j].incentives[k].end_time}`;
                  console.log(msg);
                  */
                  //if shift's start date is between an incentive's date range
                  // display this incentive info, break the loop
                  const shiftStart = moment(shift.start_at);
                  const incentiveStart = moment(costCenterData.tag_groups[i].tags[j].incentives[k].start_time);
                  const incentiveEnd = moment(costCenterData.tag_groups[i].tags[j].incentives[k].end_time);
                  if(shiftStart.isBetween(incentiveStart, incentiveEnd)) {
                    let amount = costCenterData.tag_groups[i].tags[j].incentives[k].amount;
                    let newAmount = amount.replace('hr', 'HR');
                    this.setState({firstIncentiveAmount: newAmount});
                    abort = true; // for breaking all loops
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps){
    const {costCenterData, shift} = this.props;

    if(prevProps.costCenterData !== costCenterData){
      if(costCenterData && costCenterData.tag_groups){
        //const msg = `componentDidMount: ${costCenterData ? costCenterData.tag_groups.length: null}`;
        //console.log(msg);
        let abort = false;
        for(let i=0; i<costCenterData.tag_groups.length && !abort; i++) {
          if(costCenterData.tag_groups[i].group_type === 'xpo_cost_centers'){
            for(let j=0; j<costCenterData.tag_groups[i].tags.length && !abort; j++){
              if(costCenterData.tag_groups[i].tags[j].tag_key === shift.cost_center){
                const msg = `shift.cost_center: ${shift.cost_center} found tag index: ${j} `;
                console.log(msg)
                if(costCenterData.tag_groups[i].tags[j].incentives.length>0){
                  for(let k=0; k<costCenterData.tag_groups[i].tags[j].incentives.length && !abort; k++){
                    /*
                     const msg = `tag ${j} incentives ${k}: 
                                      amount: ${costCenterData.tag_groups[i].tags[j].incentives[k].amount}
                                      id: ${costCenterData.tag_groups[i].tags[j].incentives[k].id}
                                      start_time: ${costCenterData.tag_groups[i].tags[j].incentives[k].start_time}
                                      end_time: ${costCenterData.tag_groups[i].tags[j].incentives[k].end_time}
                                      shift_start: ${shift.start_at}`;
                    console.log(msg);
                    */

                    //if shift's start date is between an incentive's date range
                    // display this incentive info, break the loop
                    const shiftStart = moment(shift.start_at);
                    const incentiveStart = moment(costCenterData.tag_groups[i].tags[j].incentives[k].start_time);
                    const incentiveEnd = moment(costCenterData.tag_groups[i].tags[j].incentives[k].end_time);
                    if(shiftStart.isBetween(incentiveStart, incentiveEnd)) {
                      
                       const msg = `tag ${j} incentives ${k}: 
                                    amount: ${costCenterData.tag_groups[i].tags[j].incentives[k].amount}
                                    id: ${costCenterData.tag_groups[i].tags[j].incentives[k].id}
                                    start_time: ${costCenterData.tag_groups[i].tags[j].incentives[k].start_time}
                                    end_time: ${costCenterData.tag_groups[i].tags[j].incentives[k].end_time}
                                    shift_start: ${shift.start_at}
                                    shift_id: ${shift.id}
                                    shift_owner: ${shift.owner.first_name} ${shift.owner.last_name}`;
                      console.log(msg);
                      
                      let amount = costCenterData.tag_groups[i].tags[j].incentives[k].amount;
                      //let newAmount = amount.replace('hr', 'HR'); // backend takes care of this
                      this.setState({firstIncentiveAmount: amount});
                      abort = true; // for breaking all loops
                    }
                  }
                }
              } //End of if(costCenterData.tag_groups[i].tags[j].tag_key === shift.cost_center)
            }
          }
        }
      }
    }

    if(prevProps.shift !== shift) {
      this.setState({currentShift: shift});
      if(shift.trade_status === 'called_off') {
        this.setState({showCallOffLabel: true});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  tick = () => {
    const {shift, costCenterData, locationSettings } = this.props;

    if(costCenterData){
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_1'){
          this.setState({findCallOffReason1: true})
        }
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_2'){
          this.setState({findCallOffReason2: true})
        }
      }
    }

    //check if the shift expired every 100 seconds (1 minutes 40 seconds)
    const startDateTime = moment(shift.start_at);
    const endDateTime = moment(shift.end_at);
    let current = moment();
    let startToNow = moment.duration(startDateTime.diff(current));
    let endToNow = moment.duration(endDateTime.diff(current));
    let minutesStartToNow = parseInt(startToNow.asMinutes());
    let minutesEndToNow = parseInt(endToNow.asMinutes());
    //if the shift starts within 24 hours and the shift has not ended yet
    //the shift can be called off
    if(locationSettings.cfg__allow__call_off) {
      if(this.state.findCallOffReason1 && this.state.findCallOffReason2){
        if(minutesStartToNow < 1440 && minutesEndToNow > 0){
          this.setState({enableCallOff: true});
        }
      }
    }
  };

  _handleCardClick = () => {
    const {
      shift,
      userCookie,
      _toggleModal,
      _updateSelectedShift,
    } = this.props;
    const { applicants } = this.state;

    const owner = shiftOwner(shift, userCookie);
    const status = shiftStatus(shift, userCookie, applicants);
    if(status !== shiftStatuses.YOUR_CALLED_OFF){
      if (owner === shiftStatuses.YOUR_SHIFT || owner === shiftStatuses.YOUR_VTO || owner === shiftStatuses.COVERING_VTO) {
        _toggleModal(ModalTypes.MY_SHIFT);
      } else if (owner === shiftStatuses.COVERING_SHIFT) {
        _toggleModal(ModalTypes.MY_COVERING_SHIFT);
      } else if (status === shiftStatuses.AVAILABLE) {
        _toggleModal(ModalTypes.COVER_SHIFT);
      }

      _updateSelectedShift(shift);
    }else {
       _toggleModal(ModalTypes.CALLED_OFF, shift);
    }
  };

  
  handleCallOffClick = (event) => {
    const {
      shift,
      userCookie,
      _toggleModal,
      _updateSelectedShift,
    } = this.props;
    //because this button is on the shift card, both the button and the card get clicked. 
    //The stopPropagation() method allows you to prevent propagation of the current event.
    event.stopPropagation();
    // important: parameter 'shift' has to be there to get 'selectedShift' in CallOffShift
    _toggleModal(ModalTypes.CALL_OFF_SHIFT, shift);
    event.preventDefault()
  };
  
  
  _renderDate = (start, end) => {
    const date = moment(start).format('ddd MMM D, YYYY');
    const timeFrom = moment(start).format('LT');
    const timeTo = moment(end).format('LT');

    return (
      <>
        <span>
          {date}
          {' '}
          <br />
          {' '}
@
          {timeFrom}
-
          {timeTo}
        </span>
        {isNextDay([moment(start), moment(end)]) ? (
          <span className="shift-card__badge shift-card__badge--next-day">
            +1
          </span>
        ) : null}
      </>
    );
  };

  render() {
    const {
      shift,
      userCookie,
      location,
      regional,
      locationSettings: {
        cfg__primary_job_and_department,
        cfg__inventory__convert,
      },
      _toggleModal,
      _updateSelectedShift,
      costCenterData,
    } = this.props;

    const {showCallOffLabel, firstIncentiveAmount} = this.state;
    
    const debugInfo = `${shift.id} ${shift.parent_card}`;

    return (
      // eslint-disable-next-line
      <div className="shift-card" onClick={this._handleCardClick}>
        <div className="shift-card__badge-container">
          <Badge userCookie={userCookie} shift={shift} />
          <RegionalBadge regional={regional} userCookie={userCookie} shift={shift} />
          {showCallOffLabel && <label className="called-off-label">
            <FormattedMessage
              id="ShiftCard.calledOffLabel"
              defaultMessage="Called Off"
            />    
          </label>}
          {this.state.debug && <label>{debugInfo}</label>}
        </div>
        {!showCallOffLabel && shift.name === 'open_shift' && firstIncentiveAmount && 
        <label className="incentive-label">{firstIncentiveAmount}</label>}
        <span className="shift-card__expand" />

        <CardProfileThumb shift={shift} userId={userCookie.user_id} />

        <p className="shift-card__date-time">
          {this._renderDate(shift.start_at, shift.end_at)}
        </p>

        <CardPosition
          shift={shift}
          showJobDepo={cfg__primary_job_and_department}
        />

        <CardAddress regional={regional} location={location} />

        <div className="shift-card__status-wrap">
          <div className="shift-card__status-icon-wrap">
            <StatusIcon userCookie={userCookie} 
                        selectedShift={shift}
                        applicants={this.state.applicants}
                        />
          </div>
          <div className="shift-card__status-message-wrap">
            <StatusMessage 
            userCookie={userCookie} 
            shift={this.state.currentShift} 
            applicants={this.state.applicants}/>
            {!this.state.showCallOffLabel && <ActionButton
              userCookie={userCookie}
              shift={shift}
              _toggleModal={_toggleModal}
              _updateSelectedShift={_updateSelectedShift}
              allowPost={cfg__inventory__convert}
            />}
            {((shift && shift.trade_status === 'inventory') && 
              this.state.enableCallOff &&
              !this.state.showCallOffLabel) && 
              <button className="call-off-btn" onClick={this.handleCallOffClick}>
                  <FormattedMessage
                    id="ShiftCard.callOff"
                    defaultMessage="Call Off Shift"
                  />
              </button>}
          </div>
        </div>
      </div>
    );
  }
}

ShiftCard.propTypes = {
  userCookie: PropTypes.shape({}).isRequired,
  shift: PropTypes.shape({}).isRequired,
  locationSettings: PropTypes.shape({}).isRequired,
  _toggleModal: PropTypes.func.isRequired,
  _updateSelectedShift: PropTypes.func.isRequired,
};

export { ShiftCard };
