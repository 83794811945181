import React from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

const Modal = ({
  _toggleModal,
  children,
}) => (
  <div
    className="general-modal"
    onClick={() => {
      _toggleModal(null, null);
    }}
  >
    <div
      className="general-modal__content"
      onClick={e => e.stopPropagation()}
    >
      {children}
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
};

export { Modal };
