import styled from 'styled-components';

const dimensions = '46px';


const DeleteWinContainer = styled.section`
  
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  //overflow: scroll;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */
  //border: 1px solid green;
  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
  /*
  @media screen and (max-width: 640px) {
    left: 50px;
    width: 600px;
  }
  
  @media screen and (min-width: 1280px) {
    //margin-left: 10px;
  }
  @media screen and (min-width: 1440px) {
    //margin-left: 90px;
  }
  @media screen and (min-width: 1680px) {
    //margin-left: 215px;
  }
  @media screen and (min-width: 1920px) {
    //margin-left: 250px;
  }
  */
`;

const DeleteWinCloseButton = styled.button`
  width: 30px;
  height: 30px;
  min-width: 30px;
  position: absolute;
  top: 10px;
  right: 5px;
  color: black;
  background-color: white; //rgba(246,222,196,255);
  background-image: url('/icons/close-modal-dark.svg');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  boxShadow: 0 5px 10px 2px rgba(195,192,192,.5);
  //outline: none;
  //border: 1px solid blue;

  @media (min-width: 600px) {
    width: 40px;
  }
`;

export default {
  DeleteWinContainer,
  DeleteWinCloseButton,
};
