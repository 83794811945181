import axios from 'axios';
import { formatOnlyProps } from '_helpers/formatting';
import { axiosInstance } from '../api-calls';

const shiftObjKeys = formatOnlyProps(`
  allow_delete,
  allow_cancel,
  channel_name,
  content,
  coverer[
    current_position,
    email,
    first_name,
    id,
    last_name,
    phone_number,
    profile_image
  ],
  created_at,
  end_at,
  id,
  name,
  owner[
    current_position,
    email,
    first_name,
    id,
    last_name,
    phone_number,
    profile_image
  ],
  location_id,
  location[
    location_name,
    formatted_address,
  ],
  post_id,
  primary_department,
  primary_job,
  segment_count,
  start_at,
  trade_status,
  call_off_reasons_1_tag_key,
  call_off_reasons_2_tag_key,
  parent_card,
  parent_id,
  parent_post_id,
  cost_center,
  current_applicant_status,
  can_apply,
  cover_mode,
  total_shifts_available,
  available_shifts_pending,
  available_shifts_covered,
  available_shifts_left,
  has_applied,
  current_applicants_pending_user_ids,
  current_applicants_approved_user_ids,
  current_applicants_denied_user_ids,
  current_applicants_nominated_user_ids,
  shift_1_5,
  channel_id,
  tip_amount,
  skill_ids,
`);

const shiftCallParams = {
  not_expired: true,
  order: 'start_at_asc',
  'response[only]': shiftObjKeys,
  'response[nested]': 2,
  per_page: 1000,
  name_is: [
    'shift',
    'shift_vto',
    'open_shift',
    'shift_inventory',
    'shift_pickup',
  ],
};

const getShiftsConfig = {
  url: 'api/customer/user_role/schedule_elements',
  method: 'GET',
  params: shiftCallParams,
};

const getAvailableShifts = (location_id, user_id) => (
  axiosInstance.request({
    ...getShiftsConfig,
    params: {
      ...getShiftsConfig.params,
      'response[keep]': 'location',
      associated_location_id: location_id,
      status_is: ['posted', 'approved'],
      owner_is_not: user_id,
    },
  })
);

export function getShift(id) {
  return axiosInstance.request({
    url: `api/customer/user_role/schedule_elements/${id}`,
    method: 'GET',
    params: getShiftsConfig.params,
  });
}

const getMyShifts = (location_id, user_id) => axiosInstance.request({
  ...getShiftsConfig,
  params: {
    ...getShiftsConfig.params,
    'response[keep]': 'location',
    associated_location_id: location_id,
    trader_id: user_id,
    status_is: [
      'posted',
      'covered',
      'approved',
      'pending',
      'rejected',
      'inventory',
      'called_off',
    ],
  },
});

export function getAssociatedShifts(user_id, location_id) {
  return axios.all([
    getAvailableShifts(location_id, user_id),
    getMyShifts(location_id, user_id),
  ]);
}

export const getShiftSegments = id => axiosInstance.get(`/api/customer/user_role/schedule_elements/${id}/segments`);

export const postShift = data => axiosInstance.request({
  url: `/api/customer/user_role/locations/${data.location_id}/schedule_elements`,
  method: 'POST',
  params: {
    'response[only]': shiftObjKeys,
    'response[nested]': 2,
  },
  data: {
    start_at: data.start_at,
    end_at: data.end_at,
    shift_content: data.shift_content,
    channel_id: data.channel_id,
    cached_primary_job: data.cached_primary_job,
    tip_amount: data.tip_amount,
  },
});

export const postShiftCalledOff = data => axiosInstance.request({
  url: `/api/customer/user_role/locations/${data.location_id}/schedule_elements`,
  method: 'POST',
  params: {
    'response[only]': shiftObjKeys,
    'response[nested]': 2,
    'response[put_return_resource]': true,
  },
  data: {
    start_at: data.start_at,
    end_at: data.end_at,
    shift_content: data.shift_content,
    name: 'shift_inventory',
    trade_status: 'called_off',
    call_off_reasons_1_tag_key: data.call_off_reasons_1_tag_key,
    call_off_reasons_2_tag_key: data.call_off_reasons_2_tag_key,
    cached_primary_job: data.cached_primary_job,
  },
});

export const postShiftInventory = ({
  inventory_id,
  shift_content,
  channel_id,
}) => axiosInstance.put(
  `/api/customer/user_role/schedule_elements/${inventory_id}/convert`,
  {
    shift_content,
    channel_id,
  },
);

export const deleteShift = (location_id, shift_id) => axiosInstance.request({
  url: `/api/customer/user_role/locations/${location_id}/schedule_elements/${shift_id}?repost=false`,
  method: 'DELETE',
});
/*
export const cancelShift = shift_id => axiosInstance.put(
  `/api/customer/user_role/schedule_elements/${shift_id}/cancel`,
  {
    'response[only]': shiftObjKeys,
    'response[nested]': 2,
  },
);
*/
export const cancelShift = shift_id => axiosInstance.request({
 url: `/api/customer/user_role/schedule_elements/${shift_id}/cancel`,
 method: 'PUT',
  params: {
    'response[only]': shiftObjKeys,
    'response[nested]': 2,
    'response[put_return_resource]': true,
  },
});

export const coverShiftAction = ({ shift_id, config_id }) => axiosInstance.request({
  url: `/api/customer/user_role/schedule_elements/${shift_id}/cover`,
  method: 'PUT',
  params: {
    'response[only]': shiftObjKeys,
    'response[nested]': 2,
    cover_config_id: config_id,
  },
});

export const getSubscriptions = location_id => axiosInstance.request({
  url: `api/customer/user_role/locations/${location_id}/users`,
  method: 'GET',
  params: {
    per_page: 500,
    'response[only]': 'first_name, last_name, id',
  },
});

export const callOffShift = ({
  shift_id,
  call_off_reasons_1_tag_key,
  call_off_reasons_2_tag_key,
}) => axiosInstance.put(
  `/api/customer/user_role/schedule_elements/${shift_id}/call_off?response[put_return_resource]=true`,
  {
    call_off_reasons_1_tag_key,
    call_off_reasons_2_tag_key,

  },
);
/*
export const getFreePosts = post_id => axiosInstance.request({
  url: `/api/compact/posts/${post_id}/free_posts`,
  method: 'GET',
  params: {
    'response[only]': shiftObjKeys,
    'response[nested]': 2,
  },
});
*/

export const getShiftApplicants = (id) =>
  axiosInstance.get(
    `/api/customer/user_role/schedule_elements/${id}/get_current_applicants`
  );  

