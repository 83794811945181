import React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import { getMyDepartments, } from '_helpers/api-calls/department-calls';
import ProfileInputText from '../ProfileInputText/ProfileInputText';


import ProfileInputWrap from '../ProfileInputWrap/ProfileInputWrap';

import './ProfileDepartment.scss';


class ProfileDepartment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      myDepartments: [],
    };

    this._isMounted = false;
    
  }

  componentDidMount () {
    const { user, } = this.props;

    this._isMounted = true;
    
    if(user) {
      getMyDepartments(user.location_id).then((data) =>{
        const copyArray = [...data.data.departments];
        this.sortDepartmentNameWithNullValues(copyArray, true);
        this.setState({myDepartments: copyArray});
      }).catch((error1) =>{
        const msg = `ProfileDepartments.componentDidMount: call getMyDepartments failed. error: ${error1}`;
        console.log(msg);
      });
    }

  }

  componentDidUpdate (prevProps, prevState) {
    
    const { user, } = this.props;
    const { myDepartments, } = this.state;
    
    if(prevProps.user !== user) {
      if(user) {

      }
    }

    if(prevState.myDepartments !== myDepartments) {
      //console.log('got');
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
  };

  sortDepartmentNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.name === b.name) // identical? return 0
        return 0;
      else if (a.name === null)  // a is null? last 
        return 1;
      else if (b.name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.name.localeCompare(b.name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  renderUserDepartments = () => {
    const { myDepartments, } = this.state;
    
    if(myDepartments && myDepartments.length > 0) {
      const departments = myDepartments.map((dept) => (
        <label className="profile-departments__department">
          {dept.name}
        </label>
      ));
      if(departments && departments[0]) {
        return departments;
      }else {
        return null;
      }
    }else {
      return null;
    }
  }

  render () {
    const { icon, placeholder, } = this.props;
    const { myDepartments, } = this.state;
    const results = this.renderUserDepartments();
    if(results) {
      return (
        <div className="profile-departments">
          <div className="profile-departments__wrap">
            <img className="profile-departments__icon" src={icon} alt="Department Icon" />
            <label className="profile-departments__label">{results}</label>
          </div>
        </div>
      );
    }

    return (
       <ProfileInputText
          icon={icon}
          name="employeeId"
          placeholder={placeholder}
          inputValue=''
          disabled={true}
        />
    );
  }
}



ProfileDepartment.propTypes = {
  icon: PropTypes.string.isRequired,
};

ProfileDepartment.defaultProps = {
  error: '',
  verifiedStatus: '',
  disabled: false,
};

//export default ProfileSkills;
export default injectIntl (ProfileDepartment);
