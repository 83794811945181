import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// import moment from 'moment';
import { Redirect } from 'react-router-dom';
// import { formatDateforApi } from '_helpers/date-time';
import { axiosInstance } from '_helpers/api-calls';
import { loadShifts } from '_helpers/app-state';
import { FindRole } from '_services/user-roles';
import { getLocationCode, getLocaleCookie, checkForAuthentication } from '_helpers/cookies';
import { LoadingSpinner } from '_components/GeneralComponents/LoadingSpinner';

/**
 * Checks to see if API has been called and then fetches data if it hasn't
 */
class FetchComponent extends React.PureComponent {
  state = {
    path: null,
  };

  componentDidMount() {
    if (checkForAuthentication()) {
      this._checkForData();
    } else {
      this.setState({
        path: '/login',
      });
    }
  }

  componentDidUpdate() {
    const { path } = this.state;
    if (!checkForAuthentication()) {
      this._redirect('/login');
    }
    if (!path) {
      this._checkForData();
    } else {
      // eslint-disable-next-line
      this.setState({
        path: null,
      });
    }
  }

  _redirect = (path) => {
    this.setState({
      path,
    });
  };

  _checkForData = () => {
    const { dataNeeded, dataNotCalled } = this.props;
    const apiCalls = [];

    if (dataNeeded.length > 0) {
      dataNeeded.forEach((element) => {
        for (let x = 0; x < dataNotCalled.length; x += 1) {
          if (dataNotCalled[x] === element) {
            const axiosCall = this._findAxiosCall(element);

            if (axiosCall) {
              apiCalls.push(axiosCall);
            }
          }
        }

        return false;
      });
      if (apiCalls.length > 0) {
        this._callNeededAPIs(apiCalls);
      }
    }
  };

  _findAxiosCall = (title) => {
    const { _setAppState, appState } = this.props;
    const location_id = getLocationCode();
    const locale = getLocaleCookie();
    const { user: { user_id } } = appState;

    if (title === 'index' && !appState.loading.shifts && location_id && user_id) {
      _setAppState({ key: 'loading', statement: { shifts: { $set: true } } });
      loadShifts(location_id, user_id, _setAppState);
    }

    if (title === 'setup_actions' && !appState.loading.setup_actions) {
      _setAppState({
        key: 'loading',
        statement: { setup_actions: { $set: true } },
      });

      return axiosInstance.request({
        url: '/api/customer/user_role/setup_actions',
        method: 'GET',
        headers: {
          locale,
        },
        params: {
          location_id,
        },
      });
    }

    return false;
  };

  _updateSetupActions = (setupActions) => {
    const updater = [
      { key: 'setupActions', statement: { $set: setupActions } },
      { key: 'userRole', statement: { $set: FindRole(setupActions) } },
      { key: 'user', statement: { position: { $set: setupActions.meta.current_user_privilege && setupActions.meta.current_user_privilege.position } } },
    ];

    return updater;
  };

  _updateAssociatedShifts = (associatedShifts) => {
    const updater = [
      {
        key: 'shifts',
        statement: { $set: associatedShifts },
      },
    ];

    return updater;
  };

  _callNeededAPIs = (arr) => {
    const updater = [];
    const { appState, _setAppState } = this.props;

    axios
      .all(arr)
      .then(
        axios.spread((...args) => {
          let apis = [];
          args.map((element) => {
            if (element.data.setup_actions) {
              const setupActionsUpdater = this._updateSetupActions(
                element.data,
              );
              apis.push('setup_actions');
              setupActionsUpdater.forEach((el) => {
                updater.push(el);
              });
            }

            if (element.data.schedule_elements) {
              const associatedShiftsUpdater = this._updateAssociatedShifts(
                element.data.schedule_elements,
              );
              apis.push('index');
              associatedShiftsUpdater.forEach((el) => {
                updater.push(el);
              });
            }

            return false;
          });

          apis = apis.join(', ');

          const apisNeeded = appState.apisNeeded.filter(
            element => !(apis.indexOf(element) > -1),
          );

          updater.push({
            key: 'apisNeeded',
            statement: { $set: apisNeeded },
          });

          updater.push({
            key: 'loading',
            statement: { $set: {} },
          });

          _setAppState(updater);
        }),
      )
      .catch(() => {
        _setAppState({
          key: 'loading',
          statement: { setup_actions: { $set: false } },
        });

        this.setState({
          path: '/login',
        });
      });
  };

  render() {
    const {
      children, appState, _setAppState, dataNeeded,
    } = this.props;
    const { path } = this.state;
    const apisNeeded = appState.apisNeeded.join(', ');
    if (path) {
      return <Redirect to={path} exact />;
    }
    if (
      dataNeeded.filter(element => apisNeeded.indexOf(element) > -1).length
      === 0
    ) {
      return (
        <>
          {React.cloneElement(children, {
            appState,
            _setAppState,
            _redirect: this._redirect,
          })}
        </>
      );
    }
    return (
      <div className="index-page index-page__loading">
        <LoadingSpinner size="large" color="red" />
      </div>
    );
  }
}

FetchComponent.propTypes = {
  dataNeeded: PropTypes.shape([]).isRequired,
  dataNotCalled: PropTypes.shape([]).isRequired,
};

export default FetchComponent;
