import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/fr";

import localeData from "../../public/locales/data.json";

const IntlContainer = ({ children, locale }) => {
  if (!Intl.PluralRules) {
    require("@formatjs/intl-pluralrules/polyfill");
    require("@formatjs/intl-pluralrules/dist/locale-data/en");
    require("@formatjs/intl-pluralrules/dist/locale-data/es");
    require("@formatjs/intl-pluralrules/dist/locale-data/fr");
  }

  if (!Intl.RelativeTimeFormat) {
    require("@formatjs/intl-relativetimeformat/polyfill");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/en");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/es");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/fr");
  }

  // Try locale without region code, fallback to 'en'
  const messages = localeData[locale] || localeData.en;

  // Modify Spanish locale with 12h time
  moment.updateLocale("es", {
    longDateFormat: {
      LT: "h:mm A",
      LTS: "h:mm:ss A",
      L: "MM/DD/YYYY",
      LL: "MMMM [de] D [de] YYYY",
      LLL: "MMMM [de] D [de] YYYY h:mm A",
      LLLL: "dddd, MMMM [de] D [de] YYYY h:mm A",
    },
  });

  // Globally set locale in moment.js
  // Set again when user changes language in dropdown
  moment.locale(locale);

  const render = () => (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );

  // Check if browser supports native intl api
  if (!global.Intl) {
    // Add intl polyfill for browsers that don't support
    require("intl"); // eslint-disable-line
    require("intl/locale-data/jsonp/en.js"); // eslint-disable-line
    require("intl/locale-data/jsonp/es.js"); // eslint-disable-line
    require("intl/locale-data/jsonp/fr.js"); // eslint-disable-line

    return render();
  }

  return render();
};

IntlContainer.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string.isRequired,
};

export default IntlContainer;
