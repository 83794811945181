import React from 'react';
import PropTypes from 'prop-types';

class EmailConfirmation extends React.Component {
  password = null;

  componentDidMount() {
    this.password.focus();
  }

  setPasswordRef = (element) => {
    this.password = element;
  };

  render() {
    const { emailChange, value, passwordChange } = this.props;

    return (
      <>
        <section className="login__form-group">
          <input
            onChange={emailChange}
            defaultValue={value}
            name="email"
            placeholder="Email"
            className="login__input-email"
            disabled
          />
        </section>

        <section className="login__form-group">
          <input
            ref={this.setPasswordRef}
            onChange={passwordChange}
            name="password"
            type="password"
            placeholder="Password"
            className="login__input-email"
          />
        </section>
      </>
    );
  }
}

export default EmailConfirmation;

EmailConfirmation.propTypes = {
  emailChange: PropTypes.func.isRequired,
  passwordChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
