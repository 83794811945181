import { defineMessages } from 'react-intl';

const messages = defineMessages({
  backButtonLabel: {
    id: 'LoginSecondStage.backButtonLabel',
    defaultMessage: 'Want to change authentication methods?',
  },
});

export default messages;
