import { defineMessages } from 'react-intl';

const messages = defineMessages({
  firstNameLabel: {
    id: 'SignupComponent.firstNameLabel',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'SignupComponent.lastNameLabel',
    defaultMessage: 'Last Name',
  },
  emailLabel: {
    id: 'SignupComponent.emailLabel',
    defaultMessage: 'Email',
  },
  passwordLabel: {
    id: 'SignupComponent.passwordLabel',
    defaultMessage: 'Password',
  },
  confirmPasswordLabel: {
    id: 'SignupComponent.confirmPasswordLabel',
    defaultMessage: 'Confirm Password',
  },
});

export default messages;
