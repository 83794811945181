import { defineMessages } from 'react-intl';

const messages = defineMessages({
  serverError: {
    id: 'ForgotPasswordPage.serverError',
    defaultMessage: 'Something went wrong while processing the information. Please try again.',
  },
  emailError: {
    id: 'ForgotPasswordPage.emailError',
    defaultMessage: 'Hmm, we couldn\'t find that email in our records.Please try another one.',
  },
  companyAccountError: {
    id: 'ForgotPasswordPage.companyAccountError',
    defaultMessage: 'Sorry, company account passwords may not be reset. Please contact us at support@myshyft.com for help.',
  },
  emailPlaceholder: {
    id: 'ForgotPasswordPage.emailPlaceholder',
    defaultMessage: 'Email',
  },
});

export default messages;
