import styled from 'styled-components';

import { ProfileThumb } from '@shyft/acorns';

const ProfileThumbStyled = styled(ProfileThumb)`
  display: inline-block;
`;

export default {
  ProfileThumb: ProfileThumbStyled,
};
