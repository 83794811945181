import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import styled from './styled';

/*
const DateTimeButton = (props) => {
  const {
    _toggleDatePicker, dateString, timeRangeString, disabled,
  } = props;

  return (
    <styled.DateTimeBtn
      disabled={disabled}
      onClick={_toggleDatePicker}
      type="button"
    >
      <styled.DateTimeSection>
        <span className="icon-calendar" />
        &nbsp;&nbsp;
        <styled.DateTimeText>{dateString}</styled.DateTimeText>
      </styled.DateTimeSection>
      <styled.DateTimeSection>
        <span className="icon-clock" />
        <styled.DateTimeText>{timeRangeString}</styled.DateTimeText>
      </styled.DateTimeSection>
    </styled.DateTimeBtn>
  );
};

DateTimeButton.propTypes = {
  _toggleDatePicker: PropTypes.func.isRequired,
  dateString: PropTypes.string,
  timeRangeString: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

DateTimeButton.defaultProps = {
  dateString: '',
  timeRangeString: '',
};

export default DateTimeButton;
*/
//-------------------------------------------------------------------------

class DateButton extends React.PureComponent {
  constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;
  }

  render () {
    const {disabled, _toggleDatePicker, dateString, timeRangeString} = this.props;
    return (
       <styled.DateTimeBtn
      disabled={disabled}
      onClick={_toggleDatePicker}
      type="button"
    >
      <styled.DateTimeSection>
        <span className="icon-calendar" />
        &nbsp;&nbsp;
        <styled.DateTimeText>{dateString}</styled.DateTimeText>
      </styled.DateTimeSection>
      {/*<styled.DateTimeSection>
        <span className="icon-clock" />
        <styled.DateTimeText>{timeRangeString}</styled.DateTimeText>
      </styled.DateTimeSection>*/}
    </styled.DateTimeBtn>
    );
  }
}

DateButton.propTypes = {
  _toggleDatePicker: PropTypes.func.isRequired,
  dateString: PropTypes.string,
  timeRangeString: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

DateButton.defaultProps = {
  dateString: '',
  timeRangeString: '',
};

export default injectIntl (DateButton);
