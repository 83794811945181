import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { StatusIcon } from '_components/GeneralComponents/ShiftStatus';
import { ModalTypes } from '_constants';
import {
  shiftStatuses,
  shiftOwner,
  shiftStatus,
  shiftNames
} from '_constants/shift-status';
import { isNextDay } from '_helpers/formatting';
import {
  ActionButton, StatusMessage, CardAddress, Badge, RegionalBadge, CardProfileThumb, CardPosition,
} from './components';

import { getShiftApplicants } from '_helpers/api-calls/shift-calls';
import { getSubscriptionsChannels } from '_helpers/api-calls/location-calls';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';

import './InventoryShiftCard.scss';


class InventoryShiftCard extends React.Component {

	constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      to: {
        id: 'ShiftCardNew.to',
        defaultMessage: 'to',
      },
      tip: {
        id: 'ShiftCardNew.tip',
        defaultMessage: 'Tip',
      },
    });

    this.state = {
      debug: false,
      debugInfo: '',
      posterIsAdmin: false,
      covererIsAdmin: false,
      ownerFullName: '',
      covererFullName: '',
      currentUserFullName: '',
      jobTitle: '',
      ownerAvatar: '',
      covererAvatar: '',
      statusBarColor: '',
      pendingApplicants: '',
      approvedApplicants: '',
      deniedApplicants: '',

      timer: null,
      enableCallOff: false,
      showCallOffLabel: false,
      firstIncentiveAmount: '',
      currentShift: null,
      firstApplicantName: '',
      firstDeniedApplicantName: '',
      shiftPosterInfo: null,
      shiftCovererInfo: null,
      tipAmount: '',
    };

    this._isMounted = false;
    this.localStorageKey;
    this.myCovered = '';
  }

  componentDidMount () {
  	const { shift, userCookie, userPrivileges, locationChannels, costCenterData, intl } = this.props;

  	this._isMounted = true;
    let msg = '';
    this.localStorageKey = `${userCookie.user_id}-${userCookie.location_id}`;
    //msg = `ShiftCardNew.componentDidMount: localStorageKey: ${this.localStorageKey}`;
    //console.log(msg);
    this.myCovered = localStorage.getItem(this.localStorageKey)
    //msg = `ShiftCardNew.componentDidMount: getItem: ${this.myCovered}`;
    //console.log(msg);

    if(shift.tip_amount) {
      const tip = `$${shift.tip_amount} ${intl.formatMessage(this.messages.tip)}`;
      if(this._isMounted) {
        this.setState({tipAmount: tip});
      }
    }

  	let myTimer = setInterval(this.tick, 10000); //unit: ms, 10000 = 10 seconds
    if(this._isMounted) {
      this.setState({timer: myTimer});
    }

    if(shift.trade_status ==='called_off'){
      if(this._isMounted) {
         this.setState({showCallOffLabel: true});
      }
    }
    if(this._isMounted) {
      this.setState({currentShift: shift});
    }

  	if(shift) {
	  	const debugInfo = `${shift.id}, ${shift.name}, owner: ${shift.owner.first_name} ${shift.owner.last_name} 
	  					slot: ${shift.total_shifts_available} channel id: ${shift.channel_id}`;
	  	const ownerFullName = `${shift.owner.first_name} ${shift.owner.last_name}`;
	  	const covererFullName = shift.coverer ? `${shift.coverer.first_name} ${shift.coverer.last_name}` : '';
      const currentUserFullName = `${userCookie.first_name} ${userCookie.last_name}`;
	  	const jobTitle = shift.primary_job;

	  	const ownerAvatar = shift.owner.profile_image ? 
                        shift.owner.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
	    const covererAvatar = (shift.coverer && shift.coverer.profile_image) ? 
	                        shift.coverer.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
	    const pendingApplicants = shift.current_applicants_pending_user_ids ? shift.current_applicants_pending_user_ids.length : '';
	  	const approvedApplicants = shift.current_applicants_approved_user_ids ? shift.current_applicants_approved_user_ids.length : '';
	  	const deniedApplicants = shift.current_applicants_denied_user_ids ? shift.current_applicants_denied_user_ids.length : '';
	     
      if(this._isMounted) {
        this.setState({
          debugInfo: debugInfo,
          ownerFullName: ownerFullName,
          covererFullName: covererFullName,
          jobTitle: jobTitle,
          ownerAvatar: ownerAvatar,
          covererAvatar: covererAvatar,
          pendingApplicants: pendingApplicants,
          approvedApplicants: approvedApplicants,
          deniedApplicants: deniedApplicants,
          currentUserFullName: currentUserFullName,
        });
      }
  
      if(userPrivileges && userPrivileges.length > 0) {
        const idx = userPrivileges.findIndex((user) => user.owner_id === shift.owner.id);
        if(idx >= 0) {
          if(userPrivileges[idx].is_admin) {
            if(this._isMounted) {
              this.setState({posterIsAdmin: true});
            }
          }
        }
        if(shift.coverer) {
          const idx2 = userPrivileges.findIndex((user) => user.owner_id === shift.coverer.id);
          if(idx2 >= 0) {
            if(userPrivileges[idx2].is_admin) {
              if(this._isMounted) {
                this.setState({covererIsAdmin: true});
              }
            }
          }
        }
      }else {
        if(shift) {
          getUserInfoNonAdmin(shift.owner.id, shift.location_id).then((response) => {
            if(this._isMounted) {
              this.setState({posterIsAdmin: response.data.user.current_is_admin});
            }
          }).catch((error) =>{
             const msg = `RTOCardNew.componentDidUpdate: call getUserInfoNonAdmin failed. error: ${error}`;
             console.log(msg);
          });
        }
      }

      let firstApplicantName = ''
      let firstDeniedApplicantName = '';
      getShiftApplicants(shift.id).then(({ data }) => {
        if(this._isMounted) {
          this.setState({applicants: data.applicants});
        }
        for(let i=0; i<data.applicants.length; i++) {
          if((data.applicants[i].status === 'applied' || data.applicants[i].status === 'nominated') && firstApplicantName === '') {
            firstApplicantName = `${data.applicants[i].user.first_name} ${data.applicants[i].user.last_name}`;
            if(this._isMounted) {
              this.setState({firstApplicantName: firstApplicantName});
            }
          }
          if(data.applicants[i].status === 'denied' && firstDeniedApplicantName === '') {
            firstDeniedApplicantName = `${data.applicants[i].user.first_name} ${data.applicants[i].user.last_name}`;
            if(this._isMounted) {
              this.setState({firstDeniedApplicantName: firstDeniedApplicantName});
            }
          }
        }
      }).catch((error) =>{
        const msg = `ShiftCardNew.componentDidMount: ${shift.id}: getShiftApplicants returns error = ${error}`;
        console.log(msg);
      });
    }
    
    if(costCenterData && costCenterData.tag_groups){
      //const msg = `componentDidMount: ${costCenterData ? costCenterData.tag_groups.length: null}`;
      //console.log(msg);
      let abort = false;
      for(let i=0; i<costCenterData.tag_groups.length && !abort; i++) {
        if(costCenterData.tag_groups[i].group_type === 'xpo_cost_centers'){
          for(let j=0; j<costCenterData.tag_groups[i].tags.length && !abort; j++){
            if(costCenterData.tag_groups[i].tags[j].tag_key === shift.cost_center) {
              if(costCenterData.tag_groups[i].tags[j].incentives.length>0){
                for(let k=0; k<costCenterData.tag_groups[i].tags[j].incentives.length && !abort; k++){
                  //if shift's start date is between an incentive's date range
                  // display this incentive info, break the loop
                  const shiftStart = moment(shift.start_at);
                  const incentiveStart = moment(costCenterData.tag_groups[i].tags[j].incentives[k].start_time);
                  const incentiveEnd = moment(costCenterData.tag_groups[i].tags[j].incentives[k].end_time);
                  if(shiftStart.isBetween(incentiveStart, incentiveEnd)) {
                    let amount = costCenterData.tag_groups[i].tags[j].incentives[k].amount;
                    let newAmount = amount.replace('hr', 'HR');
                    if(this._isMounted) {
                      this.setState({firstIncentiveAmount: newAmount});
                    }
                    abort = true; // for breaking all loops
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  componentDidUpdate (prevProps) {
  	const { shift, userCookie, userPrivileges, locationChannels,  costCenterData,} = this.props;

  	if(prevProps.userPrivileges !== userPrivileges) {
	    if(userPrivileges.length > 0) {
	      const idx = userPrivileges.findIndex((user) => user.owner_id === shift.owner.id);
	      if(idx >= 0) {
	        if(userPrivileges[idx].is_admin) {
            if(this._isMounted) {
              this.setState({posterIsAdmin: true});
            }
	        }
	      }
        if(shift.coverer) {
          const idx2 = userPrivileges.findIndex((user) => user.owner_id === shift.coverer.id);
          if(idx2 >= 0) {
            if(userPrivileges[idx2].is_admin) {
              if(this._isMounted) {
                this.setState({covererIsAdmin: true});
              }
            }
          }
        }
	    }
  	}

  	if(prevProps.shift !== shift) {
	  	const covererFullName = shift.coverer ? `${shift.coverer.first_name} ${shift.coverer.last_name}` : '';
	    const covererAvatar = (shift.coverer && shift.coverer.profile_image) ? 
	                        shift.coverer.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
	    const pendingApplicants = shift.current_applicants_pending_user_ids ? shift.current_applicants_pending_user_ids.length : '';
	  	const approvedApplicants = shift.current_applicants_approved_user_ids ? shift.current_applicants_approved_user_ids.length : '';
	  	const deniedApplicants = shift.current_applicants_denied_user_ids ? shift.current_applicants_denied_user_ids.length : '';
      if(this._isMounted) {
  	  	this.setState({
  	  		covererFullName: covererFullName,
  	  		covererAvatar: covererAvatar,
  	  		pendingApplicants: pendingApplicants,
  	  		approvedApplicants: approvedApplicants,
  	  		deniedApplicants: deniedApplicants,
  	  	});
  	  	this.setState({currentShift: shift});
      }
      if(shift.trade_status === 'called_off') {
        if(this._isMounted) {
          this.setState({showCallOffLabel: true});
        }
      }
      let firstApplicantName = ''
      let firstDeniedApplicantName = '';
      if(shift.current_applicants_pending_user_ids.length > 0) {
        getShiftApplicants(shift.id).then(({ data }) => {
          if(this._isMounted) {
            this.setState({applicants: data.applicants});
          }
          for(let i=0; i<data.applicants.length; i++) {
            if((data.applicants[i].status === 'applied' || data.applicants[i].status === 'nominated') && firstApplicantName === '') {
              firstApplicantName = `${data.applicants[i].user.first_name} ${data.applicants[i].user.last_name}`;
              if(this._isMounted) {
                this.setState({firstApplicantName: firstApplicantName});
              }
            }
            if(data.applicants[i].status === 'denied' && firstDeniedApplicantName === '') {
              firstDeniedApplicantName = `${data.applicants[i].user.first_name} ${data.applicants[i].user.last_name}`;
              if(this._isMounted) {
                this.setState({firstDeniedApplicantName: firstDeniedApplicantName});
              }
            }
          }
        }).catch((error) =>{
          const msg = `ShiftCardNew.componentDidUpdate: ${shift.id}: getShiftApplicants returns error = ${error}`;
          console.log(msg);
        });
      }
  	}
  }

  componentWillUnmount () {
  	this._isMounted = false;
  	clearInterval(this.state.timer);
  }

  tick = () => {
    const {shift, costCenterData, locationSettings } = this.props;
    //check if the shift expired every 100 seconds (1 minutes 40 seconds)
    const startDateTime = moment(shift.start_at);
    const endDateTime = moment(shift.end_at);
    let current = moment();
    let startToNow = moment.duration(startDateTime.diff(current));
    let endToNow = moment.duration(endDateTime.diff(current));
    let minutesStartToNow = parseInt(startToNow.asMinutes());
    let minutesEndToNow = parseInt(endToNow.asMinutes());
    //if the shift starts within 24 hours and the shift has not ended yet
    //the shift can be called off
    if(locationSettings.cfg__allow__call_off) {
      if(shift.trade_status === 'inventory') {
        if(this.state.findCallOffReason1){
          if(minutesStartToNow < 1440 && minutesEndToNow > 0){
            if(this._isMounted) {
              this.setState({enableCallOff: true});
            }
          }
        }
      }
    }
  };

  _handleCardClick = () => {
    const { shift, handleCardClick, } = this.props;
    handleCardClick(shift);
  };

  renderDate = () => {
    const { shift } = this.props;

    const startDate = moment(shift.start_at).format('ddd, MMM DD');
    return shift && shift.start_at ? startDate.toUpperCase(): '';
  }

  renderTime = () => {
    const { shift, userCookie, intl } = this.props;

    const startTime = moment(shift.start_at).format('h:mma');
    const endTime = moment(shift.end_at).format('h:mma');
    //const timeRange = `${startTime} - ${endTime}`;
    const start = startTime.slice(0, startTime.length-1);
    const end = endTime.slice(0, endTime.length-1);
    const timeRange = `${start} - ${end}`;
    return timeRange;
  }

  statusBarClassname = () => {
  	const { shift, userCookie, onMarketplace, locationChannels} = this.props;
  	let className = '';
    if(shift.id === 3951016) {
      //console.log('here');
    }
  	
  	const status = shiftStatus(shift, userCookie);

    switch(shift.name) {
      case 'open_shift':
        className = 'inventory-card__status-bar-blue';
        break;
      case 'shift':
        //className = 'inventory-card__status-bar-green';
        if(shift.owner.id === userCookie.user_id) {
          className = 'inventory-card__status-bar';
        }else {
          className = 'inventory-card__status-bar-green';
        }
        break;
      case 'shift_vto':
        className = 'inventory-card__status-bar-orange';
        break;
      default:
        className = 'inventory-card__status-bar';
        break;
    }

  	if(locationChannels && locationChannels.length > 0) {
      const idx = locationChannels.findIndex((channel) => channel.id === shift.channel_id);
      if(idx >= 0) {
        if(locationChannels[idx].channel_type === 'region_feed') {
          className = 'inventory-card__status-bar-magenta';
        }
      }
    }


		return className;
  }


  shiftType = () => {
  	const {shift, userCookie, onMarketplace, locationChannels, } = this.props;
  	const {ownerFullName, covererFullName} = this.state;

  	let type = '';
  	let numShifts = 0;

  	if(shift.name === 'shift') {
  		numShifts = 1;
  	}else {
      if(shift.total_shifts_available) {
        numShifts = shift.total_shifts_available;
      }else {
        if(shift.trade_status === 'approved') {
          numShifts = shift.available_shifts_covered;
        }
        if(shift.trade_status === 'pending' || shift.trade_status === 'rejected') {
          if(!shift.parent_card) {
            numShifts = 1;
          }
        }
      }
  	}
  	const scheduled = (<FormattedMessage
                id="ShiftCardNew.scheduledShift"
                defaultMessage="Scheduled Shift" />);
  	const teamShift = (<FormattedMessage
                id="ShiftCardNew.teamShift"
                defaultMessage="Team Shift" />);
  	const numOpenShifts = (<FormattedMessage
		                id="ShiftCardNew.numOpenShift"
		                defaultMessage="{number, plural, one {{number} Open Shift} other {{number} Open Shifts}}" 
		                values={{number: numShifts}}/>);
  	const numRegionalShifts = (<FormattedMessage
		                id="ShiftCardNew.numRegionalShift"
		                defaultMessage="{number, plural, one {{number} Regional Shift} other {{number} Regional Shifts}}" 
		                values={{number: numShifts}}/>);
  	const numVTOs = (<FormattedMessage
		                id="ShiftCardNew.numVTOs"
		                defaultMessage="{number, plural, one {{number} VTO} other {{number} VTOs}}" 
		                values={{number: numShifts}}/>);

    switch(shift.name) {
      case 'shift':
        if(shift.owner.id === userCookie.user_id) {
          type = scheduled;
        }else {
          type = teamShift;
        }
        break;
      case 'open_shift':
        if(shift.owner.id === userCookie.user_id) {
          type = numOpenShifts;
        }else {
          if(shift.coverer) {
            if(shift.coverer.id === userCookie.user_id) {
              type = scheduled;
            }
          }else {
            if(shift.current_applicant_status && shift.current_applicant_status !== 'denied') {
              type = numOpenShifts;
            }
          }
        }
        break;
      case 'shift_inventory':
        if(shift.owner.id === userCookie.user_id) {
          type = scheduled;
        }else {
          type = teamShift;
        }
        break;
    }
    

	  if(locationChannels && locationChannels.length > 0) {
      const idx = locationChannels.findIndex((channel) => channel.id === shift.channel_id);
      if(idx >= 0) {
        if(locationChannels[idx].channel_type === 'region_feed') {
          type = numRegionalShifts;
        }
      }
    }

  	return type;
  }

  statusMessage = () => {
  	const {shift, userCookie, onMarketplace, userPrivileges } = this.props;
  	const {ownerFullName, covererFullName, pendingApplicants, 
          approvedApplicants, deniedApplicants, firstApplicantName, currentUserFullName, firstDeniedApplicantName } = this.state;

    let allPositionFilled = false;
    if(shift.name === 'shift') {
      if(approvedApplicants === 1) {
        allPositionFilled = true;
      }
    }else {
      //when all slots are covered, pending applicants are auto-denied
      if(shift.available_shifts_covered === shift.total_shifts_available) {
        allPositionFilled = true;
      }
    }
  	let message = '';
  	const msgMyShift = (<FormattedMessage
		                id="ShiftCardNew.myShift"
		                defaultMessage="{name} is scheduled to work this shift." 
		                values={{name: ownerFullName}}/>);
    const msgMyInventoryShift = (<FormattedMessage
                    id="ShiftCardNew.myInventoryShift"
                    defaultMessage="{name} is scheduled to start this shift soon." 
                    values={{name: ownerFullName}}/>);
    const msgMyVTO = (<FormattedMessage
                    id="ShiftCardNew.myVTO"
                    defaultMessage="{name} is scheduled to work this VTO." 
                    values={{name: ownerFullName}}/>);
  	const msgCoveredTeamShift = (<FormattedMessage
		                id="ShiftCardNew.coveredTeamShift"
		                defaultMessage="{coverer} has been approved to cover {owner}'s shift." 
		                values={{coverer: covererFullName, owner: ownerFullName}}/>);
  	const msgMyCoveredOpenShift = (<FormattedMessage
		                id="ShiftCardNew.myCoveredOpenShift"
		                defaultMessage="{coverer} has been approved to cover this shift." 
		                values={{coverer: covererFullName}}/>);
    const msgMyCoveredVTO = (<FormattedMessage
                    id="ShiftCardNew.myCoveredVTO"
                    defaultMessage="{coverer} has been approved to take this VTO." 
                    values={{coverer: covererFullName}}/>);
  	const msgApprovedVTO = (<FormattedMessage
		                id="ShiftCardNew.approvedVTO"
		                defaultMessage="{coverer} has been approved to take this VTO." 
		                values={{coverer: covererFullName}}/>);
    const msgPendingShift = (<FormattedMessage
                    id="ShiftCardNew.pendingShift"
                    defaultMessage="{applicant} has applied to cover {owner}'s shift." 
                    values={{applicant: firstApplicantName, owner: ownerFullName}}/>);
    const msgMyPendingShift = (<FormattedMessage
                    id="ShiftCardNew.myPendingShift"
                    defaultMessage="{applicant} has applied to cover this shift." 
                    values={{applicant: currentUserFullName}}/>);
    const msgPendingShiftMoreApplicants = (<FormattedMessage
                    id="ShiftCardNew.myPendingShiftMoreApplicants"
                    defaultMessage="{number} applicants are pending. Time to review applicants." 
                    values={{number: shift.current_applicants_pending_user_ids.length}}/>);
    const msgPendingVTO = (<FormattedMessage
                    id="ShiftCardNew.myPendingVTO"
                    defaultMessage="{applicant} has offered to take voluntory time off." 
                    values={{applicant: firstApplicantName}}/>);
    const msgPostedTeamShift = (<FormattedMessage
                    id="ShiftCardNew.postedTeamShift"
                    defaultMessage="{owner}: {content}" 
                    values={{owner: ownerFullName, content: shift.content}}/>);
    const msgPostedTeamShift1 = (<FormattedMessage
                    id="ShiftCardNew.postedTeamShift1"
                    defaultMessage="{owner} is looking for someone to cover this shift." 
                    values={{owner: ownerFullName}}/>);
    const msgPostedOpenShift = (<FormattedMessage
                    id="ShiftCardNew.postedOpenShift"
                    defaultMessage="{owner} has posted  {number, plural, one {{number} Open Shift} other {{number} Open Shifts}}. Are you available to cover?" 
                    values={{owner: ownerFullName, number: shift.total_shifts_available}}/>);
    const msgRejectedTeamShift = (<FormattedMessage
                    id="ShiftCardNew.rejectedTeamShift"
                    defaultMessage="{applicant} was denied covering this shift for {owner}" 
                    values={{applicant: currentUserFullName, owner: ownerFullName}}/>);
    const msgTeamShiftApplicantDenied = (<FormattedMessage
                    id="ShiftCardNew.teamShiftApplicantDenied"
                    defaultMessage="{applicant} was denied covering this shift for {owner}" 
                    values={{applicant: firstDeniedApplicantName, owner: ownerFullName}}/>);
    const msgNumApproved = (<FormattedMessage
                    id="ShiftCardNew.numAppApproved"
                    defaultMessage="{approved, plural, one {{approved} applicant is approved} other {{approved} applicants are approved}}."
                    values={{approved: approvedApplicants}}/>);
    const msgNumPending = (<FormattedMessage
                    id="ShiftCardNew.numAppPending"
                    defaultMessage="{pending, plural, one {{pending} applicant is pending} other {{pending} applicants are pending}}."
                    values={{pending: pendingApplicants}}/>);
    const msgNumDenied = (<FormattedMessage
                    id="ShiftCardNew.numAppDenied"
                    defaultMessage="{denied, plural, one {{denied} applicant is denied} other {{denied} applicants are denied}}."
                    values={{denied: deniedApplicants}}/>);
    const msgNumPendingApproved =(<FormattedMessage
                    id="ShiftCardNew.numPendingApproved"
                    defaultMessage="{pending} {lineBreaker} {approved}"
                    values={{pending: <text>{msgNumPending}</text>, approved: <text>{msgNumApproved}</text>, lineBreaker: <br/>}}/>);
    const msgNumPendingDenied =(<FormattedMessage
                    id="ShiftCardNew.numPendingDenied"
                    defaultMessage="{pending} {lineBreaker} {denied}"
                    values={{pending: <text>{msgNumPending}</text>, denied: <text>{msgNumDenied}</text>, lineBreaker: <br/>}}/>);
    
    const msgNumApprovedDenied2 =(<FormattedMessage
                    id="ShiftCardNew.numApprovedDenied2Lines"
                    defaultMessage="{top} {lineBreaker} {bottom}"
                    values={{top: <text>{msgNumApproved}</text>, bottom: <text>{msgNumDenied}</text>, lineBreaker: <br/>}}/>);
    
    const msgPostedVTO = (<FormattedMessage
                    id="ShiftCardNew.postedVTO"
                    defaultMessage="{owner} has posted  {number, plural, one {{number} VTO} other {{number} VTOs}}. Want to take the day off?" 
                    values={{owner: ownerFullName, number: shift.total_shifts_available}}/>);
    const msgRejectedVTO = (<FormattedMessage
                    id="ShiftCardNew.rejectedVTO"
                    defaultMessage="{applicant} has not been approved to take this VTO." 
                    values={{applicant: currentUserFullName}}/>);
  	const status = shiftStatus(shift, userCookie);
    switch(shift.name) {
      case 'shift':
        switch(status) {
          case shiftStatuses.YOUR_PENDING:
            if(shift.current_applicants_pending_user_ids.length > 1) {
              if(shift.owner.id === userCookie.user_id) {
                if(shift.current_applicants_denied_user_ids.length === 0) {
                  message = msgPendingShiftMoreApplicants;
                }else {
                  message = msgNumPendingDenied;
                }
              }else {
                message = msgPendingShift;
              }
            }else {
              message = msgPendingShift;
            }
            break;
          case shiftStatuses.YOUR_APPROVED:
            message = msgCoveredTeamShift;
            break;
          case shiftStatuses.COVERING_APPROVED:
            message = msgCoveredTeamShift;
            break;
          case shiftStatuses.YOUR_POSTED:
            if(shift.current_applicants_pending_user_ids.length === 0 &&
              shift.current_applicants_approved_user_ids.length === 0 &&
              shift.current_applicants_denied_user_ids.length === 0) {
              if(shift.content) {
                message = `${ownerFullName}: ${shift.content}`;
              }else {
                message = msgPostedTeamShift1;
              }
            }else {
              if(shift.current_applicants_denied_user_ids.length > 0) {
                message = msgTeamShiftApplicantDenied;
              }
            }
          break;
        }
        break;
      case 'shift_inventory':
        message = this.scheculedShiftMessage();
        break;
    }
	  return message;
  }

  tradeStatus = () => {
  	const { shift, userCookie, onMarketplace, } = this.props;
    const {ownerFullName, covererFullName, pendingApplicants, 
          approvedApplicants, deniedApplicants, firstApplicantName, currentUserFullName, firstDeniedApplicantName } = this.state;

  	const tradeStatusPosted = (<FormattedMessage
                id="ShiftCardNew.tradeStatusPosted"
                defaultMessage="Posted" />);
    const tradeStatusInventory = (<FormattedMessage
                id="ShiftCardNew.tradeStatusInventory"
                defaultMessage="Inventory" />);
    const tradeStatusScheduled = (<FormattedMessage
                id="ShiftCardNew.tradeStatusScheduled"
                defaultMessage="Scheduled" />);
    const tradeStatusScheduled1 = (<FormattedMessage
                id="ShiftCardNew.tradeStatusScheduled1"
                defaultMessage="Scheduled" />);
  	const tradeStatusPending = (<FormattedMessage
                id="ShiftCardNew.tradeStatusPending"
                defaultMessage="Pending" />);
  	const tradeStatusApproved = (<FormattedMessage
                id="ShiftCardNew.tradeStatusApproved"
                defaultMessage="Approved" />);
  	const tradeStatusDenied = (<FormattedMessage
                id="ShiftCardNew.tradeStatusDenied"
                defaultMessage="Denied" />);
    const tradeStatusTransfered = (<FormattedMessage
                id="ShiftCardNew.tradeStatusTransfered"
                defaultMessage="Transfered" />);

  	let obj = {tradeStatus: '', tradeStatusClassName: '', isScheduledTag: false, };
    let allPositionFilled = false;
    if(shift.name === 'shift') {
      if(approvedApplicants === 1) {
        allPositionFilled = true;
      }
    }else {
      //when all slots are covered, pending applicants are auto-denied
      if(shift.available_shifts_covered === shift.total_shifts_available) {
        allPositionFilled = true;
      }
    }

    if(shift.id === 3950724) {
      //console.log('here');
    }

  	const status = shiftStatus(shift, userCookie);
		switch (status) {
			case shiftStatuses.AVAILABLE:
		    obj.tradeStatus = tradeStatusPosted;
				obj.tradeStatusClassName = 'inventory-card__trade-status-posted';
	      break;

	    case shiftStatuses.YOUR_UNPOSTED:
        //obj.tradeStatus = tradeStatusInventory;
        obj.tradeStatus = tradeStatusScheduled1;
        obj.tradeStatusClassName = 'inventory-card__trade-status-scheduled';
        obj.isScheduledTag = true;
        break;
	    case shiftStatuses.YOUR_SHIFT:
	    case shiftStatuses.YOUR_POSTED:
		    obj.tradeStatus = tradeStatusPosted;
				obj.tradeStatusClassName = 'inventory-card__trade-status-posted';
        if(onMarketplace) {
          if(shift.total_shifts_available === shift.available_shifts_covered) {
            obj.tradeStatus = tradeStatusApproved;
            obj.tradeStatusClassName = 'inventory-card__trade-status-approved';
          }else {
            obj.tradeStatus = tradeStatusPosted;
            obj.tradeStatusClassName = 'inventory-card__trade-status-posted';
          }
        }
	      break;

	    case shiftStatuses.YOUR_APPROVED:
        if(shift.name === 'shift') {
          if(onMarketplace) {
            obj.tradeStatus = tradeStatusTransfered;
            obj.tradeStatusClassName = 'inventory-card__trade-status-approved';
          }else {
            //obj.tradeStatus = tradeStatusApproved;
            obj.tradeStatus = tradeStatusTransfered;
            obj.tradeStatusClassName = 'inventory-card__trade-status-approved';
          }
        }else {
          obj.tradeStatus = tradeStatusApproved;
          obj.tradeStatusClassName = 'inventory-card__trade-status-approved';
        }
        break;
	    case shiftStatuses.VTO_APPROVED:
	      obj.tradeStatus = tradeStatusApproved;
				obj.tradeStatusClassName = 'inventory-card__trade-status-approved';
	      break;

	    case shiftStatuses.YOUR_PENDING:
	    case shiftStatuses.VTO_PENDING:
        obj.tradeStatus = tradeStatusPending;
        obj.tradeStatusClassName = 'inventory-card__trade-status-pending';
	      break;

	    case shiftStatuses.YOUR_REJECTED:
	    case shiftStatuses.COVERING_REJECTED:
        if(shift.name === 'shift') {
          const idx = shift.current_applicants_denied_user_ids.findIndex((id) => id === userCookie.user_id);
          if(idx >= 0) {
            obj.tradeStatus = tradeStatusDenied;
            obj.tradeStatusClassName = 'inventory-card__trade-status-rejected';
          }
        }else {
          if(allPositionFilled) {
            if(shift.owner.id === userCookie.user_id) {
              obj.tradeStatus = tradeStatusApproved;
              obj.tradeStatusClassName = 'inventory-card__trade-status-approved';
            }else {
              const idx = shift.current_applicants_denied_user_ids.findIndex((id) => id === userCookie.user_id);
              if(idx >= 0) {
                obj.tradeStatus = tradeStatusDenied;
                obj.tradeStatusClassName = 'inventory-card__trade-status-rejected';
              }
            }
          }else {
            if(shift.owner.id === userCookie.user_id) {
              if(shift.trade_status === 'rejected') {
                obj.tradeStatus = tradeStatusDenied;
                obj.tradeStatusClassName = 'inventory-card__trade-status-rejected';
              }
            }else {
              const idx = shift.current_applicants_denied_user_ids.findIndex((id) => id === userCookie.user_id);
              if(idx >= 0) {
                obj.tradeStatus = tradeStatusDenied;
                obj.tradeStatusClassName = 'inventory-card__trade-status-rejected';
              }
            }
          }
        }
        break;
	    case shiftStatuses.COVERING_APPROVED:
	    case shiftStatuses.YOUR_COVERED:
	      obj.tradeStatus = tradeStatusApproved;
        obj.tradeStatusClassName = 'inventory-card__trade-status-approved'
	      break;

	    case shiftStatuses.COVERING_PENDING:
	      obj.tradeStatus = tradeStatusPending;
				obj.tradeStatusClassName = 'inventory-card__trade-status-pending';
	      break;

	    default:
	      break;
		}
		return obj;
  }

  renderTradeStatus = () => {
    const { shift } = this.props;
    const { showCallOffLabel } = this.state;

    const obj = this.tradeStatus();

    let shiftTradeStatus = obj.tradeStatus;
    let tradeStatusClassName = obj.tradeStatusClassName;
    let scheduledTag = obj.isScheduledTag;
    let scheduledText = '';

    const startDateTime = moment(shift.start_at);
    const endDateTime = moment(shift.end_at);
    const current = moment();
    const startToNow = moment.duration(startDateTime.diff(current));
    const endToNow = moment.duration(endDateTime.diff(current));
    const hoursStartToNow = parseInt(startToNow.asHours());
    const hoursEndToNow = parseInt(endToNow.asHours());
    const minutesStartToNow = parseInt(startToNow.asMinutes());

    const currentDate = moment().format('YYYY-MM-DD');
    const diffDays = moment.duration(startDateTime.diff(currentDate));
    const daysStartToNow = parseInt(diffDays.asDays());
    
    const tradeStatusCalledOff = (<FormattedMessage
                id="ShiftCardNew.tradeStatusCalledOff"
                defaultMessage="Called Off" />);
    const daysLeft = (<FormattedMessage
                    id="ShiftCardNew.daysLeft"
                    defaultMessage="{days, plural, one {{days} day} other {{days} days}}" 
                    values={{days: daysStartToNow}}/>);

    const hoursLeft = (<FormattedMessage
                    id="ShiftCardNew.hoursLeft"
                    defaultMessage="{hours, plural, one {{hours} hour} other {{hours} hours}}" 
                    values={{hours: hoursStartToNow}}/>);
    const minutesLeft = (<FormattedMessage
                    id="ShiftCardNew.minutesLeft"
                    defaultMessage="{minutes, plural, one {{minutes} minute} other {{minutes} minutes}}" 
                    values={{minutes: minutesStartToNow}}/>);
    const onShift = (<FormattedMessage
                id="ShiftCardNew.onShift"
                defaultMessage="On Shift" />);
    if(showCallOffLabel) {
      return (<label className="inventory-card__trade-status-rejected">{tradeStatusCalledOff}</label>);
    }else {
      if(!scheduledTag) {
        return (<label className={tradeStatusClassName}>{shiftTradeStatus}</label>);
      }else {
         //check how long time left from shift start to now
        if(hoursStartToNow > 72) {
          return (
            <div className="inventory-card__scheduled-tag-wrap">
               <span className="icon-clock" />
              <label className={tradeStatusClassName}>{shiftTradeStatus}</label>
            </div>
          );
        }else if(hoursStartToNow < 72 && hoursStartToNow >= 24) {
          return (
            <div className="inventory-card__scheduled-tag-wrap">
               <span className="icon-clock" />
              <label className={tradeStatusClassName}>{daysLeft}</label>
            </div>
          );
        }else {
          if(hoursStartToNow >= 1) {
            return (<label className="inventory-card__trade-status-pending">{hoursLeft}</label>);
          }else {
            if(hoursStartToNow >= 0) {
              if(minutesStartToNow > 0) {
                return (<label className="inventory-card__trade-status-pending">{minutesLeft}</label>);
              }else {
                return (<label className="inventory-card__trade-status-approved">{onShift}</label>);
              }
            }else {
              return (<label className="inventory-card__trade-status-approved">{onShift}</label>);
            }
          }
        }
      }
    }
  }

  scheculedShiftMessage = () => {
    const { shift } = this.props;
    const { ownerFullName, callOffReason1, callOffReason2 } = this.state;

    const startDateTime = moment(shift.start_at);
    const endDateTime = moment(shift.end_at);
    let current = moment();
    let startToNow = moment.duration(startDateTime.diff(current));
    let endToNow = moment.duration(endDateTime.diff(current));
    let hoursStartToNow = parseInt(startToNow.asHours());
    let hoursEndToNow = parseInt(endToNow.asHours());
    let daysStartToNow = 0;
    let reason1_content = '';
    let reason2_content = '';
    let reasons = '';

    if(callOffReason2) {
      if(shift && callOffReason1 && callOffReason2) {
        const idx = callOffReason1.findIndex(
          (reason) => reason.tag_key === shift.call_off_reasons_1_tag_key
        );
        if(idx >=0){
          reason1_content = callOffReason1[idx].tag_content;
        }
        const idx2 = callOffReason2.findIndex(
            (reason) => reason.tag_key === shift.call_off_reasons_2_tag_key
        );
        if(idx2 >=0){
          reason2_content = callOffReason2[idx2].tag_content;
        }
        if(reason2_content && shift.call_off_reasons_2_tag_key !== 'call_off_reason_protected_sick_leave_1_no') {
          reasons = `${reason1_content} . ${reason2_content}`;
        }else {
          reasons = reason1_content;
        }
      } else {
        console.log('CalledOffShift: callOffReason1 or callOffReason2 not found.');
      }
    }else {
      if(shift && callOffReason1) {
        const idx = callOffReason1.findIndex(
          (reason) => reason.tag_key === shift.call_off_reasons_1_tag_key
        );
        if(idx >=0){
          reason1_content = callOffReason1[idx].tag_content;
        }
        reasons = reason1_content;
      } else {
        console.log('CalledOffShift: callOffReason1 not found.');
      }
    }

    const msgMoreThan3Days = (<FormattedMessage
                    id="ShiftCardNew.moreThan3Days"
                    defaultMessage="{name} is scheduled to work this shift." 
                    values={{name: ownerFullName}}/>);
    const msgMoreThan3Days1 = (<FormattedMessage
                    id="ShiftCardNew.moreThan3Days1"
                    defaultMessage="{name} is scheduled to work this shift." 
                    values={{name: ownerFullName}}/>);
    const msgLessThan3Days = (<FormattedMessage
                    id="ShiftCardNew.lessThan3Days"
                    defaultMessage="{name} is scheduled to start this shift soon." 
                    values={{name: ownerFullName}}/>);
     const msgLessThan3Days1 = (<FormattedMessage
                    id="ShiftCardNew.lessThan3Days1"
                    defaultMessage="{name} is scheduled to start this shift soon." 
                    values={{name: ownerFullName}}/>);
    const msgDuringShift = (<FormattedMessage
                    id="ShiftCardNew.duringShift"
                    defaultMessage="{name} is currently working this shift." 
                    values={{name: ownerFullName}}/>);
    const msgAfterShift = (<FormattedMessage
                    id="ShiftCardNew.afterShift"
                    defaultMessage="{name} has completed working this shift." 
                    values={{name: ownerFullName}}/>);
    const msgCallOffShift = (<FormattedMessage
                    id="ShiftCardNew.callOffShift"
                    defaultMessage="{name} has called off this shift." 
                    values={{name: ownerFullName}}/>);
    const msgCallOffShift1 = (<FormattedMessage
                    id="ShiftCardNew.callOffShift1"
                    defaultMessage="{name} has called off this shift. {reason} " 
                    values={{name: ownerFullName, reason: reasons}}/>);
    const msgCallOffShift2 = (<FormattedMessage
                    id="ShiftCardNew.callOffShift2"
                    defaultMessage="{name} has called off this shift {dot} {reason} " 
                    values={{name: ownerFullName, dot: <span className="colored-circle-small"/>, reason: reasons}}/>);

    if(shift.id === 3950016) {
      //console.log('here');
    }
    if(shift.trade_status === 'called_off') {
      return msgCallOffShift;
    }else {
      if(hoursStartToNow > 72 ) {
        return msgMoreThan3Days1;
      }else if(hoursStartToNow < 72) {
        return msgLessThan3Days1;
      }else if(current.isAfter(startDateTime) && current.isBefore(endDateTime)) {
        return msnDuringShift;
      }else if(current.isAfter(endDateTime)) {
        return msgAfterShift;
      }
    }
  }

  render () {
  	const { shift, userCookie, onMarketplace, userPrivileges } = this.props;
  	const { debug, debugInfo, posterIsAdmin, covererIsAdmin, ownerFullName, covererFullName, jobTitle, 
  					ownerAvatar, covererAvatar, statusBarColor, firstIncentiveAmount, showCallOffLabel, tipAmount } = this.state;

  	const currentUserFullName = userCookie ? `${userCookie.first_name} ${userCookie.last_name}` : '';

  	const shiftType = this.shiftType();
  	const messageOnCard = this.statusMessage();
    const currentUserAvatar = userCookie.profile_image ? userCookie.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
    
  	let showCovererAvatar = false;
    if(shift.id === 3949663) {
      //console.log('here');
    }
  	if(shift.name === 'shift') {
  		if(shift.current_applicants_approved_user_ids.length === 1) {
  			showCovererAvatar = true;
  		}
  	}else {
  		if(shift.trade_status === 'approved') {
  			showCovererAvatar = true;
  		}
  	}

  	const statusBarClassName = this.statusBarClassname();

  	return (
  		// eslint-disable-next-line
  		<div className="">
  			{debug && <label>{debugInfo}</label>}
	      <div className="inventory-card" onClick={this._handleCardClick}>
	      	<div className="inventory-card__top-wrap">
	      		<span className={statusBarClassName}/>
	      		<div className="inventory-card__top-text-wrap">
	      			<div className="inventory-card__title-type-wrap">
	      				<label className="inventory-card__jobTitle">{jobTitle}</label>
	      				<span className="colored-circle"/>
	      				<label className="inventory-card__shiftType">{shiftType}</label>
	      			</div>
	      			<label className="inventory-card__Time-text">{this.renderTime()}</label>
	      		</div>
            <div className="inventory-card__status-incentive-wrap">
              {this.renderTradeStatus()}
              {shift.name === 'open_shift' && firstIncentiveAmount && 
              <label className="inventory-card__incentive-label">{firstIncentiveAmount}</label>}
              {shift.name === 'shift' && tipAmount && 
              <label className="inventory-card__incentive-label">{tipAmount}</label>}
            </div>
	      	</div>
	      	<div className="inventory-card__bottom-wrap">
	      		{showCovererAvatar ? <div className="inventory-card__avatars-wrap">
	      				<div className="inventory-card__thumb_wrap">
	      					<img className="inventory-card__avatar" src={ownerAvatar}/>
	      				</div>
	      				{/*posterIsAdmin && <img className="inventory-card__verified-badge" src='/icons/verified-badge-icon.svg'/>*/}
                <div className="inventory-card__thumb-wrap">
                  <img className="inventory-card__avatar" src={covererAvatar}/>
                </div>
                {posterIsAdmin && <img className="inventory-card__verified-badge" src='/icons/verified-badge-icon.svg'/>}
                {covererIsAdmin && <img className="inventory-card__verified-badge-right" src='/icons/verified-badge-icon.svg'/>}
	      			</div>:
  						<div className="inventory-card__avatars-wrap-single">
  							<div className="">
                  <img className="inventory-card__avatar" src={ownerAvatar}/>
                </div>
                {posterIsAdmin && <img className="inventory-card__verified-badge" src='/icons/verified-badge-icon.svg'/>}
  						</div>
  						}
  						<label className="inventory-card__message">{messageOnCard}</label>
	      	</div>
	      </div>
      </div>

  	);
  }

}

InventoryShiftCard.propTypes = {
  costCenterData: PropTypes.shape({}).isRequired,
  userCookie: PropTypes.shape({}).isRequired,
  shift: PropTypes.shape({}).isRequired,
  locationSettings: PropTypes.shape({}).isRequired,
  _toggleModal: PropTypes.func.isRequired,
  userPrivileges: PropTypes.object.isRequired,
  locationChannels: PropTypes.shape({}).isRequired,
};

export default injectIntl (InventoryShiftCard);

