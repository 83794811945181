import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { getSubscriptions } from "_helpers/api-calls";
import { apiCallError } from "_helpers/errors";
import { createFullName } from "_helpers/formatting";
import { DataNames } from "../constants";
import { RadioButton, DateTimeButton, DateTimePicker } from "../components";
import { ModalTypes, shiftStatuses, shiftOwner } from '_constants';

class Post extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
      subscriptions: [],
      timer: null,
      findCallOffReason1: false,
      findCallOffReason2: false,
      enableCallOff: false,
      tip: '',
      locationAndCustom: [],
      allTypeChannels: [],
    };


    this._isMounted = false;
  }
  

  componentDidMount() {
    const { channels, userCookie, selectedShift, jobTitle } = this.props;

    this._isMounted = true;
    let myTimer = setInterval(this.tick, 3000); //unit: ms, 3000 = 3 seconds
    this.setState({timer: myTimer});

    // Fixing bug WS-1244
    getSubscriptions(channels.location_feed.length > 0 ? channels.location_feed[0].associated_location_id : userCookie.location_id)
    .then((response) => {
      this.setState({ subscriptions: response.data.users });
    })
    .catch((error) => {
      apiCallError(error);
    });

    if(channels) {
      if(channels.custom_feed.length > 0) {
        const toRemove = channels.custom_feed.findIndex((channel) => channel.shyft_channel === true);
        if(toRemove >= 0) {
          channels.custom_feed.splice(toRemove, 1);
        }
      }
      const twoTypeChannels = channels.location_feed.concat(channels.custom_feed);
      const allTypeChannels = twoTypeChannels.concat(channels.region_feed);
      if(this._isMounted) {
        this.setState({locationAndCustom: twoTypeChannels});
        this.setState({allTypeChannels: allTypeChannels});
      }
    }
  }

  componentDidUpdate (prevProps) {
    const { selectedShift } = this.props;
  }

  componentWillUnmount () {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  _renderGroups = () => {
    const { channels, reachValue, _updateState } = this.props;
    const groups = channels.custom_feed.map((group) => (
      <div
        key={group.id}
        className="post-shift__radio-item post-shift__radio-item--groups"
      >
        <RadioButton
          checked={reachValue === group.channel_name}
          id={group.id}
          label={group.channel_name}
          onChange={_updateState}
        />
        <div className="post-shift__member-count">
          <FormattedMessage
            id="Post.memberCount"
            defaultMessage="{memberCount} members"
            values={{ memberCount: group.member_count }}
          />
        </div>
      </div>
    ));

    if (groups[0]) {
      return groups;
    }
    return (
      <div className="post-shift__radio-item post-shift__radio-item--groups">
        <div className="post-shift__team-list__member">
          <FormattedMessage id="Post.noGroups" defaultMessage="No groups" />
        </div>
      </div>
    );
  }; 

  renderRegionGroups = () => {
    const { channels, reachValue, _updateState } = this.props;
    const groups = channels.region_feed.map((group) => (
      <div
        key={group.id}
        className="post-shift__radio-item post-shift__radio-item--groups"
      >
        <RadioButton
          checked={reachValue === group.channel_name}
          id={group.id}
          label={group.channel_name}
          onChange={_updateState}
        />
        <div className="post-shift__member-count">
          <FormattedMessage
            id="Post.memberCount"
            defaultMessage="{memberCount} members"
            values={{ memberCount: group.member_count }}
          />
        </div>
      </div>
    ));

    if (groups[0]) {
      return groups;
    }
    return (
      <div className="post-shift__radio-item post-shift__radio-item--groups">
        <div className="post-shift__team-list__member">
          <FormattedMessage id="Post.noGroups" defaultMessage="No groups" />
        </div>
      </div>
    );
  };

  renderCustomGroups = () => {
    const { channels, reachValue, _updateState } = this.props;
    const { locationAndCustom, } = this.state;

    const groups = locationAndCustom.map((group) => (
      <div
        key={group.id}
        className="post-shift__radio-item post-shift__radio-item--groups"
      >
        <RadioButton
          checked={reachValue === group.channel_name}
          id={group.id}
          label={group.channel_name}
          onChange={_updateState}
        />
        <div className="post-shift__member-count">
          <FormattedMessage
            id="Post.memberCount"
            defaultMessage="{memberCount} members"
            values={{ memberCount: group.member_count }}
          />
        </div>
      </div>
    ));

    if (groups[0]) {
      return groups;
    }
    return (
      <div className="post-shift__radio-item post-shift__radio-item--groups">
        <div className="post-shift__team-list__member">
          <FormattedMessage id="Post.noGroups" defaultMessage="No groups" />
        </div>
      </div>
    );
  };



  _renderMembers = () => {
    const { userCookie } = this.props;
    const { subscriptions } = this.state;
    const members = subscriptions.map((sub) => {
      if (sub.id !== userCookie.user_id) {
        const fullName = createFullName(sub);
        return (
          <div key={sub.id} className="post-shift__team-list__member">
            {fullName}
          </div>
        );
      }

      return null;
    });

    return members;
  };

  _toggleDatePicker = () => {
    this.setState((prevState) => ({
      showDatePicker: !prevState.showDatePicker,
    }));
  };

  tick = () => {
    const {selectedShift, costCenterData, locationSettings } = this.props;

    if(costCenterData){
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_1'){
          this.setState({findCallOffReason1: true})
        }
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_2'){
          this.setState({findCallOffReason2: true})
        }
      }
    }

    //check if the shift expired every 100 seconds (1 minutes 40 seconds)
    if(selectedShift) {
      const startDateTime = moment(selectedShift.start_at);
      const endDateTime = moment(selectedShift.end_at);
      let current = moment();
      let startToNow = moment.duration(startDateTime.diff(current));
      let endToNow = moment.duration(endDateTime.diff(current));
      let minutesStartToNow = parseInt(startToNow.asMinutes());
      let minutesEndToNow = parseInt(endToNow.asMinutes());
      //if the shift starts within 24 hours and the shift has not ended yet
      //the shift can be called off
      if(locationSettings && locationSettings.cfg__allow__call_off) {
        if(selectedShift.trade_status === 'inventory') {
          if(this.state.findCallOffReason1){
            if(minutesStartToNow < 1440 && minutesEndToNow > 0){
              this.setState({enableCallOff: true});
            }
          }
        }
      }
    }
  };

  handleCallOffClick = (event) => {
    const {
      selectedShift,
      userCookie,
      _toggleModal,
      _updateSelectedShift,
    } = this.props;
    //because this button is on the shift card, both the button and the card get clicked. 
    //The stopPropagation() method allows you to prevent propagation of the current event.
    event.stopPropagation();
    // important: parameter 'selectedShift' has to be there to get 'selectedShift' in CallOffShift
    _toggleModal(ModalTypes.CALL_OFF_SHIFT, selectedShift);
    event.preventDefault()
  };

  handleDeleteClick = (event) => {
    const {
      selectedShift,
      userCookie,
      _toggleModal,
      _updateSelectedShift,
    } = this.props;

    _toggleModal(ModalTypes.DELETE_SHIFT, selectedShift.id);
    event.preventDefault();
  };

  handleJobTitleChange = (event) => {
    event.preventDefault();
    if(this._isMounted) {
      this.setState({inputJobTitle: event.target.value});
    }
  };

  handleTipChange = (event) => {
    event.preventDefault();
    if(this._isMounted) {
      this.setState({tip: event.target.value});
    }
  };


  render() {
    const {
      _canProgress,
      dateString,
      timeRangeString,
      _updateState,
      date,
      commentsValue,
      reachValue,
      channels,
      _handleSubmit,
      locale,
      disablePicker,
      userCookie,
      selectedShift,
      jobTitle,
      channel_id,
      tip,
    } = this.props;

    const { showDatePicker, enableCallOff, locationAndCustom, allTypeChannels, } = this.state;

    const dtpWrapClass = classNames({
      "post-shift__dtp-wrap": true,
      "post-shift__dtp-wrap--show": showDatePicker,
    });
    const submitBtnClass = classNames({
      "post-shift__submit-btn": true,
      "post-shift__submit-btn--active": _canProgress(),
    });

    let members = 0;
    if(channel_id) {
      const idx = allTypeChannels.findIndex(
        (channel) => channel.id === channel_id
      );
      if(idx >= 0) {
        members = allTypeChannels[idx].member_count;
      }
    }

    const labelLocation = (<FormattedMessage
                    id="Post.location"
                    defaultMessage="Location ({members})" 
                    values={{members: channels.custom_feed.length}}/>);
    const labelRegional = (<FormattedMessage
                    id="Post.regional"
                    defaultMessage="Regional ({members})" 
                    values={{members: channels.region_feed.length}}/>);
    const postToTeamMemebers = (<FormattedMessage
                    id="Post.toTeamMember"
                    defaultMessage="Post to {number, plural, one {{number} Team Member} other {{number} Team Memebers}}" 
                    values={{number: members}}/>);

    return (
      <>
        <h1>
          <FormattedMessage
            id="Post.postShiftHeading"
            defaultMessage="Post a Shift"
          />
        </h1>
        <div className="modal-components__row">
          <div className="post-shift__top-row-wrap">
            <div className="post-shift__top-row-item">
              <p className="modal-components__label">
                <FormattedMessage
                  id="Post.shiftPeriod"
                  defaultMessage="Shift Period"
                />
              </p>
              <DateTimeButton
                _toggleDatePicker={disablePicker ? null : this._toggleDatePicker}
                dateString={dateString}
                timeRangeString={timeRangeString}
                disabled={disablePicker}
              />
              <div className={dtpWrapClass}>
                <DateTimePicker
                  _updateState={_updateState}
                  _toggleDatePicker={this._toggleDatePicker}
                  date={date}
                  locale={locale}
                />
              </div>
            </div>
            <div className="post-shift__top-row-item">
              <p className="modal-components__label">
                <FormattedMessage
                  id="Post.jobTitle"
                  defaultMessage="Job Title"
                />
              </p>
              {/*disablePicker ? <input
                className="post-shift__job-tip"
                value={jobTitle}
                readOnly={true}
                />
                : <input
                className="post-shift__job-tip"
                onChange={(e) => _updateState(DataNames.JOB, e.target.value)}
                value={jobTitle}
              />*/}
              <input
                className="post-shift__job-tip"
                onChange={(e) => _updateState(DataNames.JOB, e.target.value)}
                value={jobTitle}
              />
            </div>
            <div className="post-shift__top-row-item">
              <p className="modal-components__label">
                <FormattedMessage
                  id="Post.tip"
                  defaultMessage="Tip (Optional)"
                />
              </p>
              <input
                className="post-shift__job-tip"
                //onChange={this.handleTipChange}
                onChange={(e) => _updateState(DataNames.TIP, e.target.value)}
                value={tip}
              />
            </div>
          </div>
        </div>
        <div className="modal-components__row">
          <div className="modal-components__col">
            <p className="modal-components__label">
              <FormattedMessage
                id="Post.shiftDescriptionLabel"
                defaultMessage="Please describe the skills or daily duties required for this shift and why the shift needs to be covered."
              />
            </p>
            <textarea
              className="post-shift__comments"
              onChange={(e) => _updateState(DataNames.COMMENTS, e.target.value)}
              value={commentsValue}
            />
          </div>
        </div>
        <div className="modal-components__row">
          <div className="modal-components__col">
            <p className="modal-components__label">
              <FormattedMessage
                id="Post.shiftReachLabel"
                defaultMessage="This post will reach"
              />
            </p>
          </div>
           <div className="modal-components__col modal-components__col--reach">
            <label className="post-shift__label-bold">{labelLocation}</label>
            <div className="post-shift__col-wrap post-shift__col-wrap--groups">
              {this.renderCustomGroups()}
            </div>
          </div>
          <div className="modal-components__col modal-components__col--reach">
            <label className="post-shift__label-bold">{labelRegional}</label>
            <div className="post-shift__col-wrap post-shift__col-wrap--groups">
              {/*this._renderGroups()*/}
              {this.renderRegionGroups()}
            </div>
          </div>
        </div>
        <div className="modal-components__row">
          <div className="modal-components__col modal-components__col--submit">
             {/*(selectedShift && selectedShift.trade_status === 'inventory' && enableCallOff) &&
             <button className="post-shift__btn-call-off" onClick={this.handleCallOffClick} type="button">
              <FormattedMessage
                id="Post.callOffShift"
                defaultMessage="Call Off Shift"
              />
            </button>*/}

            {/*<button
              className={submitBtnClass}
              onClick={_handleSubmit}
              type="button"
            >
              <FormattedMessage id="Post.nextButton" defaultMessage="Next" />
              &nbsp;
              <span className="icon-submit-arrow" />
            </button>*/}
             <button className={submitBtnClass} onClick={_handleSubmit} type="button">
              {postToTeamMemebers}
            </button>
          </div>
        </div>  
        <div className="modal-components__row">
          <div className="modal-components__col modal-components__col--submit">
            {/*(selectedShift && selectedShift.allow_delete && selectedShift.trade_status === 'inventory') && 
            <button className="post-shift__btn-delete" onClick={this.handleDeleteClick}>
               <FormattedMessage
                id="Post.delete"
                defaultMessage="Delete"
              />
            </button>*/}
          </div>
        </div>
      </>
    );
  }
}

Post.propTypes = {
  _canProgress: PropTypes.func.isRequired,
  _handleSubmit: PropTypes.func.isRequired,
  _updateState: PropTypes.func.isRequired,
  channels: PropTypes.shape({}).isRequired,
  userCookie: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  disablePicker: PropTypes.bool.isRequired,
};

export default Post;
