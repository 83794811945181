import axios from 'axios';
import { loadShifts, loadSettings, loadCostCenterData, loadMyRequestTimeOffs, 
        loadUserPrivileges, loadLocationChannels, loadLocationUsableSkills, 
        loadLocationUserSkillIds, loadLocationInnerDepartments, } from '_helpers/app-state';

import { getAssociatedShifts } from './shift-calls';
import { getChannels, GetLocationSetupActions } from './location-calls';

export const callRequiredData = (user_id, location_id, setAppState, userPrivilegeId) => {
  loadShifts(user_id, location_id, setAppState);
  loadSettings(userPrivilegeId, setAppState);
  loadCostCenterData(location_id, setAppState);
  loadMyRequestTimeOffs(location_id, setAppState);
  loadUserPrivileges(location_id, setAppState);
  loadLocationChannels(location_id, setAppState);
  loadLocationUsableSkills(location_id, setAppState);
  loadLocationUserSkillIds(user_id, location_id, setAppState);
  loadLocationInnerDepartments(location_id, setAppState);
};

export const callBackgroundData = location_id => axios.all([
  getChannels('location_feed', location_id),
  getChannels('custom_feed', location_id),
  getChannels('region_feed', location_id),
]);

export const callLocationChangeRequiredData = (user_id, location_id) => axios.all([
  GetLocationSetupActions(location_id),
  getAssociatedShifts(user_id, location_id),
]);
