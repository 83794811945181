import axios from 'axios';
import { axiosInstance } from '../api-calls';
import { formatOnlyProps } from '_helpers/formatting';
import { getLocaleCookie } from '../cookies';
//import { axiosInstance, protectedAxiosInstance } from 'helpers/api-calls';
//import { formatOnlyProps } from 'helpers/formatting';
//import { BASE_URL } from '../../constants';
//import { getLocationCode, getLocaleCookie } from '../cookies';

 /**
 * Gets all usable skills for current location.
 *
 * @param  { String | Int } {location_id}
 */
export const GetLocationUsableSkills = (location_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/usable_skills`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      per_page: 500,
      total: true,
    },
  });
};






