import axios from 'axios';
import { axiosInstance } from '../api-calls';
import { formatOnlyProps } from '_helpers/formatting';
import { getLocaleCookie } from '../cookies';
//import { axiosInstance, protectedAxiosInstance } from 'helpers/api-calls';
//import { formatOnlyProps } from 'helpers/formatting';
//import { BASE_URL } from '../../constants';
//import { getLocationCode, getLocaleCookie } from '../cookies';

 /**
 * Gets all request time offs for the user's current location.
 * This will be used more and more often for checking whether
 * a user needs certain features in the app.
 * @param  { String | Int } {location_id}
 */
export const GetLocationRequestTimeOffs = (location_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/can_admin?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      per_page: 1000,
      total: true,
    },
  });
};

/**
 * Approve a request time off
 * 
 * @param  { String | Int } {rto_id}
 */
export const ApproveRequestTimeOff = (rto_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/${rto_id}/approve?response[put_return_resource]=true`, 
    method: 'PUT',
    headers: {
      Locale: locale,
    },
    params: {
      rto_id,
    },
  });
};

/**
 * Deny a request time off
 * 
 * @param  { String | Int } {rto_id}
 */
export const DenyRequestTimeOff = (rto_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/${rto_id}/deny?response[put_return_resource]=true`, 
    method: 'PUT',
    headers: {
      Locale: locale,
    },
    params: {
      rto_id,
    },
  });
};

/**
 * Delete a request time off
 * 
 * @param  { String | Int } {rto_id}
 */
export const DeleteRequestTimeOff = (rto_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/${rto_id}/?response[put_return_resource]=true`, 
    method: 'DELETE',
    headers: {
      Locale: locale,
    },
    params: {
      rto_id,
    },
  });
};

/**
 * Get user's request time off balance
 * 
 * @param  { String | Int } {user_privilege_id}
 */
export const GetUserRequestTimeOffBalance = (user_privilege_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/user_privileges/${user_privilege_id}/rto_balances`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      user_privilege_id,
    },
  });
};

/**
 * Get user's request time off balance
 * 
 * @param  { String | Int } {user_privilege_id}
 */
export const GetUserRequestTimeOffBalance1 = (user_privilege_id, rto_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/user_privileges/${user_privilege_id}/rto_balances?rto_id=${rto_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      user_privilege_id,
      rto_id,
    },
  });
};

export const PostRequestTimeOff = (data) => axiosInstance.request({
  url: `/api/customer/user_role/request_time_offs`,
  method: 'POST',
  
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    'response[nested]': 2,
  },
  
  data: {
    location_id: data.location_id,
    start_date: data.start_date,
    end_date: data.end_date,
    start_time: data.start_time,
    end_time: data.end_time,
    category: data.category,
    time_off_reason: data.time_off_reason,
  },
  
});


/**
 * Get user's request time off balance
 * 
 * @param  { String | Int } {location_id}
 */
export const getMyRequestTimeOffs = (location_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/mine?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      per_page: 500,
      total: true,
    },
  });

};






