import React from 'react';
import PropTypes from 'prop-types';
//import { FormattedMessage } from 'react-intl';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { truncateString } from '_helpers/formatting';
import { shiftStatus, shiftStatuses } from '_constants';

class StatusText extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      currentStatus: '',
      currentShift: null,
      applicants: [],
    }

    this.messages = defineMessages({
      calledOff: {
        id: 'StatusMessage.callOff',
        defaultMessage: 'has called off this shift.',
      },
    });
  }

  componentDidMount() {
    const {selectedShift, userCookie, applicants } = this.props;
    //console.log('StatusText.componentDidMount called');
    this.setState({currentShift: selectedShift});

  }

  componentDidUpdate(prevProps) {
    const {selectedShift, userCookie, applicants } = this.props;

    //const msg = `StatusText--Update -- id: ${selectedShift.id}, trade_status = ${selectedShift.trade_status}`;
    //console.log(msg);
    if(prevProps.selectedShift !== selectedShift) {
      this.setState({currentShift: selectedShift});
    }

    if(prevProps.applicants !== applicants) {
      this.setState({applicants: applicants});
    }
  }

  

  _renderStatusText() {
    const { selectedShift, userCookie, applicants} = this.props;
    const { intl: { formatMessage } } = this.props;
    const {currentShift} = this.state;

    let status = '';
    let truncatedContent = '';

    if(currentShift) {
      status = shiftStatus(currentShift, userCookie, applicants);
      truncatedContent = truncateString.apply(currentShift.content || '', [120, true]);
    }

   

    //const status = shiftStatus(selectedShift, userCookie);
    //const truncatedContent = truncateString.apply(selectedShift.content || '', [120, true]);
    //const ownerName = `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`;

    this.setState({currentStatus: status});

    switch (status) {
      case shiftStatuses.VTO_APPROVED:
        return (
          <FormattedMessage
            id="StatusMessage.vtoApproved"
            defaultMessage="Submission approved"
          />
        );

      case shiftStatuses.VTO_PENDING:
        return (
          <FormattedMessage
            id="StatusMessage.vtoPending"
            defaultMessage="VTO is pending manager approval"
          />
        );

      case shiftStatuses.VTO_REJECTED:
        return (
          <FormattedMessage
            id="StatusMessage.vtoRejected"
            defaultMessage="VTO Submission was not approved"
          />
        );

      case shiftStatuses.AVAILABLE:
      case shiftStatuses.YOUR_POSTED:
        //return truncatedContent;
        return (
          <FormattedMessage
            id="StatusMessage.shiftPosted"
            defaultMessage="Shift is posted"
          />
        );

      case shiftStatuses.YOUR_COVERED:
        return (
          <FormattedMessage
            id="StatusMessage.shiftCovered"
            defaultMessage="Shift is covered"
          />
        );

      case shiftStatuses.YOUR_APPROVED:
      case shiftStatuses.COVERING_APPROVED:
        return (
          <FormattedMessage
            id="StatusMessage.shiftApproved"
            defaultMessage="Shift is approved"
          />
        );

      case shiftStatuses.YOUR_PENDING:
      case shiftStatuses.COVERING_PENDING:
        return (
          <FormattedMessage
            id="StatusMessage.shiftPedingApproval"
            defaultMessage="Shift is pending manager approval"
          />
        );

      case shiftStatuses.YOUR_REJECTED:
      case shiftStatuses.COVERING_REJECTED:
        let covererFullName = '';
        if(selectedShift.current_applicant_status === 'denied'){
          covererFullName = `${userCookie.first_name} ${userCookie.last_name}`;
        }
        return (
          <FormattedMessage
            id="StatusMessage.shiftCoverDenied"
            defaultMessage="{name} has been denied covering this shift"
            values={{
            name: covererFullName,
          }}
          />
        );

      case shiftStatuses.YOUR_UNPOSTED:
        return (
          <FormattedMessage
            id="StatusText.yourUnposted"
            defaultMessage="Shift is not yet posted"
          />
        );
      case shiftStatuses.YOUR_CALLED_OFF:
        const ownerName = `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`;
        const calledOffInfo = `${ownerName} ${formatMessage(this.messages.calledOff)}`;
        return (calledOffInfo);
        /*
        return (
          <FormattedMessage
            id="StatusText.callcedOff"
            defaultMessage="{name} has called off this shift."
            values={{
              name: ownerName,
            }}
          />
        );
        */
        break;
      default:
        break;
    }

    return 'statusText';
  }

  render() {
    return this._renderStatusText();
  }
}

StatusText.propTypes = {
  selectedShift: PropTypes.shape({}).isRequired,
  userCookie: PropTypes.shape({}).isRequired,
};

export default injectIntl(StatusText);


