import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import '_helpers/tracking';

// import { getLocationCode } from '_helpers/cookies';

import { APP_VERSION } from '_constants';
import store from './store';
import Root from './root';

const { host } = window.location;
let environment = 'production';

if (!(host.indexOf('localhost') > -1)) {
  if (host.indexOf('dev') > -1) {
    environment = 'develop';
  }

  if (host.indexOf('test') > -1) {
    environment = 'test';
  }

  if (host.indexOf('staging') > -1) {
    environment = 'staging';
  }

  Sentry.init({
    dsn: 'https://78422f8292b3498fab5def0f9376faaa@sentry.io/1220175',
    release: APP_VERSION,
    environment,
  });
}

// export const mixpanelEvent = (event) => {
//   const data = {
//     location_id: getLocationCode(),
//   };
//   if (host === 'app.myshyft.com' && mixpanel.track) {
//     mixpanel.track(event, data);
//   }

//   return true;
// };

// mixpanelEvent('Enter');

ReactDOM.render(
  React.createElement(Root, { store }),
  document.getElementById('root'),
);
