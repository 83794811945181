import React from 'react';

import { obfuscatePhoneNumber } from '_helpers/formatting';
import Icon from '_components/GeneralComponents/Icon';

import './ExistingAccountComponent.scss';

class ExistingAccountComponent extends React.Component {
  render() {
    const CheckMark = () => {
      if (this.props.isChecked) {
        return (
          <div className="indicator indicator__checked">
            <span>
              <Icon color="#FFFFFF" type="checkmark" />
            </span>
          </div>
        );
      }
      return <div className="indicator" />;
    };
    return (
      <section className="existing-account">
        <div className="existing-account__indicator">
          <CheckMark />
        </div>
        <div className="existing-account__details">
          <p className="existing-account__name">
            {`${this.props.user.first_name} ${this.props.user.last_name}`}
          </p>
          <p className="existing-account__phone">
            {obfuscatePhoneNumber(this.props.value)}
          </p>
        </div>
      </section>
    );
  }
}

export { ExistingAccountComponent };
