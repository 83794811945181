import { axiosInstance } from '../api-calls';
import { getLocaleCookie } from '../cookies';

export const getChannels = (type, location_id) => axiosInstance.request({
  url: '/api/customer/user_role/channels',
  method: 'GET',
  params: {
    'type_is[]': type,
    location_id,
  },
});

/**
 * Returns the locations that a user (not admin) can access
 * @param { String | Int } user_id
 */
export const getLocations = user_id => axiosInstance.request({
  url: 'api/customer/user_role/locations/can_access',
  method: 'GET',
  params: {
    per_page: 100,
    user_id,
  },
});

export const LocationJoin = location_id => axiosInstance.request({
  url: '/api/customer/user_role/user_privileges',
  method: 'POST',
  data: {
    user_privilege: {
      location_id,
    },
  },
});

/**
 * Gets setup actions for the user's current location.
 * This will be used more and more often for checking whether
 * a user needs certain features in the app.
 * @param  { String | Int } {location_id}
 */
export const GetLocationSetupActions = (location_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: '/api/customer/user_role/setup_actions',
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
    },
  });
};

export const GetLocationSettings = user_privilege_id => axiosInstance.request({
  url: `/api/customer/user_role/user_privileges/${user_privilege_id}/settings`,
});

export const getLocationCostCenterData = (location_id) => 
  axiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/tag_groups`,
  });



/**
 * Gets cost center data for the user's current location.
 * This will be used more and more often for checking whether
 * a user needs certain features in the app.
 * @param  { String | Int } {location_id}
 */
export const GetLocationCostCenterData = (location_id) => {
  const locale = getLocaleCookie();

  return axiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/tag_groups`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
    },
  });
};


/**
 * GetAllEmployees - get all the employees for one location
 *
 * @param  {String | Int} location_id - the id of the location
 * @return {Array} Employee objects (privileges)
 */
export const GetAllEmployees = (location_id) => {
  const locale = getLocaleCookie();
  
  return axiosInstance.request({
    url: `/api/organization/user_privileges?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      'response[put_return_resource]': true,
    },
  });
};

export const getSubscriptionsChannels = (channelId) =>
  axiosInstance.request({
    url: `/api/customer/user_role/channels/${channelId}/subscriptions`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });

export const GetLocationChannels = (location_id) =>
  axiosInstance.request({
    url: '/api/customer/user_role/channels',
    method: 'GET',
    params: {
      location_id,
      type_is: ['location_feed', 'custom_feed', 'region_feed'],
      per_page: 10000,
    },
  });




