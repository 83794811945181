import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SubmitButton = props => {
  const { children, clickHandler, extraClasses, isDisabled } = props;

  const buttonClasses = classNames({
    'modal-components__submit-btn': true,
    'modal-components__submit-btn--active': !isDisabled,
    [extraClasses]: extraClasses,
  });

  return (
    <button
      className={buttonClasses}
      disabled={isDisabled}
      onClick={clickHandler}
    >
      {children}
    </button>
  );
};

SubmitButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  extraClasses: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export { SubmitButton };
