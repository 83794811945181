import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";

import { SearchLocation, LocationJoin } from "_helpers/api-calls";
import { setLocationCookie } from "_helpers/cookies";
import { handleAddLocation } from "_helpers/app-state";
import backArrow from "../AddLocationPage/assets/back-arrow.svg";
import LocationContainer from "./LocationContainer/LocationContainer";

import "./AddLocationPageCode.scss";

/**
 * I need a split page - there will be two routes
 * and then we can search with location codes
 * or store names
 */
class AddLocationPageCodei18n extends React.Component {
  state = {
    locationCode: "",
    isValid: false,
    showError: false,
    location: {
      isLoading: false,
      hasBeenCalled: false,
      data: null,
    },
    joinError: "",
    page: null,
  };

  messages = defineMessages({
    error1: {
      id: "AddLocationPageCode.error1",
      defaultMessage: "Oops! You're not authorized to join this location.",
    },
    error2: {
      id: "AddLocationPageCode.error2",
      defaultMessage:
        "Something went wrong while processing the information. Please try again.",
    },
    error3: {
      id: "AddLocationPageCode.error3",
      defaultMessage:
        "It looks like you're already a member of this location! If you're having trouble accessing the location, please contact us at support@myshyft.com. Otherwise, happy Shyfting!",
    },
    formPlaceholder: {
      id: "AddLocationPageCode.formPlaceholder",
      defaultMessage: "Enter your location code",
    },
  });

  back = (e) => {
    const { redirect } = this.props;
    e.preventDefault();
    this.setState({
      page: redirect("/add-location"),
    });
  };

  _handleLocationCodeChange = (e) => {
    const { value } = e.target;

    const trimmedCode = value.trim();

    this.setState({
      locationCode: trimmedCode,
      isValid: trimmedCode.length >= 6 && trimmedCode.length <= 10,
      showError: false,
    });
  };

  _handleSubmitLocationCode = (e) => {
    const { isValid } = this.state;
    e.preventDefault();

    this.setState((prevState) => ({
      showError: false,
      location: {
        ...prevState.location,
        hasBeenCalled: false,
      },
    }));

    if (isValid) {
      this._submitLocationCode();
    } else {
      this.setState({
        showError: true,
      });
    }
  };

  _submitLocationCode = () => {
    const { location, locationCode } = this.state;
    this.setState({
      location: Object.assign(location, {
        isLoading: true,
      }),
    });
    // call api
    SearchLocation(locationCode).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: response.data.location,
            }),
          });
        }

        if (response.status === 404) {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: null,
            }),
          });
        }
      },
      ({ response }) => {
        if (response && response.status === 404) {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: null,
            }),
          });
        } else {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: null,
            }),
          });
        }
      }
    );
  };

  _confirmLocation = () => {
    const { location } = this.state;
    const { _setAppState } = this.props;
    // call api to join location
    LocationJoin(location.data.id).then(
      (response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data.user_privilege.is_approved) {
            setLocationCookie(location.data);
            handleAddLocation(location.data, _setAppState);
            window.location.pathname = "/";
          } else {
            setLocationCookie(location.data);
            handleAddLocation(location.data, _setAppState);
            window.location.pathname = "/";
          }
        }
      },
      ({ response }) => {
        const { intl } = this.props;
        if (response.status === 401 || response.status === 403) {
          this.setState({
            joinError: intl.formatMessage(this.messages.error1),
          });
        }

        // 422 means "unprocessable entity", so it will provide
        // the "entity" that is wrong, and then provide what is
        // wrong with the entity
        if (response.status === 500 || response.status === 422) {
          this.setState({
            joinError: intl.formatMessage(this.messages.error2),
          });
        }

        if (response.status === 422) {
          if (response.data.errors.user[0] === "has already been taken") {
            this.setState({
              joinError: intl.formatMessage(this.messages.error3),
            });
          }
        }
      }
    );
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { showError, page, location, joinError } = this.state;
    const inputFormErrorClassName = classNames({
      "location-page--error": showError,
    });

    // const inputFormErrorDescription = classNames({
    //   'Location Codes must be 6-10 characters long!': showError,
    // });
    const inputFormErrorDescription = (
      <FormattedMessage
        id="AddLocationPageCode.codeLengthError"
        defaultMessage="Location Codes must be 6-10 characters long!"
      />
    );

    if (page) {
      return page;
    }
    return (
      <>
        <article className="location-page">
          <section className="location-page__row">
            <div className="location-type__column">
              <span
                role="button"
                tabIndex={0}
                className="location-page__back-button"
                onClick={this.back}
                onKeyDown={this.back}
              >
                <img
                  src={backArrow}
                  width="45px"
                  height="28px"
                  alt="Back arrow"
                />
              </span>
              <h2 className="location-page__header">
                <FormattedMessage
                  id="AddLocationPageCode.header"
                  defaultMessage="Enter Your Location Code"
                />
              </h2>
            </div>
          </section>

          <form
            className="location-page__row location-page__form"
            onSubmit={this._handleSubmitLocationCode}
          >
            <div className="location-type__column">
              <section className="login__form-group location-page__form-group">
                <input
                  onChange={this._handleLocationCodeChange}
                  name="location_code"
                  placeholder={formatMessage(this.messages.formPlaceholder)}
                  className={`location__input-location-code ${inputFormErrorClassName}`}
                />
                <p className="location__input-error">
                  {!showError || inputFormErrorDescription}
                </p>
              </section>

              <section className="location__form-action">
                <button
                  className="location__form-submit button button-primary"
                  type="submit"
                >
                  <FormattedMessage
                    id="AddLocationPageCode.searchBtn"
                    defaultMessage="Search"
                  />
                </button>
              </section>
            </div>
          </form>

          <LocationContainer
            isLoading={location.isLoading}
            hasBeenCalled={location.hasBeenCalled}
            location={location.data}
            confirm={this._confirmLocation}
            joinError={joinError}
          />
        </article>
      </>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
AddLocationPageCodei18n.propTypes = {
  intl: intlShape.isRequired,
  redirect: PropTypes.func.isRequired,
  _setAppState: PropTypes.func.isRequired,
};

const AddLocationPageCode = injectIntl(AddLocationPageCodei18n);

export { AddLocationPageCode };
