import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './LocationContainer.scss';

class LocationContainer extends React.PureComponent {
  render() {
    const LocationJoinError = () => {
      const { joinError } = this.props;

      if (joinError.length > 0) {
        return (
          <p className="location-summary__join-error">{joinError}</p>
        );
      }
      return null;
    };

    const CurrentComponent = () => {
      const {
        isLoading, location, confirm, hasBeenCalled,
      } = this.props;

      if (isLoading) {
        return (
          <div className="location-loading">
            <img
              src="/loader.gif"
              height="50"
              width="50"
              alt="Loading spinner"
            />
          </div>
        );
      }
      if (location) {
        return (
          <div className="location-summary">
            <h4 className="location-summary__title">
              {location.location_name
                ? location.location_name
                : ''}
            </h4>
            <h4 className="location-summary__address">
              {location.formatted_address
                ? location.formatted_address
                : ''}
            </h4>
            <p>
              <FormattedMessage
                id="LocationContainer.heading"
                defaultMessage="Is this your location? If not, please enter another location code."
              />
            </p>
            <button
              onClick={confirm}
              type="button"
              className="button button-primary location-confirm"
            >
              <FormattedMessage
                id="LocationContainer.confirmLocationButton"
                defaultMessage="Confirm Location"
              />
            </button>
            <LocationJoinError />
          </div>
        );
      }
      if (hasBeenCalled) {
        return (
          <div className="location-not-found">
            <FormattedMessage
              id="LocationContainer.locationError"
              defaultMessage="Hmm, we were unable to find that location. Please try again."
            />
          </div>
        );
      }
      return null;
    };

    return (
      <section className="location-container">
        <CurrentComponent />
      </section>
    );
  }
}

LocationContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasBeenCalled: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  confirm: PropTypes.func.isRequired,
  joinError: PropTypes.string,
};

LocationContainer.defaultProps = {
  location: null,
  joinError: '',
};

export default LocationContainer;
