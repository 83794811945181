import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CloseModal } from '_components/ModalComponents/CloseModal';
import { ModalWrapper } from '../ModalWrapper';
import './CancelShift.scss';

const CancelShift = ({
  _toggleModal, _handleCancel, selectedShift,
}) => (
  <div className="delete-shift">
    <ModalWrapper className="cover-shift">
      <div className="modal-components__container">
        <CloseModal _toggleModal={_toggleModal} dark />
        <h1>
          <FormattedMessage
            id="CancelShift.confirmCancelBody"
            defaultMessage="Are you sure you want to cancel this shift trade?"
          />
        </h1>
        <div className="modal-components__row">
          <div className="modal-components__col modal-components__col--submit">
            <button
              className="modal-components__submit-btn modal-components__submit-btn--back"
              onClick={() => {
                _toggleModal(null);
              }}
              type="button"
            >
              <FormattedMessage
                id="CancelShift.cancelNo"
                defaultMessage="No"
              />
            </button>
            <button
              className="modal-components__submit-btn modal-components__submit-btn--post"
              onClick={() => {
                _handleCancel(selectedShift.id);
              }}
              type="button"
            >
              <FormattedMessage
                id="CancelShift.confirmCancelAction"
                defaultMessage="Yes, Cancel Shift Trade"
              />
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  </div>
);

CancelShift.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
  _handleCancel: PropTypes.func.isRequired,
  selectedShift: PropTypes.shape({}),
  userCookie: PropTypes.shape({}).isRequired,
};

CancelShift.defaultProps = {
  selectedShift: {},
};

export { CancelShift };
