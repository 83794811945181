import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { CommentsSection } from "_components/GeneralComponents/Comments";
import { createDateString, createTimeString } from "_helpers/formatting";

class CoverComments2 extends React.Component {

  constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messages = defineMessages({
      to: {
        id: 'CoverComments2.to',
        defaultMessage: 'to',
      },
      
    });

    this.state = {
      showDatePicker: false,
      shiftSkills: [],
      showCoverButton: true,
      
    };
  }

  componentDidMount () {
    const { selectedShift, locationSkills, locationSettings, locationUserSkillIds, userPrivileges, } = this.props;
    let shiftSkills = [];
    if(locationSettings.cfg__skill === 1) {
      if(selectedShift && selectedShift.skill_ids && selectedShift.skill_ids.length > 0) {
        if(locationSkills && locationSkills.length > 0) {
          for(let i=0; i<selectedShift.skill_ids.length; i++) {
            for(let j=0; j<locationSkills.length; j++) {
              if(selectedShift.skill_ids[i] === locationSkills[j].id) {
                shiftSkills.push(locationSkills[j])
              }
            }
          }
        }
        this.setState({shiftSkills: shiftSkills});
      }
      
      if(shiftSkills && shiftSkills.length > 0 && locationUserSkillIds && locationUserSkillIds.length > 0) {
        for(let k=0; k< shiftSkills.length; k++) {
          if(!locationUserSkillIds.includes(shiftSkills[k].id)) {
            this.setState({showCoverButton: false});
            break;
          }
        }
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {

  }

  renderAddress = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const currentLocation = locations.filter(
      (l) => l.id === selectedShift.location_id
    );

    if (currentLocation.length) {
      return `${currentLocation[0].location_name}, ${currentLocation[0].formatted_address}`;
    }

    return "";
  }

  renderTitle = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    if (selectedShift.name === "shift_vto") {
      return (
        <FormattedMessage
          id="CoverComments2.coverShiftForVTO"
          defaultMessage="Take Voluntary Time Off?"
        />
      );
    }
    
    return (
      <FormattedMessage
        id="CoverComments2.coverShiftFor"
        defaultMessage="Cover This Shift for {name}?"
        values={{
          name: selectedShift && selectedShift.owner ? `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`: '',
        }}
      />
    );
  }

  renderType = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const teamShift = (
              <FormattedMessage
                id="CoverComments2.teamShift"
                defaultMessage="Team Shift"
              />);
     const openShift = (
              <FormattedMessage
                id="CoverComments2.openShift"
                defaultMessage="Open Shift"
              />);
     const vto = (
              <FormattedMessage
                id="CoverComments2.vto"
                defaultMessage="Voluntary Time Off"
              />);

    let typeName = '';
    switch(selectedShift.name) {
      case 'shift':
        typeName = teamShift;
        break;
      case 'open_shift':
        typeName = openShift;
        break;
      case 'shift_vto':
        typeName = vto;
        break;
    }
    return typeName;
  }

  renderJobTitle = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    return selectedShift.primary_job;
  }

  renderDate = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const startDate = moment(selectedShift.start_at).format('ddd, MMM, DD');
    return startDate;
  }

  renderTime = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, intl } = this.props;

    const startTime = moment(selectedShift.start_at).format('h:mm A');
    const endTime = moment(selectedShift.end_at).format('h:mm A');
    const timeRange = `${startTime} ${intl.formatMessage(this.messages.to)} ${endTime}`;
    return timeRange;
  }

  renderOwner = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, intl } = this.props;

    const ownerName =  selectedShift && selectedShift.owner ? `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}`: '';
    return ownerName;
  }

  renderButtonText = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    if (selectedShift.name === "shift_vto") {
      return (
        <FormattedMessage id="CoverComments2.takeVTO" defaultMessage="TAKE VTO" />
      );
    }

    return (
      <FormattedMessage
        id="CoverComments2.coverThisShift"
        defaultMessage="Cover This Shift"
      />
    );
  }

  renderTotalShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    if(selectedShift.name === 'shift') {
      return '1';
    }else {
      if(selectedShift.parent_card) {
        return selectedShift && selectedShift.total_shifts_available ? selectedShift.total_shifts_available : ' ';
      }else {
        return '1'; 
      }
    }
  }

  renderAppliedShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    if(selectedShift) {
      if(selectedShift.current_applicants_pending_user_ids) {
        //return selectedShift.current_applicants_pending_user_ids.length;
         //Fixed Bug WS-1833: applied applicants should be all applicants.
        const totalApplicants = selectedShift.current_applicants_pending_user_ids.length 
                                    + selectedShift.current_applicants_approved_user_ids.length
                                    + selectedShift.current_applicants_denied_user_ids.length
                                    + selectedShift.current_applicants_nominated_user_ids.length;
        return totalApplicants;
      }else {
        return '0';
      }
    }
  }

  renderApprovedShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    //return selectedShift.available_shifts_covered;
    if(selectedShift) {
      if(selectedShift.current_applicants_approved_user_ids) {
        return selectedShift.current_applicants_approved_user_ids.length;
      }else {
        if(selectedShift.available_shifts_covered) {
          return selectedShift.available_shifts_covered;
        }else {
          return '0';
        }
      }
    }
  }

  renderLocation = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    if(selectedShift) {
      if(selectedShift.location) {
        return selectedShift.location.formatted_address;
      }else {
        return '';
      }
    }
    //return selectedShift.location.formatted_address;
  }

  renderShiftSkills = () => {
    const { selectedShift, locationSkills, locationSettings,} = this.props;
    const { shiftSkills, } = this.state;

    const copyArray = [...shiftSkills];
    this.sortSkillContentWithNullValues(copyArray, true);

    if(copyArray.length > 0) {
      const skills = copyArray.map((skill) => (
        <label className="cover-comments2__skill">
          {skill.skill_content}
        </label>
      ));
      if(skills && skills[0]) {
        return skills;
      }else {
        return null;
      }
    }else {
      return (
         <label className="cover-comments2__skill">
          <FormattedMessage
            id="CoverComments2.noSkillAssigned1"
            defaultMessage="No skills assigned to this shift"
          />
        </label>
      );
    }
  }

  sortSkillContentWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  render () {
    const { _toggleScreen, userCookie, selectedShift, locations, locationSettings, userPrivileges, _toggleModal, } = this.props;
    const { showCoverButton, } = this.state;
    const showSkillSection = locationSettings.cfg__skill === 1 && selectedShift.name !== 'shift';


    return (
      <>
      <h1>{this.renderTitle()}</h1>
      <div className="cover-comments2__top-row">
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.type"
                defaultMessage="Type"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderType()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.jobTitle"
                defaultMessage="Job Title"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderJobTitle()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.date"
                defaultMessage="Date"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderDate()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.time"
                defaultMessage="Time"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderTime()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.postedBy"
                defaultMessage="Posted By"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderOwner()}
          </label>
        </div>
      </div>
      <div className="cover-comments2__top-row">
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.shifts"
                defaultMessage="Shifts"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderTotalShifts()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.applied"
                defaultMessage="Applied"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderAppliedShifts()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.approved"
                defaultMessage="Approved"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderApprovedShifts()}
          </label>
        </div>
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.location"  
                defaultMessage="Location"
              />
          </label>
          <label className="cover-comments2__item-value">
            {this.renderLocation()}
          </label>
        </div>
      </div>
      {showSkillSection && <div className="cover-comments2__top-row">
        <div className="cover-comments2__item-wrap">
          <label className="cover-comments2__item-name">
            <FormattedMessage
                id="CoverComments2.shiftSkills"  
                defaultMessage="Skills"
              />
          </label>
          <div className="cover-comments2__skill-wrap">
            {this.renderShiftSkills()}
          </div>
        </div>
      </div>}
      <CommentsSection 
        selectedShift={selectedShift} 
        userCookie={userCookie} 
        locationSettings={locationSettings}
        _toggleModal={_toggleModal}
      />
      {showCoverButton && <div className="modal-components__row">
        <div className="modal-components__col modal-components__col--submit">
          <button
            className="modal-components__submit-btn modal-components__submit-btn--post"
            onClick={_toggleScreen}
            type="button"
          >
            {this.renderButtonText()}
            &nbsp;
            <span className="icon-submit-arrow" />
          </button>
        </div>
      </div>}
    </>
    );
  }

}

CoverComments2.propTypes = {
  //userCookie: PropTypes.shape({}).isRequired,
  //costCenterData: PropTypes.shape({}).isRequired,
  //shift: PropTypes.shape({}).isRequired,
  //locationSettings: PropTypes.shape({}).isRequired,
  //_toggleModal: PropTypes.func.isRequired,
  //_updateSelectedShift: PropTypes.func.isRequired,
};

export default injectIntl (CoverComments2);
