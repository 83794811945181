import React from 'react';
import PropTypes from 'prop-types';

import { Shiffy } from '_components/GeneralComponents/Shiffy';
import { CloseModal } from '../../CloseModal';
import mountains from '../assets/header-mountains.svg';
import clouds from '../assets/header-clouds.svg';

class ShiffyHeader extends React.PureComponent {
  render() {
    return (
      <div className="shiffy-header">
        <img className="shiffy-header__clouds" src={clouds} alt="Clouds" />
        <img
          className="shiffy-header__mountains"
          src={mountains}
          alt="Mountains"
        />
        <Shiffy className="shiffy-header__shiffy" />
        <CloseModal _toggleModal={this.props._toggleModal} />
      </div>
    );
  }
}

ShiffyHeader.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
};

export default ShiffyHeader;
