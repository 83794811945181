import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VerifiedBadge from '../VerifiedBadge/VerifiedBadge';

import './ProfileInputWrap.scss';

const ProfileInputWrap = (props) => {
  const {
    children, error, icon, verifiedStatus,
  } = props;
  const wrapClasses = classNames({
    'profile-input__wrap': true,
    'profile-input__wrap--error': error,
  });
  const badge = <VerifiedBadge status={verifiedStatus} />;

  return (
    <div className="profile-input">
      <div className={wrapClasses}>
        <img className="profile-input__icon" src={icon} alt="Input Icon" />
        {children}
        {verifiedStatus ? badge : null}
      </div>
      <span className="profile-input__error">{error}</span>
    </div>
  );
};

ProfileInputWrap.propTypes = {
  error: PropTypes.string,
  icon: PropTypes.string.isRequired,
  verifiedStatus: PropTypes.string,
};

ProfileInputWrap.defaultProps = {
  error: '',
  verifiedStatus: '',
};

export default ProfileInputWrap;
