import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

//import TimePickerDropDown from '../TimePickerDropDown/TimePickerDropDown';
import TimePicker from '../TimePicker/TimePicker';
import { DataNames } from '../../constants';
import './DateTimePicker.scss';

class DateTimePicker extends React.PureComponent {
  yesterday = Datetime.moment().subtract(1, 'day');
  endDay = Datetime.moment().add(1, 'day');

  //_validDate = current => current.isAfter(this.yesterday);

  _validDate = current => current.isAfter(this.yesterday) && current.isBefore(this.endDay);

  render() {
    const {
      _updateState, 
      toggleDatePicker, 
      date, 
      locale, 
      getStartTime, 
      getEndTime, 
      clearDatesAndTimes,
      rtoStartDate,
      rtoEndDate,
      costCenterData,
      selectedIndex,
      selectedShift,
      handleTimeChanged,
      shiftDataChanged,
    } = this.props;
    return (
      <div className="dt-picker">
        <Datetime
          className="dt-picker__date-picker"
          closeOnTab={false}
          input={false}
          isValidDate={this._validDate}
          onChange={day => _updateState(DataNames.DATE, day)}
          timeFormat={false}
          value={date}
          locale={locale}
        />
        <div className="dt-picker__time-picker">
           <TimePicker
            _updateState={_updateState}
            _toggleDatePicker={toggleDatePicker}
            date={date}
            locale={locale}
            selectedShift={selectedShift}
            handleTimeChanged={handleTimeChanged}
            shiftDataChanged={shiftDataChanged}
          />
        </div>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  _toggleDatePicker: PropTypes.func.isRequired,
  _updateState: PropTypes.func.isRequired,
  date: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
};

//export default DateTimePicker;
export default injectIntl (DateTimePicker);
