import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { shiftStatuses, shiftOwner, shiftNames } from '_constants';

const Badge = (props) => {
  const { userCookie, shift } = props;
  const owner = shiftOwner(shift, userCookie);
  let badgeText = '';

  if (shift.name === shiftNames.SHIFT_INVENTORY) {
    return (
      <span className="shift-card__badge shift-card__badge--inventory">
        <FormattedMessage
          id="Badge.shiftInventory"
          defaultMessage="SCHEDULED SHIFT"
        />
      </span>
    );
  }

  switch (owner) {
    case shiftStatuses.YOUR_SHIFT:
      badgeText = (
        <FormattedMessage
          id="Badge.myShift"
          defaultMessage="MY SHIFT"
        />
      );
      break;

    case shiftStatuses.COVERING_SHIFT:
      badgeText = (
        <FormattedMessage
          id="Badge.covering"
          defaultMessage="COVERING"
        />
      );
      break;

    case shiftStatuses.COVERING_VTO:
      badgeText = (
        <FormattedMessage
          id="Badge.takingVTO"
          defaultMessage="TAKING VTO"
        />
      );
      break;

    case shiftStatuses.YOUR_VTO:
      badgeText = (
        <FormattedMessage
          id="Badge.myVTO"
          defaultMessage="MY VTO"
        />
      );
      break;

    default:
      badgeText = '';
  }

  if (badgeText) {
    return (
      <span className="shift-card__badge shift-card__badge--card-type">
        {badgeText}
      </span>
    );
  }

  return null;
};

Badge.propTypes = {
  userCookie: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
};

export default Badge;
