import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import '_helpers/findIndex-polyfill';

import {
  signInAttempt, SignInConfirm, signInWithEmail, AddAuthHeaders, updateUserAttributes,
} from '_helpers/api-calls';
import {
  setAuthenticationCookie, setUserCookie, getUserCookie, setLocaleCookie, setLoginURL,
} from '_helpers/cookies';
import { getBrowserLanguage } from '_helpers/localization';

import LoginFirstStage from './LoginFirstStage/LoginFirstStage';
import LoginSecondStage from './LoginSecondStage/LoginSecondStage';

import './LoginPage.scss';

class LoginPage extends React.Component {
  state = {
    stage: 1,
    email: {
      value: '',
      valid: false,
    },
    phone: {
      value: '',
      valid: false,
    },
    error: null,
    mobilePinError: '',
    emailPasswordError: '',
    path: null,
  };

  errors = [
    <FormattedMessage
      id="LoginPage.error1"
      defaultMessage="Hmm, that doesn’t match our records. Please try again."
    />,
    <FormattedMessage
      id="LoginPage.error2"
      defaultMessage="Something went wrong while processing the information. Please try again."
    />,
     <FormattedMessage
      id="LoginPage.error3"
      defaultMessage="That phone number isn't associated with an existing Shyft account. Please try again."
    />,
  ];

  mobilePinErrors = [
    <FormattedMessage
      id="LoginPage.mobilePinError1"
      defaultMessage="Oops, that PIN doesn't match our records. Please try again."
    />,
    <FormattedMessage
      id="LoginPage.mobilePinError2"
      defaultMessage="Something went wrong while processing the information. Please try again."
    />,
  ];

  emailPasswordError = [
    <FormattedMessage
      id="LoginPage.emailPasswordError404"
      defaultMessage="Hmm, that doesn’t match our records. Please try again."
    />,
    <FormattedMessage
      id="LoginPage.emailPasswordError3"
      defaultMessage="Something went wrong while processing the information. Please try again."
    />,
  ];

  _nextStep = ({ type, value }) => {
    this.setState({
      type,
      [type]: {
        value,
        valid: true,
      },
      stage: 2,
    });
  };

  back = (e) => {
    e.preventDefault();
    this.setState({
      stage: 1,
      mobilePinError: '',
      emailPasswordError: '',
    });
  };

  _checkUserForLocations = (location) => {
    if (location && location.location) {
      return location.location.id;
    }
    return false;
  };

  _setHeaders = (headers, user, cb) => {
    const { _setAppState, appState } = this.props;

    setAuthenticationCookie(headers);
    setUserCookie(user);
    AddAuthHeaders();

    const updater = [
      {
        key: 'user',
        statement: { $set: getUserCookie() },
      },
    ];

    if (user.locale) {
      // User has previously saved locale on server
      setLocaleCookie(user.locale);
      updater.push({
        key: 'locale',
        statement: { $set: user.locale === 'undefined' ? getBrowserLanguage() : user.locale },
      });
    } else if (appState.locale !== getBrowserLanguage()) {
      // User has no saved locale setting on server and
      // changed locale before logging in
      setLocaleCookie(appState.locale); // Set locale cookie from appState
      updateUserAttributes(user.id, { locale: appState.locale }); // Set locale on server
    }

    _setAppState(updater);

    cb(user.recent_user_privilege);
  };

  _phoneLogin = (pin) => {
    const { phone } = this.state;
    SignInConfirm(phone.value, pin).then(
      (response) => {
        const headers = Object.assign(response.headers, {
          'Auth-Category': 'phone_number',
        });

        setLoginURL();
        this._setHeaders(headers, response.data.user, this._redirect);
      },
      ({ response }) => {
        if ((response && response.status === 401) || (response && response.status === 403)) {
          this.setState({
            mobilePinError: 0,
          });
        } else {
          this.setState({
            mobilePinError: 1,
          });
        }
      },
    );
  };

  _emailLogin = (email, password) => {
    signInWithEmail(email, password).then(
      (response) => {
        const headers = Object.assign(response.headers, {
          'Auth-Category': 'email',
          'Auth-Target': email.toLowerCase(),
          location_id: this._checkUserForLocations(
            response.data.user.recent_user_privilege,
          ),
        });

        setLoginURL();
        this._setHeaders(headers, response.data.user, this._redirect);
      },
      ({ response }) => {
        if (
          (response && response.status === 401)
          || (response && response.status === 403)
        ) {
          this.setState({
            emailPasswordError: 0,
          });
        } else {
          this.setState({
            emailPasswordError: 1,
          });
        }
      },
    );
  };

  _redirect = (userPrivilege) => {
    if (this._checkUserForLocations(userPrivilege)) {
      this.setState({
        path: {
          pathname: '/',
        },
      });
    } else {
      this.setState({
        path: {
          pathname: '/add-location',
        },
      });
    }
  };

  _routeToSignup = (e) => {
    e.preventDefault();
    this.setState({
      path: {
        pathname: '/sign-up',
      },
    });
  };

  _routeToPasswordReset = (e) => {
    e.preventDefault();
    this.setState({
      path: {
        pathname: '/password-reset',
      },
    });
  };

  _firstValueSubmit = ({ type, value }) => {
    if (type === 'email') {
      this._nextStep({ type, value });
    } else {
      this._phoneNumberSubmit({ type, value });
    }
  };

  _phoneNumberSubmit = ({ type, value }) => {
    signInAttempt(value).then(
      (response) => {
        if (response.status === 200) {
          this._nextStep({ type, value });
        } else {
          this.setState({
            error: 0,
            type,
            [type]: {
              value,
              valid: true,
            },
          });
        }
      },
      (error) => {
        if (error.response && error.response.status === 404) {
          this.setState({
            error: 0,
            type,
            [type]: {
              value,
              valid: true,
            },
          });
        } else if (error.response && error.response.status !== 400) {
          this.setState({
            error: 1,
            type,
            [type]: {
              value,
              valid: true,
            },
          });
        } else if(error.response && error.response.status === 400) {
          this.setState({
            error: 2,
            type,
            [type]: {
              value,
              valid: true,
            },
          });
        }
      },
    );
  };

  _renderError = key => this.errors[key];

  _renderMobilePinError = key => this.mobilePinErrors[key];

  _renderEmailPasswordError = key => this.emailPasswordError[key];

  render() {
    const { path } = this.state;
    const CurrentStep = () => {
      const {
        stage,
        error,
        type,
        mobilePinError,
        emailPasswordError,
        phone,
        email,
      } = this.state;
      switch (stage) {
        case 1:
          return (
            <LoginFirstStage
              _formSubmit={this._firstValueSubmit}
              error={this._renderError(error)}
            />
          );

        case 2:
          return (
            <LoginSecondStage
              back={this.back}
              type={type}
              _pinSubmit={this._phoneLogin}
              _passwordSubmit={this._emailLogin}
              _formSubmit={this._login}
              mobilePinError={this._renderMobilePinError(mobilePinError)}
              emailPasswordError={this._renderEmailPasswordError(emailPasswordError)}
              phone={phone.value}
              email={email.value}
            />
          );

        default:
          return <LoginFirstStage formSubmit={this.nextStep} />;
      }
    };

    if (path) {
      return <Redirect push to={path} />;
    }

    return (
      <article className="login">
        <div className="login__wrap">
          <h2 className="login__header">
            <FormattedMessage
              id="LoginPage.welcomeShyft"
              defaultMessage="Welcome to Shyft!"
            />
          </h2>
          <CurrentStep />
          <footer className="login__footer">
            <p>
              <FormattedMessage
                id="LoginPage.signUpLabel"
                defaultMessage="Don't have an account?"
              />
              <button
                className="login__link"
                type="button"
                onClick={this._routeToSignup}
                data-cy="login-next-step"
              >
                <FormattedMessage
                  id="LoginPage.signUp"
                  defaultMessage="Sign Up"
                />
              </button>
            </p>
            <p>
              <FormattedMessage
                id="LoginPage.resetPasswordLabel"
                defaultMessage="Forgot your password?"
              />
              <button
                className="login__link"
                href=""
                onClick={this._routeToPasswordReset}
                type="button"
              >
                <FormattedMessage
                  id="LoginPage.resetPassword"
                  defaultMessage="Reset Password"
                />
              </button>
            </p>
          </footer>
        </div>
      </article>
    );
  }
}

export { LoginPage };
