import React from 'react';
import PropTypes from 'prop-types';

import './Shiffy.scss';

import nude from './assets/shiffy-nude.svg';
import ninja from './assets/shiffy-ninja.svg';

class Shiffy extends React.PureComponent {
  returnOutfit = () => {
    switch (this.props.outfit) {
      case 'nude':
        return nude;
      case 'ninja':
        return ninja;
      default:
        return nude;
    }
  };

  render() {
    return (
      <img
        className={`shiffy ${this.props.className}`}
        src={this.returnOutfit()}
        alt="Shiffy"
      />
    );
  }
}

Shiffy.propTypes = {
  className: PropTypes.string,
  outfit: PropTypes.oneOf(['nude', 'ninja']),
};

Shiffy.defaultProps = {
  className: '',
  outfit: 'nude',
};

export { Shiffy };
