import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import {
  ShiftCard,
  //NoShiftCard,
} from '_components/GeneralComponents/ShiftCard';
import ShiftCardNew  from '_components/GeneralComponents/ShiftCard/ShiftCardNew';
import NoShiftCard from '_components/GeneralComponents/ShiftCard/NoShiftCard';
import RTOCard from '_components/GeneralComponents/RTOCard/RTOCard';
import RTOCardNew from '_components/GeneralComponents/RTOCard/RTOCardNew';
import { trackAmplitudeEvent, amplitudeConstants } from '_helpers/tracking';
import { LocationDropdown } from '_components/GeneralComponents/LocationDropdown';
import { toggleModal } from '_helpers/app-state';
import { shiftNames } from '_constants/shift-names';
import { getShift } from '_helpers/api-calls/shift-calls';
import { addShift, removeShift } from '_helpers/app-state';
import { apiCallError } from '_helpers/errors';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import { getShiftSegments } from "_helpers/api-calls/shift-calls";
import './ShiftsPage.scss';

class ShiftsPage extends React.Component {
   constructor(props) {
    super(props);

    this.state = {
      showNewShiftCard: true,
      showNewRTOCard: true,
      missingShifts: [],
      userSkillIds: [],
    };

    this._isMounted = false;
    this.localStorageKey = '';
    this.myCovered = '';
    this.myAppliedShifts = [];
  }

  componentDidMount() {
    const {
      _setAppState, appState: {
        user,
        shifts,
        locationSettings,
        associatedLocations,
        costCenterData,
        userPrivileges,
        locationChannels,
        locationUserSkillIds,
      },
    } = this.props;
  
    this._isMounted = true;
    this.localStorageKey = `${user.user_id}-${user.location_id}`;
    let msg = `ShiftPage.componentDidMount: localStorageKey: ${this.localStorageKey}`;
    console.log(msg);
    this.myCovered = localStorage.getItem(this.localStorageKey);
    msg = `ShiftPage.componentDidMount: localStorage.getItem: ${this.myCovered}`;
    console.log(msg);
    if(this.myCovered && this.myCovered.length > 0) {
      const strArray = this.myCovered.split(',');
      let ids = [];
      for(let i=0; i<strArray.length; i++) {
        if(strArray[i].length >0 && strArray[i] !== '1234567') {
          console.log(strArray[i]);
          ids.push(parseInt(strArray[i]));
        }
      }
      this.myAppliedShifts = ids;
    }
    
    let missingShifts = [];
    if(this.myAppliedShifts && this.myAppliedShifts.length > 0) {
      for(let i=0; i<this.myAppliedShifts.length; i++) {
        let findIt = false;
        for(let j=0; j<shifts.length; j++) {
          if(shifts[j].id === this.myAppliedShifts[i]) {
            const msg = `Find shift: ${this.myAppliedShifts[i]}`;
            console.log(msg);
            findIt = true;
            break;
          }
        }
        if(!findIt) {
          const msg = `Missing shift: ${this.myAppliedShifts[i]}`;
          console.log(msg);
          const idx = this.state.missingShifts.findIndex((shift) => shift.id === this.myAppliedShifts[i]);
          if(idx < 0) {
            getShift(this.myAppliedShifts[i]).then((payload) => {
              console.log('getShift:  ' + payload.data.schedule_element.id);
              const missingShift = payload.data.schedule_element;
              const startDateTime = moment(missingShift.start_at);
              let current = moment();
              let startToNow = moment.duration(startDateTime.diff(current));
              let hoursStartToNow = parseInt(startToNow.asHours());
              if(hoursStartToNow > 0) {
                missingShifts.push(payload.data.schedule_element);
                addShift(payload.data.schedule_element, shifts, _setAppState);
                this.setState({missingShifts: missingShifts});
              }else {
                this.removePassedShiftIdFromLocalStorage(missingShift.id)
              } 
            }).catch((error) => {
              const msg = `Failed to getShift: ${this.myAppliedShifts[i]}`;
              console.log(msg);
              this.removePassedShiftIdFromLocalStorage(this.myAppliedShifts[i])
            });
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
     const {
      _setAppState, appState: {
        user,
        shifts,
        locationSettings,
        associatedLocations,
        costCenterData,
        userPrivileges,
        locationChannels,
      },
    } = this.props;
    const { missingShifts } = this.state;

    if(prevProps.shifts !== shifts) {
      if(shifts.length > 0) {
        for(let i=0; i<missingShifts.length; i++) {
          if(!shifts.includes(missingShifts[i])) {
            addShift(missingShifts[i], shifts, _setAppState);
          }
        }
      }
    }

    if(prevProps.user !== user) {
      if(user) {
        /*
         getUserInfoNonAdmin(user.user_id, user.location_id).then((response) => {
          if(this._isMounted) {
            this.setState({userSkillIds: response.data.user.skill_ids});
          }
        }).catch((error) =>{
           const msg = `ShiftPage.componentDidUpdate: call getUserInfoNonAdmin failed. error: ${error}`;
           console.log(msg);
        });
        */
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  removePassedShiftIdFromLocalStorage = (shiftId) => {
    let str = this.myCovered;
    const toRemove = `${shiftId},`;
    if(str.includes(shiftId)) {
      str = str.replace(toRemove, "");
      console.log(str);
      localStorage.setItem(this.localStorageKey, str);
      const msg= `Removed out of date shift: ${shiftId}`;
      console.log(msg);
    }
  };

  filterShiftsInDeletedGroup = (shifts) => {
    const {
      appState: {
        locationChannels,
        user,
      },
    } = this.props;

    for(let i=0; i<shifts.length; i++) {
      if(shifts[i].channel_id) {
        if(locationChannels && locationChannels.length > 0) {
          let findChannel = false;
          for(let j=0; j<locationChannels.length; j++) {
            if(locationChannels[j].id === shifts[i].channel_id) {
              findChannel = true;
              break;
            }
          }
          if(!findChannel) {
            //const msg = `Shift ${shifts[i].id}, trade status: ${shifts[i].trade_status}, belongs to channel ${shifts[i].channel_id}, the channel has been deleted.`;
            //console.log(msg);
            if(shifts[i].current_applicant_status === 'applied' || shifts[i].current_applicant_status === 'denied') {
              shifts.splice(i, 1);
              i -= 1;
            }else {
              if(shifts[i].current_applicant_status === null) {
                if(shifts[i].parent_card && shifts[i].trade_status !== 'approved') {
                  shifts.splice(i, 1);
                  i -= 1;
                }
              }
            }
          }
        }
      }
    }

    return shifts;
  }

  filterShiftsWithUserSkills = (user, shifts, userSkillIds) => {
    if(user && shifts && shifts.length > 0) {
      if(user.is_admin === 'true') {
        return shifts;
      }

      for(let j=0; j<shifts.length; j++) {
        if(shifts[j].skill_ids && shifts[j].skill_ids.length > 0 && userSkillIds) {
          if(userSkillIds.length === 0) {
            if(shifts[j].current_applicants_pending_user_ids && shifts[j].current_applicants_pending_user_ids.length > 0) {
              if(!shifts[j].current_applicants_pending_user_ids.includes(user.user_id)) {
                shifts.splice(j, 1);
                j -= 1;
              }
            }else if(shifts[j].current_applicants_denied_user_ids && shifts[j].current_applicants_denied_user_ids.length > 0) {
              if(!shifts[j].current_applicants_denied_user_ids.includes(user.user_id)) {
                shifts.splice(j, 1);
                j -= 1;
              }
            }else {
              if(shifts[j].skill_ids && shifts[j].skill_ids > 0) {
                shifts.splice(j, 1);
                j -= 1;
              }
            }
          }else {
            for(let k=0; k<shifts[j].skill_ids.length; k++) {
              if(!userSkillIds.includes(shifts[j].skill_ids[k])) {
                if(shifts[j].current_applicants_pending_user_ids && shifts[j].current_applicants_pending_user_ids.length > 0) {
                  if(!shifts[j].current_applicants_pending_user_ids.includes(user.user_id)) {
                    const msg = `1. ${j}: shift ${shifts[j].id}: user skills do not include ${shifts[j].skill_ids[k]}, removed.`;
                    console.log(msg);
                    shifts.splice(j, 1);
                    j -= 1;
                    break;
                  }
                }else if(shifts[j].current_applicants_denied_user_ids && shifts[j].current_applicants_denied_user_ids.length > 0) {
                  if(!shifts[j].current_applicants_denied_user_ids.includes(user.user_id)) {
                    const msg = `2. ${j}: shift ${shifts[j].id}: user skills do not include ${shifts[j].skill_ids[k]}, removed.`;
                    console.log(msg);
                    shifts.splice(j, 1);
                    j -= 1;
                    break;
                  }
                }else {
                  shifts.splice(j, 1);
                  j -= 1;
                  break;
                }
              }
            }
          }
        }
      }
    }

    return shifts;
  }

  sortRTOWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.start_time === b.start_time) // identical? return 0
        return 0;
      else if (a.start_time === null)  // a is null? last 
        return 1;
      else if (b.start_time === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return new Date(a.start_time) - new Date(b.start_time)
      }                  
    }
    return arr.sort(sorter);
  }

  findChildCard = (shift) => {
    const { appState: {shifts} } = this.props;
    const childen = shifts.filter((item) => item.parent_id === shift.id);
    return childen;
  }

  _renderAvailableShifts = () => {
    const {
      _setAppState, appState: {
        user,
        shifts,
        locationSettings,
        associatedLocations,
        costCenterData,
        userPrivileges,
        locationChannels,
        locationUserSkillIds,
      },
    } = this.props;
    const { customChannels } = this.state;
    //const { userSkillIds, } = this.state;

    let firstFiltered = shifts.filter((shift) => {
      if(shift.name === 'shift') {
        //return shift.owner.id === user.user_id || shift.trade_status !== 'covered';
        return shift.owner.id === user.user_id || 
              (shift.current_applicant_status && shift.current_applicant_status !== 'picked') || 
              shift.current_applicant_status === null;
      }else {
        return shift.trade_status === 'posted' || shift.trade_status === 'denied' || 
                shift.trade_status === 'pending' || shift.trade_status === 'rejected';
      }

    });

    const availableShifts = this.filterShiftsInDeletedGroup(firstFiltered);
    
    //covered shifts should not be shown
    let validShifts = [];

    for(let i=0; i<availableShifts.length; i++) {
      if(availableShifts[i].parent_card) {
        if(availableShifts[i].current_applicant_status  && 
          (availableShifts[i].current_applicant_status === 'applied' || 
            availableShifts[i].current_applicant_status === 'denied' || 
            availableShifts[i].current_applicant_status === 'nominated')) {
          if(availableShifts[i].name === 'shift') {
            validShifts.push(availableShifts[i]);
          }else {
            if(availableShifts[i].current_applicant_status === 'applied' || 
              availableShifts[i].current_applicant_status === 'denied' ||
              availableShifts[i].current_applicant_status === 'nominated') {
              //validShifts.push(availableShifts[i]);
              const idx = availableShifts.findIndex((shift) => shift.parent_id === availableShifts[i].id);
              if(idx < 0) {
                 validShifts.push(availableShifts[i]);
              }
            }
          }
        }else if(availableShifts[i].can_apply && !availableShifts[i].has_applied) {
          validShifts.push(availableShifts[i]);
        }else if(availableShifts[i].total_shifts_available === availableShifts[i].available_shifts_covered) {
          if(availableShifts[i].name === 'open_shift' || availableShifts[i].name === 'shift_vto') {
            if(availableShifts[i].owner.id === user.user_id) {
              validShifts.push(availableShifts[i]);
            }else {
              if(availableShifts[i].current_applicants_approved_user_ids && availableShifts[i].current_applicants_approved_user_ids.length > 0) {
                /*
                const idx = availableShifts[i].current_applicants_approved_user_ids.findIndex((id) => id === user.user_id);
                if(idx >= 0) {
                  validShifts.push(availableShifts[i]);
                }
                */
                //includes is not supported in IE and Edge
                if(availableShifts[i].current_applicants_approved_user_ids.includes(user.user_id)) {
                  validShifts.push(availableShifts[i])
                }
              }
              if(availableShifts[i].current_applicants_denied_user_ids && availableShifts[i].current_applicants_denied_user_ids.length > 0) {
                /*
                const idx = availableShifts[i].current_applicants_denied_user_ids.findIndex((id) => id === user.user_id);
                if(idx >= 0) {
                  validShifts.push(availableShifts[i]);
                }
                */
                //includes is not supported in IE and Edge
                if(availableShifts[i].current_applicants_denied_user_ids.includes(user.user_id)) {
                  validShifts.push(availableShifts[i])
                }
              }
            }
          }
        }else {
          if(availableShifts[i].current_applicant_status === null) {
            if(availableShifts[i].owner.id === user.user_id) {
              validShifts.push(availableShifts[i]);
            }
          }
        }
      }else {
        if(availableShifts[i].cover_mode === 'multi_applicants_picked_by_manager' ||
          availableShifts[i].trade_status === 'inventory') {
          if(availableShifts[i].current_applicant_status && availableShifts[i].current_applicant_status === 'applied'){
            if(availableShifts[i].name === 'shift') {
              if(availableShifts[i].owner.id === user.user_id) {
                validShifts.push(availableShifts[i]);
              }else {
                if(availableShifts[i].trade_status !== 'approved') {
                  validShifts.push(availableShifts[i]);
                }
              }
            }else {
              validShifts.push(availableShifts[i]);
            }
          }else if(availableShifts[i].can_apply && !availableShifts[i].has_applied) {
            validShifts.push(availableShifts[i]); 
          }else if(availableShifts[i].name === 'shift') {
            if(availableShifts[i].owner.id === user.user_id) {
              validShifts.push(availableShifts[i]); 
            }else {
              if(availableShifts[i].current_applicant_status === 'applied' || availableShifts[i].current_applicant_status === 'denied') {
                validShifts.push(availableShifts[i]);
              }else if(availableShifts[i].trade_status !== 'approved') {
                //validShifts.push(availableShifts[i]); 
              }
            }
          }
        }else {
          if(availableShifts[i].cover_mode === 'default'){
            if(availableShifts[i].coverer === null){
              validShifts.push(availableShifts[i]);
            }else if(availableShifts[i].owner.id === user.user_id || availableShifts[i].coverer.id === user.user_id){
              if(availableShifts[i].trade_status === 'pending') {
                validShifts.push(availableShifts[i]);
              }else if(availableShifts[i].trade_status === 'rejected') {
                validShifts.push(availableShifts[i]);
              }else if(availableShifts[i].trade_status === 'approved') {
                validShifts.push(availableShifts[i]);
              }
            }
          }
        }
      }
    }

    const filtered = this.filterShiftsWithUserSkills(user, validShifts, locationUserSkillIds);
    
    /*
    let skillFilteredShifts = validShifts.filter((shift) => {
      if(shift.skill_ids && shift.skill_ids.length > 0) {
        for(let j=0; j< shift.skill_ids.length; j++) {
          if(!userSkillIds.includes(skill_ids[j])) {
            if(shift.current_applicants_pending_user_ids && shift.current_applicants_pending_user_ids.length > 0) {
              if(shift.current_applicants_pending_user_ids.includes(user.user_id)) {
                return shift;
              }
            }else {
              return shift;
            }
          }
        }
      }
    });
    */
    
     //sort by start_at
    const sortedShifts = filtered.sort(
      (a, b) => new Date(a.start_at) - new Date(b.start_at)
    );
    const shiftCards = sortedShifts.map((shift) => {
      const regional = parseInt(user.location_id) !== shift.location_id;
      const locationData = associatedLocations[shift.location_id];
      
      if(this.state.showNewShiftCard) {
        return (
          <div className="shifts-page__available-shift">
            <ShiftCardNew
              key={shift.id.toString()}
              userCookie={user}
              shift={shift}
              regional={regional}
              location={locationData}
              locationSettings={locationSettings}
              _toggleModal={(modalContent) => {
                toggleModal(modalContent, _setAppState);
              }}
              _updateSelectedShift={this._updateSelectedShift}
              costCenterData={costCenterData}
              onMarketplace={true}
              userPrivileges={userPrivileges}
              locationChannels={locationChannels}
            />
          </div>
        );
      }
      
      return (
        <div className="shifts-page__available-shift">
          <ShiftCard
            key={shift.id.toString()}
            userCookie={user}
            shift={shift}
            regional={regional}
            location={locationData}
            locationSettings={locationSettings}
            _toggleModal={(modalContent) => {
              toggleModal(modalContent, _setAppState);
            }}
            _updateSelectedShift={this._updateSelectedShift}
            costCenterData={costCenterData}
            onMarketplace={true}
            userPrivileges={userPrivileges}
            locationChannels={locationChannels}
          />
        </div>
      );
    });

    const noShiftCard = (
      <NoShiftCard
        _toggleModal={(modalContent) => {
          trackAmplitudeEvent(amplitudeConstants.postShiftInsideFeed);
          toggleModal(modalContent, _setAppState);
        }}
        type="available"
      />
    );

    return (
      <>
        {shiftCards.length > 0 ? shiftCards : noShiftCard}
      </>
    );
  };

  _renderMyShifts = () => {
    const {
      _toggleModal, appState: {
        associatedLocations, locationSettings, shifts, user, user: { user_id },
      costCenterData, rtos, userPrivileges, locationChannels, },
    } = this.props;

    let firstFiltered = shifts.filter((shift) => {
      if(shift.cover_mode === 'default') {
        if (shift.coverer) {
          return shift.owner.id === user_id || shift.coverer.id === user_id;
        }else {
          return shift.owner.id === user_id;
        }
      }else if(shift.cover_mode === 'multi_applicants_picked_by_manager') {
        if(shift.name === 'shift') {
          //return shift.owner.id === user_id || (shift.current_applicant_status && shift.current_applicant_status !== 'applied');
          return shift.owner.id === user_id || shift.current_applicant_status;
        }else {
          //Open shift and vto shows on marketplace
          //return shift.owner.id === user_id || shift.trade_status === 'posted'
          const output = this.findChildCard(shift);
          if(output && output.length > 0) {
            console.log('here');
          }
          if(output.length === 0) {
            if(shift.parent_card && shift.trade_status === 'posted') {
              if(shift.current_applicants_approved_user_ids && shift.current_applicants_approved_user_ids.length > 0) {
                for(let i=0; i<shift.current_applicants_approved_user_ids.length; i++) {
                  if(shift.current_applicants_approved_user_ids[i] === user.user_id) {
                    return shift;
                  }
                }
              }
            }
          }
        }
        
      }else {
        return shift.owner.id === user_id;
      }
    });

    if (locationSettings.cfg__inventory_show !== 1) {
      firstFiltered = firstFiltered.filter(shift => shift.name !== shiftNames.SHIFT_INVENTORY);
    }

    const myShifts = this.filterShiftsInDeletedGroup(firstFiltered);

    //covered shifts should not be shown
    let validShifts = [];

   for(let i=0; i<myShifts.length; i++) {
    if(myShifts[i].parent_card) {
      if(myShifts[i].owner.id === user_id) {
        if(myShifts[i].current_applicant_status) {
          if(myShifts[i].current_applicant_status === 'approved') {
            validShifts.push(myShifts[i]);
          }
        }else {
          if(myShifts[i].available_shifts_covered < myShifts[i].total_shifts_available){
            if(myShifts[i].name === 'shift') {
              validShifts.push(myShifts[i]);
            }
          }
        }
      }else {
        if(myShifts[i].current_applicants_approved_user_ids && myShifts[i].current_applicants_approved_user_ids.length >0) {
          for(let k=0; k<myShifts[i].current_applicants_approved_user_ids.length; k++) {
            if(myShifts[i].current_applicants_approved_user_ids[k] === user.user_id) {
              validShifts.push(myShifts[i]);
            }
          }
        }
      }
    }else {
      if(myShifts[i].trade_status === 'posted' || 
        myShifts[i].trade_status === 'inventory' ||
        myShifts[i].trade_status === 'called_off' ||
        myShifts[i].trade_status === 'pending' ||
        myShifts[i].trade_status === 'approved') {
          if(myShifts[i].trade_status === 'pending'){
            //pending  open shifts moved to marketplace
            if(myShifts[i].name === 'shift') {
              if(myShifts[i].owner.id === user_id) {
                validShifts.push(myShifts[i]);
              }
            }
          }else if(myShifts[i].trade_status === 'posted' || myShifts[i].trade_status === 'approved') {
            if(myShifts[i].cover_mode === 'default') {
              if(!myShifts[i].current_applicant_status){
                if(myShifts[i].owner.id === user_id){
                  validShifts.push(myShifts[i]);
                }
              }else {
                if(myShifts[i].current_applicant_status === 'picked') {
                  validShifts.push(myShifts[i]);
                }
              }
            }else if(myShifts[i].cover_mode === 'multi_applicants_picked_by_manager'){
              if(!myShifts[i].current_applicant_status){
                if(myShifts[i].owner.id === user_id){
                  validShifts.push(myShifts[i]);
                }
              }else {
                if(myShifts[i].name !== 'shift') {
                  if(myShifts[i].current_applicant_status === 'picked') {
                    validShifts.push(myShifts[i]);
                  }
                }else {
                  if(myShifts[i].owner.id === user_id) {
                    validShifts.push(myShifts[i]);
                  }else {
                    if(myShifts[i].current_applicant_status === 'approved' || myShifts[i].current_applicant_status === 'picked') {
                      validShifts.push(myShifts[i]);
                    }
                  }
                }
              }
            }
            
          }else {
            validShifts.push(myShifts[i]);
          }
      }else {
        if(myShifts[i].trade_status === 'covered'){
          if(myShifts[i].name === 'shift') {
            if(myShifts[i].owner.id === user_id || (myShifts[i].coverer && myShifts[i].coverer.id === user_id)) {
              validShifts.push(myShifts[i]);
            }
          }else {
            if(myShifts[i].coverer.id === user_id) {
              validShifts.push(myShifts[i]);
            }
          }
        }
      }
    }
  }
    //sort by start_at
    const sortedShifts = validShifts.sort(
      (a, b) => new Date(a.start_at) - new Date(b.start_at)
    );
  //if(this._isMounted) {
    const shiftCards = sortedShifts.map((shift) => {
    const regional = parseInt(user.location_id) !== shift.location_id;
    const locationData = associatedLocations[shift.location_id];

      if(this.state.showNewShiftCard) {
        return (
          <ShiftCardNew
            key={shift.id.toString()}
            userCookie={user}
            shift={shift}
            regional={regional}
            location={locationData}
            locationSettings={locationSettings}
            _toggleModal={_toggleModal}
            _updateSelectedShift={this._updateSelectedShift}
            costCenterData={costCenterData}
            onMarketplace={false}
            userPrivileges={userPrivileges}
            locationChannels={locationChannels}
          />
        );
      }

      return (
        <ShiftCard
          key={shift.id.toString()}
          userCookie={user}
          shift={shift}
          regional={regional}
          location={locationData}
          locationSettings={locationSettings}
          _toggleModal={_toggleModal}
          _updateSelectedShift={this._updateSelectedShift}
          costCenterData={costCenterData}
          onMarketplace={false}
          userPrivileges={userPrivileges}
          locationChannels={locationChannels}
        />
      );
    });
  
    const noShiftCard = <NoShiftCard _toggleModal={_toggleModal} locationSettings={locationSettings} />;

    return (
      <>
        {shiftCards.length > 0 ? shiftCards : noShiftCard}
      </>
    );
  //}
};

  _rendRequestTimeOffs = () => {
     const {
      _toggleModal, appState: {
        associatedLocations, locationSettings, shifts, user, user: { user_id }, rtos, setupActions, userPrivileges, costCenterData, },
    } = this.props;

    if(!rtos) {
      return null;
    }
    //sort by start date
    /*
    const sortedRTOs = rtos.sort(
      (a, b) => new Date(a.start_time) - new Date(b.start_time)
    );
    */
    const sortedRTOs = [...rtos];
    this.sortRTOWithNullValues(sortedRTOs, false);
    
    //if(this._isMounted) {
      const rtoCards = sortedRTOs.map((rto) => {
        if(this.state.showNewRTOCard) {
          return (
            <RTOCardNew
              key={rto.id.toString()}
              userCookie={user}
              rto={rto}
              locationSettings={locationSettings}
              _toggleModal={_toggleModal}
              _updateSelectedRTO={this._updateSelectedRTO}
              setupActions={setupActions}
              userPrivileges={userPrivileges}
              costCenterData={costCenterData}
            />
          );
        }

        return (
          <RTOCard
            key={rto.id.toString()}
            userCookie={user}
            rto={rto}
            locationSettings={locationSettings}
            _toggleModal={_toggleModal}
            _updateSelectedRTO={this._updateSelectedRTO}
            setupActions={setupActions}
          />
        );
      });
      
      //const noRtoCard = <NoShiftCard _toggleModal={_toggleModal} locationSettings={locationSettings} />;

      return (
        <>
          {rtoCards.length > 0 ? rtoCards : null}
        </>
      );
    //}
  };

  _sortShifts = () => (a, b) => moment.utc(a.start_at).diff(moment.utc(b.start_at));

  _updateSelectedShift = (selectedShift) => {
    const { _setAppState } = this.props;

    _setAppState({
      key: 'selectedShift',
      statement: {
        $set: selectedShift.id,
      },
    });
  };

  _updateSelectedRTO = (rtoParams) => {
    const { _setAppState } = this.props;

    _setAppState({
      key: 'selectedRTO',
      statement: {
        $set: rtoParams,
      },
    });
  };

  render() {
    const { _setAppState, appState } = this.props;

    return (
      <div className="index-page__wrapper">
        <div className="index-page__row">
          <div className="shifts-page__location-dropdown">
            <LocationDropdown
              _setAppState={_setAppState}
              direction="left"
              locations={appState.locations}
              userCookie={appState.user}
            />
          </div>
        </div>
        <div className="index-page__row">
          <div className="shifts-page__my-shifts">
            <div className="shifts-page__shift-col-head">
              <p className="shifts-page__shift-type">
                <FormattedMessage
                  id="ShiftsPage.schedule1"
                  defaultMessage="Schedule"
                />
              </p>
            </div>
            {this._rendRequestTimeOffs()}
            {this._renderMyShifts()}
          </div>
          <div className="shifts-page__available-shifts">
            <div className="shifts-page__shift-col-head">
              <p className="shifts-page__shift-type">
                <FormattedMessage
                  id="ShiftsPage.shiftMarketplace2"
                  defaultMessage="Shift Marketplace"
                />
              </p>
              {this._renderAvailableShifts()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ShiftsPage.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  appState: PropTypes.shape({}).isRequired,
};

export { ShiftsPage };
