import React from 'react';
import PropTypes from 'prop-types';

//import logoWhite from './assets/shyft-logo.svg';
import logoWhite from './assets/shyft-workforce-logo-black-text.svg';
import logoGrey from './assets/shyft-logo-grey.svg';
import logoDark from './assets/shyft-logo-dark.svg';
import './ShyftLogo.scss';

class ShyftLogo extends React.PureComponent {
  _returnSrc = () => {
    switch (this.props.background) {
      case 'white':
        return logoWhite;

      case 'grey':
        return logoGrey;

      case 'dark':
        return logoDark;

      default:
        return logoWhite;
    }
  };

  render() {
    return (
      <img alt="Shyft Logo" className="shift-logo" src={this._returnSrc()} />
    );
  }
}

ShyftLogo.propTypes = {
  background: PropTypes.oneOf(['white', 'grey', 'dark']),
};

ShyftLogo.defaultProps = {
  background: 'white',
};

export { ShyftLogo };
