import axios from 'axios';
import { BASE_URL } from '_constants/index';
import { createHeadersForApiCall } from './cookies';

export const axiosInstance = axios.create({
  baseURL: `https://${BASE_URL}`,
  headers: {
    common: createHeadersForApiCall(),
  },
  params: {
    'response[put_return_resource]': 'true',
    'response[nested]': 2,
  },
});

export * from './api-calls/authentication-calls.js';

export * from './api-calls/shift-calls.js';

export * from './api-calls/location-calls.js';

export * from './api-calls/comments.js';

export * from './api-calls/user-calls';

export * from './api-calls/concurrent-calls';

export const AddAuthHeaders = () => {
  const headers = createHeadersForApiCall();

  Object.keys(headers).map((key) => {
    axiosInstance.defaults.headers.common[key] = headers[key];
    return false;
  });
};

export const SearchLocation = swiftCode => axiosInstance.request({
  url: `/api/organization/locations/${swiftCode.toUpperCase()}`,
});
