import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LoadingSpinner.scss';

const LoadingSpinner = (props) => {
  const spinnerClasses = classNames({
    'loading-spinner': true,
    'loading-spinner--small': props.size === 'small',
    'loading-spinner--medium': props.size === 'medium',
    'loading-spinner--large': props.size === 'large',
    'loading-spinner--red': props.color === 'red',
    'loading-spinner--blue': props.color === 'blue',
  });
  return <div className={spinnerClasses} />;
};

LoadingSpinner.propTypes = {
  color: PropTypes.oneOf(['blue', 'red']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

LoadingSpinner.defaultProps = {
  color: 'red',
  size: 'medium',
};

export { LoadingSpinner };
