import axios from 'axios';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import findIndex from 'lodash/findIndex';

import {
  getAssociatedShifts,
  callLocationChangeRequiredData,
  getLocations,
} from '_helpers/api-calls';
import { GetLocationSettings, 
        getLocationCostCenterData, 
        GetLocationCostCenterData,
        GetAllEmployees,
        getSubscriptionsChannels,
        GetLocationChannels } from '_helpers/api-calls/location-calls';
import { getMyRequestTimeOffs } from '_helpers/api-calls/rto-calls'
import { apiCallError } from '_helpers/errors';
import { sortShiftsByDate } from '_helpers/date-time';
import { userCookieKeys, API_SECTIONS } from '_constants';
import { FindRole } from '_services/user-roles';
import { setLocationCookie, setUserCookieProfileImage } from '_helpers/cookies';
import { GetLocationUsableSkills } from '_helpers/api-calls/skill-calls';
import { getUserInfo, getUserInfoNonAdmin, } from '_helpers/api-calls/user-calls';
import { GetLocationInnerDepartments } from '_helpers/api-calls/department-calls';

export const loadLocations = (user_id, setAppState) => {
  getLocations(user_id)
    .then((response) => {
      setAppState({
        key: 'locations',
        statement: { $set: response.data.locations },
      });
    })
    .catch((error) => {
      apiCallError(error);
    });
};

export const loadSettings = (user_privilege_id, setAppState) => {
  GetLocationSettings(user_privilege_id).then((response) => {
    setAppState({
      key: 'locationSettings',
      statement: { $set: response.data.user_privilege.final_function_setting },
    });
  });
};

// changes location id in state and then loads shifts
export const handleLocationChange = (location, setAppState) => {
  setLocationCookie({
    id: location.id,
    time_zone: location.time_zone,
  });

  setAppState([
    {
      key: 'user',
      statement: {
        [userCookieKeys.LOCATION_ID]: { $set: location.id },
      },
    },
    {
      key: 'apisNeeded',
      statement: { $set: API_SECTIONS },
    },
  ]);
};

export const handleLocaleChange = setAppState => setAppState([
  {
    key: 'apisNeeded',
    statement: {
      $push: ['setup_actions'],
    },
  },
]);

export const handleAddLocation = (location, setAppState) => {
  setLocationCookie({
    id: location.id,
    time_zone: location.time_zone,
  });

  setAppState([
    {
      key: 'user',
      statement: {
        [userCookieKeys.LOCATION_ID]: { $set: location.id },
      },
    },
    {
      key: 'apisNeeded',
      statement: { $set: API_SECTIONS },
    },
    {
      key: 'locations',
      statement: { $push: [location] },
    },
  ]);
};

export const resetApisNeeded = (setAppState) => {
  setAppState({
    key: 'apisNeeded',
    statement: { $set: API_SECTIONS },
  });
};

// after API is called, sets shift array

export const setShiftsState = (shifts, setAppState) => {
  setAppState([
    {
      key: 'shifts',
      statement: { $set: shifts },
    },
    { key: 'loading', statement: { $unset: ['shifts'] } },
  ]);
};

// set associated locations from the locations included in the shift API call
export const setAssociatedLocations = (locations, setAppState) => {
  setAppState([
    {
      key: 'associatedLocations',
      statement: { $set: locations },
    },
  ]);
};

// setup actions
export const setSetupActions = (setupActions, setAppState) => {
  setAppState([
    { key: 'setupActions', statement: { $set: setupActions } },
    { key: 'userRole', statement: { $set: FindRole(setupActions) } },
  ]);
};

// setup location cost center data
export const setLocationCostCenterData = (costCenterData, setAppState) => {
  setAppState([
    {
      key: 'costCenterData',
      statement: { $set: costCenterData },
    },
  ]);
};

// changes loading state to true and then
export const loadNewLocationData = (location_id, setAppState) => {
  setAppState({ key: 'loading', statement: { shifts: { $set: true } } });

  callLocationChangeRequiredData(location_id).then(
    axios.spread((setupActions, shifts) => {
      setShiftsState(shifts.data.schedule_elements, setAppState);
      setSetupActions(setupActions.data, setAppState);
    }),
  );
};

// loads only shifts for that location
export const loadShifts = (user_id, location_id, setAppState) => {
  getAssociatedShifts(user_id, location_id)
    .then(axios.spread((availableShifts, myShifts) => {
      const allShifts = availableShifts.data.schedule_elements.concat(myShifts.data.schedule_elements);
      const sortedShifts = sortShiftsByDate(allShifts); // Sort shifts by date
      const noDuplicateShifts = uniqBy(sortedShifts, 'id'); // Remove duplicates\

      const associatedLocations = {};

      // add associated locations to hashmap
      noDuplicateShifts.forEach((shift) => {
        if (!associatedLocations[shift.location_id]) {
          associatedLocations[shift.location_id] = shift.location;
        }
      });

      // WARNING - the order is important here - if switched, will cause errors
      setAssociatedLocations(associatedLocations, setAppState);
      setShiftsState(noDuplicateShifts, setAppState);
    }))
    .catch((error) => {
      apiCallError(error);
    });
};

export const toggleModal = (modalContent, setAppState) => {
  setAppState({
    key: 'visibleModal',
    statement: { $set: modalContent },
  });
};

export const addShift = (shift, shifts, setAppState) => {
  const newShiftDate = moment(shift.start_at);
  const indexByDate = findIndex(shifts, (currentShift) => {
    // Returns the index the new shift should be spliced to
    const currentShiftDate = moment(currentShift.start_at);

    return newShiftDate.isBefore(currentShiftDate);
  });

  setAppState({
    key: 'shifts',
    statement: { $splice: [[indexByDate, 0, shift]] },
  });
};

export const removeShift = (index, setAppState) => {
  setAppState({
    key: 'shifts',
    statement: { $splice: [[index, 1]] },
  });
};

export const updateSelectedShift = (id, setAppState) => {
  setAppState({
    key: 'selectedShift',
    statement: {
      $set: id,
    },
  });
};

export const setCalledOffShift = (shift, shifts, reason1_tag, reason2_tag, setAppState) => {
  const newShift = shift;
  newShift.trade_status = 'called_off';
  newShift.call_off_reasons_1_tag_key = reason1_tag;
  newShift.call_off_reasons_2_tag_key = reason2_tag;

  const idx = shifts.findIndex((item) =>(
    item.id === shift.id
  ));
  if(idx>=0){
    setAppState({
      key: 'shifts',
      statement: { $splice: [[idx, 1, newShift]] },
    });
  }

  setAppState({
    key: 'calledOffShifts',
    statement: { $push: [shift.id] },
  });

};

export const setCalledOffShiftNew = (shift, shifts, setAppState) => {
  const idx = shifts.findIndex((item) =>(
    item.id === shift.id
  ));
  if(idx>=0){
    setAppState({
      key: 'shifts',
      statement: { $splice: [[idx, 1, shift]] },
    });
  }
};

export const updateCoveredShift = (shift, shifts, setAppState) => {
  const newShift = shift;

  if(shift.parent_card) {
    if(!shift.current_applicant_status) {
      newShift.current_applicant_status = 'applied';
    }
  }else {
    if(shift.trade_status === 'posted') {
      newShift.trade_status = 'covered';
    }
  }
  
  const idx = shifts.findIndex((item) =>(
    item.id === shift.id
  ));
  if(idx>=0){
    setAppState({
      key: 'shifts',
      statement: { $splice: [[idx, 1, newShift]] },
    });
  }else {
    console.log('updateCoveredShift: idx < 0');
  }
};

export const updateCanceledShift = (shift, shifts, setAppState) => {
  const idx = shifts.findIndex((item) =>(
    item.id === shift.id
  ));
  if(idx>=0){
    //shift.channel_id = shifts[idx].channel_id;
    setAppState({
      key: 'shifts',
      statement: { $splice: [[idx, 1, shift]] },
    });
  }else {
    console.log('updateCoveredShift: idx < 0');
  }
};

export const updateSelectedShiftSegments = (segments, shifts, setAppState) => {
  const shiftIndex = shifts.findIndex(shift => shift.id === segments[0].schedule_element_id);

  setAppState({
    key: 'shifts',
    statement: { [shiftIndex]: { segments: { $set: segments } } },
  });
};


export const loadCostCenterData = (location_id, setAppState) => {
  GetLocationCostCenterData(location_id)
    .then((response) => {
      const costCenterData = response.data;
      setLocationCostCenterData(costCenterData, setAppState);
    })
    .catch((error) => {
      apiCallError(error);
    });

};

export const setRequestTimeOff = (rto, rtos, setAppState) => {
  //put on top of the array
  setAppState({
    key: 'rtos',
    statement: { $splice: [[0, 0, rto]] },
  });
};

export const addRequestTimeOff = (rto, rtos, setAppState) => {
  const newRTODate = moment(rto.start_at);
  const indexByDate = findIndex(rtos, (currentRTO) => {
    // Returns the index the new rto should be spliced to
    const currentRTODate = moment(currentRTO.start_at);

    return newRTODate.isBefore(currentRTODate);
  });

  setAppState({
    key: 'rtos',
    statement: { $splice: [[indexByDate, 0, rto]] },
  });
};

export const removeRequestTimeOff = (rto, rtos, setAppState) => {
  //remove deleted rto from array
   const idx = rtos.findIndex((item) =>(
    item.id === rto.id
  ));
  if(idx>=0) {
    setAppState({
      key: 'rtos',
      statement: { $splice: [[idx, 1]] },
    });
  }else {
    console.log('removeRequestTimeOff: rto not found.');
  }
};

export const loadMyRequestTimeOffs = (location_id, setAppState) => {
  getMyRequestTimeOffs(location_id)
    .then((response) => {
      const myRequestTimeOffs = response.data.request_time_offs;
      setMyRequestTimeOffs(myRequestTimeOffs, setAppState);
    })
    .catch((error) => {
      apiCallError(error);
    });

};

// setup location cost center data
export const setMyRequestTimeOffs = (rtos, setAppState) => {
  setAppState([
    {
      key: 'rtos',
      statement: { $set: rtos },
    },
  ]);
};

export const loadUserPrivileges = (location_id, setAppState) => {
  GetAllEmployees(location_id)
    .then((response) => {
      const userPrivileges = response.data.user_privileges;
      setUserPrivileges(userPrivileges, setAppState);
    })
    .catch((error) => {
      apiCallError(error);
    });
};

// setup location user privileges
export const setUserPrivileges = (userPrivileges, setAppState) => {
  setAppState([
    {
      key: 'userPrivileges',
      statement: { $set: userPrivileges },
    },
  ]);
};


export const loadLocationChannels = (location_id, setAppState) => {
  GetLocationChannels(location_id)
    .then((response) => {
      const locationChannels = response.data.channels;
      setLocationChannels(locationChannels, setAppState);
    })
    .catch((error) => {
      apiCallError(error);
    });
};

// setup location channels
export const setLocationChannels = (locationChannels, setAppState) => {
  setAppState([
    {
      key: 'locationChannels',
      statement: { $set: locationChannels },
    },
  ]);
};

export const loadLocationUsableSkills = (location_id, setAppState) => {
  GetLocationUsableSkills(location_id)
    .then((response) => {
      const locationSkills = response.data.skills;
      setLocationUsableSkills(locationSkills, setAppState);
    })
    .catch((error) => {
      apiCallError(error);
    });
};

// setup location usable skills
export const setLocationUsableSkills = (locationSkills, setAppState) => {
  setAppState([
    {
      key: 'locationSkills',
      statement: { $set: locationSkills },
    },
  ]);
};

export const loadLocationUserSkillIds = (user_id, location_id, setAppState) => {
  getUserInfoNonAdmin(user_id, location_id).then((response) => {
    const locationUserSkillIds = response.data.user.skill_ids;
    setLocationUserSkillIds(locationUserSkillIds, setAppState);
  }).catch((error) =>{
     const msg = `app-state: call getUserInfoNonAdmin failed. error: ${error}`;
     console.log(msg);
  });
};

// setup location user skill ids
export const setLocationUserSkillIds = (locationUserSkillIds, setAppState) => {
  setAppState([
    {
      key: 'locationUserSkillIds',
      statement: { $set: locationUserSkillIds },
    },
  ]);
};

export const loadLocationInnerDepartments = (location_id, setAppState) => {
  GetLocationInnerDepartments(location_id)
    .then((response) => {
      const locationDepts = response.data.departments;
      setLocationInnerDepartments(locationDepts, setAppState);
    })
    .catch((error) => {
      apiCallError(error);
    });
};

// setup location inner departments
export const setLocationInnerDepartments = (locationDepts, setAppState) => {
  setAppState([
    {
      key: 'locationInnerDepartments',
      statement: { $set: locationDepts },
    },
  ]);
};

// changes user profile image
export const handleUserProfileImageChanged = (user, setAppState) => {
  setUserCookieProfileImage(user.profile_image);
  
  setAppState([
    {
      key: 'user',
      statement: {
        [userCookieKeys.PROFILE_IMAGE]: { $set: user.profile_image },
      },
    },
    {
      key: 'apisNeeded',
      statement: { $set: API_SECTIONS },
    },
  ]);
};


