import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import {
  validateName,
  // validatePhoneNumber,
} from '_helpers/validation';
import { apiCallError, isEmpty } from '_helpers/errors';
import { updateProfile, updateUserAttributes } from '_helpers/api-calls/user-calls';
import { setProfileCookie, setProfilePhotoCookie, } from '_helpers/cookies';
import { getUserCookie } from "_helpers/cookies";
import { callRequiredData } from "_helpers/api-calls";
import { ProfileImageEditor } from '@shyft/acorns';
import { SubmitButton } from '_components/GeneralComponents/Buttons';
import messages from './messages';
import ProfileInputText from './components/ProfileInputText/ProfileInputText';
import ProfileSkills from './components/ProfileSkills/ProfileSkills';
import ProfileDepartment from './components/ProfileDepartment/ProfileDepartment';

import userIconSvg from './assets/user-icon.svg';
import businessCardIcon from './assets/business-card-icon.svg';
import phoneIconSvg from './assets/mobile-phone-icon.svg';
import envelopePhoneIcon from './assets/envelope-icon.svg';
import skillIcon from './assets/skills-icon.svg';
import departmentsIcon from './assets/departments-icon.svg';
import './ProfilePage.scss';


/*
class ProfilePagei18n extends React.PureComponent {
  constructor(props) {
    super();
    const { appState, intl } = props;

    this.profileImgRef = React.createRef();

    this.errorMessages = {
      email: intl.formatMessage(messages.emailError),
      first_name: intl.formatMessage(messages.firstNameError),
      last_name: intl.formatMessage(messages.lastNameError),
      phone_number: intl.formatMessage(messages.phoneNumberError),
      position: intl.formatMessage(messages.positionError),
    };

    this.state = {
      values: {
        email: appState.user.email,
        first_name: appState.user.first_name,
        last_name: appState.user.last_name,
        phone_number: appState.user.phone_number,
        position: appState.user.position,
        locationSkills: appState.locationSkills,
        user: appState.user,
      },
      errors: {},
      submitted: false,
    };
  }

  _isValid = () => {
    const { values } = this.state;
    // const { appState } = this.props;

    const validFirstName = this._validate(
      'first_name',
      values.first_name,
      validateName,
    );
    const validLastName = this._validate(
      'last_name',
      values.last_name,
      validateName,
    );
    const validPosition = this._validate(
      'position',
      values.position,
      validateName,
    );
    // const validEmail = this._validate('email', values.email, validateEmail);
    // const validPhone = this._validate(
    //   'phone_number',
    //   values.phone_number,
    //   validatePhoneNumber,
    // );
    const errorCondition = validFirstName
      && validLastName
      && validPosition;
      // && appState.user.phone_number ? validPhone : true;
      // && validEmail;

    if (errorCondition) {
      return true;
    }

    return false;
  };

  _validate = (key, text, validationFn) => {
    if (validationFn(text)) {
      this._clearErrors(key);
      return true;
    }

    this._setError(key, this.errorMessages[key]);
    return false;
  };

  _clearErrors = (key) => {
    const { errors } = this.state;

    delete errors[key];
    this.setState(errors);
  };

  _setError = (key, error) => {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [key]: error,
      },
    }));
  };

  _handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        ...{ [name]: value },
      },
    }));
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const { _setAppState, appState } = this.props;

    if (this._isValid()) {
      const userId = appState.user.user_id;
      const currentUserPrivilegeId = appState.setupActions.meta.current_user_privilege.id;
      const user = {
        first_name: values.first_name,
        last_name: values.last_name,
        position: values.position,
      };

      updateProfile(userId, currentUserPrivilegeId, user)
        .then(() => {
          this.setState({
            errors: {},
            submitted: true,
          });

          // Update AppState with new user profile
          _setAppState({
            key: 'user',
            statement: {
              first_name: { $set: values.first_name },
              last_name: { $set: values.last_name },
              position: { $set: values.position },
            },
          });

          // Set cookie manually until we sort out syncing on page refresh
          setProfileCookie({
            first_name: values.first_name,
            last_name: values.last_name,
            position: values.position,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
    }
  };

  _handlePhotoSubmit = (imgData) => {
    const { _setAppState, appState } = this.props;
    const userId = appState.user.user_id;
    const user = {
      profile_image_attributes: {
        image_name: 'user_profile',
        avatar_base: imgData,
      },
    };

    updateUserAttributes(userId, user)
      .then(({ data }) => {
        const imgObj = data.user.profile_image;

        // Update AppState with new user profile
        _setAppState({
          key: 'user',
          statement: {
            profile_image: { $set: imgObj },
          },
        });

        // Set cookie manually until we sort out syncing on page refresh
        setProfilePhotoCookie(imgObj);

        // Clear errors
        this._clearErrors('profileImage');
      })
      .catch(() => {
        this._setError('profileImage', true);
      });
  }

  _onErrorHandler = () => {
    this.profileImgRef.current.src = '/icons/default-profile-thumb.svg';
  }

  render() {
    const { errors, submitted, values, } = this.state;
    const { appState, intl } = this.props;
    const successMessageClasses = classNames({
      'profile-page__submit-message': true,
      'profile-page__submit-message--error': !isEmpty(errors),
    });
    const successMessage = (
      <div className={successMessageClasses}>
        {isEmpty(errors)
          ? intl.formatMessage(messages.profileUpdated)
          : intl.formatMessage(messages.profileError)}
      </div>
    );

    return (
      <div className="index-page__wrapper index-page__wrapper--white-bg">
        <form className="profile-page__form" onSubmit={this._handleSubmit}>
          <div className="index-page__row">
            <div className="profile-page__photo-col">
              <ProfileImageEditor
                _submitImgHandler={this._handlePhotoSubmit}
                imgSrc={appState.user.profile_image.full_url}
                error={errors.profileImage}
                locale={appState.locale}
              />
            </div>
            <div className="profile-page__info-col">
              <div className="profile-page__info-group">
                <ProfileInputText
                  _changeHandler={this._handleChange}
                  error={errors.first_name}
                  icon={userIconSvg}
                  name="first_name"
                  placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
                  inputValue={values.first_name}
                />
                <ProfileInputText
                  _changeHandler={this._handleChange}
                  error={errors.last_name}
                  icon={userIconSvg}
                  name="last_name"
                  placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                  inputValue={values.last_name}
                />
                <ProfileInputText
                  _changeHandler={this._handleChange}
                  error={errors.position}
                  icon={businessCardIcon}
                  name="position"
                  placeholder={intl.formatMessage(messages.jobTitlePlaceholder)}
                  inputValue={values.position}
                />
                <ProfileSkills
                  icon={skillIcon}
                  locationSkills={values.locationSkills}
                  user={values.user}
                />
              </div>
              {appState.user && appState.user.email ? (
                <div className="profile-page__info-group">
                  <h3 className="profile-page__info-label">
                    <FormattedMessage
                      id="ProfilePage.emailHeader"
                      defaultMessage="Email Login"
                    />
                  </h3>
                  <p className="profile-page__info-details">
                    <FormattedMessage
                      id="ProfilePage.emailDescription"
                      defaultMessage="You can log in to the Shyft web app using this email and your password."
                    />
                  </p>
                  <ProfileInputText
                    _changeHandler={this._handleChange}
                    error={errors.email}
                    icon={envelopePhoneIcon}
                    name="email"
                    placeholder={intl.formatMessage(messages.emailPlaceholder)}
                    inputValue={values.email}
                    disabled
                    verifiedStatus="verified"
                  />
                </div>
              ) : null}
              {appState.user.phone_number && appState.user.phone_number !== 'NC:' && appState.user.phone_number !== 'null' ? (
                <div className="profile-page__info-group">
                  <h3 className="profile-page__info-label">
                    <FormattedMessage
                      id="ProfilePage.phoneNumberHeader"
                      defaultMessage="Mobile Login"
                    />
                  </h3>
                  <p className="profile-page__info-details">
                    <FormattedMessage
                      id="ProfilePage.phoneNumberDescription"
                      defaultMessage="You can log in to the Shyft mobile app using this phone number."
                    />
                  </p>
                  <ProfileInputText
                    _changeHandler={this._handleChange}
                    error={errors.phone_number}
                    icon={phoneIconSvg}
                    name="phone_number"
                    placeholder={intl.formatMessage(messages.phoneNumberPlaceholder)}
                    inputValue={values.phone_number}
                    disabled
                    verifiedStatus="verified"
                  />
                </div>
              ) : null}
              <Link
                className="profile-page__reset-password-link"
                to="/password-reset"
              >
                <FormattedMessage
                  id="ProfilePage.resetPasswordLink"
                  defaultMessage="Reset Password"
                />
              </Link>
              {submitted ? successMessage : null}
              <SubmitButton
                clickHandler={this._handleSubmit}
                isDisabled={false}
              >
                <FormattedMessage
                  id="ProfilePage.submitButton"
                  defaultMessage="Save Profile"
                />
              </SubmitButton>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ProfilePagei18n.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  appState: PropTypes.shape({}).isRequired,
};

const ProfilePage = injectIntl(ProfilePagei18n);

export { ProfilePage };
*/

//---------------------------------------------------------

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    const { appState, intl, } = props;
    
    this.profileImgRef = React.createRef();
    
    
    this.errorMessages = {
      email: intl.formatMessage(messages.emailError),
      first_name: intl.formatMessage(messages.firstNameError),
      last_name: intl.formatMessage(messages.lastNameError),
      phone_number: intl.formatMessage(messages.phoneNumberError),
      position: intl.formatMessage(messages.positionError),
    };
    
    this.state = {
      values: {
        email: appState.user.email,
        first_name: appState.user.first_name,
        last_name: appState.user.last_name,
        phone_number: appState.user.phone_number,
        position: appState.user.position,
        locationSkills: appState.locationSkills,
        user: appState.user,
        locationInnerDepartments: appState.locationInnerDepartments,
        locationSettings: appState.locationSettings,
        userPrivileges: appState.setupActions.meta.current_user_privilege,
      },
      errors: {},
      submitted: false,
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { appState, } = this.props;

    this._isMounted = true;

    this.setState({
       values: {
        email: appState.user.email,
        first_name: appState.user.first_name,
        last_name: appState.user.last_name,
        phone_number: appState.user.phone_number,
        position: appState.user.position,
        locationSkills: appState.locationSkills,
        user: appState.user,
        locationInnerDepartments: appState.locationInnerDepartments,
        locationSettings: appState.locationSettings,
        userPrivileges: appState.setupActions.meta.current_user_privilege,
      },
    });

    if(appState.locationSettings.cfg__skill === undefined) {
      const { _setAppState, appState } = this.props;
      const userCookie = getUserCookie();
       // Load data required for app to function
      if(userCookie && userCookie.location_id && 
        appState.setupActions && appState.setupActions.meta &&
        appState.setupActions.meta.current_user_privilege && appState.setupActions.meta.current_user_privilege.id){
        callRequiredData(
          userCookie.user_id,
          userCookie.location_id,
          _setAppState,
          appState.setupActions.meta.current_user_privilege.id
        );
      }
    }
  }

  componentDidUpdate (prevProps) {
    const { appState, } = this.props;
    if(prevProps.appState !== appState) {
      this.setState({
         values: {
          email: appState.user.email,
          first_name: appState.user.first_name,
          last_name: appState.user.last_name,
          phone_number: appState.user.phone_number,
          position: appState.user.position,
          locationSkills: appState.locationSkills,
          user: appState.user,
          locationInnerDepartments: appState.locationInnerDepartments,
          locationSettings: appState.locationSettings,
          userPrivileges: appState.setupActions.meta.current_user_privilege,
        },
      });
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
    //Fixed WS-1697: When changing location, close profile page
    //Fixed WS-1713: When select menu "Join a location", navigate to right page
    if(window.location.href.indexOf('/profile') > -1) {
      window.location.pathname = '/';
    }
  }

  _isValid = () => {
    const { values } = this.state;
    // const { appState } = this.props;

    const validFirstName = this._validate(
      'first_name',
      values.first_name,
      validateName,
    );
    const validLastName = this._validate(
      'last_name',
      values.last_name,
      validateName,
    );
    const validPosition = this._validate(
      'position',
      values.position,
      validateName,
    );
    // const validEmail = this._validate('email', values.email, validateEmail);
    // const validPhone = this._validate(
    //   'phone_number',
    //   values.phone_number,
    //   validatePhoneNumber,
    // );
    const errorCondition = validFirstName
      && validLastName
      && validPosition;
      // && appState.user.phone_number ? validPhone : true;
      // && validEmail;

    if (errorCondition) {
      return true;
    }

    return false;
  };

  _validate = (key, text, validationFn) => {
    if (validationFn(text)) {
      this._clearErrors(key);
      return true;
    }

    this._setError(key, this.errorMessages[key]);
    return false;
  };

  _clearErrors = (key) => {
    const { errors } = this.state;

    delete errors[key];
    this.setState(errors);
  };

  _setError = (key, error) => {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [key]: error,
      },
    }));
  };

  _handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        ...{ [name]: value },
      },
    }));
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const { _setAppState, appState } = this.props;

    if (this._isValid()) {
      const userId = appState.user.user_id;
      const currentUserPrivilegeId = appState.setupActions.meta.current_user_privilege.id;
      const user = {
        first_name: values.first_name,
        last_name: values.last_name,
        position: values.position,
      };

      updateProfile(userId, currentUserPrivilegeId, user)
        .then(() => {
          this.setState({
            errors: {},
            submitted: true,
          });

          // Update AppState with new user profile
          _setAppState({
            key: 'user',
            statement: {
              first_name: { $set: values.first_name },
              last_name: { $set: values.last_name },
              position: { $set: values.position },
            },
          });

          // Set cookie manually until we sort out syncing on page refresh
          setProfileCookie({
            first_name: values.first_name,
            last_name: values.last_name,
            position: values.position,
          });
        })
        .catch((error) => {
          apiCallError(error);
        });
    }
  };

  _handlePhotoSubmit = (imgData) => {
    const { _setAppState, appState } = this.props;
    const userId = appState.user.user_id;
    const user = {
      profile_image_attributes: {
        image_name: 'user_profile',
        avatar_base: imgData,
      },
    };

    updateUserAttributes(userId, user)
      .then(({ data }) => {
        const imgObj = data.user.profile_image;

        // Update AppState with new user profile
        _setAppState({
          key: 'user',
          statement: {
            profile_image: { $set: imgObj },
          },
        });

        // Set cookie manually until we sort out syncing on page refresh
        setProfilePhotoCookie(imgObj);

        // Clear errors
        this._clearErrors('profileImage');
      })
      .catch(() => {
        this._setError('profileImage', true);
      });
  }


  _onErrorHandler = () => {
    this.profileImgRef.current.src = '/icons/default-profile-thumb.svg';
  }

  renderEmployeeId = (id) => {
    const { intl, } = this.props;
    return (
       <ProfileInputText
          icon={businessCardIcon}
          name="employeeId"
          placeholder={intl.formatMessage(messages.employeeIdPlaceholder)}
          inputValue={id}
          disabled={true}
        />
    );
  }

  render () {
    const { errors, submitted, values, } = this.state;
    const { appState, intl, } = this.props;
    const successMessageClasses = classNames({
      'profile-page__submit-message': true,
      'profile-page__submit-message--error': !isEmpty(errors),
    });
    const successMessage = (
      <div className={successMessageClasses}>
        {isEmpty(errors)
          ? intl.formatMessage(messages.profileUpdated)
          : intl.formatMessage(messages.profileError)}
      </div>
    );

    const badgeId = values.userPrivileges && values.userPrivileges.badge_id ? values.userPrivileges.badge_id : '';

    return (
       <div className="profile-page__wrapper profile-page__wrapper--white-bg">
        <form className="profile-page__form" onSubmit={this._handleSubmit}>
          <div className="index-page__row">
            <div className="profile-page__photo-col">
              <ProfileImageEditor
                _submitImgHandler={this._handlePhotoSubmit}
                imgSrc={appState.user.profile_image ? appState.user.profile_image.full_url : null}
                error={errors.profileImage}
                locale={appState.locale}
              />
            </div>
            <div className="profile-page__info-col">
              <div className="profile-page__info-group">
                <ProfileInputText
                  _changeHandler={this._handleChange}
                  error={errors.first_name}
                  icon={userIconSvg}
                  name="first_name"
                  placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
                  inputValue={values.first_name}
                />
                <ProfileInputText
                  _changeHandler={this._handleChange}
                  error={errors.last_name}
                  icon={userIconSvg}
                  name="last_name"
                  placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                  inputValue={values.last_name}
                />
                <ProfileInputText
                  _changeHandler={this._handleChange}
                  error={errors.position}
                  icon={businessCardIcon}
                  name="position"
                  placeholder={intl.formatMessage(messages.jobTitlePlaceholder)}
                  inputValue={values.position}
                />
                {values.locationSettings.cfg__badge_id === 1 && this.renderEmployeeId(badgeId)}
                {values.locationSettings.cfg__skill === 1 && <ProfileSkills
                  icon={skillIcon}
                  locationSkills={values.locationSkills}
                  user={values.user}
                  placeholder={intl.formatMessage(messages.skillsPlaceholder)}
                />}
                {values.locationSettings.cfg__departments === 1 && <ProfileDepartment
                  icon={departmentsIcon}
                  user={values.user}
                  placeholder={intl.formatMessage(messages.departmentsPlaceholder)}
                />}
              </div>
              {appState.user && appState.user.email ? (
                <div className="profile-page__info-group">
                  <h3 className="profile-page__info-label">
                    <FormattedMessage
                      id="ProfilePage.emailHeader"
                      defaultMessage="Email Login"
                    />
                  </h3>
                  <p className="profile-page__info-details">
                    <FormattedMessage
                      id="ProfilePage.emailDescription"
                      defaultMessage="You can log in to the Shyft web app using this email and your password."
                    />
                  </p>
                  <ProfileInputText
                    _changeHandler={this._handleChange}
                    error={errors.email}
                    icon={envelopePhoneIcon}
                    name="email"
                    placeholder={intl.formatMessage(messages.emailPlaceholder)}
                    inputValue={values.email}
                    disabled
                    verifiedStatus="verified"
                  />
                </div>
              ) : null}
              {appState.user.phone_number && appState.user.phone_number !== 'NC:' && appState.user.phone_number !== 'null' ? (
                <div className="profile-page__info-group">
                  <h3 className="profile-page__info-label">
                    <FormattedMessage
                      id="ProfilePage.phoneNumberHeader"
                      defaultMessage="Mobile Login"
                    />
                  </h3>
                  <p className="profile-page__info-details">
                    <FormattedMessage
                      id="ProfilePage.phoneNumberDescription"
                      defaultMessage="You can log in to the Shyft mobile app using this phone number."
                    />
                  </p>
                  <ProfileInputText
                    _changeHandler={this._handleChange}
                    error={errors.phone_number}
                    icon={phoneIconSvg}
                    name="phone_number"
                    placeholder={intl.formatMessage(messages.phoneNumberPlaceholder)}
                    inputValue={values.phone_number}
                    disabled
                    verifiedStatus="verified"
                  />
                </div>
              ) : null}
              <Link
                className="profile-page__reset-password-link"
                to="/password-reset"
              >
                <FormattedMessage
                  id="ProfilePage.resetPasswordLink"
                  defaultMessage="Reset Password"
                />
              </Link>
              {submitted ? successMessage : null}
              <SubmitButton
                clickHandler={this._handleSubmit}
                isDisabled={false}
              >
                <FormattedMessage
                  id="ProfilePage.submitButton"
                  defaultMessage="Save Profile"
                />
              </SubmitButton>
            </div>
          </div>
        </form>
      </div>
    );

  }
}


ProfilePage.propTypes = {
  _setAppState: PropTypes.func.isRequired,
  appState: PropTypes.shape({}).isRequired,
};

export default injectIntl (ProfilePage);



