import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
//import { injectIntl } from "react-intl";
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { ProfileThumb } from "@shyft/acorns";
import { formatTimeElapsed } from "_helpers/date-time";
import { createFullName } from "_helpers/formatting";
import { ModalTypes } from '_constants';

import Styled from "../styled";
import "./Comment.scss";

/*
const CommentI18n = ({ comment, sub, intl: { formatMessage } }) => {
  const parentClasses = classNames({
    comment: true,
    "comment--sub": sub
  });

  let avatarUrl = null;

  if(comment && comment.owner) {
    if(comment.owner.profile_image) {
      avatarUrl = comment.owner.profile_image.thumb_url;
    }else {
      if(comment.owner.full_url) {
        avatarUrl = comment.owner.full_url;
      }
    }
  }

  return (
    <div className={parentClasses}>
      <Styled.ThumbWrap>
        <ProfileThumb
          thumbUrl={avatarUrl}
        />
      </Styled.ThumbWrap>
      <div className="comment__content">
        <p className="comment__username">{createFullName(comment.owner)}</p>
        <p className="comment__time">
          {formatTimeElapsed(comment.created_at, formatMessage)}
        </p>
        <p className="comment__text">{comment.content ? comment.content : comment.default}</p>
      </div>
    </div>
  );
};

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
CommentI18n.propTypes = {
  comment: PropTypes.shape({}).isRequired,
  sub: PropTypes.bool,
  intl: intlShape.isRequired
};

CommentI18n.defaultProps = {
  sub: false
};

const Comment = injectIntl(CommentI18n);

export { Comment };

*/

class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      myComment: false,
      showDeleteWin: false,
    }
    this._isMounted = false;
  }

  componentDidMount () {
    const { comment, sub, userCookie, _toggleModal, } = this.props;
    this._isMounted = true;
  }

  componentDidUpdate (prevProps, prevState) {
    const { comment, sub, userCookie, } = this.props;

  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  handleMouseEnter = () => {
    const { comment, sub, userCookie, } = this.props;
    this.setState({ hovered: true });
    if(comment && comment.owner) {
      if(userCookie) {
        if(comment.owner.first_name === userCookie.first_name && comment.owner.last_name === userCookie.last_name) {
          this.setState({myComment: true});
        }
      }
    }
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  handleDelete = () => {
    const { comment, handleShowDeleteWin, } = this.props;
    this.setState({showDeleteWin: true});
    handleShowDeleteWin(true, comment.id);
  }

  render () {
    const { comment, sub, intl, _toggleModal, handleShowDeleteWin, } = this.props;
    const { myComment, hovered, showDeleteWin, } = this.state;

    const parentClasses = classNames({
      comment: true,
      "comment--sub": sub,
      "comment--sub__my": sub && myComment
    });
    
    let avatarUrl = null;
    if(comment && comment.owner) {
      if(comment.owner.profile_image) {
        avatarUrl = comment.owner.profile_image.thumb_url;
      }else {
        if(comment.owner.full_url) {
          avatarUrl = comment.owner.full_url;
        }
      }
    }
    
    return (
      <div className={parentClasses} 
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}>
        <Styled.ThumbWrap>
          <ProfileThumb
            thumbUrl={avatarUrl}
          />
        </Styled.ThumbWrap>
        <div className="comment__content">
          <p className="comment__username">{createFullName(comment.owner)}</p>
          <p className="comment__time">
            {formatTimeElapsed(comment.created_at, intl.formatMessage)}
          </p>
          <p className="comment__text">{comment.content ? comment.content : comment.default}</p>
        </div>
        {myComment && hovered && <Styled.DeleteButton onClick={this.handleDelete}></Styled.DeleteButton>}
      </div>
    );
  }
}

Comment.propTypes = {
  comment: PropTypes.shape({}).isRequired,
  sub: PropTypes.bool,
  //intl: intlShape.isRequired
};

Comment.defaultProps = {
  sub: false
};

export default injectIntl(Comment);


