import { createReducer, createAction } from '@reduxjs/toolkit';

// import all reducers from individual ducks folders

// mock actions and reducers for sake of development. please delete
const increment = createAction('increment');
const decrement = createAction('decrement');

const counterReducer = createReducer(0, {
  [increment]: (state, action) => state + action.payload,
  [decrement]: (state, action) => state - action.payload,
});

const rootReducer = {
  // Create initial state from imported reducers
  counter: counterReducer,
};

export default rootReducer;
