import Cookies from 'js-cookie';

import { isEmptyCookie } from '_helpers/formatting';
import { getBrowserLanguage } from '_helpers/localization';
import { COOKIE_DOMAIN, API_SERVER_VERSION } from '_constants';


export const authHeaders = {
  5: [
    'uid',
    'client',
    'expiry',
    'token-type',
    'access-token',
  ],
  6: ['session-uukey'],
};

const possibleHeaders = authHeaders[API_SERVER_VERSION].concat([
  'user_id',
  'location_id',
  'first_name',
  'last_name',
  'profile_image',
  'time_zone',
  'email',
  'is_admin',
  'phone_number',
  'position',
  'locale',
  'uid',
  'access-token',
  'client',
  'login_url',
]);

function getCookieDomain() {
  return COOKIE_DOMAIN || window.location.hostname;
}
const getDomainName = () => window.location.hostname;
const defaultCookieAttr = {
  expires: 365,
  domain: getCookieDomain(),
  secure: !!COOKIE_DOMAIN,
};
const domainCookieAttr = { domain: getCookieDomain() };

/*
  Internal API
*/

const setCookie = (cookieName, cookieVal) => {
  Cookies.set(cookieName, cookieVal, defaultCookieAttr);
};

const getCookie = cookieName => Cookies.get(cookieName);

const removeCookie = (cookieName) => {
  Cookies.remove(cookieName, domainCookieAttr);
};

const getNumberCookie = cookieName => (
  parseInt(getCookie(cookieName), 10)
);

const getEmptyStringCookie = (cookieName) => {
  const cookieVal = getCookie(cookieName);

  return isEmptyCookie(cookieVal) ? '' : cookieVal;
};

const getJsonCookie = (cookieName) => {
  const cookieVal = getCookie(cookieName);

  return isEmptyCookie(cookieVal) ? '' : JSON.parse(cookieVal);
};

const getNullCookie = (cookieName) => {
  const cookieVal = getCookie(cookieName);

  return isEmptyCookie(cookieVal) ? null : cookieVal;
};


/*
  External API
*/
export const getLocaleCookie = () => getNullCookie('locale');


export const setUserCookie = ({
  id,
  email,
  first_name,
  last_name,
  profile_image,
  recent_user_privilege,
  locale,
}) => {
  setCookie('user_id', id);
  setCookie('email', email);
  setCookie('first_name', first_name);
  setCookie('last_name', last_name);
  setCookie('profile_image', profile_image);
  setCookie('locale', locale);

  if (recent_user_privilege) {
    setCookie('phone_number', recent_user_privilege.phone_number);
    setCookie('time_zone', recent_user_privilege.location.time_zone);
    setCookie('location_id', recent_user_privilege.location_id);
    setCookie('is_admin', recent_user_privilege.is_admin);
    setCookie('position', recent_user_privilege.position);
  }
};

export const getUserCookie = () => ({
  user_id: getNumberCookie('user_id'),
  email: getEmptyStringCookie('email'),
  first_name: getCookie('first_name'),
  last_name: getCookie('last_name'),
  profile_image: getJsonCookie('profile_image'),
  location_id: getEmptyStringCookie('location_id'),
  time_zone: getEmptyStringCookie('time_zone'),
  is_admin: getNullCookie('is_admin'),
  position: getEmptyStringCookie('position'),
  phone_number: getCookie('phone_number'),
});

export const purgeAuthenticationCookie = () => {
  possibleHeaders.forEach((key) => {
    removeCookie(key);
  });
};

export const setAuthenticationCookie = (headers) => {
  Object.keys(headers).forEach((key) => {
    setCookie(key, headers[key]);
  });
};

export const checkForAuthentication = () => authHeaders[API_SERVER_VERSION].every(key => getCookie(key));

export const createHeadersForApiCall = () => {
  const headerObject = { Platform: 'web', Locale: getLocaleCookie() };

  authHeaders[API_SERVER_VERSION].forEach((key) => {
    headerObject[key] = getCookie(key);
  });

  return headerObject;
};

export const checkForLocations = () => getCookie('location_id');

export const getLoginURL = () => getCookie('login_url');

export const setLocationCookie = ({ id, time_zone }) => {
  setCookie('location_id', id);
  setCookie('time_zone', time_zone);
};

export const setLoginURL = () => {
  removeCookie('login_url');
  setCookie('login_url', getDomainName());
};

export const setProfileCookie = ({
  first_name,
  last_name,
  position,
}) => {
  setCookie('first_name', first_name);
  setCookie('last_name', last_name);
  setCookie('position', position);
};

export const setProfilePhotoCookie = (imgObj) => {
  setCookie('profile_image', JSON.stringify(imgObj));
};

export const setLocaleCookie = (locale) => {
  setCookie('locale', locale === undefined || locale === 'undefined' ? getBrowserLanguage() : locale);
};


export const getLocationCode = () => getCookie('location_id');

export const setUserCookieProfileImage = (profile_image) => {
  setCookie('profile_image', profile_image);
};
