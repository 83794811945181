import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { ConfirmShiftTemplate } from '_components/ModalComponents/_templates/ConfirmShiftTemplate';
import { CloseModal } from '_components/ModalComponents/CloseModal';
import { CommentsSection } from '_components/GeneralComponents/Comments';
import { ModalTypes, shiftStatuses, shiftOwner, shiftStatus, shiftNames } from "_constants";
import { StatusIcon, StatusText, } from "_components/GeneralComponents/ShiftStatus";
import { ModalWrapper } from '../ModalWrapper';
import { getLocations, } from '_helpers/api-calls';
import './ConfirmShift.scss';

class ConfirmShift extends React.Component {
  constructor(props) {
    super(props);

    const { intl: { formatMessage } } = this.props;

    this.state = {
      timer: null,
      findCallOffReason1: false,
      findCallOffReason2: false,
      enableCallOff: false,
      shiftLocation: null,
      makeUpChildCard: false,
    };

    this.messages = defineMessages({
      to: {
        id: 'ConfirmShift.to',
        defaultMessage: 'to',
      },
      
    });

    this._isMounted = false;
  }
  componentDidMount () {
    const {shift, costCenterData, locationSettings, selectedShift, locationSkills, _toggleModal, userPrivileges, 
          locationChannels, userCookie, } = this.props;

    this._isMounted = true;
    let myTimer = setInterval(this.tick, 3000); //unit: ms, 3000 = 3 seconds
    this.setState({timer: myTimer});
    if(selectedShift && !selectedShift.location) {
       getLocations(selectedShift.owner.id).then((response) => {
        const idx = response.data.locations.findIndex((loc) => loc.id === selectedShift.location_id);
        if(idx >= 0) {
          this.setState({shiftLocation: response.data.locations[idx]});
        }
      }).catch((error) => {
        const msg = `ConfirmShift.componentDidMount: failed on calling getLocations`;
        console.log(msg);
      });
    }

    if(selectedShift) {
      if(selectedShift.channel_id) {
        if(locationChannels && locationChannels.length > 0) {
          let findChannel = false;
          for(let i=0; i<locationChannels.length; i++) {
            if(locationChannels[i].id === selectedShift.channel_id) {
              findChannel = true;
              break;
            }
          }
          if(!findChannel) {
            if(selectedShift.owner.id !== userCookie.user_id) {
              this.setState({makeUpChildCard : true});
            }
          }
        }
      }
    }
  }

  componentDidUpdate (prevProps) {

  }

  componentWillUnmount () {
    this._isMounted = false;
    clearInterval(this.state.timer);
  }

  tick = () => {
    const {selectedShift, costCenterData, locationSettings } = this.props;

    if(costCenterData){
      for(let i=0; i< costCenterData.tag_groups.length; i++){
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_1'){
          this.setState({findCallOffReason1: true})
        }
        if(costCenterData.tag_groups[i].group_type === 'call_off_reasons_2'){
          this.setState({findCallOffReason2: true})
        }
      }
    }

    //check if the shift expired every 100 seconds (1 minutes 40 seconds)
    const startDateTime = moment(selectedShift.start_at);
    const endDateTime = moment(selectedShift.end_at);
    let current = moment();
    let startToNow = moment.duration(startDateTime.diff(current));
    let endToNow = moment.duration(endDateTime.diff(current));
    let minutesStartToNow = parseInt(startToNow.asMinutes());
    let minutesEndToNow = parseInt(endToNow.asMinutes());
    //if the shift starts within 24 hours and the shift has not ended yet
    //the shift can be called off
    if(locationSettings && locationSettings.cfg__allow__call_off) {
      if(selectedShift.trade_status === 'inventory') {
        if(this.state.findCallOffReason1){
          if(minutesStartToNow < 1440 && minutesEndToNow > 0){
            this.setState({enableCallOff: true});
          }
        }
      }
    }
  };

  handleCallOffClick = (event) => {
    const {
      selectedShift,
      userCookie,
      _toggleModal,
      _updateSelectedShift,
    } = this.props;
    //because this button is on the shift card, both the button and the card get clicked. 
    //The stopPropagation() method allows you to prevent propagation of the current event.
    event.stopPropagation();
    // important: parameter 'selectedShift' has to be there to get 'selectedShift' in CallOffShift
    _toggleModal(ModalTypes.CALL_OFF_SHIFT, selectedShift);
    event.preventDefault()
  };

  renderHeader = () => {
    const { selectedShift, userCookie, } = this.props;
    const owner = shiftOwner(selectedShift, userCookie);
    let headerText = '';
    switch (owner) {
      case shiftStatuses.YOUR_SHIFT:
        /*
        headerText = (
          <FormattedMessage
            id="ConfirmShift.yourShift"
            defaultMessage="Your Shift"
          />
        );
        */
        headerText = (
          <FormattedMessage
            id="ConfirmShift.scheduledShift"
            defaultMessage="Scheduled Shift"
          />
        );
        break;

      case shiftStatuses.COVERING_SHIFT:
        headerText = (
          <FormattedMessage
            id="ConfirmShift.yourCoveringShift"
            defaultMessage="Your Covering Shift"
          />
        );
        break;
      case shiftStatuses.COVERING_REJECTED:
        headerText = (
          <FormattedMessage
            id="ConfirmShift.yourCoveringShift"
            defaultMessage="Your Covering Shift"
          />
        );
        break;
      case shiftStatuses.COVERING_VTO:
      case shiftStatuses.YOUR_VTO:
        headerText = (
          <FormattedMessage
            id="ConfirmShift.vtoModalTitle"
            defaultMessage="Voluntary Time Off"
          />
        );
        break;

      default:
        break;
    }

    if(selectedShift && selectedShift.name === 'shift') {
      if(selectedShift.trade_status === 'approved' && selectedShift.owner.id === userCookie.user_id) {
        /*
        headerText = (
          <FormattedMessage
            id="ConfirmShift.transferred1"
            defaultMessage="Transferred Shift"
          />
        );
        */
        headerText = (
          <FormattedMessage
            id="ConfirmShift.scheduledShift"
            defaultMessage="Scheduled Shift"
          />
        );
      }else if(selectedShift.trade_status === 'approved' && selectedShift.owner.id !== userCookie.user_id) {
         headerText = (
          <FormattedMessage
            id="ConfirmShift.teamShift"
            defaultMessage="Team Shift"
          />
        );
      }
    }

    return headerText;
  };

  renderType = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const teamShift = (
              <FormattedMessage
                id="ConfirmShift.teamShift"
                defaultMessage="Team Shift"
              />);
     const openShift = (
              <FormattedMessage
                id="ConfirmShift.openShift"
                defaultMessage="Open Shift"
              />);
     const vto = (
              <FormattedMessage
                id="ConfirmShift.vto"
                defaultMessage="Voluntary Time Off"
              />);

    let typeName = '';
    switch(selectedShift.name) {
      case 'shift':
        typeName = teamShift;
        break;
      case 'open_shift':
        typeName = openShift;
        break;
      case 'shift_vto':
        typeName = vto;
        break;
    }
    return typeName;
  }

  renderJobTitle = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    return selectedShift && selectedShift.primary_job ?  selectedShift.primary_job : '--';
  }

  renderDate = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    const startDate = moment(selectedShift.start_at).format('ddd, MMM, DD');
    return startDate;
  }

  renderTime = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, intl } = this.props;

    const startTime = moment(selectedShift.start_at).format('h:mm A');
    const endTime = moment(selectedShift.end_at).format('h:mm A');
    const timeRange = `${startTime} ${intl.formatMessage(this.messages.to)} ${endTime}`;
    return timeRange;
  }

  renderOwner = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, intl } = this.props;

    const ownerName = selectedShift && selectedShift.owner ? `${selectedShift.owner.first_name} ${selectedShift.owner.last_name}` : '';
    return ownerName;
  }

  renderButtonText = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;

    if (selectedShift.name === "shift_vto") {
      return (
        <FormattedMessage id="CoverComments2.takeVTO" defaultMessage="TAKE VTO" />
      );
    }

    return (
      <FormattedMessage id="CoverComments2.coverThisShift" defaultMessage="Cover This Shift"/>
    );
  }

  renderTotalShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    const { makeUpChildCard } = this.state;
    if(selectedShift.name === 'shift') {
      return '1';
    }else {
      if(selectedShift.parent_card) {
        if(makeUpChildCard) {
          return '1';
        }else {
          return selectedShift && selectedShift.total_shifts_available ? selectedShift.total_shifts_available : ' ';
        }
      }else {
        return '1'; 
      }
    }
  }

  renderAppliedShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    const { makeUpChildCard } = this.state;
    if(selectedShift) {
      if(selectedShift.current_applicants_pending_user_ids) {
        if(makeUpChildCard) {
          return '0';
        }else {
          //return selectedShift.current_applicants_pending_user_ids.length;
          //Fixed Bug WS-1833: applied applicants should be all applicants.
          const totalApplicants = selectedShift.current_applicants_pending_user_ids.length 
                                  + selectedShift.current_applicants_approved_user_ids.length
                                  + selectedShift.current_applicants_denied_user_ids.length
                                  + selectedShift.current_applicants_nominated_user_ids.length;
          return totalApplicants;
        }
      }else {
        return '0';
      }
    }
  }

  renderApprovedShifts = () => {
    const { _toggleScreen, userCookie, selectedShift, locations } = this.props;
    if(selectedShift) {
      if(selectedShift.current_applicants_approved_user_ids) {
        return selectedShift.current_applicants_approved_user_ids.length;
      }else {
        if(selectedShift.available_shifts_covered) {
          return selectedShift.available_shifts_covered;
        }else {
          return '0';
        }
      }
    }
  };

  renderLocation = () => {
    const { _toggleScreen, userCookie, selectedShift, locations, } = this.props;
    const { shiftLocation, } = this.state;
    if(selectedShift && selectedShift.location) {
       return selectedShift.location.formatted_address;
    }else {
      if(shiftLocation) {
        return shiftLocation.formatted_address;
      }
    }
    //return  selectedShift && selectedShift.location ? selectedShift.location.formatted_address : '';
  };

  renderStatus = () => {
    const { selectedShift, userCookie } = this.props;
    const status = shiftStatus(selectedShift, userCookie);
    const statusText =
      status === shiftStatuses.AVAILABLE ? (
        <FormattedMessage
          id="ConfirmShift.available"
          defaultMessage="Available"
        />
      ) : (
        <StatusText selectedShift={selectedShift} userCookie={userCookie} />
      );

    return (
      <div className="">
        <div className="confirm-shift__item-wrap">
          <label className="confirm-shift__item-name">
            <FormattedMessage
                id="ConfirmShift.status"
                defaultMessage="Status"
              />
          </label>
          <p className="modal-components__confirm-details modal-components__confirm-details--date-time">
            <StatusIcon
              className="confirm-shift-template__status-icon"
              selectedShift={selectedShift}
              userCookie={userCookie}
            />
            <label className="confirm-shift__item-value">{statusText}</label>
          </p>
        </div>
      </div>
    );
  };

  

  renderShiftSkills = () => {
    const { selectedShift, locationSkills, } = this.props;
    let shiftSkills = [];
    if(selectedShift && selectedShift.skill_ids && selectedShift.skill_ids.length > 0) {
      if(locationSkills && locationSkills.length > 0) {
        for(let i=0; i<selectedShift.skill_ids.length; i++) {
          for(let j=0; j<locationSkills.length; j++) {
            if(selectedShift.skill_ids[i] === locationSkills[j].id) {
              shiftSkills.push(locationSkills[j])
            }
          }
        }
      }
    }
    if(shiftSkills.length > 0) {
      const skills = shiftSkills.map((skill) => (
        <label className="cover-comments2__skill">
          {skill.skill_content}
        </label>
      ));
      if(skills && skills[0]) {
        return skills;
      }else {
        return null;
      }
    }else {
      return (
        <label className="cover-comments2__skill">
          <FormattedMessage
            id="ConfirmShift.noSkillAssigned1"
            defaultMessage="No skills assigned to this shift"
          />
        </label>
      );
    }
  }

  render() {
    const {
      _toggleModal, _setAppState, selectedShift, userCookie, shifts, locationSettings,
    } = this.props;
    const owner = shiftOwner(selectedShift, userCookie);
    //let headerText = '';
    let notCovered = false;
    let isInventory = false;
    let allowDelete = false;
    let allowCancel = false;
    let myCoveringShift = false;
    let myCoveringShift2 = false;
    let myNoCancelDeleteShift2 = false;

    if(selectedShift && userCookie) {
      const shift = selectedShift;
      notCovered = shift.coverer === null || shift.approver === null;
      isInventory = shift.name === 'inventory';
      allowDelete = !isInventory && shift.allow_delete &&  shift.owner.id === userCookie.user_id;
      allowCancel = shift.allow_cancel && shift.owner.id === userCookie.user_id;
      myCoveringShift = shift.coverer !== null && shift.coverer.id === userCookie.user_id;
      myCoveringShift2 = shift.cover_mode === 'multi_applicants_picked_by_manager' && shift.current_applicant_status !== null
      myNoCancelDeleteShift2 = shift.cover_mode === 'multi_applicants_picked_by_manager' &&
                                !shift.allow_cancel &&
                                !shift.allow_delete &&
                                shift.owner.id === userCookie.user_id &&
                                shift.trade_status === 'posted' &&
                                !shift.current_applicant_status;
    }

    const oldDeleteBtnClassName = 'modal-components__submit-btn modal-components__submit-btn--delete';
    const newDeleteBtnClassName = 'confirm-shift__btn-delete';
    const oldCancelBtnClassName = 'modal-components__submit-btn modal-components__submit-btn--delete';
    const newCancelBtnClassName = 'confirm-shift__btn-cancel';
    const oldButtonWrapClassName = 'modal-components__col modal-components__col--submit';

    const showSkillSection = locationSettings.cfg__skill === 1 && selectedShift.name !== 'shift';

    const deleteRow = (
      <div className="modal-components__row">
        <div className={oldButtonWrapClassName}>
          <button
            className={newDeleteBtnClassName}
            onClick={() => {
              _toggleModal(ModalTypes.DELETE_SHIFT, selectedShift.id);
            }}
            type="button"
          >
            {owner === shiftStatuses.YOUR_VTO ? (
              <FormattedMessage
                id="ConfirmShift.deleteVTO1"
                defaultMessage="Delete VTO"
              />
            ) : (
              <FormattedMessage
                id="ConfirmShift.deleteShift1"
                defaultMessage="Delete Shift"
              />
            )}
          </button>
        </div>
      </div>
    );

     const cancelShift = (
      <div className="modal-components__row">
        <div className={oldButtonWrapClassName}>
          <button
            className={newCancelBtnClassName}
            onClick={() => {
              _toggleModal(ModalTypes.CANCEL_SHIFT, selectedShift.id);
            }}
            type="button"
          >
            {owner === shiftStatuses.YOUR_VTO ? (
              <FormattedMessage
                id="ConfirmShift.cancelVTO1"
                defaultMessage="Cancel VTO Post"
              />
            ) : (
              <FormattedMessage
                id="ConfirmShift.cancelShift1"
                defaultMessage="Cancel Shift Post"
              />
            )}
          </button>
        </div>
      </div>
    );

    const callOffShift = (
      <div className="modal-components__row">
        <div className={oldButtonWrapClassName}>
          <button
            className={newCancelBtnClassName}
            onClick={this.handleCallOffClick}
            type="button"
          >
            <FormattedMessage
                id="ConfirmShift.callOffShift"
                defaultMessage="Call Off Shift"
              />
          </button>
        </div>
      </div>
    );

    const postRow = (
      <div className="modal-components__row">
        <div className="modal-components__col modal-components__col--submit">
          <button
            className="modal-components__submit-btn modal-components__submit-btn--active"
            onClick={() => {
              _toggleModal(ModalTypes.POST_SHIFT, selectedShift.id);
            }}
            type="button"
          >
            <FormattedMessage
              id="ConfirmShift.postShift"
              defaultMessage="Post this Shift"
            />
          </button>
        </div>
      </div>
    );

    return (
      <div className="confirm-shift">
        <ModalWrapper>
          <CloseModal _toggleModal={_toggleModal} dark />
          <div className="modal-components__container">
            <h1>{this.renderHeader()}</h1>
            {/*<ConfirmShiftTemplate
              {...this.props}
              shifts={shifts}
              selectedShift={selectedShift}
              userCookie={userCookie}
              _setAppState={_setAppState}
            />*/}
            <div className="confirm-shift__top-row">
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.type"
                      defaultMessage="Type"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderType()}
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.jobTitle"
                      defaultMessage="Job Title"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderJobTitle()}
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.date"
                      defaultMessage="Date"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderDate()}
                </label>
              </div>
               <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.time"
                      defaultMessage="Time"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderTime()}
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.postedBy"
                      defaultMessage="Posted By"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderOwner()}
                </label>
              </div>
            </div>
            <div className="confirm-shift__top-row">
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.shifts"
                      defaultMessage="Shifts"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderTotalShifts()}
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.applied"
                      defaultMessage="Applied"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderAppliedShifts()}
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.approved"
                      defaultMessage="Approved"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderApprovedShifts()}
                </label>
              </div>
              <div className="confirm-shift__item-wrap">
                <label className="cover-comments2__item-name">
                  <FormattedMessage
                      id="ConfirmShift.location"  
                      defaultMessage="Location"
                    />
                </label>
                <label className="confirm-shift__item-value">
                  {this.renderLocation()}
                </label>
              </div>
            </div>
            {showSkillSection && <div className="confirm-shift__top-row">
              <div className="confirm-shift__item-wrap">
                <label className="confirm-shift__item-name">
                  <FormattedMessage
                      id="ConfirmShift.shiftSkills"
                      defaultMessage="Skills"
                    />
                </label>
                <div className="cover-comments2__skill-wrap">
                  {this.renderShiftSkills()}
                </div>
              </div>
            </div>}
            <div className="confirm-shift__top-row">
              {this.renderStatus()}
            </div>
            {selectedShift.name !== 'shift_inventory' ? (
              <CommentsSection
                selectedShift={selectedShift}
                size="small"
                userCookie={userCookie}
                locationSettings={locationSettings}
                _toggleModal={_toggleModal}
              />
            ) : postRow}
            {selectedShift.allow_cancel
              && selectedShift.owner.id === userCookie.user_id
              ? cancelShift
              : null}
            {/*((selectedShift && selectedShift.trade_status === 'inventory') && 
              this.state.enableCallOff) && callOffShift*/}
            {selectedShift.allow_delete
              && selectedShift.owner.id === userCookie.user_id
              ? deleteRow
              : null}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

ConfirmShift.propTypes = {
  _toggleModal: PropTypes.func.isRequired,
  _setAppState: PropTypes.func.isRequired,
  selectedShift: PropTypes.shape({}),
  userCookie: PropTypes.shape({}).isRequired,
  shifts: PropTypes.shape([]).isRequired,
};

ConfirmShift.defaultProps = {
  selectedShift: {},
};

//export { ConfirmShift };
export default injectIntl (ConfirmShift);

